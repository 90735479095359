import React, { useEffect, useState } from 'react';
import { useForm, useFieldArray, Controller } from 'react-hook-form';
import { motion } from 'framer-motion';
import { Input } from '../components/ui/Input';
import { Textarea } from '../components/ui/Textarea';
import { Button } from '../components/ui/Button';
import { Card, CardHeader, CardContent } from '../components/ui/Card';
import { Tooltip, TooltipContent, TooltipProvider, TooltipTrigger } from '../components/ui/Tooltip';
import useAISuggestions from '../components/useAISuggestions';
import { Sparkles, Bot, AlertTriangle } from 'lucide-react';

const RiskAssessment = ({ data, updateData, setStepValidity }) => {
  const {
    control,
    handleSubmit,
    formState: { errors, isValid },
    watch,
  } = useForm({
    defaultValues: data,
    mode: 'onChange',
  });

  const { fields, append, remove } = useFieldArray({
    control,
    name: 'risks',
  });

  const { aiSuggestions, updateAISuggestions, isLoading, error } = useAISuggestions();
  const [aiGeneratedRisks, setAiGeneratedRisks] = useState([]);

  const watchRisks = watch('risks');

  useEffect(() => {
    if (data) {
      updateAISuggestions(data);
    }
  }, [data, updateAISuggestions]);

  useEffect(() => {
    if (aiSuggestions && aiSuggestions.risks && fields.length === 0) {
      const newRisks = aiSuggestions.risks.map((risk) => ({
        name: risk.name || '',
        description: risk.description || '',
        impact: risk.impact || '',
        isAiGenerated: true,
      }));
      setAiGeneratedRisks(newRisks.map(risk => risk.name));
      newRisks.forEach(risk => append(risk));
    }
  }, [aiSuggestions, append, fields.length]);

  useEffect(() => {
    const isStepValid = isValid && watchRisks.length > 0 && watchRisks.some(risk => 
      risk.name && risk.description && risk.impact
    );
    setStepValidity(isStepValid);
  }, [isValid, watchRisks, setStepValidity]);

  const onSubmit = (formData) => {
    updateData(formData);
  };

  const fadeInUp = {
    initial: { opacity: 0, y: 20 },
    animate: { opacity: 1, y: 0 },
    transition: { duration: 0.5 },
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)} className="space-y-6">
      <motion.div
        initial="initial"
        animate="animate"
        variants={fadeInUp}
      >
        <h3 className="text-2xl font-semibold mb-4 flex items-center">
          Risk Assessment
          <Sparkles className="ml-2 text-yellow-500" />
        </h3>

        {isLoading && (
          <div className="flex items-center justify-center space-x-2 text-blue-500">
            <Bot className="animate-pulse" />
            <p>AI is generating risk suggestions...</p>
          </div>
        )}
        {error && (
          <div className="flex items-center space-x-2 text-red-500">
            <AlertTriangle />
            <p>Error: {error}</p>
          </div>
        )}

        {fields.map((field, index) => (
          <motion.div
            key={field.id}
            variants={fadeInUp}
            className="mb-4"
          >
            <Card className={`${aiGeneratedRisks.includes(field.name) ? 'border-blue-300 bg-blue-50' : ''}`}>
              <CardHeader className="flex flex-row items-center justify-between space-y-0 pb-2">
                <h4 className="text-sm font-medium">Risk {index + 1}</h4>
                {aiGeneratedRisks.includes(field.name) && (
                  <TooltipProvider>
                    <Tooltip>
                      <TooltipTrigger>
                        <Bot className="text-blue-500" />
                      </TooltipTrigger>
                      <TooltipContent>
                        <p>AI-generated risk based on your audit scope</p>
                      </TooltipContent>
                    </Tooltip>
                  </TooltipProvider>
                )}
              </CardHeader>
              <CardContent>
                <Controller
                  name={`risks.${index}.name`}
                  control={control}
                  rules={{ required: 'Risk name is required' }}
                  render={({ field }) => (
                    <Input
                      {...field}
                      placeholder="Risk Name"
                      className="mb-2"
                    />
                  )}
                />
                {errors.risks?.[index]?.name && (
                  <p className="text-red-500 text-sm mt-1">{errors.risks[index].name.message}</p>
                )}

                <Controller
                  name={`risks.${index}.description`}
                  control={control}
                  rules={{ required: 'Risk description is required' }}
                  render={({ field }) => (
                    <Textarea
                      {...field}
                      placeholder="Risk Description"
                      className="mb-2"
                    />
                  )}
                />
                {errors.risks?.[index]?.description && (
                  <p className="text-red-500 text-sm mt-1">{errors.risks[index].description.message}</p>
                )}

                <Controller
                  name={`risks.${index}.impact`}
                  control={control}
                  rules={{ required: 'Impact is required' }}
                  render={({ field }) => (
                    <select
                      {...field}
                      className="w-full border rounded-md p-2"
                    >
                      <option value="">Select Impact</option>
                      <option value="Low">Low</option>
                      <option value="Medium">Medium</option>
                      <option value="High">High</option>
                    </select>
                  )}
                />
                {errors.risks?.[index]?.impact && (
                  <p className="text-red-500 text-sm mt-1">{errors.risks[index].impact.message}</p>
                )}

                <Button
                  type="button"
                  onClick={() => remove(index)}
                  className="mt-2"
                  variant="destructive"
                >
                  Remove Risk
                </Button>
              </CardContent>
            </Card>
          </motion.div>
        ))}

        <Button
          type="button"
          onClick={() => append({ name: '', description: '', impact: '', isAiGenerated: false })}
          className="mt-4"
        >
          Add Risk
        </Button>

        <Button type="submit" className="mt-6">
          Save and Continue
        </Button>
      </motion.div>
    </form>
  );
};

export default RiskAssessment;