import React, { useState } from 'react';
import { Tab, TabGroup, TabList, TabPanel, TabPanels } from '@headlessui/react'
import { CheckCircle, XCircle, Download, ExternalLink } from 'lucide-react';

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

const LeadershipContent = () => {
  const [quizStarted, setQuizStarted] = useState(false);
  const [currentQuestion, setCurrentQuestion] = useState(0);
  const [score, setScore] = useState(0);
  const [showResults, setShowResults] = useState(false);

  const content = {
    overview: {
      introduction: "Leadership commitment is the cornerstone of an effective compliance management system. This section explores how top management's role in demonstrating leadership and commitment to the CMS shapes the organization's compliance culture.",
      keyPoints: [
        "Establishing and communicating a clear compliance policy",
        "Integrating compliance requirements into core business processes",
        "Providing adequate resources for implementing and maintaining the CMS",
        "Promoting continuous improvement and a speak-up culture",
        "Ensuring compliance objectives align with business strategy"
      ],
      importance: "Strong leadership in compliance sets the tone for the entire organization, fostering a culture of integrity and ethical behavior. It ensures that compliance is not just a box-ticking exercise, but a fundamental part of how the organization operates.",
      bestPractices: [
        "Conduct regular compliance briefings in executive meetings",
        "Clearly delegate compliance responsibilities across all levels",
        "Lead by example in adhering to compliance policies",
        "Incorporate compliance metrics in performance evaluations",
        "Engage in open dialogue about compliance challenges and successes",
        "Regularly review and update the compliance program"
      ]
    },
    questionnaire: [
      { id: 1, question: "Has top management demonstrated visible commitment to the CMS?", type: "yesNo" },
      { id: 2, question: "Is there a documented compliance policy appropriate to the organization?", type: "yesNo" },
      { id: 3, question: "Are compliance objectives established for relevant functions and levels?", type: "yesNo" },
      { id: 4, question: "Is compliance integrated into the organization's business processes?", type: "yesNo" },
      { id: 5, question: "How would you rate the resources allocated to compliance management?", type: "scale", min: 1, max: 5 },
      { id: 6, question: "Is there a process for employees to report compliance concerns without fear of retaliation?", type: "yesNo" },
      { id: 7, question: "Does leadership regularly communicate the importance of compliance to all employees?", type: "yesNo" }
    ],
    quiz: [
      {
        question: "Who is primarily responsible for demonstrating leadership and commitment to the CMS?",
        options: ["Top management", "Compliance officer", "Board of directors", "External auditor"],
        correctAnswer: 0
      },
      {
        question: "What is NOT typically a responsibility of leadership in compliance management?",
        options: ["Setting compliance policy", "Conducting detailed audits", "Allocating resources", "Promoting a compliance culture"],
        correctAnswer: 1
      },
      {
        question: "How often should leadership review the compliance management system?",
        options: ["Monthly", "Quarterly", "Annually", "Only when issues arise"],
        correctAnswer: 2
      },
      {
        question: "Which of the following is a key aspect of leadership's role in compliance?",
        options: ["Micromanaging compliance tasks", "Delegating all responsibility", "Leading by example", "Focusing solely on financial performance"],
        correctAnswer: 2
      },
      {
        question: "What is the primary purpose of a compliance policy established by leadership?",
        options: ["To satisfy regulators", "To guide organizational behavior", "To punish non-compliance", "To increase profits"],
        correctAnswer: 1
      }
    ],
    resources: [
      { title: "The Role of Leadership in Building a Strong Compliance Culture", url: "https://www2.deloitte.com/us/en/pages/risk/articles/the-role-of-leadership-in-shaping-program-culture.html" },
      { title: "How Top Management Can Drive Compliance Success", url: "https://www.corporatecomplianceinsights.com/tone-at-the-top-compliance/" },
      { title: "Integrating Compliance into Business Strategy: A Leadership Guide", url: "https://www.mckinsey.com/capabilities/risk-and-resilience/our-insights/the-compliance-function-at-an-inflection-point" },
      { title: "Measuring the Effectiveness of Compliance Leadership", url: "https://www.protiviti.com/us-en/insights/whitepaper-ways-measure-effectiveness-compliance-program" },
      { title: "ISO 37301:2021 Compliance Management Systems", url: "https://www.iso.org/standard/75080.html" }
    ]
  };

  const handleQuizStart = () => {
    setQuizStarted(true);
    setCurrentQuestion(0);
    setScore(0);
    setShowResults(false);
  };

  const handleAnswer = (selectedAnswer) => {
    if (selectedAnswer === content.quiz[currentQuestion].correctAnswer) {
      setScore(score + 1);
    }

    if (currentQuestion < content.quiz.length - 1) {
      setCurrentQuestion(currentQuestion + 1);
    } else {
      setShowResults(true);
    }
  };

  return (
    <div className="space-y-6">
      <div className="bg-white p-6 rounded-lg shadow-md">
        <h2 className="text-2xl font-bold mb-6 text-gray-800">Leadership in Compliance Management</h2>
        <TabGroup>
          <TabList className="flex space-x-1 rounded-xl bg-blue-900/20 p-1">
            {['Overview', 'Questionnaire', 'Quiz', 'Resources'].map((tab) => (
              <Tab
                key={tab}
                className={({ selected }) =>
                  classNames(
                    'w-full rounded-lg py-2.5 text-sm font-medium leading-5 text-blue-700',
                    'ring-white ring-opacity-60 ring-offset-2 ring-offset-blue-400 focus:outline-none focus:ring-2',
                    selected
                      ? 'bg-white shadow'
                      : 'text-blue-100 hover:bg-white/[0.12] hover:text-white'
                  )
                }
              >
                {tab}
              </Tab>
            ))}
          </TabList>
          <TabPanels className="mt-2">
            <TabPanel className="rounded-xl bg-white p-3">
              <h3 className="text-lg font-medium leading-6 text-gray-900 mb-2">Overview</h3>
              <p className="text-gray-500 mb-4">{content.overview.introduction}</p>
              <h4 className="font-semibold text-gray-900 mb-2">Key Points:</h4>
              <ul className="list-disc pl-5 mb-4 space-y-1">
                {content.overview.keyPoints.map((point, index) => (
                  <li key={index} className="text-gray-500">{point}</li>
                ))}
              </ul>
              <h4 className="font-semibold text-gray-900 mb-2">Importance:</h4>
              <p className="text-gray-500 mb-4">{content.overview.importance}</p>
              <h4 className="font-semibold text-gray-900 mb-2">Best Practices:</h4>
              <ul className="list-disc pl-5 space-y-1">
                {content.overview.bestPractices.map((practice, index) => (
                  <li key={index} className="text-gray-500">{practice}</li>
                ))}
              </ul>
            </TabPanel>
            <TabPanel className="rounded-xl bg-white p-3">
              <h3 className="text-lg font-medium leading-6 text-gray-900 mb-4">Leadership Questionnaire</h3>
              {content.questionnaire.map((item) => (
                <div key={item.id} className="mb-4">
                  <p className="text-gray-700 mb-2">{item.question}</p>
                  {item.type === 'yesNo' ? (
                    <div className="flex space-x-4">
                      <button className="px-4 py-2 bg-green-100 text-green-800 rounded-md hover:bg-green-200">Yes</button>
                      <button className="px-4 py-2 bg-red-100 text-red-800 rounded-md hover:bg-red-200">No</button>
                    </div>
                  ) : (
                    <input type="range" min={item.min} max={item.max} className="w-full" />
                  )}
                </div>
              ))}
            </TabPanel>
            <TabPanel className="rounded-xl bg-white p-3">
              <h3 className="text-lg font-medium leading-6 text-gray-900 mb-4">Leadership Quiz</h3>
              {!quizStarted ? (
                <button 
                  onClick={handleQuizStart}
                  className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
                >
                  Start Quiz
                </button>
              ) : showResults ? (
                <div>
                  <h4 className="font-semibold text-lg mb-2">Quiz Results</h4>
                  <p className="mb-4">You scored {score} out of {content.quiz.length} correct.</p>
                  <button 
                    onClick={handleQuizStart}
                    className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
                  >
                    Retake Quiz
                  </button>
                </div>
              ) : (
                <div>
                  <h4 className="font-semibold mb-2">Question {currentQuestion + 1} of {content.quiz.length}</h4>
                  <p className="mb-4">{content.quiz[currentQuestion].question}</p>
                  {content.quiz[currentQuestion].options.map((option, index) => (
                    <button
                      key={index}
                      onClick={() => handleAnswer(index)}
                      className="block w-full text-left p-2 mb-2 bg-gray-100 hover:bg-gray-200 rounded"
                    >
                      {option}
                    </button>
                  ))}
                </div>
              )}
            </TabPanel>
            <TabPanel className="rounded-xl bg-white p-3">
              <h3 className="text-lg font-medium leading-6 text-gray-900 mb-4">Leadership Resources</h3>
              <ul className="space-y-2">
                {content.resources.map((resource, index) => (
                  <li key={index} className="flex items-center">
                    <ExternalLink size={16} className="mr-2 text-blue-500" />
                    <a href={resource.url} target="_blank" rel="noopener noreferrer" className="text-blue-600 hover:underline">
                      {resource.title}
                    </a>
                  </li>
                ))}
              </ul>
            </TabPanel>
          </TabPanels>
        </TabGroup>
      </div>
    </div>
  );
};

export default LeadershipContent;