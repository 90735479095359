import React, { useState, useEffect } from 'react';
import {
    Dialog,
    DialogContent,
    DialogHeader,
    DialogTitle,
    DialogFooter
  } from './ui/Dialog';
import { Tabs, TabsList, TabsTrigger, TabsContent } from './ui/Tabs';
import { Button } from './ui/Button';
import { Input } from './ui/Input';
import { Label } from './ui/Label';
import { Select } from './ui/Select';
import Switch from './ui/Switch';
import { Alert, AlertDescription } from './ui/Alert';
import {
  RefreshCw,
  Link,
  Clock,
  Bell,
  Terminal,
  TableProperties,
} from 'lucide-react';

const IntegrationConfigDialog = ({ integration, onClose, onUpdate }) => {
  const [activeTab, setActiveTab] = useState('connection');
  const [isLoading, setIsLoading] = useState(false);
  const [testResult, setTestResult] = useState(null);
  const [config, setConfig] = useState({
    connection: {
      host: '',
      port: '',
      username: '',
      password: '',
      database: '',
      ssl: true,
      connectionType: 'direct', // direct, ssh, vpn
    },
    mapping: {
      sourceFields: [],
      targetFields: [],
      mappings: [],
      transformations: [],
    },
    schedule: {
      enabled: true,
      frequency: 'hourly',
      retryAttempts: 3,
      retryInterval: 5,
      notifyOnFailure: true,
    },
    monitoring: {
      logLevel: 'info',
      alertThreshold: 3,
      notifications: {
        email: true,
        slack: false,
        teams: false,
      },
    },
  });

  useEffect(() => {
    if (integration.data.configuration) {
      setConfig(integration.data.configuration);
    }
  }, [integration]);

  const handleTestConnection = async () => {
    setIsLoading(true);
    try {
      // Simulate API call
      await new Promise((resolve) => setTimeout(resolve, 2000));
      setTestResult({ success: true, message: 'Connection successful!' });
    } catch (error) {
      setTestResult({ success: false, message: 'Connection failed: ' + error.message });
    } finally {
      setIsLoading(false);
    }
  };

  const handleSave = () => {
    onUpdate(config);
    onClose();
  };

  return (
    <Dialog open onOpenChange={onClose} className="max-w-4xl">
      <DialogContent className="max-w-4xl max-h-[90vh] overflow-auto">
        <DialogHeader>
          <DialogTitle>Configure {integration.data.label}</DialogTitle>
        </DialogHeader>

        <Tabs value={activeTab} onValueChange={setActiveTab}>
          <TabsList className="grid w-full grid-cols-5">
            <TabsTrigger value="connection">
              <Link className="w-4 h-4 mr-2" />
              Connection
            </TabsTrigger>
            <TabsTrigger value="mapping">
              <TableProperties className="w-4 h-4 mr-2" />
              Mapping
            </TabsTrigger>
            <TabsTrigger value="transformations">
              <RefreshCw className="w-4 h-4 mr-2" />
              Transformations
            </TabsTrigger>
            <TabsTrigger value="schedule">
              <Clock className="w-4 h-4 mr-2" />
              Schedule
            </TabsTrigger>
            <TabsTrigger value="monitoring">
              <Bell className="w-4 h-4 mr-2" />
              Monitoring
            </TabsTrigger>
          </TabsList>

          {/* Connection Tab */}
          <TabsContent value="connection" className="space-y-4">
            <div className="grid grid-cols-2 gap-4">
              <div>
                <Label>Host</Label>
                <Input
                  value={config.connection.host}
                  onChange={(e) =>
                    setConfig({
                      ...config,
                      connection: { ...config.connection, host: e.target.value },
                    })
                  }
                  placeholder="hostname or IP"
                />
              </div>
              <div>
                <Label>Port</Label>
                <Input
                  value={config.connection.port}
                  onChange={(e) =>
                    setConfig({
                      ...config,
                      connection: { ...config.connection, port: e.target.value },
                    })
                  }
                  placeholder="port number"
                />
              </div>
              <div>
                <Label>Username</Label>
                <Input
                  value={config.connection.username}
                  onChange={(e) =>
                    setConfig({
                      ...config,
                      connection: { ...config.connection, username: e.target.value },
                    })
                  }
                />
              </div>
              <div>
                <Label>Password</Label>
                <Input
                  type="password"
                  value={config.connection.password}
                  onChange={(e) =>
                    setConfig({
                      ...config,
                      connection: { ...config.connection, password: e.target.value },
                    })
                  }
                />
              </div>
              <div className="col-span-2">
                <Label>Connection Type</Label>
                <Select
                  value={config.connection.connectionType}
                  onValueChange={(value) =>
                    setConfig({
                      ...config,
                      connection: { ...config.connection, connectionType: value },
                    })
                  }
                >
                  <option value="direct">Direct Connection</option>
                  <option value="ssh">SSH Tunnel</option>
                  <option value="vpn">VPN</option>
                </Select>
              </div>
              <div className="col-span-2 flex items-center space-x-2">
                <Switch
                  checked={config.connection.ssl}
                  onCheckedChange={(checked) =>
                    setConfig({
                      ...config,
                      connection: { ...config.connection, ssl: checked },
                    })
                  }
                />
                <Label>Enable SSL/TLS</Label>
              </div>
            </div>

            <Button onClick={handleTestConnection} disabled={isLoading} className="mt-4">
              {isLoading ? (
                <RefreshCw className="w-4 h-4 mr-2 animate-spin" />
              ) : (
                <Terminal className="w-4 h-4 mr-2" />
              )}
              Test Connection
            </Button>

            {testResult && (
              <Alert variant={testResult.success ? 'success' : 'destructive'}>
                <AlertDescription>{testResult.message}</AlertDescription>
              </Alert>
            )}
          </TabsContent>

          {/* Mapping Tab */}
          <TabsContent value="mapping" className="space-y-4">
            <div className="bg-gray-50 p-4 rounded-lg">
              <Label>Data Mapping Configuration</Label>
              <div className="grid grid-cols-3 gap-4 mt-4">
                <div>
                  <Label>Source Field</Label>
                  <Select>
                    <option>Select field...</option>
                  </Select>
                </div>
                <div className="flex items-center justify-center">
                  <Link className="w-6 h-6 text-gray-400" />
                </div>
                <div>
                  <Label>Target Field</Label>
                  <Select>
                    <option>Select field...</option>
                  </Select>
                </div>
              </div>
              <Button variant="outline" className="mt-4">
                Add Mapping
              </Button>
            </div>
          </TabsContent>

          {/* Transformations Tab */}
          <TabsContent value="transformations" className="space-y-4">
            <div className="bg-gray-50 p-4 rounded-lg">
              <Label>Data Transformations</Label>
              <div className="space-y-4 mt-4">
                <div className="grid grid-cols-2 gap-4">
                  <div>
                    <Label>Field</Label>
                    <Select>
                      <option>Select field...</option>
                      {config.mapping.mappings.map((mapping, index) => (
                        <option key={index} value={mapping.target}>
                          {mapping.target}
                        </option>
                      ))}
                    </Select>
                  </div>
                  <div>
                    <Label>Transformation Type</Label>
                    <Select>
                      <option value="format">Format</option>
                      <option value="convert">Convert</option>
                      <option value="calculate">Calculate</option>
                      <option value="combine">Combine Fields</option>
                      <option value="split">Split Field</option>
                      <option value="custom">Custom Function</option>
                    </Select>
                  </div>
                </div>
                <div>
                  <Label>Transformation Rule</Label>
                  <Input placeholder="Enter transformation rule or formula" className="font-mono" />
                </div>
                <Button variant="outline">Add Transformation</Button>
              </div>
            </div>
          </TabsContent>

          {/* Schedule Tab */}
          <TabsContent value="schedule" className="space-y-4">
            <div className="grid grid-cols-2 gap-4">
              <div>
                <Label>Sync Frequency</Label>
                <Select
                  value={config.schedule.frequency}
                  onValueChange={(value) =>
                    setConfig({
                      ...config,
                      schedule: { ...config.schedule, frequency: value },
                    })
                  }
                >
                  <option value="realtime">Real-time</option>
                  <option value="hourly">Hourly</option>
                  <option value="daily">Daily</option>
                  <option value="weekly">Weekly</option>
                </Select>
              </div>
              <div>
                <Label>Retry Attempts</Label>
                <Input
                  type="number"
                  min="0"
                  max="10"
                  value={config.schedule.retryAttempts}
                  onChange={(e) =>
                    setConfig({
                      ...config,
                      schedule: {
                        ...config.schedule,
                        retryAttempts: parseInt(e.target.value),
                      },
                    })
                  }
                />
              </div>
            </div>
          </TabsContent>

          {/* Monitoring Tab */}
          <TabsContent value="monitoring" className="space-y-4">
            <div className="space-y-4">
              <div>
                <Label>Log Level</Label>
                <Select
                  value={config.monitoring.logLevel}
                  onValueChange={(value) =>
                    setConfig({
                      ...config,
                      monitoring: { ...config.monitoring, logLevel: value },
                    })
                  }
                >
                  <option value="debug">Debug</option>
                  <option value="info">Info</option>
                  <option value="warn">Warning</option>
                  <option value="error">Error</option>
                </Select>
              </div>
              <div>
                <Label>Alert Threshold</Label>
                <Input
                  type="number"
                  value={config.monitoring.alertThreshold}
                  onChange={(e) =>
                    setConfig({
                      ...config,
                      monitoring: {
                        ...config.monitoring,
                        alertThreshold: parseInt(e.target.value),
                      },
                    })
                  }
                />
              </div>
              <div className="space-y-2">
                <Label>Notifications</Label>
                <div className="space-y-2">
                  {Object.entries(config.monitoring.notifications).map(([key, value]) => (
                    <div key={key} className="flex items-center space-x-2">
                      <Switch
                        checked={value}
                        onCheckedChange={(checked) =>
                          setConfig({
                            ...config,
                            monitoring: {
                              ...config.monitoring,
                              notifications: {
                                ...config.monitoring.notifications,
                                [key]: checked,
                              },
                            },
                          })
                        }
                      />
                      <Label className="capitalize">{key}</Label>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </TabsContent>

          {/* System-specific configurations based on integration type */}
          {integration.data.integrationType === 'SAP' && (
            <TabsContent value="sap-config" className="space-y-4">
              <div className="space-y-4">
                <div>
                  <Label>SAP System Type</Label>
                  <Select>
                    <option value="ecc">SAP ECC</option>
                    <option value="s4hana">SAP S/4HANA</option>
                    <option value="bw">SAP BW</option>
                  </Select>
                </div>
                <div>
                  <Label>Client</Label>
                  <Input placeholder="Enter SAP client number" />
                </div>
                <div>
                  <Label>RFC Configuration</Label>
                  <Input placeholder="Enter RFC destination" />
                </div>
                <div className="flex items-center space-x-2">
                  <Switch />
                  <Label>Enable SAP Single Sign-On</Label>
                </div>
              </div>
            </TabsContent>
          )}

          {integration.data.integrationType === 'Database' && (
            <TabsContent value="db-config" className="space-y-4">
              <div className="space-y-4">
                <div>
                  <Label>Database Type</Label>
                  <Select>
                    <option value="postgresql">PostgreSQL</option>
                    <option value="mysql">MySQL</option>
                    <option value="oracle">Oracle</option>
                    <option value="sqlserver">SQL Server</option>
                  </Select>
                </div>
                <div>
                  <Label>Schema</Label>
                  <Input placeholder="Enter database schema" />
                </div>
                <div>
                  <Label>Connection Pool Size</Label>
                  <Input type="number" min="1" max="100" defaultValue="10" />
                </div>
                <div className="flex items-center space-x-2">
                  <Switch />
                  <Label>Enable Query Logging</Label>
                </div>
              </div>
            </TabsContent>
          )}

          {integration.data.integrationType === 'API' && (
            <TabsContent value="api-config" className="space-y-4">
              <div className="space-y-4">
                <div>
                  <Label>API Version</Label>
                  <Input placeholder="Enter API version" />
                </div>
                <div>
                  <Label>Authentication Type</Label>
                  <Select>
                    <option value="basic">Basic Auth</option>
                    <option value="oauth2">OAuth 2.0</option>
                    <option value="apikey">API Key</option>
                    <option value="jwt">JWT</option>
                  </Select>
                </div>
                <div>
                  <Label>Rate Limiting</Label>
                  <div className="grid grid-cols-2 gap-4">
                    <Input type="number" placeholder="Requests" />
                    <Select>
                      <option value="second">Per Second</option>
                      <option value="minute">Per Minute</option>
                      <option value="hour">Per Hour</option>
                    </Select>
                  </div>
                </div>
                <div>
                  <Label>Retry Strategy</Label>
                  <div className="space-y-2">
                    <div className="flex items-center space-x-2">
                      <Switch />
                      <Label>Enable Automatic Retries</Label>
                    </div>
                    <Input type="number" placeholder="Max retry attempts" />
                    <Input type="number" placeholder="Retry delay (seconds)" />
                  </div>
                </div>
              </div>
            </TabsContent>
          )}
        </Tabs>

        <DialogFooter className="mt-6">
          <div className="flex justify-between items-center w-full">
            <div className="flex items-center space-x-2">
              <div
                className={`w-2 h-2 rounded-full ${
                  testResult?.success ? 'bg-green-500' : 'bg-red-500'
                }`}
              />
              <span className="text-sm text-gray-600">
                {testResult?.success ? 'Connected' : 'Not Connected'}
              </span>
            </div>
            <div className="space-x-2">
              <Button variant="outline" onClick={onClose}>
                Cancel
              </Button>
              <Button onClick={handleSave} disabled={!testResult?.success}>
                Save Configuration
              </Button>
            </div>
          </div>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
};

export default IntegrationConfigDialog;
