import React from 'react';
import { HeroVideoDialog } from './ui/HeroVideoDialog';
import { ArrowRight, Brain, Workflow, Bot, Calendar } from 'lucide-react';
import { Button } from './ui/Button';
import { motion } from 'framer-motion';
import { NumberTicker } from './ui/NumberTicker';

const HowItWorksSection = () => {
  const videoConfig = {
    videoSrc: "https://player.vimeo.com/video/1025453920?badge=0&autopause=0&player_id=0&app_id=58479",
    title: "LOKATIAL_How it Works",
    thumbnailSrc: "/videos/lokatial-demo-thumbnail.jpg", 
    thumbnailAlt: "LOKATIAL Platform Demo"
  };

  const HeroVideoDialog = ({ videoSrc, title }) => {
    // Load Vimeo player script
    React.useEffect(() => {
      const script = document.createElement("script");
      script.src = "https://player.vimeo.com/api/player.js";
      script.async = true;
      document.body.appendChild(script);
  
      return () => {
        document.body.removeChild(script);
      };
    }, []);
  
    return (
      <div style={{ padding: '56.25% 0 0 0', position: 'relative' }}>
        <iframe
          src={videoSrc}
          style={{ position: 'absolute', top: 0, left: 0, width: '100%', height: '100%' }}
          frameBorder="0"
          allow="autoplay; fullscreen; picture-in-picture; clipboard-write"
          allowFullScreen
          title={title}
        />
      </div>
    );
  };
  
  const features = [
    {
      icon: Brain,
      title: "Design with AI",
      metrics: {
        value: 85,
        label: "Time saved on process design"
      },
      description: "Design compliant processes in minutes with AI assistance",
      color: "purple",
    },
    {
      icon: Workflow,
      title: "Implement Visually",
      metrics: {
        value: 60,
        label: "Reduction in implementation time"
      },
      description: "Deploy and modify workflows with drag-and-drop simplicity",
      color: "blue",
    },
    {
      icon: Bot,
      title: "Monitor Continuously",
      metrics: {
        value: 95,
        label: "Audit readiness score"
      },
      description: "Stay audit-ready with our mock audit wizard",
      color: "green",
    }
  ];

  return (
    <div className="bg-white py-24">
      <div className="max-w-7xl mx-auto px-6 lg:px-8">
        <div className="relative">
          {/* Section Header */}
          <div className="text-center mb-12">
            <h2 className="text-4xl font-bold text-gray-900 mb-4">
              See How It Works
            </h2>
            <p className="text-gray-600 text-lg max-w-2xl mx-auto">
              Watch how LOKATIAL helps you design, implement, and monitor compliance processes
            </p>
          </div>
          
          {/* Video Demo */}
          <motion.div 
            className="max-w-4xl mx-auto mb-16"
            initial={{ opacity: 0, y: 20 }}
            whileInView={{ opacity: 1, y: 0 }}
            viewport={{ once: true }}
            transition={{ duration: 0.6 }}
          >
            <div className="relative rounded-xl overflow-hidden shadow-2xl">
              <HeroVideoDialog {...videoConfig} />
            </div>
          </motion.div>

          {/* Features Grid */}
          <div className="grid grid-cols-1 md:grid-cols-3 gap-8 mt-16">
            {features.map((feature, index) => (
              <motion.div
                key={index}
                className="bg-white rounded-xl p-8 shadow-sm hover:shadow-lg transition-all duration-300"
                initial={{ opacity: 0, y: 20 }}
                whileInView={{ opacity: 1, y: 0 }}
                viewport={{ once: true }}
                transition={{ duration: 0.5, delay: index * 0.1 }}
              >
                {/* Header */}
                <div className="flex items-center mb-6">
                  <feature.icon className={`h-6 w-6 text-${feature.color}-600 mr-2`} />
                  <h3 className="text-xl font-semibold text-gray-900">{feature.title}</h3>
                </div>

                {/* Metrics */}
                <div className="mb-4">
                  <div className="flex items-baseline">
                    <span className="text-gray-500 text-sm">Up to</span>
                    <span className={`text-4xl font-bold ml-2 text-${feature.color}-600`}>
                      <NumberTicker value={feature.metrics.value} />
                    </span>
                    <span className="text-xl ml-1 text-gray-500">%</span>
                  </div>
                  <p className="text-gray-600 mt-1">{feature.metrics.label}</p>
                </div>

                {/* Description */}
                <p className="text-gray-600">{feature.description}</p>

                {/* Progress Bar */}
                <div className={`h-1 w-24 bg-${feature.color}-500 mt-6 rounded-full`} />
              </motion.div>
            ))}
          </div>

          {/* CTA Section 
         <motion.div 
            className="mt-16 text-center"
            initial={{ opacity: 0, y: 20 }}
            whileInView={{ opacity: 1, y: 0 }}
            viewport={{ once: true }}
            transition={{ duration: 0.5, delay: 0.3 }}
          >
            <Button 
              onClick={() => window.open("https://cal.com/tarunjot/15min")}
              className="inline-flex items-center bg-gradient-to-r from-purple-600 to-blue-600 text-white px-8 py-3 rounded-lg hover:from-purple-700 hover:to-blue-700 transition-all duration-300"
            >
              <Calendar className="w-5 h-5 mr-2" />
              Schedule Product Demo
              <ArrowRight className="w-5 h-5 ml-2" />
            </Button>
          </motion.div> */}
          
        </div>
      </div>
    </div>
  );
};

export default HowItWorksSection;