import React, { useState, useEffect } from 'react';
import { Textarea } from '../ui/Textarea';
import { Tooltip } from '../ui/Tooltip';
import { Button } from '../ui/Button';
import { Bot } from 'lucide-react';
import AIThinkingAnimation from '../AIThinkingAnimation';

const ProcessScopeStep = ({ data, onChange, aiSuggestions, isLoading }) => {
  const [scopeText, setScopeText] = useState(data || '');
  const [suggestedScope, setSuggestedScope] = useState('');

  useEffect(() => {
    if (typeof aiSuggestions === 'string' && aiSuggestions.trim() !== '') {
      setSuggestedScope(aiSuggestions);
    } else {
      setSuggestedScope('');
    }
  }, [aiSuggestions]);

  const handleAcceptSuggestion = () => {
    setScopeText(suggestedScope);
    onChange(suggestedScope);
    setSuggestedScope('');
  };

  return (
    <div className="space-y-4">
      {isLoading ? (
        <div className="my-8 p-4 bg-yellow-50 rounded-lg shadow-inner">
          <AIThinkingAnimation />
          <p className="text-center text-yellow-700 mt-4 font-medium">
            AI is generating process scope suggestions...
          </p>
        </div>
      ) : suggestedScope ? (
        <div className="bg-yellow-50 p-4 rounded-md">
          <div className="flex items-center mb-2">
            <Bot className="w-5 h-5 mr-2 text-yellow-500" />
            <p className="text-yellow-700 font-medium">AI-Suggested Scope</p>
          </div>
          <p className="text-yellow-700 mb-4">{suggestedScope}</p>
          <Button variant="outline" onClick={handleAcceptSuggestion}>
            Accept Suggestion
          </Button>
        </div>
      ) : null}
      <Tooltip content="Define the boundaries of this process (e.g., what is included in this scope).">
        <Textarea
          value={scopeText}
          onChange={(e) => {
            setScopeText(e.target.value);
            onChange(e.target.value);
          }}
          className="w-full text-gray-700 bg-blue-50 p-2 rounded"
          placeholder="Describe what is included in this process..."
          rows={6}
        />
      </Tooltip>
    </div>
  );
};

export default ProcessScopeStep;