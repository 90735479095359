import React from 'react';
import { 
  Shield, 
  Workflow, 
  BrainCircuit, 
  ArrowRight,
  CheckCircle
} from 'lucide-react';
import { motion } from 'framer-motion';

const ValueProposition = () => {
  const valueProps = [
    {
      title: "Design & Deploy",
      icon: Shield,
      color: "purple",
      description: "Design comprehensive compliance & AI governance frameworks",
      points: [
        "ISO 37301 Process Design",
        "AI Risk Classification",
        "Policy & Controls Framework",
        "Third Party Risk Management",
        "Technical Documentation Setup"
      ],
      comingSoon: [
        "NIST AI RMF Integration",
        "FDA 21 CFR Part 11"
      ]
    },
    {
      title: "Automate & Integrate",
      icon: Workflow,
      color: "blue",
      description: "Streamline compliance processes & AI governance workflows",
      points: [
        "Compliance Workflow Builder",
        "AI Model Registry & Lifecycle",
        "Smart Forms & Assessments",
        "Automated Impact Analysis",
        "Document Management"
      ],
      comingSoon: [
        "Third-party Integrations",
        "Model Validation Pipelines"
      ]
    },
    {
      title: "Monitor & Improve",
      icon: BrainCircuit,
      color: "green",
      description: "Continuous compliance monitoring for processes & AI systems",
      points: [
        "Mock Audit Generation",
        "AI Model Performance Tracking",
        "Compliance Dashboards",
        "Bias & Fairness Monitoring",
        "Regulatory Change Alerts"
      ],
      comingSoon: [
        "Expert Network Access",
        "Advanced AI Observability"
      ]
    }
  ];
  
  return (
    <div className="bg-white py-24">
      <div className="max-w-7xl mx-auto px-6 lg:px-8">
        {/* Section Header */}
        <motion.div 
          className="text-center mb-16"
          initial={{ opacity: 0, y: 20 }}
          whileInView={{ opacity: 1, y: 0 }}
          viewport={{ once: true }}
          transition={{ duration: 0.5 }}
        >
          <h2 className="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl mb-4">
          <span className="text-transparent bg-clip-text bg-gradient-to-r from-purple-600 to-blue-600">
          Comprehensive
            </span>
            {" "} Compliance Platform
          </h2>
          <p className="text-lg text-gray-600 max-w-2xl mx-auto">
            Everything you need to design, implement, and monitor compliance processes
          </p>
        </motion.div>

        {/* Value Props Grid */}
        <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
          {valueProps.map((prop, index) => (
            <motion.div
              key={index}
              className="flex flex-col h-full"
              initial={{ opacity: 0, y: 20 }}
              whileInView={{ opacity: 1, y: 0 }}
              viewport={{ once: true }}
              transition={{ duration: 0.5, delay: index * 0.1 }}
            >
              <div className={`bg-${prop.color}-50 p-8 rounded-2xl h-full flex flex-col relative group hover:shadow-lg transition-all duration-300`}>
                {/* Icon & Title */}
                <div className="flex items-center mb-4">
                  <div className={`p-2 rounded-lg bg-${prop.color}-100`}>
                    <prop.icon className={`h-6 w-6 text-${prop.color}-600`} />
                  </div>
                  <h3 className="text-xl font-semibold text-gray-900 ml-3">
                    {prop.title}
                  </h3>
                </div>

                {/* Description */}
                <p className="text-gray-600 mb-6">
                  {prop.description}
                </p>

                {/* Available Features */}
                <div className="space-y-3 mb-6">
                  {prop.points.map((point, i) => (
                    <div key={i} className="flex items-start">
                      <CheckCircle className={`h-5 w-5 text-${prop.color}-500 mr-2 flex-shrink-0 mt-0.5`} />
                      <span className="text-gray-600">{point}</span>
                    </div>
                  ))}
                </div>

                {/* Coming Soon Features */}
                <div className="mt-auto">
                  <p className="text-sm font-medium text-gray-500 mb-2">Coming Soon</p>
                  {prop.comingSoon.map((feature, i) => (
                    <div key={i} className="flex items-center text-sm text-gray-400">
                      <ArrowRight className="h-4 w-4 mr-2" />
                      {feature}
                    </div>
                  ))}
                </div>
                
                {/* Hover highlight */}
                <div className={`absolute bottom-0 left-0 right-0 h-1 bg-gradient-to-r from-${prop.color}-500 to-transparent rounded-b-2xl transition-opacity duration-300 opacity-0 group-hover:opacity-100`} />
              </div>
            </motion.div>
          ))}
        </div>

        {/* Platform Status */}
        <motion.div 
          className="mt-16 bg-gradient-to-r from-purple-50 to-blue-50 rounded-2xl p-8"
          initial={{ opacity: 0, y: 20 }}
          whileInView={{ opacity: 1, y: 0 }}
          viewport={{ once: true }}
          transition={{ duration: 0.5 }}
        >
          <div className="text-center max-w-3xl mx-auto">
            <h3 className="text-xl font-semibold text-gray-900 mb-4">
              Early Access Program
            </h3>
            <p className="text-gray-600 mb-6">
              Join our early access program to help shape the future of compliance management and get premium support during your implementation journey.
            </p>
            <motion.a 
              href="https://cal.com/tarunjot/15min"
              target="_blank"
              rel="noopener noreferrer"
              className="inline-flex items-center justify-center px-6 py-3 text-base font-medium text-white bg-gradient-to-r from-purple-600 to-blue-600 hover:from-purple-700 hover:to-blue-700 rounded-lg transition-all duration-200 hover:shadow-lg"
              whileHover={{ scale: 1.02 }}
              whileTap={{ scale: 0.98 }}
            >
              Schedule a Demo
            </motion.a>
          </div>
        </motion.div>
      </div>
    </div>
  );
};

export default ValueProposition;