import { motion } from 'framer-motion';
import { 
    Cloud, 
    Lock, 
    Zap, 
    SearchCode, 
    ShieldCheck, 
    Files, 
    GitBranch,
    History,
    Radar,
    Fingerprint
  } from 'lucide-react';
import { Card } from './ui/Card';


const TechnologyStrategy = () => {
    const capabilities = [
      {
        name: "Intelligent Design",
        description: "Design compliant processes with AI assistance",
        icon: SearchCode,
        color: "purple",
        features: [
          "Process Design Wizard",
          "Visual Workflow Builder",
          "Mock Audit Wizard",
          "Compliance Templates"
        ]
      },
      {
        name: "Trust & Security",
        description: "Built-in verification and audit trails",
        icon: ShieldCheck,
        color: "blue",
        features: [
          "Document Provenance",
          "Immutable Audit Logs",
          "E-signatures Support",
          "Version Control"
        ]
      },
      {
        name: "Data Governance",
        description: "Smart data handling for compliance",
        icon: Files,
        color: "purple",
        features: [
          "Data Classification",
          "Data Localization",
          "Retention Rules",
          "Secure Storage"
        ]
      },
      {
        name: "Process Monitoring",
        description: "Real-time compliance oversight",
        icon: Radar,
        color: "blue",
        features: [
          "Real-time Monitoring",
          "Risk Analytics",
          "Process Mining",
          "Auto-generated Reports"
        ]
      },
      {
        name: "Access Control",
        description: "Enterprise-grade security controls",
        icon: GitBranch,
        color: "purple",
        features: [
          "Role-based Access",
          "Approval Workflows",
          "Duty Segregation",
          "Delegated Access"
        ]
      },
      {
        name: "Digital Records",
        description: "Complete compliance traceability",
        icon: History,
        color: "blue",
        features: [
          "Activity Tracking",
          "Evidence Collection",
          "Change Tracking",
          "Analysis Records"
        ]
      }
    ];
  
    return (
      <section className="py-24 bg-gradient-to-b from-white to-gray-50">
        <div className="max-w-7xl mx-auto px-6 lg:px-8">
          <motion.div
            className="text-center mb-16"
            initial={{ opacity: 0, y: 20 }}
            whileInView={{ opacity: 1, y: 0 }}
            viewport={{ once: true }}
          >
            <div className="inline-flex items-center px-4 py-2 rounded-full bg-purple-100 mb-6">
              <Fingerprint className="w-5 h-5 text-purple-600 mr-2" />
              <span className="text-sm font-medium text-purple-600">Enterprise Compliance Suite</span>
            </div>
            
            <h2 className="text-4xl font-bold text-gray-600 mb-6">
              Built for
              <span className="text-transparent bg-clip-text bg-gradient-to-r from-purple-600 to-blue-600">
                {" "}Compliance Excellence
              </span>
            </h2>
            
            <p className="text-xl text-gray-600 max-w-2xl mx-auto">
              Your tech stack is complex enough. We help you stay compliant without adding 
              to the complexity.
            </p>
          </motion.div>
  
          {/* Capability Cards */}
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
            {capabilities.map((capability, index) => (
              <motion.div
                key={index}
                initial={{ opacity: 0, y: 20 }}
                whileInView={{ opacity: 1, y: 0 }}
                viewport={{ once: true }}
                transition={{ delay: index * 0.1 }}
                whileHover={{ y: -5 }}
              >
                <Card className="p-6 bg-white hover:shadow-lg transition-all duration-300 h-full flex flex-col">
                  <div className="flex items-center mb-4">
                    <div className={`p-2 rounded-lg bg-${capability.color}-100`}>
                      <capability.icon className={`h-6 w-6 text-${capability.color}-600`} />
                    </div>
                    <h3 className="text-lg font-semibold text-gray-900 ml-3">
                      {capability.name}
                    </h3>
                  </div>
  
                  <p className="text-gray-600 mb-6">
                    {capability.description}
                  </p>
  
                  <ul className="space-y-2.5 mt-auto">
                    {capability.features.map((feature, i) => (
                      <li key={i} className="flex items-center text-gray-600">
                        <div className={`w-1.5 h-1.5 rounded-full bg-${capability.color}-500 mr-2.5`} />
                        <span className="text-sm">{feature}</span>
                      </li>
                    ))}
                  </ul>
                </Card>
              </motion.div>
            ))}
          </div>
  
        </div>
      </section>
    );
  };
  
  export default TechnologyStrategy;