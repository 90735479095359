import React from 'react';
import { Shield, Map, Activity, Cog } from 'lucide-react';
import { useNavigate } from 'react-router-dom';

const FrameworkItem = ({ icon: Icon, title, description, status }) => (
  <div className="flex items-start p-4 bg-white rounded-lg shadow">
    <Icon className="w-6 h-6 text-blue-600 mr-3 flex-shrink-0" />
    <div>
      <h4 className="text-lg font-semibold mb-1 text-gray-500">{title}</h4>
      <p className="text-sm text-gray-600 mb-2">{description}</p>
      <div className={`text-sm font-medium ${status === 'Compliant' ? 'text-green-600' : 'text-yellow-600'}`}>
        Status: {status}
      </div>
    </div>
  </div>
);

const NISTAIFrameworkOverview = () => {
  const navigate = useNavigate();

  const frameworkItems = [
    {
      icon: Shield,
      title: "GOVERN",
      description: "Policies and processes for AI risk management",
      status: "In Progress"
    },
    {
      icon: Map,
      title: "MAP",
      description: "Identify AI system context and potential impacts",
      status: "Compliant"
    },
    {
      icon: Activity,
      title: "MEASURE",
      description: "Analyze and assess AI risks",
      status: "In Progress"
    },
    {
      icon: Cog,
      title: "MANAGE",
      description: "Respond to and manage AI risks",
      status: "Not Started"
    }
  ];

  const handleViewFullFramework = () => {
    navigate('/nist-ai-framework');
  };

  return (
    <div className="bg-gray-100 p-6 rounded-lg shadow-md">
      <h3 className="text-xl font-bold mb-4 text-gray-600">NIST AI Risk Management Framework</h3>
      <div className="grid grid-cols-2 gap-4">
        {frameworkItems.map((item, index) => (
          <FrameworkItem key={index} {...item} />
        ))}
      </div>
      <button 
        onClick={handleViewFullFramework}
        className="mt-4 bg-blue-600 text-white px-4 py-2 rounded hover:bg-blue-700 transition-colors"
      >
        View Full Framework
      </button>
    </div>
  );
};

export default NISTAIFrameworkOverview;