import React, { useState, useEffect, useCallback } from 'react';
import { Input } from '../ui/Input';
import { Button } from '../ui/Button';
import { PlusCircle, Trash2, Bot } from 'lucide-react';
import AIThinkingAnimation from '../AIThinkingAnimation';

const RegulatoryRequirementsStep = ({ data, onChange, aiSuggestions, isLoading }) => {
  const [newRequirement, setNewRequirement] = useState('');
  const [userRequirements, setUserRequirements] = useState(data || []);
  const [suggestedRequirements, setSuggestedRequirements] = useState([]);

  useEffect(() => {
    if (Array.isArray(aiSuggestions)) {
      // Filter out suggestions that are already in userRequirements
      const newSuggestions = aiSuggestions.filter(
        suggestion => !userRequirements.includes(suggestion)
      );
      setSuggestedRequirements(newSuggestions);
    }
  }, [aiSuggestions, userRequirements]);

  const handleAddRequirement = useCallback(() => {
    if (newRequirement.trim()) {
      const updatedRequirements = [...userRequirements, newRequirement.trim()];
      setUserRequirements(updatedRequirements);
      onChange(updatedRequirements);
      setNewRequirement('');
    }
  }, [newRequirement, userRequirements, onChange]);

  const handleRemoveRequirement = useCallback((index) => {
    const updatedRequirements = userRequirements.filter((_, i) => i !== index);
    setUserRequirements(updatedRequirements);
    onChange(updatedRequirements);
  }, [userRequirements, onChange]);

  const handleAcceptSuggestion = useCallback((index) => {
    const acceptedRequirement = suggestedRequirements[index];
    const updatedUserRequirements = [...userRequirements, acceptedRequirement];
    setUserRequirements(updatedUserRequirements);
    onChange(updatedUserRequirements);

    const updatedSuggestions = suggestedRequirements.filter((_, i) => i !== index);
    setSuggestedRequirements(updatedSuggestions);
  }, [suggestedRequirements, userRequirements, onChange]);

  return (
    <div className="space-y-6">
      {/* Suggested Requirements */}
      {suggestedRequirements.length > 0 && (
        <div>
          <h3 className="text-lg font-medium flex items-center text-green-600">
            <Bot className="w-5 h-5 mr-2 text-green-500" />
            AI-Suggested Requirements
          </h3>
          <div className="space-y-2 mt-2">
            {isLoading ? (
              <div className="my-8 p-4 bg-green-50 rounded-lg shadow-inner">
                <AIThinkingAnimation />
                <p className="text-center text-green-700 mt-4 font-medium">
                  AI is generating requirements...
                </p>
              </div>
            ) : (
              suggestedRequirements.map((requirement, index) => (
                <div key={index} className="flex items-center bg-green-50 p-2 rounded">
                  <span className="flex-grow text-green-700">{requirement}</span>
                  <Button
                    variant="outline"
                    size="sm"
                    className="ml-2"
                    onClick={() => handleAcceptSuggestion(index)}
                  >
                    Accept
                  </Button>
                </div>
              ))
            )}
          </div>
        </div>
      )}

      {/* User Requirements */}
      <div>
        <h3 className="text-lg font-medium text-gray-800">Your Requirements</h3>
        {userRequirements.map((requirement, index) => (
          <div key={index} className="flex items-center mt-2">
            <Input
              value={requirement}
              onChange={(e) => {
                const updatedRequirements = [...userRequirements];
                updatedRequirements[index] = e.target.value;
                setUserRequirements(updatedRequirements);
                onChange(updatedRequirements);
              }}
              className="flex-grow text-gray-800"
            />
            <Button
              variant="ghost"
              size="icon"
              className="text-red-500 hover:text-red-700 hover:bg-red-100"
              onClick={() => handleRemoveRequirement(index)}
            >
              <Trash2 className="w-4 h-4" />
            </Button>
          </div>
        ))}

        {/* Add New Requirement */}
        <div className="flex items-center mt-4">
          <Input
            value={newRequirement}
            onChange={(e) => setNewRequirement(e.target.value)}
            placeholder="Enter a new regulatory requirement"
            className="flex-grow text-gray-800"
          />
          <Button 
            variant="outline" 
            onClick={handleAddRequirement} 
            className="ml-2 bg-purple-100 text-purple-700 hover:bg-purple-200"
          >
            <PlusCircle className="w-4 h-4 mr-1" />
            Add
          </Button>
        </div>
      </div>
    </div>
  );
};

export default RegulatoryRequirementsStep;