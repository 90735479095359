// src/components/workflowSuggestions.js
const workflowTemplates = {
    gdpr: {
      default: [
        {
          id: 'start-1',
          type: 'custom',
          position: { x: 100, y: 100 },
          data: { 
            label: 'Start GDPR Process',
            type: 'start',
            description: 'Initiates the GDPR compliance workflow'
          }
        },
        {
          id: 'task-1',
          type: 'custom',
          position: { x: 100, y: 200 },
          data: {
            label: 'Data Mapping',
            type: 'task',
            description: 'Identify and document all personal data processing activities'
          }
        },
        {
          id: 'decision-1',
          type: 'custom',
          position: { x: 100, y: 300 },
          data: {
            label: 'Is Processing Lawful?',
            type: 'decision',
            description: 'Determine if there is a lawful basis for processing'
          }
        },
        {
          id: 'task-2',
          type: 'custom',
          position: { x: 250, y: 400 },
          data: {
            label: 'Implement Safeguards',
            type: 'task',
            description: 'Apply necessary technical and organizational measures'
          }
        },
        {
          id: 'task-3',
          type: 'custom',
          position: { x: 100, y: 500 },
          data: {
            label: 'Document Compliance',
            type: 'task',
            description: 'Record all compliance measures taken'
          }
        },
        {
          id: 'end-1',
          type: 'custom',
          position: { x: 100, y: 600 },
          data: {
            label: 'End GDPR Process',
            type: 'end',
            description: 'Completes the GDPR compliance workflow'
          }
        }
      ]
    },
    tprm: {
      default: [
        {
          id: 'start-1',
          type: 'custom',
          position: { x: 100, y: 100 },
          data: {
            label: 'Start TPRM Process',
            type: 'start',
            description: 'Initiates the Third-Party Risk Management workflow'
          }
        },
        {
          id: 'task-1',
          type: 'custom',
          position: { x: 100, y: 200 },
          data: {
            label: 'Vendor Identification',
            type: 'task',
            description: 'Identify and catalog all third-party vendors'
          }
        },
        {
          id: 'task-2',
          type: 'custom',
          position: { x: 100, y: 300 },
          data: {
            label: 'Due Diligence Questionnaire',
            type: 'task',
            description: 'Send and collect comprehensive questionnaire'
          }
        },
        {
          id: 'task-3',
          type: 'custom',
          position: { x: 100, y: 400 },
          data: {
            label: 'Risk Assessment',
            type: 'task',
            description: 'Evaluate vendor responses and assess risk level'
          }
        },
        {
          id: 'task-4',
          type: 'custom',
          position: { x: 100, y: 500 },
          data: {
            label: 'Implement Controls',
            type: 'task',
            description: 'Apply necessary risk mitigation controls'
          }
        },
        {
          id: 'task-5',
          type: 'custom',
          position: { x: 100, y: 600 },
          data: {
            label: 'Ongoing Monitoring',
            type: 'task',
            description: 'Continuously monitor vendor compliance and performance'
          }
        },
        {
          id: 'end-1',
          type: 'custom',
          position: { x: 100, y: 700 },
          data: {
            label: 'End TPRM Process',
            type: 'end',
            description: 'Completes the Third-Party Risk Management workflow'
          }
        }
      ],
      riskBased: [
        {
          id: 'start-1',
          type: 'custom',
          position: { x: 100, y: 100 },
          data: {
            label: 'Start Risk-Based TPRM',
            type: 'start',
            description: 'Initiates risk-based Third-Party Risk Management workflow'
          }
        },
        {
          id: 'task-1',
          type: 'custom',
          position: { x: 100, y: 200 },
          data: {
            label: 'Initial Risk Assessment',
            type: 'task',
            description: 'Complete internal risk assessment questionnaire'
          }
        },
        {
          id: 'decision-1',
          type: 'custom',
          position: { x: 100, y: 300 },
          data: {
            label: 'Risk Level?',
            type: 'decision',
            description: 'Determine vendor risk category (Low/Medium/High)'
          }
        },
        {
          id: 'task-2-low',
          type: 'custom',
          position: { x: 0, y: 400 },
          data: {
            label: 'Basic Background Check',
            type: 'task',
            description: 'Database check for low-risk vendors'
          }
        },
        {
          id: 'task-2-medium',
          type: 'custom',
          position: { x: 200, y: 400 },
          data: {
            label: 'Standard Due Diligence',
            type: 'task',
            description: 'Basic questionnaire and background check'
          }
        },
        {
          id: 'task-2-high',
          type: 'custom',
          position: { x: 400, y: 400 },
          data: {
            label: 'Enhanced Due Diligence',
            type: 'task',
            description: 'Comprehensive assessment questionnaire'
          }
        },
        {
          id: 'task-3',
          type: 'custom',
          position: { x: 100, y: 500 },
          data: {
            label: 'Risk-Based Controls',
            type: 'task',
            description: 'Implement controls based on risk level'
          }
        },
        {
          id: 'task-4',
          type: 'custom',
          position: { x: 100, y: 600 },
          data: {
            label: 'Risk-Based Monitoring',
            type: 'task',
            description: 'Monitor based on risk category'
          }
        },
        {
          id: 'end-1',
          type: 'custom',
          position: { x: 100, y: 700 },
          data: {
            label: 'End TPRM Process',
            type: 'end',
            description: 'Completes the risk-based TPRM workflow'
          }
        }
      ]
    }
  };
  
  const workflowDescriptions = {
    gdpr: {
      default: "Standard GDPR compliance workflow with comprehensive data mapping and controls."
    },
    tprm: {
      default: "Standard TPRM workflow where all vendors go through the same assessment process.",
      riskBased: "Risk-based TPRM workflow with differentiated assessment paths based on initial risk categorization."
    }
  };
  
  export const getWorkflowSuggestion = (type, variant = 'default') => {
    return workflowTemplates[type]?.[variant] || workflowTemplates[type]?.default;
  };
  
  export const getWorkflowDescription = (type, variant = 'default') => {
    return workflowDescriptions[type]?.[variant] || workflowDescriptions[type]?.default;
  };
  
  export const analyzeUserInput = (input) => {
    const lowerInput = input.toLowerCase();
    
    if (lowerInput.includes('tprm') || lowerInput.includes('third party')) {
      if (lowerInput.includes('risk based') || lowerInput.includes('risk-based')) {
        return {
          type: 'tprm',
          variant: 'riskBased',
          reason: 'Suggested based on request for risk-based approach'
        };
      }
      return {
        type: 'tprm',
        variant: 'default',
        reason: 'Standard TPRM workflow suggested'
      };
    }
    
    if (lowerInput.includes('gdpr') || lowerInput.includes('data protection')) {
      return {
        type: 'gdpr',
        variant: 'default',
        reason: 'GDPR workflow suggested based on requirements'
      };
    }
  
    return null;
  };