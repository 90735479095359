import React from 'react';
import { Link } from 'react-router-dom';

const PrivacyPolicy = () => {
  return (
    <div className="bg-gray-100 min-h-screen">
      <header className="bg-white shadow">
        <div className="max-w-7xl mx-auto py-6 px-4 sm:px-6 lg:px-8 flex justify-between items-center">
          <h1 className="text-3xl font-bold text-gray-900">Privacy Policy</h1>
          <Link
            to="/"
            className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded transition duration-300"
          >
            Back to Lokatial
          </Link>
        </div>
      </header>
      <main>
        <div className="max-w-7xl mx-auto py-6 sm:px-6 lg:px-8">
          <div className="px-4 py-6 sm:px-0">
            <div className="border-4 border-dashed border-gray-200 rounded-lg p-4 bg-white">
              <section className="mb-8">
                <h2 className="text-2xl font-semibold mb-4 text-gray-800">1. Introduction</h2>
                <p className="text-gray-600">Welcome to Lokatial's Privacy Policy. This policy describes how we collect, use, and handle your personal information when you use our services.</p>
              </section>

              <section className="mb-8">
                <h2 className="text-2xl font-semibold mb-4 text-gray-800">2. Information We Collect</h2>
                <p className="text-gray-600">We collect information to provide better services to our users. This includes:</p>
                <ul className="list-disc pl-8 mt-2 text-gray-600">
                  <li>Information you provide to us (e.g., name, email address)</li>
                  <li>Usage information when you interact with our services</li>
                  <li>Device information</li>
                  <li>Log information</li>
                </ul>
              </section>

      <section className="mb-8">
        <h2 className="text-2xl font-semibold text-gray-600 mb-4">3. How We Use Information</h2>
        <p className="text-gray-600">We use the information we collect to:</p>
        <ul className="list-disc pl-8 mt-2 text-gray-600">
          <li>Provide, maintain, and improve our services</li>
          <li>Develop new services</li>
          <li>Protect Lokatial and our users</li>
          <li>Communicate with you about our services</li>
        </ul>
      </section>

      <section className="mb-8">
      <h2 className="text-2xl font-semibold mb-4 text-gray-800">4. Data Sharing and Disclosure</h2>
      <p className="text-gray-600">We do not share personal information with companies, organizations, or individuals outside of Lokatial except in the following cases:</p>
      <ul className="list-disc pl-8 mt-2 text-gray-600">
          <li>With your consent</li>
          <li>For legal reasons</li>
        </ul>
      </section>

      <section className="mb-8">
      <h2 className="text-2xl font-semibold mb-4 text-gray-800">5. Data Security</h2>
      <p className="text-gray-600">We work hard to protect our users from unauthorized access, alteration, disclosure, or destruction of information we hold.</p>
      </section>

      <section className="mb-8">
      <h2 className="text-2xl font-semibold mb-4 text-gray-800">6. Your Rights</h2>
      <p className="text-gray-600">Depending on your location, you may have certain rights regarding your personal data, including:</p>
      <ul className="list-disc pl-8 mt-2 text-gray-600">
          <li>The right to access your data</li>
          <li>The right to rectify your data</li>
          <li>The right to erase your data</li>
          <li>The right to restrict processing of your data</li>
          <li>The right to data portability</li>
          <li>The right to object to processing of your data</li>
        </ul>
      </section>

      <section className="mb-8">
      <h2 className="text-2xl font-semibold mb-4 text-gray-800">7. Changes to This Policy</h2>
        <p className="text-gray-600">We may update this privacy policy from time to time. We will notify you of any changes by posting the new privacy policy on this page.</p>
      </section>

      <section>
                <h2 className="text-2xl font-semibold mb-4 text-gray-800">8. Contact Us</h2>
                <p className="text-gray-600">If you have any questions about this privacy policy, please contact us at: hello@lokatial.app</p>
              </section>
            </div>
          </div>
        </div>
      </main>
    </div>
  );
};

export default PrivacyPolicy;