import AuditScopeDefinition from '../auditsteps/AuditScopeDefinition';
import RiskAssessment from '../auditsteps/RiskAssessment';
import AuditPlanning from '../auditsteps/AuditPlanning';
import EvidenceCollection from '../auditsteps/EvidenceCollection';
import FieldworkVerification from '../auditsteps/FieldworkVerification';
import AnalysisEvaluation from '../auditsteps/AnalysisEvaluation';
import FindingsReview from '../auditsteps/FindingsReview';
import Reporting from '../auditsteps/Reporting';
import FollowUpActions from '../auditsteps/FollowUpActions';
import QualityAssurance from '../auditsteps/QualityAssurance';

const AuditSteps = [
  { name: 'Audit Scope Definition', component: AuditScopeDefinition },
  { name: 'Risk Assessment', component: RiskAssessment },
  { name: 'Audit Planning', component: AuditPlanning },
  { name: 'Evidence Collection', component: EvidenceCollection },
  { name: 'Fieldwork and Verification', component: FieldworkVerification },
  { name: 'Analysis and Evaluation', component: AnalysisEvaluation },
  { name: 'Findings Review', component: FindingsReview },
  { name: 'Reporting', component: Reporting },
  { name: 'Follow-up Actions', component: FollowUpActions },
  { name: 'Quality Assurance', component: QualityAssurance },
];

export default AuditSteps;