import React, { memo } from 'react';
import { Handle, Position } from 'reactflow';

const CustomNode = ({ data }) => {
  const nodeStyle = getNodeStyle(data.type);

  const renderHandles = () => {
    switch (data.type) {
      case 'start':
        return <Handle type="source" position={Position.Bottom} />;
      case 'end':
        return <Handle type="target" position={Position.Top} />;
      case 'decision':
        return (
          <>
            <Handle type="target" position={Position.Top} />
            <Handle type="source" position={Position.Bottom} id="yes" style={{ left: '30%' }} />
            <Handle type="source" position={Position.Bottom} id="no" style={{ left: '70%' }} />
          </>
        );
      default:
        return (
          <>
            <Handle type="target" position={Position.Top} />
            <Handle type="source" position={Position.Bottom} />
          </>
        );
    }
  };

  return (
    <div className={`px-4 py-2 shadow-md rounded-md border-2 ${nodeStyle} relative min-w-[150px] max-w-[250px]`}>
      <div className="font-bold text-lg mb-2">{data.label}</div>
      <div className="text-sm">{data.description}</div>
      {data.type === 'decision' && (
        <div className="text-xs mt-2">
          <span className="mr-2">Yes (Left)</span>
          <span>No (Right)</span>
        </div>
      )}
      {renderHandles()}
    </div>
  );
};

const getNodeStyle = (type) => {
  const styles = {
    start: 'bg-green-100 border-green-500 text-green-800',
    task: 'bg-blue-100 border-blue-500 text-blue-800',
    decision: 'bg-yellow-100 border-yellow-500 text-yellow-800',
    end: 'bg-red-100 border-red-500 text-red-800',
    form: 'bg-purple-100 border-purple-500 text-purple-800',
    calculation: 'bg-indigo-100 border-indigo-500 text-indigo-800',
    notification: 'bg-orange-100 border-orange-500 text-orange-800',
  };
  return styles[type] || 'bg-gray-100 border-gray-500 text-gray-800';
};

export default memo(CustomNode);