import React, { useState, useEffect, useCallback } from 'react';
import { Input } from '../ui/Input';
import { Button } from '../ui/Button';
import { PlusCircle, Trash2, Bot } from 'lucide-react';
import AIThinkingAnimation from '../AIThinkingAnimation';

const DepartmentsStep = ({ data, onChange, aiSuggestions, isLoading }) => {
  const [newDepartment, setNewDepartment] = useState('');
  const [userDepartments, setUserDepartments] = useState(data || []);
  const [suggestedDepartments, setSuggestedDepartments] = useState([]);
 
  useEffect(() => {
    if (Array.isArray(aiSuggestions)) {
      setSuggestedDepartments(aiSuggestions.filter(suggestion => 
        !userDepartments.includes(suggestion)
      ));
    }
  }, [aiSuggestions, userDepartments]);

  const handleAddDepartment = useCallback(() => {
    if (newDepartment.trim()) {
      const updatedDepartments = [...userDepartments, newDepartment.trim()];
      setUserDepartments(updatedDepartments);
      onChange(updatedDepartments);
      setNewDepartment('');
    }
  }, [newDepartment, userDepartments, onChange]);

  const handleRemoveDepartment = useCallback((index) => {
    const updatedDepartments = userDepartments.filter((_, i) => i !== index);
    setUserDepartments(updatedDepartments);
    onChange(updatedDepartments);
  }, [userDepartments, onChange]);

  const handleAcceptSuggestion = useCallback((acceptedDepartment) => {
    const updatedUserDepartments = [...userDepartments, acceptedDepartment];
    setUserDepartments(updatedUserDepartments);
    onChange(updatedUserDepartments);
    setSuggestedDepartments(prevSuggestions => 
      prevSuggestions.filter(suggestion => suggestion !== acceptedDepartment)
    );
  }, [userDepartments, onChange]);

  return (
    <div className="space-y-4">
      {/* AI-Suggested Departments */}
      <div>
        <h3 className="text-lg font-medium flex items-center text-purple-500">
          <Bot className="w-5 h-5 mr-2 text-purple-500" />
          AI-Suggested Departments
        </h3>
        <div className="space-y-2 mt-2">
          {isLoading ? (
            <div className="my-8 p-4 bg-purple-50 rounded-lg shadow-inner">
              <AIThinkingAnimation />
              <p className="text-center text-purple-700 mt-4 font-medium">
                AI is generating department suggestions...
              </p>
            </div>
          ) : suggestedDepartments.length > 0 ? (
            suggestedDepartments.map((department, index) => (
              <div key={index} className="flex items-center bg-purple-50 p-2 rounded">
                <span className="flex-grow text-purple-700">{department}</span>
                <Button
                  variant="outline"
                  size="sm"
                  className="ml-2"
                  onClick={() => handleAcceptSuggestion(department)}
                >
                  Accept
                </Button>
              </div>
            ))
          ) : (
            <p className="text-gray-500 italic">No AI suggestions available.</p>
          )}
        </div>
      </div>

      {/* User Departments */}
      <div>
        <h3 className="text-lg font-medium text-gray-800">Added Departments</h3>
        <div className="space-y-2 mt-2">
          {userDepartments.map((department, index) => (
            <div key={index} className="flex items-center bg-gray-100 p-2 rounded">
              <span className="flex-grow text-gray-700">{department}</span>
              <Button
                variant="ghost"
                size="icon"
                className="text-red-500 hover:text-red-700 hover:bg-red-100"
                onClick={() => handleRemoveDepartment(index)}
              >
                <Trash2 className="w-4 h-4" />
              </Button>
            </div>
          ))}
        </div>
      </div>

      {/* Add New Department */}
      <div className="flex items-center mt-4">
        <Input
          placeholder="Add a new department"
          value={newDepartment}
          onChange={(e) => setNewDepartment(e.target.value)}
        />
        <Button
          variant="outline"
          onClick={handleAddDepartment}
          className="ml-2 bg-purple-100 text-purple-700 hover:bg-purple-200"
        >
          <PlusCircle className="w-4 h-4 mr-1" />
          Add
        </Button>
      </div>
    </div>
  );
};

export default DepartmentsStep;