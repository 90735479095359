import React, { useState } from 'react';
import { Tab, TabGroup, TabList, TabPanel, TabPanels } from '@headlessui/react';
import { CheckCircle, XCircle, ExternalLink } from 'lucide-react';

function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

const PerformanceContent = () => {
  const [quizStarted, setQuizStarted] = useState(false);
  const [currentQuestion, setCurrentQuestion] = useState(0);
  const [score, setScore] = useState(0);
  const [showResults, setShowResults] = useState(false);

  const content = {
    overview: {
      introduction: "Performance evaluation in compliance management focuses on assessing the effectiveness of compliance processes and identifying areas for improvement. It helps organizations measure how well they are adhering to regulations and achieving compliance objectives.",
      keyPoints: [
        "Conducting regular performance evaluations of compliance processes",
        "Setting clear performance indicators and benchmarks",
        "Using data analytics to monitor compliance trends",
        "Evaluating the effectiveness of training and awareness programs",
        "Reporting on compliance performance to stakeholders"
      ],
      importance: "Regularly assessing compliance performance ensures that the organization remains aligned with regulatory requirements and that compliance initiatives are effective. It also provides insights into potential risks and areas for improvement.",
      bestPractices: [
        "Establish key performance indicators (KPIs) for compliance objectives",
        "Use technology to track and report on compliance metrics",
        "Conduct regular audits and assessments to ensure continuous improvement",
        "Engage employees in performance evaluations to gather feedback",
        "Implement corrective actions based on performance results"
      ]
    },
    quiz: [
      {
        question: "What is the main purpose of evaluating compliance performance?",
        options: [
          "To identify underperforming employees",
          "To measure the effectiveness of compliance efforts",
          "To increase workload for compliance officers",
          "To satisfy external auditors"
        ],
        correctAnswer: 1
      },
      {
        question: "Which tool can be most helpful for monitoring compliance performance trends?",
        options: [
          "Paper-based reports",
          "Data analytics platforms",
          "Verbal feedback from employees",
          "Annual compliance meetings"
        ],
        correctAnswer: 1
      },
      {
        question: "What is a critical best practice in performance evaluation?",
        options: [
          "Establishing KPIs for compliance",
          "Conducting evaluations only during audits",
          "Ignoring employee feedback",
          "Focusing only on financial metrics"
        ],
        correctAnswer: 0
      }
    ],
    resources: [
      { title: "ISO 9001:2015 Performance Evaluation Guidelines", url: "https://www.iso.org/standard/62085.html" },
      { title: "Key Performance Indicators for Compliance Management", url: "https://www.corporatecomplianceinsights.com/key-performance-indicators-kpis-compliance/" },
      { title: "Using Data Analytics for Compliance Performance", url: "https://www2.deloitte.com/global/en/pages/risk/articles/using-data-analytics-in-compliance.html" }
    ]
  };

  // Logic for handling quiz submission and scoring
  const handleAnswerClick = (index) => {
    if (!quizStarted) return;
    if (index === content.quiz[currentQuestion].correctAnswer) {
      setScore(score + 1);
    }
    const nextQuestion = currentQuestion + 1;
    if (nextQuestion < content.quiz.length) {
      setCurrentQuestion(nextQuestion);
    } else {
      setShowResults(true);
    }
  };

  const startQuiz = () => {
    setQuizStarted(true);
    setCurrentQuestion(0);
    setScore(0);
    setShowResults(false);
  };

  return (
    <TabGroup>
      <TabList className="flex space-x-1 rounded-xl bg-blue-900/20 p-1">
        {['Overview', 'Key Points', 'Quiz', 'Resources'].map((tab) => (
          <Tab
            key={tab}
            className={({ selected }) =>
              classNames(
                'w-full rounded-lg py-2.5 text-sm font-medium leading-5 text-blue-700',
                'ring-white ring-opacity-60 ring-offset-2 ring-offset-blue-400 focus:outline-none focus:ring-2',
                selected
                  ? 'bg-white shadow'
                  : 'text-blue-100 hover:bg-white/[0.12] hover:text-white'
              )
            }
          >
            {tab}
          </Tab>
        ))}
      </TabList>
      <TabPanels className="mt-2">
        {/* Overview */}
        <TabPanel className="rounded-xl bg-white p-3">
          <p className="text-gray-700">{content.overview.introduction}</p>
          <h3 className="font-semibold mt-4 mb-2">Importance:</h3>
          <p className="text-gray-700">{content.overview.importance}</p>
        </TabPanel>
        {/* Key Points */}
        <TabPanel className="rounded-xl bg-white p-3">
          <h3 className="font-semibold mb-2">Key Points:</h3>
          <ul className="list-disc pl-5 space-y-2">
            {content.overview.keyPoints.map((point, index) => (
              <li key={index} className="text-gray-700">{point}</li>
            ))}
          </ul>
          <h3 className="font-semibold mt-4 mb-2">Best Practices:</h3>
          <ul className="list-disc pl-5 space-y-2">
            {content.overview.bestPractices.map((practice, index) => (
              <li key={index} className="text-gray-700">{practice}</li>
            ))}
          </ul>
        </TabPanel>
        {/* Quiz */}
        <TabPanel className="rounded-xl bg-white p-3">
          {!quizStarted ? (
            <button onClick={startQuiz} className="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600 transition duration-300">
              Start Quiz
            </button>
          ) : showResults ? (
            <div>
              <h2 className="text-xl font-semibold mb-4">Your Score: {score}/{content.quiz.length}</h2>
              <button onClick={startQuiz} className="bg-green-500 text-white px-4 py-2 rounded hover:bg-green-600 transition duration-300 mt-2">
                Retry Quiz
              </button>
            </div>
          ) : (
            <div>
              <h3 className="text-lg font-semibold mb-4">{content.quiz[currentQuestion].question}</h3>
              <ul className="space-y-2">
                {content.quiz[currentQuestion].options.map((option, index) => (
                  <li 
                    key={index} 
                    onClick={() => handleAnswerClick(index)} 
                    className="cursor-pointer p-2 rounded bg-gray-100 hover:bg-gray-200 transition duration-300"
                  >
                    {option}
                  </li>
                ))}
              </ul>
            </div>
          )}
        </TabPanel>
        {/* Resources */}
        <TabPanel className="rounded-xl bg-white p-3">
          <ul className="space-y-2">
            {content.resources.map((resource, index) => (
              <li key={index} className="flex items-center">
                <ExternalLink size={18} className="mr-2 text-blue-500" /> 
                <a href={resource.url} target="_blank" rel="noreferrer" className="text-blue-500 hover:underline">
                  {resource.title}
                </a>
              </li>
            ))}
          </ul>
        </TabPanel>
      </TabPanels>
    </TabGroup>
  );
};

export default PerformanceContent;
