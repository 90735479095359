import React from 'react';
import { Heart, MapPin, Mail, Brain, Shield, Bot } from 'lucide-react';

const Footer = () => {
  const typeformUrl = "https://adi18k4liww.typeform.com/to/DYf77usz";
  
  return (
    <footer className="bg-gray-900 text-white">
      <div className="max-w-7xl mx-auto px-6 lg:px-8 py-16">
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-12">
          {/* Branding & Description */}
          <div>
            <div className="font-bold text-2xl mb-6 text-white">LOKATIAL</div>
            <div className="space-y-4">
              <p className="text-gray-400">
                Next-gen compliance platform for AI-first enterprises.
              </p>
              <div className="flex items-center space-x-2 text-purple-400">
                <Brain className="w-4 h-4" />
                <span className="text-sm">Early Access Program</span>
              </div>
            </div>
          </div>

          {/* Product Features */}
          <div>
            <h4 className="text-lg font-semibold mb-4">Platform</h4>
            <ul className="space-y-3 text-gray-400">
              <li className="hover:text-white transition-colors">
                Process Design Wizard
              </li>
              <li className="hover:text-white transition-colors">
                Visual Workflow Builder
              </li>
              <li className="hover:text-white transition-colors">
                Mock Audit Wizard
              </li>
              <li className="hover:text-white transition-colors">
                LokCO AI Co-pilot
              </li>
            </ul>
          </div>

          {/* Solutions */}
          <div>
            <h4 className="text-lg font-semibold mb-4">Solutions</h4>
            <ul className="space-y-3 text-gray-400">
              <li className="hover:text-white transition-colors group flex items-center">
                <Shield className="w-4 h-4 mr-2 text-blue-400 group-hover:text-white transition-colors" />
                EU AI Act Compliance
              </li>
              <li className="hover:text-white transition-colors group flex items-center">
                <Brain className="w-4 h-4 mr-2 text-purple-400 group-hover:text-white transition-colors" />
                AI Model Governance
              </li>
              <li className="hover:text-white transition-colors group flex items-center">
                <Bot className="w-4 h-4 mr-2 text-green-400 group-hover:text-white transition-colors" />
                AI Risk Management
              </li>
            </ul>

            
          </div>

          {/* Company Links */}
          <div>
            <h4 className="text-lg font-semibold mb-4">Company</h4>
            <ul className="space-y-3 text-gray-400">
              <li>
                <a 
                  href={typeformUrl}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="hover:text-white transition-colors block"
                >
                  Contact
                </a>
              </li>
            </ul>

          </div>
        </div>

        {/* Footer Bottom */}
        <div className="mt-16 pt-8 border-t border-gray-800">
          <div className="flex flex-col md:flex-row justify-between items-center gap-4">
            <div className="flex flex-col md:flex-row items-center md:items-start gap-2 text-gray-400 text-sm">
              <span>&copy; {new Date().getFullYear()} LOKATIAL</span>
              <span className="hidden md:inline">•</span>
              <a 
                href="mailto:hello@lokatial.app" 
                className="text-gray-400 hover:text-purple-400 transition-colors duration-200 flex items-center gap-1"
              >
                <Mail className="w-4 h-4" />
                hello@lokatial.app
              </a>
              <span className="hidden md:inline">•</span>
              <span>All rights reserved.</span>
            </div>
            
            <div className="flex items-center text-gray-400 text-sm gap-1">
              <MapPin className="w-4 h-4" />
              Made with <Heart className="mx-1 text-red-500 animate-pulse" size={14} /> in Germany
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;