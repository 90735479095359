import React from 'react';
import { Clipboard, Database, FileText, Eye, UserCheck, Shield, Activity } from 'lucide-react';

const KeyRequirements = () => {
  const requirements = [
    {
      id: 1,
      title: "Risk Management System",
      icon: Clipboard,
      description: "Establish and maintain a risk management system throughout the entire lifecycle of the AI system.",
      details: [
        "Identify and analyze known and foreseeable risks",
        "Estimate and evaluate risks that may emerge",
        "Adopt suitable risk management measures",
      ],
      article: "Article 9"
    },
    {
      id: 2,
      title: "Data and Data Governance",
      icon: Database,
      description: "Implement appropriate data governance and management practices.",
      details: [
        "Ensure relevance, representativeness, and freedom from errors in training, validation, and testing data sets",
        "Examine for possible biases",
        "Identify any data gaps or shortcomings",
      ],
      article: "Article 10"
    },
    {
      id: 3,
      title: "Technical Documentation",
      icon: FileText,
      description: "Maintain detailed technical documentation for demonstrating compliance.",
      details: [
        "General description of the AI system",
        "Detailed information on data sets used",
        "Description of system architecture",
        "Documentation of monitoring, functioning, and control procedures",
      ],
      article: "Article 11"
    },
    {
      id: 4,
      title: "Record-Keeping",
      icon: Activity,
      description: "Maintain logs automatically generated by the AI system.",
      details: [
        "Enable monitoring of system operation",
        "Facilitate post-market monitoring",
        "Allow for traceability and investigation of incidents",
      ],
      article: "Article 12"
    },
    {
      id: 5,
      title: "Transparency",
      icon: Eye,
      description: "Ensure transparency and provide information to users.",
      details: [
        "Clear and adequate information on system capabilities and limitations",
        "Instructions for use, including human oversight measures",
        "Expected lifetime of the AI system and necessary maintenance measures",
      ],
      article: "Article 13"
    },
    {
      id: 6,
      title: "Human Oversight",
      icon: UserCheck,
      description: "Implement appropriate human oversight measures and interfaces.",
      details: [
        "Enable human monitoring of AI system operation",
        "Ability to intervene or interrupt the system through a stop button or similar procedure",
        "Ensure oversight is carried out by competent and properly trained individuals",
      ],
      article: "Article 14"
    },
    {
      id: 7,
      title: "Accuracy, Robustness, and Cybersecurity",
      icon: Shield,
      description: "Ensure appropriate levels of accuracy, robustness, and cybersecurity.",
      details: [
        "Achieve appropriate levels of accuracy for the intended purpose",
        "Be resilient to errors, faults, and inconsistencies",
        "Implement measures to protect against attempts to manipulate system behavior",
      ],
      article: "Article 15"
    },
  ];

  return (
    <div className="space-y-8">
      <p className="text-lg text-gray-700 mb-6">
        The EU AI Act sets out specific requirements for high-risk AI systems, including those used in healthcare and pharma. 
        Compliance with these key requirements is essential for organizations developing or deploying AI in these sectors.
      </p>
      
      {requirements.map((req) => (
        <div key={req.id} className="bg-white p-6 rounded-lg shadow-md">
          <div className="flex items-center mb-4">
            <req.icon className="w-8 h-8 text-blue-500 mr-3" />
            <h3 className="text-xl font-semibold">{req.title}</h3>
          </div>
          <p className="text-gray-600 mb-4">{req.description}</p>
          <ul className="list-disc pl-5 mb-4">
            {req.details.map((detail, index) => (
              <li key={index} className="text-gray-700">{detail}</li>
            ))}
          </ul>
          <p className="text-sm text-blue-600">Relevant EU AI Act Article: {req.article}</p>
        </div>
      ))}
      
      <div className="bg-yellow-50 p-6 rounded-lg border border-yellow-200 mt-8">
        <h4 className="text-lg font-semibold mb-2 text-yellow-800">Important Note for Healthcare & Pharma</h4>
        <p className="text-gray-700">
          AI systems used in healthcare, such as those for diagnosis, prognosis, treatment planning, or as medical devices, 
          are typically classified as high-risk under the EU AI Act. This means they must comply with all the above requirements. 
          Particular attention should be paid to patient safety, data privacy, and the potential impact on clinical decisions.
        </p>
      </div>
    </div>
  );
};

export default KeyRequirements;