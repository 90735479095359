import React, { useState } from 'react';
import { 
  FileText, 
  Users, 
  CheckSquare, 
  AlertTriangle, 
  Shield, 
  Layout,
  Book,
  FileCheck,
  Settings,
  Calendar,
  Clock,
  Search
} from 'lucide-react';
import { Card } from './ui/Card';
import { Button } from './ui/Button';
import { Input } from './ui/Input';

// Import your existing components
import EUAIActOverview from './EUAIActOverview';
import RiskManagement from './RiskManagement';
import KeyRequirements from './KeyRequirements';
import TechnicalDocumentation from './TechnicalDocumentation';
import HumanOversight from './HumanOversight';
import ConformityAssessment from './ConformityAssessment';

const navigationItems = [
  {
    category: "Essential",
    items: [
      { id: 'overview', label: 'Overview', icon: Layout, component: EUAIActOverview },
      { id: 'requirements', label: 'Key Requirements', icon: CheckSquare, component: KeyRequirements },
      { id: 'risk', label: 'Risk Management', icon: AlertTriangle, component: RiskManagement }
    ]
  },
  {
    category: "Documentation",
    items: [
      { id: 'technical', label: 'Technical Documentation', icon: FileText, component: TechnicalDocumentation },
      { id: 'conformity', label: 'Conformity Assessment', icon: FileCheck, component: ConformityAssessment }
    ]
  },
  {
    category: "Oversight",
    items: [
      { id: 'human', label: 'Human Oversight', icon: Users, component: HumanOversight },
      { id: 'monitoring', label: 'Continuous Monitoring', icon: Clock, component: null }
    ]
  },
  {
    category: "Resources",
    items: [
      { id: 'guidelines', label: 'Guidelines & Templates', icon: Book, component: null },
      { id: 'timeline', label: 'Compliance Timeline', icon: Calendar, component: null }
    ]
  }
];

const EUAIActComplianceHub = () => {
  const [activeTab, setActiveTab] = useState('overview');
  const [searchQuery, setSearchQuery] = useState('');

  const ActiveComponent = navigationItems
    .flatMap(category => category.items)
    .find(item => item.id === activeTab)?.component || (() => <div>Coming Soon</div>);

  return (
    <div className="flex h-full bg-gray-50">
      {/* Left Navigation */}
      <div className="w-64 bg-white border-r border-gray-200 overflow-y-auto">
        <div className="p-4">
          <h2 className="text-xl font-bold text-gray-800">EU AI Act Hub</h2>
          <div className="mt-4 relative">
            <Search className="absolute left-3 top-2.5 h-4 w-4 text-gray-500" />
            <Input
              placeholder="Search..."
              className="pl-9 w-full"
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
            />
          </div>
        </div>
        
        <nav className="mt-2">
          {navigationItems.map((category) => (
            <div key={category.category} className="mb-4">
              <div className="px-4 py-2">
                <h3 className="text-sm font-semibold text-gray-500">{category.category}</h3>
              </div>
              {category.items.map((item) => (
                <Button
                  key={item.id}
                  variant="ghost"
                  className={`w-full justify-start px-4 py-2 ${
                    activeTab === item.id
                      ? 'bg-blue-50 text-blue-700'
                      : 'text-gray-700 hover:bg-gray-100'
                  }`}
                  onClick={() => setActiveTab(item.id)}
                >
                  <item.icon className="mr-2 h-4 w-4" />
                  {item.label}
                </Button>
              ))}
            </div>
          ))}
        </nav>
      </div>

      {/* Main Content */}
      <div className="flex-1 overflow-y-auto">
        <div className="p-6">
          {/* Quick Actions */}
          <div className="mb-6">
            <div className="flex gap-4">
              <Button className="bg-blue-600 hover:bg-blue-700">
                <FileCheck className="mr-2 h-4 w-4" />
                Start Assessment
              </Button>
              <Button variant="outline">
                <Settings className="mr-2 h-4 w-4" />
                Configure
              </Button>
            </div>
          </div>

          {/* Main Content Area */}
          <Card className="p-6">
            <ActiveComponent />
          </Card>
        </div>
      </div>
    </div>
  );
};

export default EUAIActComplianceHub;