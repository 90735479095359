import React, { useState } from 'react';
import { motion } from 'framer-motion';
import ReactConfetti from 'react-confetti';
import { Card, CardContent, CardHeader, CardTitle } from "./Card";
import { Tabs, TabsContent, TabsList, TabsTrigger } from "./Tabs";
import { Button } from "./Button";
import { Accordion, AccordionContent, AccordionItem, AccordionTrigger } from "./Accordion";
import { Badge } from "./Badge";
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from "./Table";
import { BarChart, PieChart, Pie, Bar, Cell, XAxis, YAxis, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import { Download, BarChart2, PieChart as PieChartIcon, CheckCircle } from "lucide-react";

const CompletionModal = ({ isOpen, onClose, onFinish }) => {
  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
      <ReactConfetti />
      <div className="bg-white p-6 rounded-lg shadow-xl max-w-md w-full">
        <div className="flex items-center justify-center mb-4">
          <CheckCircle className="text-green-500 w-12 h-12" />
        </div>
        <h2 className="text-2xl font-bold text-center mb-4">Process Draft Saved!</h2>
        <p className="text-gray-600 text-center mb-6">
          Congratulations! Your draft process has been saved in the Lokatial CMS. To make it production-ready, consider the following steps:
        </p>
        <ul className="list-disc pl-5 mb-6 text-gray-600">
          <li>Connect to relevant data sources for real-time insights</li>
          <li>Integrate with external compliance tools and databases</li>
          <li>Set up automated alerts and notifications</li>
          <li>Define user roles and permissions</li>
          <li>Schedule regular reviews and updates</li>
        </ul>
        <Button onClick={onFinish} className="w-full">
          Close and View Drafts
        </Button>
      </div>
    </div>
  );
};

const ProcessSummaryVisualization = ({
  processName,
  processType,
  objectives,
  involvedDepartments,
  selectedRiskCategories,
  riskPrioritizationData,
  monitoringFrequency,
  auditFrequency,
  complianceActivities,
  correctionPlan,
  preventionPlan,
  onFinishAndSave
}) => {
  const [activeTab, setActiveTab] = useState("summary");
  const [isCompletionModalOpen, setIsCompletionModalOpen] = useState(false);

  const handleTabChange = (value) => {
    setActiveTab(value);
  };

  const generateSummary = () => {
    if (processType === 'Risk Assessment') {
      return `This Risk Assessment process "${processName}" involves ${objectives.split('\n').length} key objectives and spans across ${involvedDepartments.length} departments. It focuses on ${selectedRiskCategories.length} risk categories.`;
    } else {
      return `This Compliance Monitoring process "${processName}" involves ${objectives.split('\n').length} key objectives and spans across ${involvedDepartments.length} departments. It includes regular monitoring (${monitoringFrequency}) and audits (${auditFrequency}).`;
    }
  };

  const handleExport = (format) => {
    console.log(`Exporting as ${format}`);
    // Implement export functionality here
  };

  const handleFinishAndSave = () => {
    setIsCompletionModalOpen(true);
  };

  const handleCloseAndFinish = () => {
    setIsCompletionModalOpen(false);
    if (onFinishAndSave) onFinishAndSave();
  };

  const riskData = Object.entries(riskPrioritizationData).map(([category, data]) => ({
    category,
    score: data.score
  }));

  const departmentData = involvedDepartments.map((dept, index) => ({
    name: dept,
    value: Math.floor(Math.random() * 100) + 1 // Placeholder for actual involvement data
  }));

  const COLORS = ['#0088FE', '#00C49F', '#FFBB28', '#FF8042', '#8884d8', '#82ca9d'];

  return (
    <>
      <Card className="w-full max-w-4xl mx-auto">
        <CardHeader>
          <CardTitle className="text-2xl font-bold">Process Summary & Visualization</CardTitle>
        </CardHeader>
        <CardContent>
          <Tabs value={activeTab} onValueChange={handleTabChange}>
            <TabsList>
              <TabsTrigger 
                value="summary" 
                onClick={() => handleTabChange("summary")} 
                active={activeTab === "summary"}
              >
                Summary
              </TabsTrigger>
              <TabsTrigger 
                value="visualization" 
                onClick={() => handleTabChange("visualization")} 
                active={activeTab === "visualization"}
              >
                Visualization
              </TabsTrigger>
            </TabsList>
            <TabsContent value="summary" activeValue={activeTab}>
            <motion.div
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.5 }}
              className="space-y-6"
            >
              <div className="p-4 bg-gradient-to-r from-purple-100 to-blue-100 rounded-md shadow-inner">
                <h3 className="text-xl font-semibold mb-2 text-purple-800">Process Overview</h3>
                <p className="text-gray-800">{generateSummary()}</p>
              </div>
              
              <Accordion type="single" collapsible className="w-full">
                <AccordionItem value="objectives">
                  <AccordionTrigger>Objectives</AccordionTrigger>
                  <AccordionContent>
                    <ul className="list-disc pl-5 space-y-1">
                      {objectives.split('\n').map((objective, index) => (
                        <li key={index} className="text-gray-700">{objective}</li>
                      ))}
                    </ul>
                  </AccordionContent>
                </AccordionItem>

                <AccordionItem value="departments">
                  <AccordionTrigger>Involved Departments</AccordionTrigger>
                  <AccordionContent>
                    <div className="flex flex-wrap gap-2">
                      {involvedDepartments.map((dept, index) => (
                        <Badge key={index} variant="secondary" className="text-sm">{dept}</Badge>
                      ))}
                    </div>
                  </AccordionContent>
                </AccordionItem>

                {processType === 'Risk Assessment' && (
                  <AccordionItem value="risks">
                    <AccordionTrigger>Risk Categories & Prioritization</AccordionTrigger>
                    <AccordionContent>
                      <Table>
                        <TableHeader>
                          <TableRow>
                            <TableHead>Risk Category</TableHead>
                            <TableHead>Likelihood</TableHead>
                            <TableHead>Impact</TableHead>
                            <TableHead>Score</TableHead>
                          </TableRow>
                        </TableHeader>
                        <TableBody>
                          {Object.entries(riskPrioritizationData).map(([category, data]) => (
                            <TableRow key={category}>
                              <TableCell>{category}</TableCell>
                              <TableCell>{data.likelihood}</TableCell>
                              <TableCell>{data.impact}</TableCell>
                              <TableCell>{data.score}</TableCell>
                            </TableRow>
                          ))}
                        </TableBody>
                      </Table>
                    </AccordionContent>
                  </AccordionItem>
                )}

                {processType === 'Compliance Monitoring' && (
                  <AccordionItem value="monitoring">
                    <AccordionTrigger>Monitoring & Audit Plan</AccordionTrigger>
                    <AccordionContent>
                      <p><strong>Monitoring Frequency:</strong> {monitoringFrequency}</p>
                      <p><strong>Audit Frequency:</strong> {auditFrequency}</p>
                      <p className="mt-2"><strong>Compliance Activities:</strong></p>
                      <ul className="list-disc pl-5 space-y-1">
                        {complianceActivities.map((activity, index) => (
                          <li key={index} className="text-gray-700">{activity}</li>
                        ))}
                      </ul>
                    </AccordionContent>
                  </AccordionItem>
                )}

                <AccordionItem value="actions">
                  <AccordionTrigger>Corrective & Preventive Actions</AccordionTrigger>
                  <AccordionContent>
                    <p><strong>Correction Plan:</strong> {correctionPlan}</p>
                    <p className="mt-2"><strong>Prevention Plan:</strong> {preventionPlan}</p>
                  </AccordionContent>
                </AccordionItem>
              </Accordion>
            </motion.div>
          </TabsContent>
          <TabsContent value="visualization" activeValue={activeTab}>
            <motion.div
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.5 }}
              className="space-y-6"
            >
              <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                {processType === 'Risk Assessment' && (
                  <Card>
                    <CardHeader>
                      <CardTitle className="text-lg font-semibold flex items-center">
                        <BarChart2 className="mr-2" /> Risk Prioritization
                      </CardTitle>
                    </CardHeader>
                    <CardContent>
                      <ResponsiveContainer width="100%" height={300}>
                        <BarChart data={riskData}>
                          <XAxis dataKey="category" />
                          <YAxis />
                          <Tooltip />
                          <Legend />
                          <Bar dataKey="score" fill="#8884d8" />
                        </BarChart>
                      </ResponsiveContainer>
                    </CardContent>
                  </Card>
                )}
                <Card>
                  <CardHeader>
                    <CardTitle className="text-lg font-semibold flex items-center">
                      <PieChartIcon className="mr-2" /> Department Involvement
                    </CardTitle>
                  </CardHeader>
                  <CardContent>
                    <ResponsiveContainer width="100%" height={300}>
                      <PieChart>
                        <Pie
                          data={departmentData}
                          cx="50%"
                          cy="50%"
                          labelLine={false}
                          outerRadius={80}
                          fill="#8884d8"
                          dataKey="value"
                        >
                          {departmentData.map((entry, index) => (
                            <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                          ))}
                        </Pie>
                        <Tooltip />
                        <Legend />
                      </PieChart>
                    </ResponsiveContainer>
                  </CardContent>
                </Card>
              </div>
            </motion.div>
          </TabsContent>
        </Tabs>
        <div className="mt-6 flex justify-between items-center">
            <div>
            <Button onClick={() => handleExport("pdf")} variant="outline" className="flex items-center mr-2">
              <Download className="mr-2 h-4 w-4" /> Export as PDF
            </Button>
            <Button onClick={() => handleExport("png")} variant="outline" className="flex items-center">
              <Download className="mr-2 h-4 w-4" /> Export as PNG
            </Button>
          </div>
          <Button 
            onClick={handleFinishAndSave} 
            className="bg-green-500 hover:bg-green-600 text-white px-6 py-2"
          >
            Finish and Save Draft
            </Button>
          </div>
        </CardContent>
      </Card>
      <CompletionModal 
        isOpen={isCompletionModalOpen} 
        onClose={() => setIsCompletionModalOpen(false)}
        onFinish={handleCloseAndFinish}
      />
    </>
  );
};

export default ProcessSummaryVisualization;