// RiskManagement.js

import React, { useEffect, useReducer, Suspense } from 'react';
import {
  AlertTriangle,
  Shield,
  CheckCircle,
  XCircle,
  AlertCircle,
  Activity,
  Sliders,
  ChevronDown,
  ChevronUp,
  Download,
} from 'lucide-react';
import { saveAs } from 'file-saver'; // Ensure you've installed this package
import RiskAssessmentForm from './RiskAssessmentForm'; // Ensure this component exists
import Checklist from './Checklist'; // Ensure this component exists

// Define riskCategories outside the component
const riskCategories = [
  { id: 1, name: 'Patient Safety', icon: AlertTriangle, color: 'text-red-500' },
  { id: 2, name: 'Data Privacy', icon: Shield, color: 'text-blue-500' },
  { id: 3, name: 'Model Bias', icon: Sliders, color: 'text-yellow-500' },
  { id: 4, name: 'Regulatory Compliance', icon: CheckCircle, color: 'text-green-500' },
  { id: 5, name: 'Operational Risks', icon: Activity, color: 'text-purple-500' },
];

const riskMatrix = [
  { id: 1, level: 'High', icon: XCircle, color: 'bg-red-100 text-red-700' },
  { id: 2, level: 'Medium', icon: AlertCircle, color: 'bg-yellow-100 text-yellow-700' },
  { id: 3, level: 'Low', icon: CheckCircle, color: 'bg-green-100 text-green-700' },
];

const initialState = {
  selectedRisk: null,
  expandedStep: null,
  assessments: [], // Stores user assessments
};

const reducer = (state, action) => {
  switch (action.type) {
    case 'SET_SELECTED_RISK':
      return { ...state, selectedRisk: action.payload };
    case 'TOGGLE_STEP':
      return {
        ...state,
        expandedStep: state.expandedStep === action.payload ? null : action.payload,
      };
    case 'ADD_ASSESSMENT':
      return { ...state, assessments: [...state.assessments, action.payload] };
    case 'REMOVE_ASSESSMENT':
      return {
        ...state,
        assessments: state.assessments.filter((_, index) => index !== action.payload),
      };
    case 'SET_ASSESSMENTS':
      return { ...state, assessments: action.payload };
    default:
      return state;
  }
};

const RiskManagement = () => {
  const [state, dispatch] = useReducer(reducer, initialState);
  const { selectedRisk, expandedStep, assessments } = state;

  const riskMitigationSteps = [
    // ... [same as before]
  ];

  // Persist assessments in localStorage
  useEffect(() => {
    const storedAssessments = localStorage.getItem('assessments');
    if (storedAssessments) {
      try {
        const parsedAssessments = JSON.parse(storedAssessments);
        // Validate that parsedAssessments is an array
        if (Array.isArray(parsedAssessments)) {
          // Map through parsedAssessments to add riskCategoryName if missing
          const updatedAssessments = parsedAssessments.map((assessment) => {
            if (!assessment.riskCategoryName && assessment.riskCategory) {
              const category = riskCategories.find((c) => c.id === assessment.riskCategory);
              return {
                ...assessment,
                riskCategoryName: category ? category.name : 'Unknown Category',
              };
            }
            return assessment;
          });
          dispatch({ type: 'SET_ASSESSMENTS', payload: updatedAssessments });
        } else {
          console.error('Stored assessments are not an array.');
        }
      } catch (error) {
        console.error('Failed to parse assessments from localStorage:', error);
      }
    }
  }, []); // Empty dependency array ensures this runs once

  useEffect(() => {
    localStorage.setItem('assessments', JSON.stringify(assessments));
  }, [assessments]);

  const toggleStep = (index) => {
    dispatch({ type: 'TOGGLE_STEP', payload: index });
  };

  const handleRiskSelection = (id) => {
    dispatch({ type: 'SET_SELECTED_RISK', payload: id });
  };

  const handleAddAssessment = (assessment) => {
    dispatch({ type: 'ADD_ASSESSMENT', payload: assessment });
  };

  const handleRemoveAssessment = (index) => {
    dispatch({ type: 'REMOVE_ASSESSMENT', payload: index });
  };

  const exportReport = () => {
    const blob = new Blob([JSON.stringify(assessments, null, 2)], { type: 'application/json;charset=utf-8' });
    saveAs(blob, 'risk_management_report.json');
  };

  return (
    <div className="p-6 bg-white rounded-lg shadow-md">
      <h2 className="text-3xl font-bold mb-6 text-blue-800">AI Risk Management Framework</h2>

      <p className="mb-6 text-lg leading-relaxed text-gray-700">
        Under the EU AI Act, healthcare and pharma organizations must implement a robust risk management system for AI
        applications. This framework helps identify, assess, and mitigate risks associated with AI systems, as mandated
        by Article 9 of the EU AI Act.
      </p>

      {/* Layout with Flexbox */}
      <div className="flex flex-col lg:flex-row lg:space-x-8">
        {/* Risk Categories */}
        <div className="lg:w-1/3">
          <h3 className="text-2xl font-semibold mb-4 text-blue-700">Key Risk Categories</h3>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mb-8">
            {riskCategories.map((category) => (
              <button
                key={category.id}
                className={`p-4 rounded-lg border-2 transition-all focus:outline-none focus:ring-2 focus:ring-blue-500 flex flex-col items-center ${
                  selectedRisk === category.id
                    ? 'border-blue-500 bg-blue-50 shadow-lg transform scale-105'
                    : 'border-gray-200 hover:border-blue-300 hover:shadow'
                }`}
                onClick={() => handleRiskSelection(category.id)}
                aria-pressed={selectedRisk === category.id}
              >
                <category.icon className={`w-8 h-8 mb-2 ${category.color} transition-transform duration-200`} />
                <h4 className="font-semibold text-center">{category.name}</h4>
              </button>
            ))}
          </div>
        </div>

        {/* Assessment Form and Existing Assessments */}
        <div className="lg:w-2/3">
          {/* Risk Assessment Form */}
          <Suspense fallback={<div>Loading Risk Assessment Form...</div>}>
            <RiskAssessmentForm
              selectedRisk={selectedRisk}
              onAddAssessment={handleAddAssessment}
              riskCategories={riskCategories}
            />
          </Suspense>

          {/* Existing Assessments */}
          <div className="mt-6">
            <h3 className="text-2xl font-semibold mb-4 text-blue-700">Your Risk Assessments</h3>
            {assessments.length === 0 ? (
              <p className="text-gray-600">No assessments added yet.</p>
            ) : (
              <ul className="space-y-4">
                {assessments.map((assessment, index) => {
                  const riskItem = riskMatrix.find((r) => r.level === assessment.riskLevel);
                  const IconComponent = riskItem?.icon;

                  return (
                    <li key={index} className="border border-gray-200 rounded-lg p-4 shadow-sm">
                      <div className="flex justify-between items-center">
                        <div>
                          <h4 className="font-semibold text-lg">{assessment.riskCategoryName}</h4>
                          <p className={`mt-1 flex items-center ${riskItem?.color}`}>
                            {IconComponent && <IconComponent className="inline mr-2" />}
                            {assessment.riskLevel}
                          </p>
                        </div>
                        <button
                          className="text-red-500 hover:text-red-700 focus:outline-none"
                          onClick={() => handleRemoveAssessment(index)}
                          aria-label={`Remove assessment ${assessment.riskCategoryName}`}
                        >
                          <XCircle className="w-6 h-6" />
                        </button>
                      </div>
                      <div className="mt-2">
                        <h5 className="font-medium">Mitigation Strategies:</h5>
                        <ul className="list-disc pl-5">
                          {Array.isArray(assessment.mitigationStrategies) && assessment.mitigationStrategies.length > 0 ? (
                            assessment.mitigationStrategies.map((strategy, i) => (
                              <li key={i} className="text-gray-700">
                                {strategy}
                              </li>
                            ))
                          ) : (
                            <li className="text-gray-700">No mitigation strategies provided.</li>
                          )}
                        </ul>
                      </div>
                    </li>
                  );
                })}
              </ul>
            )}
          </div>

          {/* Export Report */}
          {assessments.length > 0 && (
            <button
              className="mt-6 flex items-center px-4 py-2 bg-blue-600 text-white rounded-lg hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500"
              onClick={exportReport}
            >
              <Download className="w-5 h-5 mr-2" />
              Export Risk Management Report
            </button>
          )}
        </div>
      </div>

      {/* Risk Mitigation Steps */}
      <h3 className="text-2xl font-semibold mt-8 mb-4 text-blue-700">EU AI Act: Risk Mitigation Steps</h3>
      <div className="space-y-4 mb-8">
        {riskMitigationSteps.map((step, index) => (
          <div key={index} className="border border-gray-200 rounded-lg">
            <button
              className="w-full px-4 py-2 flex justify-between items-center bg-gray-50 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-blue-500"
              onClick={() => toggleStep(index)}
              aria-expanded={expandedStep === index}
            >
              <span className="font-semibold text-left">{step.step}</span>
              {expandedStep === index ? <ChevronUp /> : <ChevronDown />}
            </button>
            {expandedStep === index && (
              <div className="p-4 bg-white">
                <p className="mb-2 font-medium">{step.details}</p>
                <ul className="list-disc pl-5 mb-4">
                  {step.examples.map((example, i) => (
                    <li key={i} className="text-gray-700">
                      {example}
                    </li>
                  ))}
                </ul>
                <p className="text-sm text-blue-600">
                  Relevant EU AI Act Articles: {step.articles.join(', ')}
                </p>
              </div>
            )}
          </div>
        ))}
      </div>

      {/* Key Considerations */}
      <div className="bg-blue-50 p-4 rounded-lg border border-blue-200">
        <h4 className="text-lg font-semibold mb-2 text-blue-800">Key Considerations for Healthcare & Pharma</h4>
        <ul className="list-disc pl-5 text-gray-700 space-y-2">
          <li>
            Ensure AI systems for diagnosis and treatment planning are classified as high-risk and comply with stringent
            requirements.
          </li>
          <li>
            Implement robust data governance practices to protect patient data used in AI training and operations.
          </li>
          <li>
            Establish clear protocols for human oversight in critical decision-making processes involving AI.
          </li>
          <li>
            Regularly assess and mitigate potential biases in AI models, especially those affecting patient outcomes.
          </li>
          <li>
            Maintain comprehensive documentation of AI systems, including their development, testing, and ongoing performance
            monitoring.
          </li>
        </ul>
      </div>

      {/* Checklist Component */}
      <Suspense fallback={<div>Loading Checklist...</div>}>
        <Checklist />
      </Suspense>
    </div>
  );
};

export default RiskManagement;
