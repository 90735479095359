import React, { useState, useEffect } from 'react';
import { Textarea } from '../ui/Textarea';
import { Tooltip } from '../ui/Tooltip';
import { Button } from '../ui/Button';
import { Bot } from 'lucide-react';
import AIThinkingAnimation from '../AIThinkingAnimation';

const ProcessExclusionsStep = ({ data, onChange, aiSuggestions, isLoading }) => {
  const [exclusionsText, setExclusionsText] = useState(data || '');
  const [suggestedExclusions, setSuggestedExclusions] = useState('');

  useEffect(() => {
    if (typeof aiSuggestions === 'string' && aiSuggestions.trim() !== '') {
      setSuggestedExclusions(aiSuggestions);
    } else {
      setSuggestedExclusions('');
    }
  }, [aiSuggestions]);

  const handleAcceptSuggestion = () => {
    setExclusionsText(suggestedExclusions);
    onChange(suggestedExclusions);
    setSuggestedExclusions('');
  };

  return (
    <div className="space-y-4">
      {isLoading ? (
        <div className="my-8 p-4 bg-red-50 rounded-lg shadow-inner">
          <AIThinkingAnimation />
          <p className="text-center text-red-700 mt-4 font-medium">
            AI is generating process exclusion suggestions...
          </p>
        </div>
      ) : suggestedExclusions ? (
        <div className="bg-red-50 p-4 rounded-md">
          <div className="flex items-center mb-2">
            <Bot className="w-5 h-5 mr-2 text-red-500" />
            <p className="text-red-700 font-medium">AI-Suggested Exclusions</p>
          </div>
          <p className="text-red-700 mb-4">{suggestedExclusions}</p>
          <Button variant="outline" onClick={handleAcceptSuggestion}>
            Accept Suggestion
          </Button>
        </div>
      ) : null}
      <Tooltip content="Mention what is *not* covered in this process. For example, tasks that are managed by another department.">
        <Textarea
          value={exclusionsText}
          onChange={(e) => {
            setExclusionsText(e.target.value);
            onChange(e.target.value);
          }}
          className="w-full text-gray-700 bg-blue-50 p-2 rounded"
          placeholder="Describe what is excluded from this process..."
          rows={6}
        />
      </Tooltip>
    </div>
  );
};

export default ProcessExclusionsStep;