import React, { useState, createContext, useContext } from 'react';

const TabsContext = createContext(null);

export const Tabs = React.forwardRef(({ children, defaultValue, onValueChange, className, ...props }, ref) => {
  const [value, setValue] = useState(defaultValue);

  return (
    <TabsContext.Provider value={{ value, setValue, onValueChange }}>
      <div ref={ref} className={`${className}`} {...props}>
        {children}
      </div>
    </TabsContext.Provider>
  );
});
Tabs.displayName = "Tabs";

export const TabsList = React.forwardRef(({ children, className, ...props }, ref) => (
  <div ref={ref} className={`flex space-x-2 mb-4 ${className}`} {...props}>
    {children}
  </div>
));
TabsList.displayName = "TabsList";

export const TabsTrigger = React.forwardRef(({ children, value, className, ...props }, ref) => {
  const { value: activeValue, setValue, onValueChange } = useContext(TabsContext);
  const isActive = value === activeValue;

  const handleClick = () => {
    setValue(value);
    if (onValueChange) onValueChange(value);
  };

  return (
    <button
      ref={ref}
      onClick={handleClick}
      className={`px-4 py-2 font-medium rounded-md transition-colors
        ${isActive 
          ? 'bg-blue-500 text-white' 
          : 'bg-gray-200 text-gray-700 hover:bg-gray-300'}
        ${className}`}
      {...props}
    >
      {children}
    </button>
  );
});
TabsTrigger.displayName = "TabsTrigger";

export const TabsContent = React.forwardRef(({ children, value, className, ...props }, ref) => {
  const { value: activeValue } = useContext(TabsContext);
  
  if (value !== activeValue) return null;

  return (
    <div ref={ref} className={className} {...props}>
      {children}
    </div>
  );
});
TabsContent.displayName = "TabsContent";