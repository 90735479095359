import React, { forwardRef } from 'react';
import { Loader } from 'lucide-react';
import PropTypes from 'prop-types';

export const Button = forwardRef(
  (
    {
      children,
      className = '',
      variant = 'primary',
      size = 'md',
      disabled = false,
      loading = false,
      icon = null,
      iconPosition = 'left',
      onClick,
      ...props
    },
    ref
  ) => {
    const baseStyles =
      'inline-flex items-center justify-center font-semibold rounded-md focus:outline-none focus:ring-2 focus:ring-offset-2 transition duration-200 ease-in-out';

    const variantStyles = {
      primary: 'bg-purple-600 text-white hover:bg-purple-700 focus:ring-purple-500',
      secondary: 'bg-gray-300 text-gray-700 hover:bg-gray-400 focus:ring-gray-400',
      success: 'bg-green-600 text-white hover:bg-green-700 focus:ring-green-500',
      danger: 'bg-red-600 text-white hover:bg-red-700 focus:ring-red-500',
      warning: 'bg-yellow-500 text-white hover:bg-yellow-600 focus:ring-yellow-500',
      info: 'bg-blue-500 text-white hover:bg-blue-600 focus:ring-blue-500',
      outline: 'bg-transparent border border-blue-500 text-blue-700 hover:bg-blue-500 hover:text-white focus:ring-blue-500',
    };

    const sizeStyles = {
      sm: 'py-1 px-3 text-sm',
      md: 'py-2 px-4 text-sm',
      lg: 'py-3 px-6 text-base',
    };

    const disabledStyles = disabled || loading ? 'opacity-50 cursor-not-allowed' : '';

    const handleClick = (event) => {
      if (onClick && !disabled && !loading) {
        onClick(event);
      }
    };

    const renderIcon = () => {
      if (loading) {
        return <Loader className="animate-spin h-5 w-5 mr-2" />;
      }
      if (icon) {
        return <span className={`h-5 w-5 ${iconPosition === 'left' ? 'mr-2' : 'ml-2'}`}>{icon}</span>;
      }
      return null;
    };

    return (
      <button
        ref={ref}
        className={`${baseStyles} ${variantStyles[variant]} ${sizeStyles[size]} ${disabledStyles} ${className}`}
        disabled={disabled || loading}
        aria-disabled={disabled || loading}
        aria-busy={loading}
        onClick={handleClick}
        {...props}
      >
        {iconPosition === 'left' && renderIcon()}
        {children}
        {iconPosition === 'right' && renderIcon()}
      </button>
    );
  }
);

Button.displayName = 'Button';

Button.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  variant: PropTypes.oneOf([
    'primary',
    'secondary',
    'success',
    'danger',
    'warning',
    'info',
    'outline',
  ]),
  size: PropTypes.oneOf(['sm', 'md', 'lg']),
  disabled: PropTypes.bool,
  loading: PropTypes.bool,
  icon: PropTypes.element,
  iconPosition: PropTypes.oneOf(['left', 'right']),
  onClick: PropTypes.func,
};
