// Checklist.js
import React, { useState, useEffect } from 'react';

const initialChecklist = [
  { id: 1, task: 'Identify all AI systems and their use cases', completed: false },
  { id: 2, task: 'Assess AI systems against EU AI Act criteria', completed: false },
  { id: 3, task: 'Classify AI systems by risk level', completed: false },
  { id: 4, task: 'Implement required controls based on classification', completed: false },
  { id: 5, task: 'Establish ongoing monitoring and reporting processes', completed: false },
  { id: 6, task: 'Conduct regular audits and updates', completed: false },
];

const Checklist = () => {
  const [items, setItems] = useState(initialChecklist);

  useEffect(() => {
    const storedChecklist = localStorage.getItem('checklist');
    if (storedChecklist) {
      setItems(JSON.parse(storedChecklist));
    }
  }, []);

  useEffect(() => {
    localStorage.setItem('checklist', JSON.stringify(items));
  }, [items]);

  const toggleCompletion = (id) => {
    setItems((prev) =>
      prev.map((item) =>
        item.id === id ? { ...item, completed: !item.completed } : item
      )
    );
  };

  return (
    <div className="mt-8">
      <h3 className="text-2xl font-semibold mb-4 text-blue-700">Compliance Checklist</h3>
      <ul className="space-y-2">
        {items.map((item) => (
          <li key={item.id} className="flex items-center">
            <input
              type="checkbox"
              checked={item.completed}
              onChange={() => toggleCompletion(item.id)}
              className="mr-2 h-4 w-4 text-blue-600"
            />
            <span className={item.completed ? 'line-through text-gray-500' : 'text-gray-700'}>
              {item.task}
            </span>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default Checklist;
