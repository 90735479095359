// Import Statements
import React from 'react';
import AssessmentCard from './AssessmentCard';
import PropTypes from 'prop-types';
import {
  Chart as ChartJS,
  RadialLinearScale,
  PointElement,
  LineElement,
  Filler,
  Tooltip,
  Legend,
} from 'chart.js';
import { Radar } from 'react-chartjs-2';
import {
  AlertTriangle,
  Award,
  TrendingUp,
  ChevronRight,
  Info,
  CheckCircle,
  XCircle,
  Building,
  BookOpen,
  Shield,
  Star,
  FileText,
} from 'lucide-react';
import { Card, CardHeader, CardTitle, CardContent } from './ui/Card';
import { Button } from './ui/Button';

// Register ChartJS Components
ChartJS.register(
  RadialLinearScale,
  PointElement,
  LineElement,
  Filler,
  Tooltip,
  Legend
);

// Utility Function for Tier Color
const getTierColor = (tier) => {
  switch (tier) {
    case 'critical':
      return 'text-blue-600';
    case 'high':
      return 'text-blue-500';
    case 'medium':
      return 'text-blue-400';
    default:
      return 'text-gray-400';
  }
};

// Main Component
const EnhancedMaturityAssessmentResults = ({
  riskProfile,
  maturityLevel,
  categoryScores,
  companyProfile,
  regulatoryLandscape,
  complianceItems,
  onProceed,
}) => {
  // Calculate Maximum Score for Chart Scaling
  const maxScore = Math.max(
    ...Object.values(categoryScores).map((score) => score.maxScore)
  );

  // Prepare Chart Data
  const chartData = {
    labels: Object.keys(categoryScores),
    datasets: [
      {
        label: 'Your Score',
        data: Object.values(categoryScores).map((score) => score.yourScore),
        backgroundColor: 'rgba(147, 51, 234, 0.2)',
        borderColor: 'rgba(147, 51, 234, 0.8)',
        borderWidth: 2,
        pointBackgroundColor: 'rgba(147, 51, 234, 1)',
        pointBorderColor: '#fff',
        pointHoverBackgroundColor: '#fff',
        pointHoverBorderColor: 'rgba(147, 51, 234, 1)',
      },
    ],
  };

  // Configure Chart Options
  // Radar Chart Options with Enhanced Visuals
const chartOptions = {
  maintainAspectRatio: false,
  responsive: true,
  layout: {
    padding: {
      top: 10,     // Reduced padding for better space usage
      bottom: 10,
      left: 10,
      right: 10,
    },
  },
  scales: {
    r: {
      beginAtZero: true,
      max: maxScore,
      ticks: {
        stepSize: 2,
        font: {
          size: 10,   // Reduced font size for better label fitting
        },
        color: '#4A5568',
      },
      pointLabels: {
        font: {
          size: 12,   // Adjusted label font size
        },
        color: '#4A5568',
        // Rotate labels dynamically to prevent cutting off
        callback: function (value) {
          return value.length > 25 ? value : value; // Removed ellipsis
        },
      },
      grid: {
        circular: true,
        color: 'rgba(74, 85, 104, 0.1)',
      },
    },
  },
  plugins: {
    legend: {
      display: false,
    },
    tooltip: {
      enabled: true,
      callbacks: {
        label: function (context) {
          const label = context.label || '';
          const value = context.formattedValue || '';
          return `${label}: ${value}`;
        },
        title: function (context) {
          const index = context[0].dataIndex;
          const label = chartData.labels[index];
          return label;
        },
      },
      backgroundColor: 'rgba(0,0,0,0.7)',
      titleFont: {
        size: 14,
        weight: 'bold',
      },
      bodyFont: {
        size: 12,
      },
      displayColors: false,
    },
  },
  elements: {
    line: {
      tension: 0.4,
      borderWidth: 2,
    },
    point: {
      radius: 4,    // Slightly smaller points for clarity
      hoverRadius: 6,
      hitRadius: 8,
    },
  },
};

  return (
    <div className="max-w-4xl mx-auto p-6 space-y-8 bg-white rounded-lg shadow-lg">
      <h2 className="text-3xl font-bold text-center text-purple-800 mb-6">
        Compliance Maturity Assessment Results
      </h2>

      {/* Company Profile Summary */}
      <Card>
        <CardHeader>
          <CardTitle className="flex items-center text-xl">
            <Building className="mr-2" />
            Company Profile Summary
          </CardTitle>
        </CardHeader>
        <CardContent className="text-gray-600">
          <p>
            <strong>Company:</strong> {companyProfile.companyName}
          </p>
          <p>
            <strong>Industry:</strong> {companyProfile.industry}
          </p>
          <p>
            <strong>Size:</strong> {companyProfile.size}
          </p>
          <p>
            <strong>Country:</strong> {companyProfile.country}
          </p>
          <p>
            <strong>Region:</strong> {companyProfile.region}
          </p>
        </CardContent>
      </Card>

      {/* Regulatory Landscape Summary */}
      <Card>
        <CardHeader>
          <CardTitle className="flex items-center text-xl">
            <BookOpen className="mr-2" />
            Regulatory Landscape Summary
          </CardTitle>
        </CardHeader>
        <CardContent className="text-gray-600">
          <p>
            <strong>Applicable Regulations, Standards, and Guidelines:</strong>
          </p>
          <ul className="list-disc pl-5 mt-2 space-y-2">
            {regulatoryLandscape.applicableItems.map((itemId) => {
              const item = complianceItems.find((i) => i.id === itemId);
              return item ? (
                <li
                  key={item.id}
                  className="flex items-center justify-between"
                >
                  <div className="flex items-center">
                    {item.type === 'regulation' ? (
                      <Shield className="h-4 w-4 text-blue-600 mr-2" />
                    ) : item.type === 'standard' ? (
                      <BookOpen className="h-4 w-4 text-green-600 mr-2" />
                    ) : (
                      <FileText className="h-4 w-4 text-purple-600 mr-2" />
                    )}
                    <span className="text-gray-800">{item.name}</span>
                  </div>
                  <div className="flex items-center">
                    {['critical', 'high', 'medium'].includes(item.tier) && (
                      <Star
                        className={`h-4 w-4 ${getTierColor(
                          item.tier
                        )} mr-1`}
                        fill="currentColor"
                      />
                    )}
                    <span
                      className={`text-xs font-semibold ${getTierColor(
                        item.tier
                      )}`}
                    >
                      {item.tier.charAt(0).toUpperCase() +
                        item.tier.slice(1)}
                    </span>
                  </div>
                </li>
              ) : null;
            })}
          </ul>
        </CardContent>
      </Card>

      {/* Risk Profile and Maturity Level */}
      <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
        <AssessmentCard
          title="Risk Profile"
          current={riskProfile}
          items={[
            {
              name: 'Low Risk',
              color: 'bg-green-500',
              description:
                'Minimal compliance risks, strong controls in place.',
            },
            {
              name: 'Medium Risk',
              color: 'bg-yellow-500',
              description:
                'Moderate compliance risks, some areas need improvement.',
            },
            {
              name: 'High Risk',
              color: 'bg-red-500',
              description:
                'Significant compliance risks, immediate action required.',
            },
          ]}
          icon={AlertTriangle}
        />
        <AssessmentCard
          title="Maturity Level"
          current={maturityLevel}
          items={[
            {
              name: 'Initial',
              color: 'bg-red-500',
              description:
                'Ad-hoc processes, reactive approach to compliance.',
            },
            {
              name: 'Managed',
              color: 'bg-yellow-500',
              description:
                'Basic processes established, some proactive measures.',
            },
            {
              name: 'Defined',
              color: 'bg-blue-500',
              description:
                'Standardized processes, consistent approach to compliance.',
            },
            {
              name: 'Optimizing',
              color: 'bg-green-500',
              description:
                'Continuous improvement, advanced compliance practices.',
            },
          ]}
          icon={Award}
        />
      </div>

      {/* Category Breakdown Chart */}
      <Card className="h-[450px]">
        <CardHeader>
          <CardTitle className="flex items-center text-xl">
            <TrendingUp className="mr-2" />
            Category Breakdown
          </CardTitle>
        </CardHeader>
        <CardContent className="h-[400px] flex items-center justify-center">
          <Radar data={chartData} options={chartOptions} />
        </CardContent>
      </Card>

      {/* Key Findings & Recommendations */}
      <Card>
        <CardHeader>
          <CardTitle className="text-xl">
            Key Findings & Recommendations
          </CardTitle>
        </CardHeader>
        <CardContent>
          <ul className="space-y-4">
            <li className="flex items-start">
              <span className="mr-3 mt-1">
                {riskProfile === 'High Risk' ? (
                  <XCircle className="text-red-500 w-6 h-6" />
                ) : (
                  <CheckCircle className="text-green-500 w-6 h-6" />
                )}
              </span>
              <span className="text-gray-700">
                Your {companyProfile.industry} organization of {companyProfile.size}{' '}
                size is at a{' '}
                <strong className="text-purple-700">
                  {riskProfile.toLowerCase()}
                </strong>{' '}
                with a{' '}
                <strong className="text-purple-700">
                  {maturityLevel.toLowerCase()}
                </strong>{' '}
                level of compliance maturity.
              </span>
            </li>
            <li className="flex items-start">
              <span className="mr-3 mt-1">
                <Info className="text-purple-500 w-6 h-6" />
              </span>
              <span className="text-gray-700">
                Given your company profile and regulatory landscape, focus on
                establishing robust compliance processes for key regulations such
                as{' '}
                <strong className="text-purple-700">
                  {regulatoryLandscape.applicableItems
                    .slice(0, 2)
                    .map((itemId) =>
                      complianceItems.find((i) => i.id === itemId)?.name
                    )
                    .filter(Boolean)
                    .join(' and ')}
                </strong>
                .
              </span>
            </li>
            <li className="flex items-start">
              <span className="mr-3 mt-1">
                <TrendingUp className="text-purple-500 w-6 h-6" />
              </span>
              <span className="text-gray-700">
                Prioritize improvements in{' '}
                <strong className="text-purple-700">
                  {Object.entries(categoryScores)
                    .sort((a, b) => a[1].yourScore - b[1].yourScore)[0][0]}
                </strong>{' '}
                to strengthen your overall compliance posture and align with
                industry best practices.
              </span>
            </li>
          </ul>
        </CardContent>
      </Card>

      {/* Proceed Button */}
      <div className="flex justify-center">
        <Button
          onClick={onProceed}
          size="lg"
          className="mt-6 bg-purple-600 hover:bg-purple-700 text-white px-8 py-3 rounded-full transition duration-300 ease-in-out transform hover:scale-105 flex items-center"
        >
          Proceed to Process Selection
          <ChevronRight className="ml-2 h-5 w-5" />
        </Button>
      </div>
    </div>
  );
};

// PropTypes Validation
EnhancedMaturityAssessmentResults.propTypes = {
  riskProfile: PropTypes.oneOf(['Low Risk', 'Medium Risk', 'High Risk'])
    .isRequired,
  maturityLevel: PropTypes.oneOf([
    'Initial',
    'Managed',
    'Defined',
    'Optimizing',
  ]).isRequired,
  categoryScores: PropTypes.objectOf(
    PropTypes.shape({
      yourScore: PropTypes.number.isRequired,
      maxScore: PropTypes.number.isRequired,
    })
  ).isRequired,
  companyProfile: PropTypes.shape({
    companyName: PropTypes.string.isRequired,
    industry: PropTypes.string.isRequired,
    size: PropTypes.string.isRequired,
    country: PropTypes.string.isRequired,
    region: PropTypes.string.isRequired,
  }).isRequired,
  regulatoryLandscape: PropTypes.shape({
    applicableItems: PropTypes.arrayOf(PropTypes.string).isRequired,
  }).isRequired,
  complianceItems: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
      type: PropTypes.oneOf(['regulation', 'standard', 'guideline'])
        .isRequired,
      tier: PropTypes.oneOf(['critical', 'high', 'medium', 'low'])
        .isRequired,
    })
  ).isRequired,
  onProceed: PropTypes.func.isRequired,
};

export default EnhancedMaturityAssessmentResults;
