// workflowValidation.js

const validateWorkflow = (nodes, edges) => {
    const errors = [];
  
    // Check if there's exactly one start node
    const startNodes = nodes.filter(node => node.data.type === 'start');
    if (startNodes.length === 0) {
      errors.push('Workflow must have a start node');
    } else if (startNodes.length > 1) {
      errors.push('Workflow can only have one start node');
    }
  
    // Check if there's at least one end node
    const endNodes = nodes.filter(node => node.data.type === 'end');
    if (endNodes.length === 0) {
      errors.push('Workflow must have at least one end node');
    }
  
    // Check if all nodes are connected
    const connectedNodeIds = new Set();
    edges.forEach(edge => {
      connectedNodeIds.add(edge.source);
      connectedNodeIds.add(edge.target);
    });
    const disconnectedNodes = nodes.filter(node => !connectedNodeIds.has(node.id));
    if (disconnectedNodes.length > 0) {
      errors.push(`The following nodes are not connected: ${disconnectedNodes.map(n => n.data.label).join(', ')}`);
    }
  
    // Check if decision nodes have both 'yes' and 'no' connections
    const decisionNodes = nodes.filter(node => node.data.type === 'decision');
    decisionNodes.forEach(node => {
      const nodeEdges = edges.filter(edge => edge.source === node.id);
      const hasYesPath = nodeEdges.some(edge => edge.sourceHandle === 'yes');
      const hasNoPath = nodeEdges.some(edge => edge.sourceHandle === 'no');
      if (!hasYesPath || !hasNoPath) {
        errors.push(`Decision node "${node.data.label}" must have both 'yes' and 'no' paths`);
      }
    });
  
    // Check for cycles in the workflow
    const hasCycle = detectCycle(nodes, edges);
    if (hasCycle) {
      errors.push('Workflow contains a cycle, which is not allowed');
    }
  
    return errors;
  };
  
  const detectCycle = (nodes, edges) => {
    const graph = {};
    nodes.forEach(node => {
      graph[node.id] = [];
    });
    edges.forEach(edge => {
      graph[edge.source].push(edge.target);
    });
  
    const visited = new Set();
    const recursionStack = new Set();
  
    const dfs = (nodeId) => {
      visited.add(nodeId);
      recursionStack.add(nodeId);
  
      for (const neighbor of graph[nodeId]) {
        if (!visited.has(neighbor)) {
          if (dfs(neighbor)) {
            return true;
          }
        } else if (recursionStack.has(neighbor)) {
          return true;
        }
      }
  
      recursionStack.delete(nodeId);
      return false;
    };
  
    for (const node of nodes) {
      if (!visited.has(node.id)) {
        if (dfs(node.id)) {
          return true;
        }
      }
    }
  
    return false;
  };
  
  export default validateWorkflow;