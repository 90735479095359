import React, { useState, useEffect, useCallback, useMemo } from 'react';
import {
  Shield,
  ClipboardList,
  Clock,
  FileText,
  ClipboardCheck,
  BookOpen,
  ShieldX,
  Truck,
  CheckCircle,
  FileBarChart,
  Repeat,
  FlaskConical,
  Activity,
  Handshake,
  LifeBuoy,
  ShieldAlert,
  Info,
} from 'lucide-react';
import { Badge } from './ui/Badge';
import { Button } from './ui/Button';
import { Tooltip } from './ui/Tooltip';

const ProcessSelectionStep = ({ 
  onSelectProcess, 
  riskProfile, 
  maturityLevel,
  companyProfile,
  regulatoryLandscape
}) => {
  const [selectedProcess, setSelectedProcess] = useState(null);
  const [recommendedProcesses, setRecommendedProcesses] = useState([]);

  // Define all available processes with their details
  const allProcesses = useMemo(() => [
    // Enabled processes
    {
      name: 'Risk Assessment',
      icon: Shield,
      description: 'Identify and evaluate potential risks in your organization.',
      enabled: true,
    },
    {
      name: 'Compliance Monitoring',
      icon: ClipboardList,
      description:
        'Monitor and track compliance with regulations and internal policies.',
      enabled: true,
    },
    {
      name: 'Policy Management',
      icon: FileText,
      description:
        'Create, review, and maintain compliance policies and procedures.',
      enabled: true,
    },
    {
      name: 'Incident Management',
      icon: Clock,
      description: 'Respond to and manage compliance incidents effectively.',
      enabled: true,
    },
    {
      name: 'Audit Management',
      icon: ClipboardCheck,
      description:
        'Plan, execute, and track internal and external compliance audits.',
      enabled: true,
    },
    {
      name: 'Training & Awareness',
      icon: BookOpen,
      description:
        'Develop and manage compliance training programs for employees.',
      enabled: true,
    },
    {
      name: 'Data Privacy Compliance',
      icon: ShieldX,
      description:
        'Ensure compliance with data protection regulations like GDPR and HIPAA.',
      enabled: true,
    },
    {
      name: 'Vendor Compliance Management',
      icon: Truck,
      description:
        'Monitor and manage compliance of third-party vendors and suppliers.',
      enabled: true,
    },
    {
      name: 'Regulatory Reporting',
      icon: FileBarChart,
      description: 'Prepare and submit required reports to regulatory authorities.',
      enabled: true,
    },
    {
      name: 'Document Control',
      icon: FileText,
      description:
        'Manage compliance documents and ensure version control.',
      enabled: true,
    },
    {
      name: 'Change Management',
      icon: Repeat,
      description:
        'Oversee changes in processes or systems to maintain compliance.',
      enabled: true,
    },
    {
      name: 'Anti-Bribery & Anti-Corruption Compliance',
      icon: Handshake,
      description: 'Implement policies to prevent bribery and corruption.',
      enabled: true,
    },
    {
      name: 'Environmental Health & Safety (EHS) Compliance',
      icon: LifeBuoy,
      description:
        'Ensure compliance with environmental and workplace safety regulations.',
      enabled: true,
    },
    {
      name: 'Cybersecurity Compliance',
      icon: ShieldAlert,
      description:
        'Protect sensitive data and systems from cyber threats.',
      enabled: true,
    },
    // Disabled processes (Coming Soon)
    {
      name: 'Quality Management (GxP Compliance)',
      icon: CheckCircle,
      description:
        'Maintain quality standards in accordance with Good Practice (GxP) regulations.',
      enabled: false,
    },
    {
      name: 'Clinical Trial Compliance',
      icon: FlaskConical,
      description:
        'Ensure adherence to regulations in clinical research activities.',
      enabled: false,
    },
    {
      name: 'Pharmacovigilance',
      icon: Activity,
      description:
        'Monitor and report adverse drug reactions and ensure drug safety.',
      enabled: false,
    },
  ], []);

  const calculateRecommendationScore = useCallback((process) => {
    let score = 0;

    // Risk profile considerations
    if (riskProfile === 'High Risk' && ['Risk Assessment', 'Compliance Monitoring', 'Audit Management'].includes(process.name)) {
      score += 3;
    } else if (riskProfile === 'Medium Risk' && ['Policy Management', 'Training & Awareness'].includes(process.name)) {
      score += 2;
    } else if (riskProfile === 'Low Risk' && ['Document Control', 'Regulatory Reporting'].includes(process.name)) {
      score += 1;
    }

    // Maturity level considerations
    if (maturityLevel === 'Initial' && ['Policy Management', 'Risk Assessment'].includes(process.name)) {
      score += 3;
    } else if (maturityLevel === 'Managed' && ['Compliance Monitoring', 'Audit Management'].includes(process.name)) {
      score += 2;
    } else if (maturityLevel === 'Defined' && ['Vendor Compliance Management', 'Change Management'].includes(process.name)) {
      score += 2;
    } else if (maturityLevel === 'Optimizing' && ['Cybersecurity Compliance', 'Anti-Bribery & Anti-Corruption Compliance'].includes(process.name)) {
      score += 3;
    }

    // Industry-specific considerations
    if (companyProfile.industry === 'healthcare' && ['Data Privacy Compliance', 'Quality Management (GxP Compliance)'].includes(process.name)) {
      score += 2;
    } else if (companyProfile.industry === 'pharmaceutical' && ['Clinical Trial Compliance', 'Pharmacovigilance'].includes(process.name)) {
      score += 2;
    }

    // Regulatory landscape considerations
    if (regulatoryLandscape.applicableItems.includes('GDPR') && process.name === 'Data Privacy Compliance') {
      score += 2;
    } else if (regulatoryLandscape.applicableItems.includes('FDA 21 CFR Part 11') && process.name === 'Document Control') {
      score += 2;
    }

    return score;
  }, [riskProfile, maturityLevel, companyProfile.industry, regulatoryLandscape.applicableItems]);

  useEffect(() => {
    // Calculate scores and sort processes
    const scoredProcesses = allProcesses
      .map(process => ({
        ...process,
        score: calculateRecommendationScore(process)
      }))
      .sort((a, b) => b.score - a.score);

    // Set top 5 as recommended
    setRecommendedProcesses(scoredProcesses.slice(0, 5));
  }, [allProcesses, calculateRecommendationScore]);

  const handleProcessClick = (process) => {
    if (process.enabled) {
      setSelectedProcess(process.name);
      onSelectProcess(process.name);
    } else {
      alert(`${process.name} is coming soon! Stay tuned for updates.`);
    }
  };

  const getRecommendationReason = useCallback((process) => {
    const reasons = [];
    if (process.score >= 3) {
      if (riskProfile === 'High Risk' && ['Risk Assessment', 'Compliance Monitoring', 'Audit Management'].includes(process.name)) {
        reasons.push('Highly recommended for high-risk profiles');
      }
      if (maturityLevel === 'Initial' && ['Policy Management', 'Risk Assessment'].includes(process.name)) {
        reasons.push('Essential for organizations at the initial maturity level');
      }
      if (maturityLevel === 'Optimizing' && ['Cybersecurity Compliance', 'Anti-Bribery & Anti-Corruption Compliance'].includes(process.name)) {
        reasons.push('Important for optimizing-level organizations');
      }
    }
    if (companyProfile.industry === 'healthcare' && ['Data Privacy Compliance', 'Quality Management (GxP Compliance)'].includes(process.name)) {
      reasons.push('Relevant for the healthcare industry');
    }
    if (regulatoryLandscape.applicableItems.includes('GDPR') && process.name === 'Data Privacy Compliance') {
      reasons.push('Addresses GDPR requirements');
    }
    return reasons.length > 0 ? reasons.join('. ') : 'Recommended based on your organization\'s profile.';
  }, [riskProfile, maturityLevel, companyProfile.industry, regulatoryLandscape.applicableItems]);

  return (
    <div className="flex flex-col space-y-6">
      <h2 className="text-2xl font-bold text-purple-700">
        Select a Compliance Process to Design
      </h2>
      <p className="text-gray-700">
        Based on your organization's current maturity level of{' '}
        <strong>{maturityLevel}</strong>, risk profile of{' '}
        <strong>{riskProfile}</strong>, and industry ({companyProfile.industry}), 
        we recommend prioritizing the following compliance processes:
      </p>
      <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
        {allProcesses.map((process) => {
          const isRecommended = recommendedProcesses.some(p => p.name === process.name);
          return (
            <div
              key={process.name}
              className={`relative p-6 border-2 rounded-lg transition-all hover:shadow-lg ${
                selectedProcess === process.name
                  ? 'border-purple-500 bg-purple-50'
                  : 'border-gray-200'
              } ${process.enabled ? '' : 'opacity-50'}`}
            >
              {isRecommended && (
                <Badge className="absolute top-2 right-2 bg-green-500 text-white">
                  Recommended
                </Badge>
              )}
              <div className="flex flex-col space-y-3">
                <div className={`${process.enabled ? 'text-purple-500' : 'text-gray-400'}`}>
                  <process.icon size={32} />
                </div>
                <p className={`font-bold ${process.enabled ? 'text-purple-700' : 'text-gray-400'}`}>
                  {process.name}
                </p>
                <p className={`text-sm ${process.enabled ? 'text-gray-600' : 'text-gray-400'}`}>
                  {process.description}
                </p>
                {isRecommended && (
                  <Tooltip content={getRecommendationReason(process)}>
                    <div className="flex items-center text-green-600 hover:text-green-700 cursor-help">
                      <Info size={16} className="mr-1" />
                      Why recommended?
                    </div>
                  </Tooltip>
                )}
                {!process.enabled && (
                  <p className="text-xs italic text-gray-500">Coming Soon</p>
                )}
              </div>
              <Button
                onClick={() => handleProcessClick(process)}
                disabled={!process.enabled}
                className="mt-4 w-full"
                variant={selectedProcess === process.name ? "secondary" : "outline"}
              >
                {selectedProcess === process.name ? "Selected" : "Select Process"}
              </Button>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default ProcessSelectionStep;