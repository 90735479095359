import React, { useState } from 'react';
import { Shield, Map, Activity, Cog, ChevronDown, ChevronUp } from 'lucide-react';

const FrameworkSection = ({ title, icon: Icon, content, isOpen, toggle }) => (
  <div className="mb-6 border rounded-lg overflow-hidden">
    <div
      className="flex items-center justify-between p-4 bg-gray-100 cursor-pointer"
      onClick={toggle}
    >
      <div className="flex items-center">
        <Icon className="w-6 h-6 text-blue-600 mr-3" />
        <h3 className="text-lg font-semibold text-gray-600">{title}</h3>
      </div>
      {isOpen ? <ChevronUp /> : <ChevronDown />}
    </div>
    {isOpen && (
      <div className="p-4 bg-white">
        {content}
      </div>
    )}
  </div>
);

const NISTAIFrameworkHub = () => {
  const [openSections, setOpenSections] = useState({
    govern: true,
    map: false,
    measure: false,
    manage: false
  });

  const toggleSection = (section) => {
    setOpenSections(prev => ({ ...prev, [section]: !prev[section] }));
  };

  return (
    <div className="p-6 bg-white rounded-lg shadow-md">
      <h2 className="text-2xl font-bold mb-6 text-gray-600">NIST AI Risk Management Framework</h2>
      
      <FrameworkSection
        title="GOVERN"
        icon={Shield}
        isOpen={openSections.govern}
        toggle={() => toggleSection('govern')}
        content={
          <div>
            <p className='text-gray-600'>Establish governance structures and processes to manage AI risks:</p>
            <ul className="list-disc pl-5 mt-2 text-gray-500">
              <li>Define AI risk management roles and responsibilities</li>
              <li>Develop AI risk management policies and procedures</li>
              <li>Establish metrics for AI governance effectiveness</li>
            </ul>
            {/* Add more detailed content and potentially interactive elements */}
          </div>
        }
      />

      <FrameworkSection
        title="MAP"
        icon={Map}
        isOpen={openSections.map}
        toggle={() => toggleSection('map')}
        content={
          <div>
            <p className='text-gray-600'>Identify and document context and potential impacts of AI systems:</p>
            <ul className="list-disc pl-5 mt-2 text-gray-500">
              <li>Map AI systems and their contexts</li>
              <li>Identify stakeholders and their concerns</li>
              <li>Assess potential individual and societal impacts</li>
            </ul>
            {/* Add more detailed content and potentially interactive elements */}
          </div>
        }
      />

      <FrameworkSection
        title="MEASURE"
        icon={Activity}
        isOpen={openSections.measure}
        toggle={() => toggleSection('measure')}
        content={
          <div>
            <p className='text-gray-600'>Analyze and assess AI risks:</p>
            <ul className="list-disc pl-5 mt-2 text-gray-500">
              <li>Identify and categorize AI risks</li>
              <li>Assess likelihood and potential impact of risks</li>
              <li>Evaluate AI system trustworthiness</li>
            </ul>
            {/* Add more detailed content and potentially interactive elements */}
          </div>
        }
      />

      <FrameworkSection
        title="MANAGE"
        icon={Cog}
        isOpen={openSections.manage}
        toggle={() => toggleSection('manage')}
        content={
          <div>
            <p className='text-gray-600'>Respond to and manage AI risks:</p>
            <ul className="list-disc pl-5 mt-2 text-gray-500">
              <li>Prioritize risks and develop mitigation strategies</li>
              <li>Implement risk controls and monitor their effectiveness</li>
              <li>Continuously improve AI risk management processes</li>
            </ul>
            {/* Add more detailed content and potentially interactive elements */}
          </div>
        }
      />
    </div>
  );
};

export default NISTAIFrameworkHub;