// ComplianceMaturityAssessment.js

import React, { useState } from 'react';
import { Progress } from './ui/Progress';
import { ChevronLeft, ChevronRight } from 'lucide-react';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import EnhancedMaturityAssessmentResults from './EnhancedMaturityAssessmentResults';
import { Button } from './ui/Button';

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

const questions = [
  {
    id: 1,
    question: 'In how many countries does your organization operate?',
    options: [
      { text: '1', score: 1 },
      { text: '2-5', score: 2 },
      { text: '6-10', score: 3 },
      { text: 'More than 10', score: 4 },
    ],
    weight: 1,
    category: 'Operational Complexity',
  },
  {
    id: 2,
    question: 'Do you have documented compliance policies and procedures?',
    options: [
      { text: 'Yes, fully documented and regularly updated', score: 1 },
      { text: 'Yes, but not regularly updated', score: 2 },
      { text: 'Partially documented', score: 3 },
      { text: 'No documented policies', score: 4 },
    ],
    weight: 2,
    category: 'Policy Management',
  },
  {
    id: 3,
    question: 'How many team members are dedicated to compliance?',
    options: [
      { text: '5 or more', score: 1 },
      { text: '3-4', score: 2 },
      { text: '1-2', score: 3 },
      { text: 'None', score: 4 },
    ],
    weight: 2,
    category: 'Resource Allocation',
  },
  {
    id: 4,
    question: 'Are you currently using any compliance management software?',
    options: [
      { text: 'Yes, a comprehensive system', score: 1 },
      { text: 'Yes, basic tools', score: 2 },
      { text: 'No, but planning to implement', score: 3 },
      { text: 'No', score: 4 },
    ],
    weight: 1,
    category: 'Technology Adoption',
  },
  {
    id: 5,
    question: 'How often do you conduct compliance risk assessments?',
    options: [
      { text: 'Quarterly or more often', score: 1 },
      { text: 'Bi-annually', score: 2 },
      { text: 'Annually', score: 3 },
      { text: 'Rarely or never', score: 4 },
    ],
    weight: 2,
    category: 'Risk Management',
  },
  {
    id: 6,
    question:
      'Have you faced any compliance issues or regulatory fines in the past year?',
    options: [
      { text: 'No issues or fines', score: 1 },
      { text: 'Minor issues resolved internally', score: 2 },
      { text: 'Significant issues but no fines', score: 3 },
      { text: 'Yes, fines or sanctions imposed', score: 4 },
    ],
    weight: 2,
    category: 'Compliance History',
  },
  {
    id: 7,
    question:
      'Is your organization planning to enter new markets in the next 12 months?',
    options: [
      { text: 'No', score: 1 },
      { text: 'Yes, 1-2 new markets', score: 2 },
      { text: 'Yes, 3-5 new markets', score: 3 },
      { text: 'Yes, more than 5 new markets', score: 4 },
    ],
    weight: 1,
    category: 'Growth Strategy',
  },
  {
    id: 8,
    question: 'How would you describe executive leadership’s involvement in compliance initiatives?',
    options: [
      { text: 'Highly involved and supportive', score: 1 },
      { text: 'Moderately involved', score: 2 },
      { text: 'Minimally involved', score: 3 },
      { text: 'Not involved', score: 4 },
    ],
    weight: 2,
    category: 'Leadership Commitment',
  },
  {
    id: 9,
    question: 'How frequently does your organization conduct compliance training for employees?',
    options: [
      { text: 'Quarterly or more often', score: 1 },
      { text: 'Bi-annually', score: 2 },
      { text: 'Annually', score: 3 },
      { text: 'Rarely or never', score: 4 },
    ],
    weight: 2,
    category: 'Employee Training',
  },
  {
    id: 10,
    question: 'What level of automation exists in your compliance processes?',
    options: [
      { text: 'Fully automated', score: 1 },
      { text: 'Partially automated', score: 2 },
      { text: 'Minimal automation', score: 3 },
      { text: 'Manual processes', score: 4 },
    ],
    weight: 1,
    category: 'Process Automation',
  },
];

const ComplianceMaturityAssessment = ({ onComplete, companyProfile, regulatoryLandscape, complianceItems }) => {
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
  const [responses, setResponses] = useState(Array(questions.length).fill(null));
  const [showResult, setShowResult] = useState(false);
  const [assessmentResults, setAssessmentResults] = useState(null);

  const handleOptionSelect = (score) => {
    const updatedResponses = [...responses];
    updatedResponses[currentQuestionIndex] = score;
    setResponses(updatedResponses);
  };

  const handleNavigation = (direction) => {
    if (direction === 'next' && currentQuestionIndex < questions.length - 1) {
      setCurrentQuestionIndex(currentQuestionIndex + 1);
    } else if (direction === 'prev' && currentQuestionIndex > 0) {
      setCurrentQuestionIndex(currentQuestionIndex - 1);
    } else if (direction === 'submit') {
      calculateResults(responses);
    }
  };

  const calculateResults = (allScores) => {
    const totalWeightedScore = allScores.reduce((acc, score, idx) => {
      const questionWeight = questions[idx].weight;
      return acc + (score || 0) * questionWeight;
    }, 0);

    const maxPossibleScore = questions.reduce(
      (acc, question) => acc + question.weight * 4,
      0
    );

    const riskPercentage = (totalWeightedScore / maxPossibleScore) * 100;

    let riskProfile = '';
    if (riskPercentage <= 33) {
      riskProfile = 'Low Risk';
    } else if (riskPercentage <= 66) {
      riskProfile = 'Medium Risk';
    } else {
      riskProfile = 'High Risk';
    }

    const averageScore = totalWeightedScore / questions.length;
    let maturityLevel = '';
    if (averageScore <= 1.5) {
      maturityLevel = 'Optimizing';
    } else if (averageScore <= 2.5) {
      maturityLevel = 'Defined';
    } else if (averageScore <= 3.5) {
      maturityLevel = 'Managed';
    } else {
      maturityLevel = 'Initial';
    }

    const categoryScores = questions.reduce((acc, question, index) => {
      if (!acc[question.category]) {
        acc[question.category] = { yourScore: 0, maxScore: 0 };
      }
      acc[question.category].yourScore += (allScores[index] || 0) * question.weight;
      acc[question.category].maxScore += 4 * question.weight;
      return acc;
    }, {});

    setAssessmentResults({ riskProfile, maturityLevel, categoryScores });
    setShowResult(true);
  };

  if (showResult) {
    return (
      <EnhancedMaturityAssessmentResults
        {...assessmentResults}
        companyProfile={companyProfile}
        regulatoryLandscape={regulatoryLandscape}
        complianceItems={complianceItems}
        onProceed={() =>
          onComplete(
            assessmentResults.riskProfile,
            assessmentResults.maturityLevel
          )
        }
      />
    );
  }

  const question = questions[currentQuestionIndex];
  const selectedScore = responses[currentQuestionIndex];

  return (
    <div className="max-w-3xl mx-auto">
      <Progress
        value={((currentQuestionIndex + 1) / questions.length) * 100}
        className="mb-8"
      />

      <h2 className="text-2xl font-bold text-center text-purple-800 mb-4">
        Compliance Maturity Assessment
      </h2>
      <p className="text-sm text-center text-gray-600 mb-8">
        Question {currentQuestionIndex + 1} of {questions.length}
      </p>

      <div className="bg-white p-8 rounded-lg shadow-md">
        <p className="text-xl font-semibold text-gray-800 mb-6">
          {question.question}
        </p>
        <div className="space-y-4">
          {question.options.map((option, index) => (
            <button
              key={index}
              onClick={() => handleOptionSelect(option.score)}
              className={`w-full text-left p-4 rounded-lg transition duration-200 ${
                selectedScore === option.score
                  ? 'bg-purple-100 border-2 border-purple-500 text-purple-700'
                  : 'bg-gray-50 border border-gray-300 text-gray-700 hover:bg-purple-50 hover:border-purple-300'
              }`}
            >
              {option.text}
            </button>
          ))}
        </div>
      </div>

      {/* Navigation Buttons */}
      <div className="flex justify-between mt-6">
        <Button
          onClick={() => handleNavigation('prev')}
          variant="secondary"
          className="flex items-center"
          disabled={currentQuestionIndex === 0}
        >
          <ChevronLeft className="mr-2" /> Back
        </Button>
        {currentQuestionIndex < questions.length - 1 ? (
          <Button
            onClick={() => handleNavigation('next')}
            variant="primary"
            className="flex items-center"
            disabled={responses[currentQuestionIndex] === null}
          >
            Next <ChevronRight className="ml-2" />
          </Button>
        ) : (
          <Button
            onClick={() => handleNavigation('submit')}
            variant="success"
            className="flex items-center"
            disabled={responses[currentQuestionIndex] === null}
          >
            Submit <ChevronRight className="ml-2" />
          </Button>
        )}
      </div>

      {/* Navigation Dots */}
      <div className="flex justify-center mt-8 space-x-2">
        {questions.map((_, index) => (
          <button
            key={index}
            onClick={() => setCurrentQuestionIndex(index)}
            className={`w-2 h-2 rounded-full focus:outline-none ${
              index === currentQuestionIndex
                ? 'bg-purple-600'
                : 'bg-gray-300 hover:bg-purple-400'
            }`}
          />
        ))}
      </div>
    </div>
  );
};

export default ComplianceMaturityAssessment;