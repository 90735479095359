// src/components/ui/Select.js
import React from 'react'

export const Select = React.forwardRef(({ ...props }, ref) => (
  <select ref={ref} {...props} />
))

export const SelectTrigger = React.forwardRef(({ ...props }, ref) => (
  <button ref={ref} {...props} />
))

export const SelectValue = React.forwardRef(({ ...props }, ref) => (
  <span ref={ref} {...props} />
))

export const SelectContent = React.forwardRef(({ ...props }, ref) => (
  <div ref={ref} {...props} />
))

export const SelectItem = React.forwardRef(({ ...props }, ref) => (
  <option ref={ref} {...props} />
))

Select.displayName = "Select"
SelectTrigger.displayName = "SelectTrigger"
SelectValue.displayName = "SelectValue"
SelectContent.displayName = "SelectContent"
SelectItem.displayName = "SelectItem"