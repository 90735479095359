import React from 'react';
import { useForm, useFieldArray } from 'react-hook-form';
import { Input} from '../components/ui/Input';
import { Textarea } from '../components/ui/Textarea';
import { Button } from '../components/ui/Button';

const AnalysisEvaluation = ({ data, updateData }) => {
  const { register, control, handleSubmit, formState: { errors } } = useForm({
    defaultValues: data
  });
  const { fields, append, remove } = useFieldArray({
    control,
    name: "findings"
  });

  const onSubmit = (formData) => {
    updateData(formData);
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)} className="space-y-4">
      <h3 className="text-xl font-semibold mb-4">Analysis and Evaluation</h3>
      
      {fields.map((field, index) => (
        <div key={field.id} className="border p-4 rounded-md">
          <Input
            {...register(`findings.${index}.title`, { required: 'Finding title is required' })}
            placeholder="Finding Title"
          />
          {errors.findings?.[index]?.title && <p className="text-red-500 text-sm mt-1">{errors.findings[index].title.message}</p>}
          
          <Textarea
            {...register(`findings.${index}.description`)}
            placeholder="Finding Description"
            className="mt-2"
          />
          
          <select {...register(`findings.${index}.severity`)} className="mt-2 w-full border rounded-md p-2">
            <option value="">Select Severity</option>
            <option value="low">Low</option>
            <option value="medium">Medium</option>
            <option value="high">High</option>
          </select>
          
          <Button type="button" onClick={() => remove(index)} className="mt-2">Remove Finding</Button>
        </div>
      ))}
      
      <Button type="button" onClick={() => append({ title: '', description: '', severity: '' })}>
        Add Finding
      </Button>

      <Button type="submit">Save and Continue</Button>
    </form>
  );
};

export default AnalysisEvaluation;