import React, { useState } from 'react';
import { Handle, Position } from 'reactflow';
import { Badge } from './ui/Badge';
import { Button } from './ui/Button';
import {
  Box as BoxIcon,
  Server as ServerIcon,
  Database,
  Terminal as ApiIcon,
  Webhook,
  Wifi as WifiIcon,
  Settings,
  AlertCircle
} from 'lucide-react';

const integrationIcons = {
  SAP: BoxIcon,
  ERP: ServerIcon,
  Database: Database,
  API: ApiIcon,
  Webhook: Webhook,
  'Real-time': WifiIcon,
};

const getStatusColor = (status) => {
  switch (status) {
    case 'Connected':
      return 'bg-green-500';
    case 'Failed':
      return 'bg-red-500';
    case 'Pending':
      return 'bg-yellow-500';
    default:
      return 'bg-gray-500';
  }
};

const IntegrationNode = ({ data, isConnectable, selected }) => {
  const [showDetails, setShowDetails] = useState(false);
  const Icon = integrationIcons[data.integrationType] || ServerIcon;

  return (
    <div className={`
      relative p-4 rounded-lg border-2 bg-white
      ${selected ? 'border-blue-500 shadow-lg' : 'border-gray-200'}
      min-w-[200px] max-w-[300px]
    `}>
      {/* Connection Status Indicator */}
      <div className="absolute -top-2 -right-2">
        <div className={`
          w-4 h-4 rounded-full ${getStatusColor(data.connectionStatus)}
          shadow-sm border-2 border-white
        `} />
      </div>

      {/* Node Content */}
      <div className="flex items-center mb-2">
        <Icon className="w-6 h-6 text-blue-500 mr-2" />
        <div className="flex-grow">
          <div className="font-semibold text-gray-800">{data.label}</div>
          <div className="text-xs text-gray-500">{data.integrationType}</div>
        </div>
        <Button
          variant="ghost"
          size="sm"
          className="p-1"
          onClick={() => setShowDetails(!showDetails)}
        >
          <Settings className="w-4 h-4" />
        </Button>
      </div>

      {/* Status Badge */}
      <Badge 
        variant={data.connectionStatus === 'Connected' ? 'success' : 'secondary'}
        className="mb-2"
      >
        {data.connectionStatus}
      </Badge>

      {/* Details Section */}
      {showDetails && (
        <div className="mt-2 text-xs space-y-1 border-t pt-2">
          {data.configuration?.connection?.host && (
            <div className="flex justify-between">
              <span className="text-gray-500">Host:</span>
              <span className="text-gray-700">{data.configuration.connection.host}</span>
            </div>
          )}
          {data.configuration?.schedule?.frequency && (
            <div className="flex justify-between">
              <span className="text-gray-500">Sync:</span>
              <span className="text-gray-700">{data.configuration.schedule.frequency}</span>
            </div>
          )}
          {data.lastSync && (
            <div className="flex justify-between">
              <span className="text-gray-500">Last Sync:</span>
              <span className="text-gray-700">{data.lastSync}</span>
            </div>
          )}
          {data.error && (
            <div className="flex items-center text-red-500 mt-2">
              <AlertCircle className="w-4 h-4 mr-1" />
              <span>{data.error}</span>
            </div>
          )}
        </div>
      )}

      {/* Handles */}
      <Handle
        type="target"
        position={Position.Top}
        isConnectable={isConnectable}
        className="w-3 h-3 bg-blue-500"
      />
      <Handle
        type="source"
        position={Position.Bottom}
        isConnectable={isConnectable}
        className="w-3 h-3 bg-blue-500"
      />
    </div>
  );
};

export default IntegrationNode;