// Tooltip.js
import React from 'react';

const Tooltip = ({ children, message }) => {
  return (
    <div className="relative flex flex-col items-center group">
      {children}
      <div className="absolute bottom-full mb-2 hidden flex-col items-center group-hover:flex">
        <span className="relative z-10 p-2 text-xs leading-none text-white whitespace-no-wrap bg-gray-700 rounded-md shadow-lg">
          {message}
        </span>
        <div className="w-3 h-3 -mt-2 rotate-45 bg-gray-700"></div>
      </div>
    </div>
  );
};

export default Tooltip;
