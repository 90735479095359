// TechnicalDocumentation.js
import React, { useState } from 'react';
import { FileText, GitBranch, Plus, Upload, List, ChevronDown, ChevronUp } from 'lucide-react';
import Tooltip from './Tooltip';
import Modal from './Modal'; // Ensure Modal.js is in the same directory

const TechnicalDocumentation = () => {
  const [documents, setDocuments] = useState([
    {
      id: 1,
      name: 'AI System Architecture',
      version: '1.2',
      lastUpdated: '2023-07-15',
      compliant: true,
      owner: 'John Doe',
      auditTrail: [
        { version: '1.0', date: '2023-05-10', changes: 'Initial draft.' },
        { version: '1.1', date: '2023-06-20', changes: 'Updated architecture diagrams.' },
        { version: '1.2', date: '2023-07-15', changes: 'Finalized compliance sections.' },
      ],
    },
    {
      id: 2,
      name: 'Data Management Protocol',
      version: '2.0',
      lastUpdated: '2023-08-01',
      compliant: false,
      owner: 'Jane Smith',
      auditTrail: [
        { version: '1.0', date: '2023-06-01', changes: 'Initial draft.' },
        { version: '2.0', date: '2023-08-01', changes: 'Reviewed for compliance; pending updates.' },
      ],
    },
    {
      id: 3,
      name: 'Model Training Methodology',
      version: '1.5',
      lastUpdated: '2023-07-22',
      compliant: true,
      owner: 'Alice Johnson',
      auditTrail: [
        { version: '1.0', date: '2023-05-15', changes: 'Initial draft.' },
        { version: '1.5', date: '2023-07-22', changes: 'Updated data sources and compliance checks.' },
      ],
    },
  ]);

  const [isCreateModalOpen, setIsCreateModalOpen] = useState(false);
  const [isUploadModalOpen, setIsUploadModalOpen] = useState(false);

  const [newDocName, setNewDocName] = useState('');
  const [newDocVersion, setNewDocVersion] = useState('');
  const [newDocCompliant, setNewDocCompliant] = useState(false);

  const [searchQuery, setSearchQuery] = useState('');
  const [filterCompliant, setFilterCompliant] = useState('all'); // 'all', 'compliant', 'non-compliant'

  const handleCreateDocument = () => {
    setIsCreateModalOpen(true);
  };

  const handleUploadDocument = () => {
    setIsUploadModalOpen(true);
  };

  const handleViewAllDocuments = () => {
    alert('View All Documents functionality is under development.');
  };

  const handleViewVersionHistory = () => {
    alert('View Version History functionality is under development.');
  };

  const closeCreateModal = () => {
    setIsCreateModalOpen(false);
    setNewDocName('');
    setNewDocVersion('');
    setNewDocCompliant(false);
  };

  const closeUploadModal = () => {
    setIsUploadModalOpen(false);
    // Reset upload form fields if any
  };

  const submitCreateDocument = (e) => {
    e.preventDefault();
    if (!newDocName || !newDocVersion) {
      alert('Please provide both document name and version.');
      return;
    }

    const newDocument = {
      id: documents.length + 1,
      name: newDocName,
      version: newDocVersion,
      lastUpdated: new Date().toISOString().split('T')[0],
      compliant: newDocCompliant,
      owner: 'Current User', // Replace with actual user data
      auditTrail: [
        { version: newDocVersion, date: new Date().toISOString().split('T')[0], changes: 'Initial creation.' },
      ],
    };

    setDocuments([...documents, newDocument]);
    closeCreateModal();
  };

  const submitUploadDocument = (e) => {
    e.preventDefault();
    // Handle file upload logic ensuring compliance requirements
    alert('Upload Document functionality is under development.');
    closeUploadModal();
  };

  // Filtered Documents based on search and filter
  const filteredDocuments = documents.filter(doc => {
    const matchesSearch = doc.name.toLowerCase().includes(searchQuery.toLowerCase());
    const matchesFilter =
      filterCompliant === 'all' ||
      (filterCompliant === 'compliant' && doc.compliant) ||
      (filterCompliant === 'non-compliant' && !doc.compliant);
    return matchesSearch && matchesFilter;
  });

  return (
    <div className="p-6 bg-white rounded-lg shadow-md">
      <h2 className="text-2xl font-semibold mb-4">Technical Documentation</h2>
      <p className="mb-6 text-gray-600">
        Comprehensive documentation of AI systems as required by the EU AI Act, including system architecture, 
        data specifications, and performance metrics. This ensures transparency and facilitates compliance assessment.
      </p>
      <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
        {/* Document Management Section */}
        <div>
          <h3 className="text-lg font-medium mb-2">Document Management</h3>
          <div className="space-y-2">
            <Tooltip message="Create comprehensive technical documentation for new AI systems, ensuring all compliance criteria are met.">
              <button
                onClick={handleCreateDocument}
                className="flex items-center text-blue-600 hover:text-blue-800"
              >
                <Plus size={16} className="mr-1" /> Create New Document
              </button>
            </Tooltip>
            <Tooltip message="Upload existing documentation to the compliance hub. Ensure all files are up-to-date and meet EU AI Act standards.">
              <button
                onClick={handleUploadDocument}
                className="flex items-center text-blue-600 hover:text-blue-800"
              >
                <Upload size={16} className="mr-1" /> Upload Document
              </button>
            </Tooltip>
            <Tooltip message="View a comprehensive list of all technical documents. Utilize search and filter options to locate specific documents.">
              <button
                onClick={handleViewAllDocuments}
                className="flex items-center text-blue-600 hover:text-blue-800"
              >
                <List size={16} className="mr-1" /> View All Documents
              </button>
            </Tooltip>
          </div>

          {/* Search and Filter Controls */}
          <div className="mt-4">
            <h4 className="font-medium mb-2">Manage Documents:</h4>
            <div className="flex flex-col md:flex-row md:items-center md:space-x-4 space-y-2 md:space-y-0">
              <input
                type="text"
                placeholder="Search documents..."
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)}
                className="w-full md:w-1/2 p-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
              />
              <select
                value={filterCompliant}
                onChange={(e) => setFilterCompliant(e.target.value)}
                className="w-full md:w-1/4 p-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
              >
                <option value="all">All Documents</option>
                <option value="compliant">Compliant</option>
                <option value="non-compliant">Non-Compliant</option>
              </select>
            </div>
          </div>

          {/* Recent Documents */}
          <div className="mt-6">
            <h4 className="font-medium mb-2">Recent Documents:</h4>
            {filteredDocuments.length === 0 ? (
              <p className="text-gray-600">No documents match your search/filter criteria.</p>
            ) : (
              <ul className="space-y-2">
                {filteredDocuments.map(doc => (
                  <li key={doc.id} className="flex flex-col">
                    <div className="flex justify-between items-center">
                      <span className="flex items-center">
                        <FileText size={16} className="mr-2 text-gray-500" />
                        {doc.name}
                      </span>
                      <span className={`text-sm flex items-center ${doc.compliant ? 'text-green-600' : 'text-red-600'}`}>
                        v{doc.version} {doc.compliant ? '✅' : '❌'}
                      </span>
                    </div>
                    <div className="flex justify-between items-center text-xs text-gray-500 mt-1">
                      <span>Owner: {doc.owner}</span>
                      {/* Audit Trail Toggle */}
                      <AuditTrailToggle auditTrail={doc.auditTrail} />
                    </div>
                  </li>
                ))}
              </ul>
            )}
          </div>
        </div>

        {/* Version Control Section */}
        <div>
          <h3 className="text-lg font-medium mb-2">Version Control</h3>
          <div className="space-y-2">
            <Tooltip message="Access the version history of each document to track changes and ensure accountability.">
              <button
                onClick={handleViewVersionHistory}
                className="flex items-center text-blue-600 hover:text-blue-800"
              >
                <GitBranch size={16} className="mr-1" /> View Version History
              </button>
            </Tooltip>
          </div>
          <div className="mt-6">
            <h4 className="font-medium mb-2">Recent Updates:</h4>
            <ul className="space-y-2">
              {documents.map(doc => (
                <li key={doc.id} className="text-sm flex flex-col">
                  <div className="flex justify-between items-center">
                    <span className="font-medium">{doc.name}</span>
                    <span className={`text-sm flex items-center ${doc.compliant ? 'text-green-600' : 'text-red-600'}`}>
                      v{doc.version} on {doc.lastUpdated} {doc.compliant ? '✅' : '❌'}
                    </span>
                  </div>
                  <span className="text-xs text-gray-500">Owner: {doc.owner}</span>
                </li>
              ))}
            </ul>
          </div>
        </div>
      </div>

      {/* Create New Document Modal */}
      <Modal isOpen={isCreateModalOpen} onClose={closeCreateModal} title="Create New Document">
        <form onSubmit={submitCreateDocument} className="space-y-4">
          <div>
            <label className="block text-gray-700 mb-1">Document Name<span className="text-red-500">*</span></label>
            <input
              type="text"
              value={newDocName}
              onChange={(e) => setNewDocName(e.target.value)}
              className="w-full p-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
              required
              placeholder="e.g., AI System Architecture"
            />
          </div>
          <div>
            <label className="block text-gray-700 mb-1">Version<span className="text-red-500">*</span></label>
            <input
              type="text"
              value={newDocVersion}
              onChange={(e) => setNewDocVersion(e.target.value)}
              className="w-full p-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
              required
              placeholder="e.g., 1.0"
            />
          </div>
          <div>
            <label className="block text-gray-700 mb-1">Owner<span className="text-red-500">*</span></label>
            <input
              type="text"
              value="Current User" // Replace with actual user data
              disabled
              className="w-full p-2 border border-gray-300 rounded-lg bg-gray-100 cursor-not-allowed"
            />
          </div>
          <div className="flex items-center">
            <input
              type="checkbox"
              checked={newDocCompliant}
              onChange={(e) => setNewDocCompliant(e.target.checked)}
              className="mr-2"
              id="compliant"
            />
            <label htmlFor="compliant" className="text-gray-700">This document is compliant with EU AI Act.</label>
          </div>
          <div className="flex justify-end space-x-2">
            <button
              type="button"
              onClick={closeCreateModal}
              className="px-4 py-2 bg-gray-300 text-gray-700 rounded-lg hover:bg-gray-400"
            >
              Cancel
            </button>
            <button
              type="submit"
              className="px-4 py-2 bg-blue-600 text-white rounded-lg hover:bg-blue-700"
            >
              Create
            </button>
          </div>
        </form>
      </Modal>

      {/* Upload Document Modal */}
      <Modal isOpen={isUploadModalOpen} onClose={closeUploadModal} title="Upload Document">
        <form onSubmit={submitUploadDocument} className="space-y-4">
          <div>
            <label className="block text-gray-700 mb-1">Select Document<span className="text-red-500">*</span></label>
            <input
              type="file"
              accept=".pdf,.docx,.xlsx"
              className="w-full p-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
              required
            />
          </div>
          <div>
            <label className="block text-gray-700 mb-1">Document Name<span className="text-red-500">*</span></label>
            <input
              type="text"
              className="w-full p-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
              required
              placeholder="e.g., AI System Architecture"
            />
          </div>
          <div>
            <label className="block text-gray-700 mb-1">Version<span className="text-red-500">*</span></label>
            <input
              type="text"
              className="w-full p-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
              required
              placeholder="e.g., 1.0"
            />
          </div>
          <div className="flex items-center">
            <input
              type="checkbox"
              // Implement logic to set compliance status based on actual document analysis or manual input
              className="mr-2"
              id="uploadCompliant"
            />
            <label htmlFor="uploadCompliant" className="text-gray-700">This document is compliant with EU AI Act.</label>
          </div>
          <div className="flex justify-end space-x-2">
            <button
              type="button"
              onClick={closeUploadModal}
              className="px-4 py-2 bg-gray-300 text-gray-700 rounded-lg hover:bg-gray-400"
            >
              Cancel
            </button>
            <button
              type="submit"
              className="px-4 py-2 bg-blue-600 text-white rounded-lg hover:bg-blue-700"
            >
              Upload
            </button>
          </div>
        </form>
      </Modal>
    </div>
  );
};

// AuditTrailToggle Component
const AuditTrailToggle = ({ auditTrail }) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <div className="relative">
      <button
        onClick={() => setIsOpen(!isOpen)}
        className="flex items-center space-x-1 text-blue-600 hover:text-blue-800 focus:outline-none"
        aria-expanded={isOpen}
      >
        <span>View Audit Trail</span>
        {isOpen ? <ChevronUp size={16} /> : <ChevronDown size={16} />}
      </button>
      {isOpen && (
        <div className="absolute right-0 mt-2 w-64 bg-white border border-gray-200 rounded-lg shadow-lg p-2 z-10">
          <h5 className="text-sm font-semibold mb-2">Audit Trail:</h5>
          <ul className="space-y-1">
            {auditTrail.map((entry, index) => (
              <li key={index} className="text-xs">
                <span className="font-medium">v{entry.version}</span> on {entry.date}: {entry.changes}
              </li>
            ))}
          </ul>
        </div>
      )}
    </div>
  );
};

export default TechnicalDocumentation;
