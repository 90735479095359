import React from 'react';
import { TrendingUp, TrendingDown } from 'lucide-react';

const ScoreCard = ({ title, score, color, trend }) => {
  const getTrendIcon = () => {
    if (trend === 'up') return <TrendingUp className="w-5 h-5 text-green-500" />;
    if (trend === 'down') return <TrendingDown className="w-5 h-5 text-red-500" />;
    return null;
  };

  return (
    <div className={`bg-white p-6 rounded-lg shadow-md border-l-4 border-${color}-500`}>
      <h3 className="text-lg font-semibold mb-2">{title}</h3>
      <div className="flex items-center">
        <span className={`text-3xl font-bold text-${color}-500`}>{score}%</span>
        <div className="ml-2">
          {getTrendIcon()}
          <span className="text-sm text-gray-500 ml-1">
            {trend === 'up' ? '+2%' : trend === 'down' ? '-1%' : 'No change'}
          </span>
        </div>
      </div>
    </div>
  );
};

export default ScoreCard;