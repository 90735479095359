// src/api/config.js

const config = {
  apiUrl: process.env.REACT_APP_API_URL,

  // Feature flags
  enableDashboards: process.env.REACT_APP_ENABLE_DASHBOARDS === 'true',

  // Environment
  isDevelopment: process.env.NODE_ENV === 'development',

  // Auth configuration
  authConfig: {
    tokenKey: 'auth_token',
    refreshTokenKey: 'refresh_token',
    tokenExpiry: 60 * 60 * 1000, // 1 hour in milliseconds
  },
};

// Environment-specific validation
if (!config.isDevelopment && !config.apiUrl) {
  throw new Error('API URL is not configured. Set REACT_APP_API_URL in your environment.');
}

export default config;
