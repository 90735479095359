// File: src/contexts/AuditContext.js

import React, { createContext, useContext, useState } from 'react';

const AuditContext = createContext();

export const AuditProvider = ({ children }) => {
  const [currentStep, setCurrentStep] = useState(0);
  const [auditData, setAuditData] = useState({});

  const updateAuditData = (stepData) => {
    setAuditData(prevData => ({ ...prevData, ...stepData }));
  };

  return (
    <AuditContext.Provider value={{ currentStep, setCurrentStep, auditData, updateAuditData }}>
      {children}
    </AuditContext.Provider>
  );
};

export const useAuditContext = () => {
  const context = useContext(AuditContext);
  if (context === undefined) {
    throw new Error('useAuditContext must be used within an AuditProvider');
  }
  return context;
};