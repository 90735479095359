// riskAnalysisSuggestions.js
export const riskFactors = [
    {
      id: 'EUUS-DPF',
      title: "EU-US Data Privacy Framework",
      description: "New framework replacing Privacy Shield, affecting transatlantic data flows.",
      date: "July 2023",
      category: "Data Protection",
      severity: "high",
      recommendation: "Review and update data transfer mechanisms for EU citizen data. Consider applying for certification under the new framework.",
      additionalResources: ["Framework Documentation", "Certification Guidelines"]
    },
    {
      id: 'EU-AI-ACT',
      title: "AI Act in EU",
      description: "Introduces strict rules for AI in healthcare, impacting data usage and model training.",
      date: "Expected adoption in early 2024",
      category: "AI Governance",
      severity: "high",
      recommendation: "Conduct comprehensive AI impact assessments for healthcare-related AI systems. Implement robust governance framework for AI data usage.",
      additionalResources: ["AI Act Overview", "Impact Assessment Template"]
    },
    {
      id: 'CHINA-DSL',
      title: "China's Data Security Law",
      description: "Strict requirements for data collection, processing, and cross-border transfers.",
      date: "Implemented September 2021",
      category: "Data Security",
      severity: "medium",
      recommendation: "Review data practices for Chinese operations. Implement country-specific data transfer agreements.",
      additionalResources: ["DSL Guidelines", "Transfer Agreement Templates"]
    }
  ];
  
  export const priorityMatrix = {
    high: {
      immediate: ['EU-AI-ACT', 'EUUS-DPF'],
      shortTerm: ['CHINA-DSL']
    },
    medium: {
      shortTerm: ['CHINA-DSL'],
      mediumTerm: []
    },
    low: {
      mediumTerm: [],
      longTerm: []
    }
  };
  
  export const getRelevantRisks = (industry, region, features = []) => {
    return riskFactors.filter(risk => {
      // Filter based on industry
      if (industry === 'healthcare' && risk.id === 'EU-AI-ACT') return true;
      if (industry === 'tech' && risk.id === 'CHINA-DSL') return true;
      
      // Filter based on region
      if (region === 'EU' && ['EUUS-DPF', 'EU-AI-ACT'].includes(risk.id)) return true;
      if (region === 'APAC' && risk.id === 'CHINA-DSL') return true;
      
      // Filter based on features
      if (features.includes('ai') && risk.id === 'EU-AI-ACT') return true;
      if (features.includes('dataTransfer') && risk.id === 'EUUS-DPF') return true;
      
      return false;
    });
  };
  
  export const getRiskStatus = (riskId, currentControls = []) => {
    const risk = riskFactors.find(r => r.id === riskId);
    if (!risk) return null;
  
    const hasRequiredControls = currentControls.some(control => 
      control.riskId === riskId && control.status === 'implemented'
    );
  
    return {
      status: hasRequiredControls ? 'mitigated' : 'active',
      severity: risk.severity,
      nextSteps: hasRequiredControls ? 
        'Continue monitoring' : 
        `Implement controls for ${risk.title}`
    };
  };
  
  export const generateRiskReport = (risks, controls) => {
    return risks.map(risk => ({
      ...risk,
      status: getRiskStatus(risk.id, controls),
      timeline: getImplementationTimeline(risk.id),
      requiredActions: getRequiredActions(risk.id, controls)
    }));
  };
  
  const getImplementationTimeline = (riskId) => {
    for (const [priority, timeframes] of Object.entries(priorityMatrix)) {
      for (const [timeframe, risks] of Object.entries(timeframes)) {
        if (risks.includes(riskId)) {
          return { priority, timeframe };
        }
      }
    }
    return { priority: 'low', timeframe: 'longTerm' };
  };
  
  const getRequiredActions = (riskId, currentControls) => {
    const risk = riskFactors.find(r => r.id === riskId);
    if (!risk) return [];
  
    const baseActions = [
      `Review ${risk.title} requirements`,
      `Assess current compliance status`,
      `Develop implementation plan`
    ];
  
    const hasControls = currentControls.some(control => 
      control.riskId === riskId && control.status === 'implemented'
    );
  
    return hasControls ? 
      [...baseActions, 'Monitor effectiveness', 'Review periodically'] :
      [...baseActions, 'Implement controls', 'Validate implementation'];
  };
  
  export const getRiskAnalysisPrompts = (currentStep = 0) => {
    const prompts = [
      "Let's analyze your organization's risk exposure. Would you like to proceed with the analysis?",
      "I'll guide you through each risk factor. For each one, please indicate if it's relevant to your organization.",
      "Based on your responses, I can provide tailored recommendations. Would you like to see them?",
      "Would you like me to generate a detailed risk report with implementation timelines?"
    ];
    return prompts[currentStep] || prompts[0];
  };