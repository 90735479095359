import React, { useState, useRef, useEffect } from "react";
import { Menu, X, Brain, Workflow, Bot, ArrowUp } from "lucide-react";
import Button from "./Button";
import HowItWorksSection from "./HowItWorksSection";
import ValueProposition from "./ValueProposition";
import ProcessAndGovernance from "./ProcessAndGovernance";
import UseCases from "./UseCases";
import CallToAction from "./CallToAction";
import TechnologyStrategy from "./TechnologyStrategy";
import Footer from "./Footer";
import Ripple from "./ui/ripple";
import ShinyButton from "./ui/ShinyButton";

const LandingPage = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [showScrollTop, setShowScrollTop] = useState(false);

  // Create refs
  const valuePropositionRef = useRef(null);
  const useCasesSectionRef = useRef(null);

  const typeformUrl = "https://adi18k4liww.typeform.com/to/DYf77usz";

  useEffect(() => {
    const handleScroll = () => {
      setShowScrollTop(window.scrollY > 500);
    };

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  const toggleMenu = () => setIsMenuOpen(!isMenuOpen);

  const scrollToSection = (elementRef) => {
    const yOffset = -80; // Adjust this value based on your header height
    const element = elementRef.current;
    if (element) {
      const y =
        element.getBoundingClientRect().top + window.pageYOffset + yOffset;
      window.scrollTo({
        top: y,
        behavior: "smooth",
      });
    }
    setIsMenuOpen(false);
  };

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  return (
    <div className="min-h-screen relative">
      {/* Header */}
      <header className="bg-white shadow-sm sticky top-0 z-50">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-4">
          <div className="flex justify-between items-center">
            <h1 className="text-2xl font-bold text-blue-600">LOKATIAL</h1>

            {/* Desktop Menu */}
            <nav className="hidden md:flex items-center space-x-6">
              <Button
                variant="ghost"
                className="text-black hover:text-gray-700"
                onClick={() => scrollToSection(valuePropositionRef)}
              >
                Features
              </Button>
              <Button
                variant="ghost"
                className="text-black hover:text-gray-700"
                onClick={() => scrollToSection(useCasesSectionRef)}
              >
                Use Cases
              </Button>
              <a href={typeformUrl} target="_blank" rel="noopener noreferrer">
                <Button
                  variant="ghost"
                  className="text-black hover:text-gray-700"
                >
                  Contact
                </Button>
              </a>
            </nav>

            {/* Mobile menu button */}
            <button className="md:hidden" onClick={toggleMenu}>
              {isMenuOpen ? <X size={24} /> : <Menu size={24} />}
            </button>
          </div>
        </div>

        {/* Mobile Menu */}
        <div
          className={`md:hidden ${
            isMenuOpen ? "block" : "hidden"
          } absolute top-full left-0 right-0 bg-white shadow-md z-50`}
        >
          <div className="px-4 py-2 space-y-2">
            <Button
              variant="ghost"
              className="text-black hover:text-gray-700 w-full text-left"
              onClick={() => scrollToSection(valuePropositionRef)}
            >
              Features
            </Button>
            <Button
              variant="ghost"
              className="text-black hover:text-gray-700 w-full text-left"
              onClick={() => scrollToSection(useCasesSectionRef)}
            >
              Use Cases
            </Button>
            <a
              href={typeformUrl}
              target="_blank"
              rel="noopener noreferrer"
              className="block w-full"
              onClick={toggleMenu}
            >
              <Button
                variant="ghost"
                className="text-black hover:text-gray-700 w-full text-left"
              >
                Contact
              </Button>
            </a>
          </div>
        </div>
      </header>

      {/* New Hero Section with Ripple Effect */}
      <div className="relative min-h-[90vh] bg-[#1a1f2e]">
        <Ripple
          mainCircleSize={400}
          mainCircleOpacity={0.35}
          numCircles={6}
          className="absolute inset-0 z-0"
          customColors={{
            from: "rgba(139, 92, 246, 0.3)",
            to: "rgba(59, 130, 246, 0.3)",
          }}
        />

        <div className="absolute inset-0 bg-gradient-to-b from-transparent to-[#1a1f2e]/50" />

        <div className="relative z-10 max-w-7xl mx-auto px-6 py-24 text-center">
          {/* Feature badges row */}
          <div className="flex flex-wrap items-center justify-center gap-4 mb-8">
            <div className="flex items-center bg-purple-500/10 rounded-full px-4 py-2">
              <Brain className="h-6 w-6 text-purple-400" />
              <span className="ml-2 text-sm font-medium text-purple-400">
                Intelligent Design
              </span>
            </div>
            <div className="flex items-center bg-blue-500/10 rounded-full px-4 py-2">
              <Workflow className="h-6 w-6 text-blue-400" />
              <span className="ml-2 text-sm font-medium text-blue-400">
                Visual Implementation
              </span>
            </div>
            <div className="flex items-center bg-green-500/10 rounded-full px-4 py-2">
              <Bot className="h-6 w-6 text-green-400" />
              <span className="ml-2 text-sm font-medium text-green-400">
                Continuous Compliance
              </span>
            </div>
          </div>

          {/* Main headline */}
          <h1 className="text-5xl md:text-6xl font-bold text-white mb-8 tracking-tight">
            Design and Automate
            <span className="block text-transparent bg-clip-text bg-gradient-to-r from-purple-400 to-blue-400 mt-2">
              Next-Gen Compliance Processes
            </span>
          </h1>

          {/* Key points with improved styling */}
          <div className="max-w-3xl mx-auto space-y-4 mb-12">
            <div className="flex items-start gap-3">
              <div className="w-2 h-2 rounded-full bg-purple-400 mt-2 flex-shrink-0" />
              <p className="text-xl text-gray-300 leading-relaxed">
                Convert compliance & AI regulations into operational excellence
              </p>
            </div>

            <div className="flex items-start gap-3">
              <div className="w-2 h-2 rounded-full bg-purple-400 mt-2 flex-shrink-0" />
              <p className="text-xl text-gray-300 leading-relaxed">
                Deploy compliance processes in days, not months
              </p>
            </div>

            <div className="flex items-start gap-3">
              <div className="w-2 h-2 rounded-full bg-purple-400 mt-2 flex-shrink-0" />
              <p className="text-xl text-gray-300 leading-relaxed">
                Accelerate innovation without compromising on regulatory
                compliance
              </p>
            </div>
          </div>

          <div className="flex justify-center">
            <a
              href={typeformUrl}
              target="_blank"
              rel="noopener noreferrer"
              className="inline-flex items-center"
            >
              <ShinyButton className="flex items-center gap-2">
                Get Early Access
              </ShinyButton>
            </a>
          </div>
        </div>

        <div className="max-w-6xl mx-auto pb-24">
          <div className="grid grid-cols-1 md:grid-cols-3 gap-6 px-4">
            {[
              {
                icon: Brain,
                title: "Design Processes Fast",
                textColor: "text-purple-400",
                bulletColor: "bg-purple-400",
                points: [
                  "Transform complex requirements into operational workflows in minutes",
                  "Cut external consulting costs dramatically",
                ],
              },
              {
                icon: Workflow,
                title: "Implement Visually",
                textColor: "text-blue-400",
                bulletColor: "bg-blue-400",
                points: [
                  "Deploy and modify compliance workflows 5x faster",
                  "Adapt to regulatory changes in real-time",
                ],
              },
              {
                icon: Bot,
                title: "Monitor Continuously",
                textColor: "text-green-400",
                bulletColor: "bg-green-400",
                points: [
                  "Automate compliance checks with Mock Audit Wizard",
                  "Let LokCO Co-Pilot handle routine compliance tasks",
                ],
              },
            ].map((card, index) => (
              <div
                key={index}
                className="bg-gray-800/50 backdrop-blur-sm rounded-xl p-6 border border-gray-700/50 hover:bg-gray-800/60 transition-all duration-300"
              >
                <div className="flex items-center space-x-3 mb-4">
                  <card.icon className={`h-6 w-6 ${card.textColor}`} />
                  <h3 className="text-lg font-semibold text-white">
                    {card.title}
                  </h3>
                </div>
                <div className="space-y-3">
                  {card.points.map((point, idx) => (
                    <div key={idx} className="flex items-start gap-2">
                      <div
                        className={`w-1.5 h-1.5 rounded-full ${card.bulletColor} mt-2 flex-shrink-0`}
                      />
                      <p className="text-gray-400 leading-relaxed">{point}</p>
                    </div>
                  ))}
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>

      <main className="bg-white">
        <HowItWorksSection />
        <section ref={valuePropositionRef} className="scroll-mt-20">
          <ValueProposition />
        </section>
        <ProcessAndGovernance />
        <section ref={useCasesSectionRef} className="scroll-mt-20">
          <UseCases />
        </section>
        <TechnologyStrategy />
        <CallToAction />
      </main>

      {/* Scroll to Top Button */}
      <button
        onClick={scrollToTop}
        className={`fixed right-8 bottom-8 p-3 rounded-full bg-purple-600 text-white shadow-lg
                   transition-all duration-300 hover:bg-purple-700 z-50
                   ${
                     showScrollTop
                       ? "opacity-100 translate-y-0"
                       : "opacity-0 translate-y-10 pointer-events-none"
                   }`}
        aria-label="Scroll to top"
      >
        <ArrowUp className="h-6 w-6" />
      </button>

      <Footer />
    </div>
  );
};

export default LandingPage;
