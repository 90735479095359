// NodeContextMenu.jsx

import React from 'react';

const NodeContextMenu = ({ onEdit, onDelete }) => {
  return (
    <div className="bg-white border rounded shadow-md">
      <button
        onClick={onEdit}
        className="block w-full text-left px-4 py-2 hover:bg-gray-100"
      >
        Edit
      </button>
      <button
        onClick={onDelete}
        className="block w-full text-left px-4 py-2 hover:bg-gray-100 text-red-500"
      >
        Delete
      </button>
    </div>
  );
};

export default NodeContextMenu;
