// src/components/ui/Tooltip.js
import React, { useState } from 'react';

const TooltipContext = React.createContext({
  open: false,
  setOpen: () => {},
  content: null,
});

export const Tooltip = React.forwardRef(({ children, ...props }, ref) => {
  const [open, setOpen] = useState(false);
  
  return (
    <TooltipContext.Provider value={{ open, setOpen, content: props.content }}>
      <div ref={ref} className="relative inline-block" {...props}>
        {children}
      </div>
    </TooltipContext.Provider>
  );
});

export const TooltipProvider = ({ children }) => (
  <>{children}</>
);

export const TooltipTrigger = React.forwardRef(({ children, ...props }, ref) => {
  const { setOpen } = React.useContext(TooltipContext);
  
  return (
    <div
      ref={ref}
      onMouseEnter={() => setOpen(true)}
      onMouseLeave={() => setOpen(false)}
      className="inline-block"
      {...props}
    >
      {children}
    </div>
  );
});

export const TooltipContent = React.forwardRef(({ children, className = '', ...props }, ref) => {
  const { open, content } = React.useContext(TooltipContext);
  
  if (!open) return null;
  
  return (
    <div
      ref={ref}
      className={`absolute z-50 px-3 py-2 text-sm bg-gray-900 text-white rounded-md shadow-lg transform -translate-x-1/2 left-1/2 -top-2 -translate-y-full mt-1 ${className}`}
      {...props}
    >
      <div className="relative">
        {children || content}
        <div className="absolute -bottom-1 left-1/2 transform -translate-x-1/2 translate-y-full">
          <div className="border-8 border-transparent border-t-gray-900" />
        </div>
      </div>
    </div>
  );
});

Tooltip.displayName = "Tooltip";
TooltipTrigger.displayName = "TooltipTrigger";
TooltipContent.displayName = "TooltipContent";

export default Tooltip;