import React from 'react';
import { Card, CardHeader, CardTitle, CardContent } from './ui/Card';
import { Tooltip } from './ui/Tooltip';

const AssessmentCard = ({ title, current, items, icon: Icon }) => {
  const currentIndex = items.findIndex(item => item.name === current);
  const progress = ((currentIndex + 1) / items.length) * 100;

  return (
    <Card className="overflow-hidden">
      <CardHeader className="bg-purple-50">
        <CardTitle className="flex items-center text-lg text-purple-800">
          <Icon className="mr-2" />
          {title}
        </CardTitle>
      </CardHeader>
      <CardContent className="pt-4">
        <div className="relative h-2 bg-gray-200 rounded-full mb-4">
          <div 
            className="absolute h-full bg-purple-600 rounded-full"
            style={{ width: `${progress}%` }}
          />
        </div>
        <div className="flex justify-between mb-2">
          {items.map((item, index) => (
            <Tooltip key={index} content={item.description}>
              <div className={`text-center ${current === item.name ? 'font-bold' : ''}`}>
                <span className={`text-xs ${current === item.name ? 'text-purple-700' : 'text-gray-600'}`}>
                  {item.name}
                </span>
              </div>
            </Tooltip>
          ))}
        </div>
        <div className="mt-4 text-center">
          <span className="text-lg font-semibold text-purple-700">
            {current}
          </span>
        </div>
      </CardContent>
    </Card>
  );
};

export default AssessmentCard;