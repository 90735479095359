import React from 'react';
import { motion } from 'framer-motion';
import { cn } from '../../utils/cn';

const ShinyButton = ({ children, className, ...props }) => {
  const animationProps = {
    initial: { '--x': '100%', scale: 0.95 },
    animate: { '--x': '-100%', scale: 1 },
    whileHover: { scale: 1.02 },
    whileTap: { scale: 0.98 },
    transition: {
      repeat: Infinity,
      repeatType: 'loop',
      repeatDelay: 1,
      duration: 2,
      ease: 'linear',
      scale: {
        type: 'spring',
        stiffness: 200,
        damping: 10
      }
    }
  };

  return (
    <motion.button
      {...animationProps}
      {...props}
      className={cn(
        `relative px-8 py-4 text-lg font-medium rounded-xl
        bg-gradient-to-r from-purple-600 to-blue-600
        hover:from-purple-700 hover:to-blue-700
        text-white backdrop-blur-xl
        transition-all duration-300
        hover:shadow-[0_0_30px_rgba(139,92,246,0.3)]`,
        className
      )}
    >
      <span
        className="relative block tracking-wide"
        style={{
          maskImage: `linear-gradient(
            -75deg,
            rgba(255,255,255,1) calc(var(--x) + 20%),
            rgba(255,255,255,0.6) calc(var(--x) + 30%),
            rgba(255,255,255,1) calc(var(--x) + 100%)
          )`
        }}
      >
        {children}
      </span>
      <span
        style={{
          mask: 'linear-gradient(#fff, #fff) content-box, linear-gradient(#fff, #fff)',
          maskComposite: 'exclude'
        }}
        className="absolute inset-0 z-10 block rounded-[inherit] 
          bg-[linear-gradient(-75deg,rgba(255,255,255,0.1)_calc(var(--x)+20%),rgba(255,255,255,0.3)_calc(var(--x)+25%),rgba(255,255,255,0.1)_calc(var(--x)+100%))] 
          p-px"
      />
    </motion.button>
  );
};

export default ShinyButton;