import React, { useState, useEffect } from 'react';
import { Input } from '../ui/Input';
import { Textarea } from '../ui/Textarea';
import { Button } from '../ui/Button';
import { Select } from '../ui/Select';
import { PlusCircle, Trash2, BarChart2, AlertTriangle } from 'lucide-react';

const PerformanceMetricsSection = ({ metrics: initialMetrics, onUpdate, aiSuggestions, maturityLevel }) => {
  const [metrics, setMetrics] = useState(initialMetrics || []);
  const [newMetric, setNewMetric] = useState({ name: '', description: '', target: '', frequency: '', dataSource: '' });
  const [filteredSuggestions, setFilteredSuggestions] = useState([]);
  const [error, setError] = useState(null);

  useEffect(() => {
    if (aiSuggestions && aiSuggestions.kpis) {
      const filtered = aiSuggestions.kpis.filter(metric => {
        if (maturityLevel === 'Initial') return metric.complexity === 'Low';
        if (maturityLevel === 'Managed') return ['Low', 'Medium'].includes(metric.complexity);
        return true; // For 'Defined' and above, include all suggestions
      });
      setFilteredSuggestions(filtered);
    }
  }, [aiSuggestions, maturityLevel]);

  useEffect(() => {
    if (typeof onUpdate === 'function') {
      try {
        onUpdate(metrics);
      } catch (err) {
        console.error('Error in onUpdate:', err);
        setError('An error occurred while updating metrics. Please try again.');
      }
    } else {
      console.warn('onUpdate is not a function. Metrics will not be synced with parent component.');
    }
  }, [metrics, onUpdate]);

  const handleAddMetric = () => {
    if (newMetric.name && newMetric.description && newMetric.target && newMetric.frequency && newMetric.dataSource) {
      setMetrics(prevMetrics => [...prevMetrics, { ...newMetric, id: Date.now() }]);
      setNewMetric({ name: '', description: '', target: '', frequency: '', dataSource: '' });
    }
  };

  const handleRemoveMetric = (id) => {
    setMetrics(prevMetrics => prevMetrics.filter(metric => metric.id !== id));
  };

  const handleUpdateMetric = (id, field, value) => {
    setMetrics(prevMetrics => prevMetrics.map(metric => 
      metric.id === id ? { ...metric, [field]: value } : metric
    ));
  };

  const handleUseSuggestedMetric = (suggestedMetric) => {
    setNewMetric({
      name: suggestedMetric.name,
      description: suggestedMetric.description,
      target: suggestedMetric.target || '',
      frequency: suggestedMetric.frequency || '',
      dataSource: suggestedMetric.dataSource || '',
    });
  };

  return (
    <div className="space-y-6">
      <h3 className="text-xl font-semibold text-indigo-700 flex items-center">
        <BarChart2 className="mr-2" /> Performance Metrics
      </h3>

      {error && (
        <div className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative" role="alert">
          <strong className="font-bold">Error!</strong>
          <span className="block sm:inline"> {error}</span>
        </div>
      )}
      
      {/* Existing Metrics */}
      {metrics.length > 0 && (
        <div className="space-y-4">
          <h4 className="text-lg font-medium text-gray-700">Current Metrics</h4>
          {metrics.map((metric) => (
            <div key={metric.id} className="bg-indigo-50 p-4 rounded-lg space-y-2">
              <div className="flex items-center justify-between">
                <Input 
                  value={metric.name} 
                  onChange={(e) => handleUpdateMetric(metric.id, 'name', e.target.value)}
                  className="font-medium text-indigo-700"
                />
                <Button variant="ghost" onClick={() => handleRemoveMetric(metric.id)}>
                  <Trash2 className="h-4 w-4 text-red-500" />
                </Button>
              </div>
              <Textarea 
                value={metric.description}
                onChange={(e) => handleUpdateMetric(metric.id, 'description', e.target.value)}
                rows={2}
              />
              <div className="flex space-x-2">
                <Input 
                  value={metric.target}
                  onChange={(e) => handleUpdateMetric(metric.id, 'target', e.target.value)}
                  placeholder="Target value"
                />
                <Select 
                  value={metric.frequency}
                  onChange={(e) => handleUpdateMetric(metric.id, 'frequency', e.target.value)}
                >
                  <option value="">Measurement Frequency</option>
                  <option value="Daily">Daily</option>
                  <option value="Weekly">Weekly</option>
                  <option value="Monthly">Monthly</option>
                  <option value="Quarterly">Quarterly</option>
                  <option value="Annually">Annually</option>
                </Select>
              </div>
              <Input 
                value={metric.dataSource}
                onChange={(e) => handleUpdateMetric(metric.id, 'dataSource', e.target.value)}
                placeholder="Data source"
              />
            </div>
          ))}
        </div>
      )}
      
      {/* New Metric Form */}
      <div className="bg-white p-4 rounded-lg shadow space-y-4">
        <h4 className="text-lg font-medium text-gray-700">Add New Metric</h4>
        <Input 
          value={newMetric.name}
          onChange={(e) => setNewMetric({...newMetric, name: e.target.value})}
          placeholder="Metric name"
        />
        <Textarea 
          value={newMetric.description}
          onChange={(e) => setNewMetric({...newMetric, description: e.target.value})}
          placeholder="Describe the metric"
          rows={3}
        />
        <div className="flex space-x-2">
          <Input 
            value={newMetric.target}
            onChange={(e) => setNewMetric({...newMetric, target: e.target.value})}
            placeholder="Target value"
          />
          <Select 
            value={newMetric.frequency}
            onChange={(e) => setNewMetric({...newMetric, frequency: e.target.value})}
          >
            <option value="">Measurement Frequency</option>
            <option value="Daily">Daily</option>
            <option value="Weekly">Weekly</option>
            <option value="Monthly">Monthly</option>
            <option value="Quarterly">Quarterly</option>
            <option value="Annually">Annually</option>
          </Select>
        </div>
        <Input 
          value={newMetric.dataSource}
          onChange={(e) => setNewMetric({...newMetric, dataSource: e.target.value})}
          placeholder="Data source"
        />
        <Button onClick={handleAddMetric} variant="outline" className="w-full">
          <PlusCircle className="h-4 w-4 mr-2" /> Add Metric
        </Button>
      </div>

      {/* AI Suggested Metrics */}
      {filteredSuggestions.length > 0 && (
        <div className="bg-indigo-100 p-4 rounded-lg space-y-4">
          <h4 className="text-lg font-medium text-indigo-700 flex items-center">
            <BarChart2 className="mr-2" /> AI-Suggested Metrics
          </h4>
          {filteredSuggestions.map((suggestedMetric, index) => (
            <div key={index} className="bg-white p-3 rounded-md shadow-sm">
              <p className="font-medium text-gray-700">{suggestedMetric.name}</p>
              <p className="text-sm text-gray-600 mt-1">{suggestedMetric.description}</p>
              <div className="flex justify-between items-center mt-2">
                <div className="text-sm">
                  <span className="text-indigo-600 font-medium">Frequency:</span> {suggestedMetric.frequency}
                </div>
                <Button 
                  onClick={() => handleUseSuggestedMetric(suggestedMetric)} 
                  variant="outline" 
                  size="sm"
                >
                  Use This Metric
                </Button>
              </div>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default PerformanceMetricsSection;