import React, { useState, useEffect, useCallback, useRef } from 'react';
import { Button } from '../ui/Button';
import { Input } from '../ui/Input';
import { Select } from '../ui/Select';
import { Textarea } from '../ui/Textarea';
import { PlusCircle, Trash2, AlertTriangle, Bot } from 'lucide-react';
import AIThinkingAnimation from '../AIThinkingAnimation';
import { debounce } from 'lodash';

const AISuggestedRisks = React.memo(({ risks, onAccept }) => (
  <div className="space-y-4 bg-blue-50 p-4 rounded-lg">
    <h3 className="text-lg font-medium flex items-center text-blue-600">
      <Bot className="w-5 h-5 mr-2 text-blue-500" />
      AI-Suggested Risks
    </h3>
    <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
      {risks.map((risk) => (
        <div key={risk.id} className="flex items-center bg-white p-2 rounded shadow">
          <span className="flex-grow text-blue-700">{risk.name}</span>
          <Button
            variant="outline"
            size="sm"
            className="ml-2"
            onClick={() => onAccept(risk)}
          >
            Accept
          </Button>
        </div>
      ))}
    </div>
  </div>
));

const RiskWithControls = React.memo(
  ({
    risk,
    onUpdateRisk,
    onRemoveRisk,
    controls,
    onAddControl,
    onUpdateControl,
    onRemoveControl,
    controlSuggestions,
    allRisks,
  }) => {
    const handleRiskChange = useCallback(
      (field, value) => {
        onUpdateRisk(risk.id, { [field]: value });
      },
      [risk.id, onUpdateRisk]
    );

    const handleAddControlToRisk = useCallback(
      (control = { name: '', description: '', type: '', associatedRiskId: risk.id }) => {
        onAddControl({ ...control, associatedRiskId: risk.id });
      },
      [onAddControl, risk.id]
    );

    return (
      <div className="mb-6 border rounded-lg p-4 bg-white shadow-sm">
        {/* Risk Details */}
        <div className="flex items-center space-x-2 mb-2">
          <Input
            value={risk.name}
            onChange={(e) => handleRiskChange('name', e.target.value)}
            placeholder="Risk name"
            className="flex-grow"
          />
          <Button
            variant="ghost"
            size="icon"
            onClick={() => onRemoveRisk(risk.id)}
            className="text-red-500 hover:text-red-700 hover:bg-red-100"
          >
            <Trash2 className="w-4 h-4" />
          </Button>
        </div>
        <Textarea
          value={risk.description}
          onChange={(e) => handleRiskChange('description', e.target.value)}
          placeholder="Risk description"
          rows={3}
          className="w-full mt-2 text-gray-600"
        />
        <div className="flex items-center space-x-2 mt-2">
          <Select
            value={risk.likelihood}
            onChange={(e) => handleRiskChange('likelihood', e.target.value)}
            className="w-1/3 text-gray-700"
          >
            <option value="">Likelihood</option>
            <option value="low">Low</option>
            <option value="medium">Medium</option>
            <option value="high">High</option>
          </Select>
          <Select
            value={risk.impact}
            onChange={(e) => handleRiskChange('impact', e.target.value)}
            className="w-1/3 text-gray-700"
          >
            <option value="">Impact</option>
            <option value="low">Low</option>
            <option value="medium">Medium</option>
            <option value="high">High</option>
          </Select>
          <Input
            value={risk.category}
            onChange={(e) => handleRiskChange('category', e.target.value)}
            placeholder="Risk category"
            className="w-1/3"
          />
        </div>
        {(!risk.name || !risk.likelihood || !risk.impact) && (
          <p className="text-sm text-red-500 mt-2">
            Please fill in all required fields.
          </p>
        )}

        {/* Suggested Controls */}
        {controlSuggestions.length > 0 && (
          <div className="mt-4 bg-gray-50 p-2 rounded">
            <h5 className="text-sm font-medium text-gray-700">
              AI-Suggested Controls:
            </h5>
            {controlSuggestions.map((control) => (
              <div key={control.id} className="flex items-center">
                <span className="flex-grow text-gray-700">
                  {control.name} - {control.description}
                </span>
                <Button
                  variant="outline"
                  size="sm"
                  onClick={() => handleAddControlToRisk(control)}
                >
                  Accept
                </Button>
              </div>
            ))}
          </div>
        )}

        {/* Controls Associated with Risk */}
        <div className="mt-4">
          <h5 className="text-sm font-medium text-gray-700">Controls:</h5>
          {controls.map((control) => (
            <ControlItem
              key={control.id}
              control={control}
              onUpdate={onUpdateControl}
              onRemove={onRemoveControl}
              allRisks={allRisks}
            />
          ))}
          <Button
            variant="outline"
            size="sm"
            onClick={() => handleAddControlToRisk()}
            className="mt-2"
          >
            <PlusCircle className="w-4 h-4 mr-2" />
            Add Control
          </Button>
        </div>
      </div>
    );
  }
);

const ControlItem = React.memo(({ control, onUpdate, onRemove, allRisks }) => {
  const handleControlChange = useCallback(
    (field, value) => {
      onUpdate(control.id, { [field]: value });
    },
    [control.id, onUpdate]
  );

  return (
    <div className="mb-4 p-2 border rounded bg-white shadow-sm">
      <div className="flex items-center space-x-2">
        <Input
          value={control.name}
          onChange={(e) => handleControlChange('name', e.target.value)}
          placeholder="Control name"
          className="flex-grow"
        />
        <Button
          variant="ghost"
          size="icon"
          onClick={() => onRemove(control.id)}
          className="text-red-500 hover:text-red-700 hover:bg-red-100"
        >
          <Trash2 className="w-4 h-4" />
        </Button>
      </div>
      <Textarea
        value={control.description}
        onChange={(e) => handleControlChange('description', e.target.value)}
        placeholder="Control description"
        rows={2}
        className="w-full mt-2 text-gray-600"
      />
      <div className="flex items-center space-x-2 mt-2">
        <Select
          value={control.type}
          onChange={(e) => handleControlChange('type', e.target.value)}
          className="w-1/3 text-gray-700"
        >
          <option value="">Type</option>
          <option value="Preventive">Preventive</option>
          <option value="Detective">Detective</option>
          <option value="Corrective">Corrective</option>
        </Select>
        <Select
          value={control.associatedRiskId}
          onChange={(e) => handleControlChange('associatedRiskId', e.target.value)}
          className="w-2/3 text-gray-700"
        >
          <option value="">Associated Risk</option>
          {allRisks.map((risk) => (
            <option key={risk.id} value={risk.id}>
              {risk.name}
            </option>
          ))}
        </Select>
      </div>
    </div>
  );
});

const RiskAndControlAssessmentSection = ({
  onUpdate,
  initialRisks = [],
  initialControls = [],
  aiSuggestions = {},
  isLoading,
  riskProfile,
  maturityLevel,
}) => {
  const [risks, setRisks] = useState(initialRisks);
  const [controls, setControls] = useState(initialControls);
  const [suggestedRisks, setSuggestedRisks] = useState([]);
  const [suggestedControls, setSuggestedControls] = useState([]);

  useEffect(() => {
    if (aiSuggestions?.riskAreas?.length > 0) {
      setSuggestedRisks(
        aiSuggestions.riskAreas
          .filter((suggestion) => !risks.some((risk) => risk.name === suggestion.name))
          .map((suggestion) => ({
            id: suggestion.id,
            name: suggestion.name,
            description: '',
            likelihood: '',
            impact: '',
            category: '',
          }))
      );
    }
  }, [aiSuggestions, risks]);

  useEffect(() => {
    if (aiSuggestions?.controlSuggestions?.length > 0) {
      setSuggestedControls(
        aiSuggestions.controlSuggestions.filter(
          (control) => !controls.some((c) => c.name === control.name)
        )
      );
    }
  }, [aiSuggestions, controls]);

  const debouncedOnUpdate = useRef(
    debounce((updatedRisks, updatedControls) => {
      onUpdate({ risks: updatedRisks, controls: updatedControls });
    }, 500)
  ).current;

  useEffect(() => {
    debouncedOnUpdate(risks, controls);
    return () => {
      debouncedOnUpdate.cancel();
    };
  }, [risks, controls, debouncedOnUpdate]);

  // Handlers for Risks
  const handleAddRisk = useCallback(
    (
      risk = {
        id: Date.now().toString(),
        name: '',
        description: '',
        likelihood: '',
        impact: '',
        category: '',
      }
    ) => {
      setRisks((prevRisks) => [...prevRisks, risk]);

      if (risk.name) {
        setSuggestedRisks((prevSuggestions) =>
          prevSuggestions.filter((suggestion) => suggestion.name !== risk.name)
        );
      }
    },
    [setRisks, setSuggestedRisks]
  );

  const handleRemoveRisk = useCallback(
    (id) => {
      setRisks((prevRisks) => prevRisks.filter((risk) => risk.id !== id));
      setControls((prevControls) =>
        prevControls.filter((control) => control.associatedRiskId !== id)
      );
    },
    [setRisks, setControls]
  );

  const handleRiskChange = useCallback(
    (id, updates) => {
      setRisks((prevRisks) =>
        prevRisks.map((risk) => (risk.id === id ? { ...risk, ...updates } : risk))
      );
    },
    [setRisks]
  );

  // Handlers for Controls
  const handleAddControl = useCallback(
    (
      control = {
        id: Date.now().toString(),
        name: '',
        description: '',
        type: '',
        associatedRiskId: '',
      }
    ) => {
      setControls((prevControls) => [...prevControls, control]);

      if (control.name) {
        setSuggestedControls((prevSuggestions) =>
          prevSuggestions.filter((suggestion) => suggestion.name !== control.name)
        );
      }
    },
    [setControls, setSuggestedControls]
  );

  const handleRemoveControl = useCallback(
    (id) => {
      setControls((prevControls) => prevControls.filter((control) => control.id !== id));
    },
    [setControls]
  );

  const handleControlChange = useCallback(
    (id, updates) => {
      setControls((prevControls) =>
        prevControls.map((control) =>
          control.id === id ? { ...control, ...updates } : control
        )
      );
    },
    [setControls]
  );

  return (
    <div className="space-y-6">
      <h2 className="text-xl font-semibold text-gray-800 flex items-center">
        <AlertTriangle className="mr-2 text-yellow-500" />
        Risk and Control Assessment
      </h2>

      {isLoading ? (
        <div className="my-8 p-4 bg-gray-50 rounded-lg shadow-inner">
          <AIThinkingAnimation />
          <p className="text-center text-gray-700 mt-4 font-medium">
            AI is analyzing potential risks and controls...
          </p>
        </div>
      ) : (
        <>
          {/* AI-Suggested Risks */}
          {suggestedRisks.length > 0 && (
            <AISuggestedRisks risks={suggestedRisks} onAccept={handleAddRisk} />
          )}

          {/* User-Defined Risks and Controls */}
          <div>
            <h3 className="text-lg font-medium text-gray-800 mt-6 mb-4">Your Risks</h3>
            {risks.map((risk) => (
              <RiskWithControls
                key={risk.id}
                risk={risk}
                onUpdateRisk={handleRiskChange}
                onRemoveRisk={handleRemoveRisk}
                controls={controls.filter(
                  (control) => control.associatedRiskId === risk.id
                )}
                onAddControl={handleAddControl}
                onUpdateControl={handleControlChange}
                onRemoveControl={handleRemoveControl}
                controlSuggestions={suggestedControls.filter(
                  (control) => control.associatedRiskId === risk.id
                )}
                allRisks={risks}
              />
            ))}
          </div>

          <Button variant="outline" onClick={() => handleAddRisk()} className="mt-4">
            <PlusCircle className="w-4 h-4 mr-2" />
            Add Risk
          </Button>
        </>
      )}
    </div>
  );
};

export default RiskAndControlAssessmentSection;
