import React, { useState, useEffect } from 'react';
import { Input } from './ui/Input';
import { Textarea } from './ui/Textarea';
import { Select } from './ui/Select';
import { Button } from './ui/Button';
import FormBuilder from './FormBuilder';

const NodeEditor = ({ node, onUpdate, onClose }) => {
  const [nodeData, setNodeData] = useState(node.data);

  useEffect(() => {
    setNodeData(node.data);
  }, [node]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setNodeData(prev => ({ ...prev, [name]: value }));
  };

  const handleSave = () => {
    onUpdate(node.id, nodeData);
    onClose();
  };

  const renderNodeTypeSpecificFields = () => {
    switch (nodeData.type) {
      case 'task':
        return (
          <Textarea
            name="description"
            value={nodeData.description || ''}
            onChange={handleInputChange}
            placeholder="Task description"
            className="mt-2"
          />
        );
      case 'decision':
        return (
          <div className="mt-2">
            <Input
              name="condition"
              value={nodeData.condition || ''}
              onChange={handleInputChange}
              placeholder="Decision condition"
              className="mb-2"
            />
            <Input
              name="yesLabel"
              value={nodeData.yesLabel || 'Yes'}
              onChange={handleInputChange}
              placeholder="Label for 'Yes' path"
              className="mb-2"
            />
            <Input
              name="noLabel"
              value={nodeData.noLabel || 'No'}
              onChange={handleInputChange}
              placeholder="Label for 'No' path"
            />
          </div>
        );
      case 'form':
        return (
          <div className="mt-4">
            <h3 className="text-lg font-semibold mb-2">Form Fields</h3>
            <FormBuilder
              fields={nodeData.formFields || []}
              onUpdate={(updatedFields) => setNodeData(prev => ({ ...prev, formFields: updatedFields }))}
            />
          </div>
        );
      case 'calculation':
        return (
          <Textarea
            name="formula"
            value={nodeData.formula || ''}
            onChange={handleInputChange}
            placeholder="Calculation formula"
            className="mt-2"
          />
        );
      case 'notification':
        return (
          <div className="mt-2">
            <Select
              name="notificationType"
              value={nodeData.notificationType || ''}
              onChange={handleInputChange}
              className="mb-2"
            >
              <option value="">Select Notification Type</option>
              <option value="email">Email</option>
              <option value="sms">SMS</option>
              <option value="inApp">In-App Notification</option>
            </Select>
            <Textarea
              name="notificationContent"
              value={nodeData.notificationContent || ''}
              onChange={handleInputChange}
              placeholder="Notification content"
            />
          </div>
        );
      default:
        return null;
    }
  };

  return (
    <div className="p-4 bg-white rounded-lg shadow-lg">
      <h2 className="text-2xl font-bold mb-4">Edit Node: {nodeData.type}</h2>
      <Input
        name="label"
        value={nodeData.label || ''}
        onChange={handleInputChange}
        placeholder="Node Label"
        className="mb-4"
      />
      {renderNodeTypeSpecificFields()}
      <div className="mt-4 flex justify-end space-x-2">
        <Button onClick={onClose} variant="outline">Cancel</Button>
        <Button onClick={handleSave} variant="primary">Save Changes</Button>
      </div>
    </div>
  );
};

export default NodeEditor;