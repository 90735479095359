import React from 'react';

export const Card = ({ children, className = '', shadow = 'shadow-md', ...props }) => (
  <div className={`bg-white rounded-lg ${shadow} ${className}`} {...props} role="region" aria-label="Card">
    {children}
  </div>
);

export const CardHeader = ({ children, className = '', ...props }) => (
  <div className={`px-4 py-5 border-b border-gray-200 sm:px-6 ${className}`} {...props} aria-label="Card Header">
    {children}
  </div>
);

export const CardTitle = ({ children, className = '', ...props }) => (
  <h3 className={`text-lg leading-6 font-semibold text-gray-900 ${className}`} {...props}>
    {children}
  </h3>
);

export const CardDescription = ({ children, className = '', ...props }) => (
  <p className={`mt-1 max-w-2xl text-sm text-gray-500 ${className}`} {...props}>
    {children}
  </p>
);

export const CardContent = ({ children, className = '', ...props }) => (
  <div className={`px-4 py-5 sm:p-6 ${className}`} {...props} aria-label="Card Content">
    {children}
  </div>
);

export const CardFooter = ({ children, className = '', ...props }) => (
  <div className={`px-4 py-4 border-t border-gray-200 sm:px-6 ${className}`} {...props} aria-label="Card Footer">
    {children}
  </div>
);