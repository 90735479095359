import React from 'react';
import { ChevronLeft, ChevronRight } from 'lucide-react';
import { Button } from './ui/Button';
import { Progress } from './ui/Progress';
import ObjectivesStep from './steps/ObjectivesStep';
import RegulatoryRequirementsStep from './steps/RegulatoryRequirementsStep';
import ProcessScopeStep from './steps/ProcessScopeStep';
import ProcessExclusionsStep from './steps/ProcessExclusionsStep';
import StakeholdersStep from './steps/StakeholdersStep';
import DepartmentsStep from './steps/DepartmentsStep';

const BaseProcessDesign = ({
  step,
  steps,
  selectedProcess,
  riskProfile,
  maturityLevel,
  aiSuggestions,
  onUpdate,
  initialData = {}, // Provide a default value here
  onNext,
  onPrevious,
  isLoading,
}) => {
  const stepComponents = [
    ObjectivesStep,
    RegulatoryRequirementsStep,
    ProcessScopeStep,
    ProcessExclusionsStep,
    StakeholdersStep,
    DepartmentsStep,
  ];

  const stepAiSuggestionKeys = {
    'Objectives': 'ObjectivesStep',
    'Regulatory Requirements': 'RegulatoryRequirementsStep',
    'Process Scope': 'ProcessScopeStep',
    'Process Exclusions': 'ProcessExclusionsStep',
    'Key Stakeholders': 'StakeholdersStep',
    'Involved Departments': 'DepartmentsStep',
  };

  const CurrentStepComponent = stepComponents[step];
  const currentStepKey = stepAiSuggestionKeys[steps[step]];

  return (
    <div className="space-y-6 p-6 bg-white rounded shadow-lg">
      <div className="flex items-center justify-between mb-6">
        <Button
          onClick={onPrevious}
          disabled={step === 0}
          className="p-2 bg-purple-100 text-purple-700 hover:bg-purple-200 rounded-full transition-colors duration-200"
        >
          <ChevronLeft className="w-6 h-6" />
        </Button>
        <div className="text-center">
          <div className="text-lg font-semibold text-purple-700 mb-2">
            Step {step + 1} of {steps.length}
          </div>
          <Progress
            value={((step + 1) / steps.length) * 100}
            className="w-64 h-2 bg-purple-200"
            indicatorClassName="bg-purple-600"
          />
        </div>
        <Button
          onClick={onNext}
          disabled={step === steps.length - 1}
          className="p-2 bg-purple-100 text-purple-700 hover:bg-purple-200 rounded-full transition-colors duration-200"
        >
          <ChevronRight className="w-6 h-6" />
        </Button>
      </div>

      <h2 className="text-2xl font-bold text-purple-700 mb-4">{steps[step]}</h2>

      <CurrentStepComponent
        data={initialData[currentStepKey] || []}
        onChange={(value) => onUpdate({ [currentStepKey]: value })}
        aiSuggestions={aiSuggestions?.[currentStepKey] || []}
        processType={selectedProcess}
        riskProfile={riskProfile}
        maturityLevel={maturityLevel}
        isLoading={isLoading}
      />
    </div>
  );
};

export default BaseProcessDesign;