import React, { useState } from 'react';
import { Users, Eye, AlertCircle, ClipboardList, Plus } from 'lucide-react';

const HumanOversight = () => {
  const [oversightMeasures, setOversightMeasures] = useState([
    { id: 1, name: 'Real-time monitoring of AI decision outputs', status: 'Active' },
    { id: 2, name: 'Periodic review of AI system performance', status: 'Scheduled' },
    { id: 3, name: 'User feedback collection and analysis', status: 'Active' },
  ]);

  const [interventions, setInterventions] = useState([
    { id: 1, date: '2023-08-05', description: 'Manual override of AI recommendation due to unusual patient data', reviewer: 'Dr. Smith' },
    { id: 2, date: '2023-08-03', description: 'Adjustment of AI model parameters after detecting bias', reviewer: 'AI Ethics Team' },
  ]);

  return (
    <div className="p-4 bg-white rounded shadow">
      <h2 className="text-xl font-semibold mb-4">Human Oversight</h2>
      <p className="mb-4 text-gray-600">
        Ensures meaningful human supervision over AI systems as mandated by the EU AI Act, 
        enabling intervention in decision-making processes and maintaining human agency in AI-driven outcomes.
      </p>
      <div className="grid grid-cols-2 gap-4">
        <div>
          <h3 className="text-lg font-medium mb-2">Oversight Measures</h3>
          <button className="mb-2 flex items-center text-blue-600 hover:text-blue-800">
            <Plus size={16} className="mr-1" /> Add New Measure
          </button>
          <ul className="space-y-2">
            {oversightMeasures.map(measure => (
              <li key={measure.id} className="flex items-center justify-between">
                <span className="flex items-center">
                  <Eye size={16} className="mr-2 text-gray-500" />
                  {measure.name}
                </span>
                <span className={`text-sm ${measure.status === 'Active' ? 'text-green-500' : 'text-yellow-500'}`}>
                  {measure.status}
                </span>
              </li>
            ))}
          </ul>
        </div>
        <div>
          <h3 className="text-lg font-medium mb-2">Intervention Logging</h3>
          <button className="mb-2 flex items-center text-blue-600 hover:text-blue-800">
            <AlertCircle size={16} className="mr-1" /> Log New Intervention
          </button>
          <ul className="space-y-2">
            {interventions.map(intervention => (
              <li key={intervention.id} className="text-sm">
                <span className="font-medium">{intervention.date}</span>: {intervention.description}
                <br />
                <span className="text-gray-500">Reviewer: {intervention.reviewer}</span>
              </li>
            ))}
          </ul>
        </div>
      </div>
    </div>
  );
};

export default HumanOversight;