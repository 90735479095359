import React, { useState, useEffect } from 'react';
import { Checkbox } from './ui/Checkbox';
import { Label } from './ui/Label';
import { Shield, BookOpen, FileText, ArrowRight, Star } from 'lucide-react';
import { Button } from './ui/Button';

const RegulatoryLandscapeAnalysis = ({ onComplete, companyProfile }) => {
  const [selectedItems, setSelectedItems] = useState([]);
  const [complianceItems, setComplianceItems] = useState([]);

  useEffect(() => {
    const fetchComplianceItems = () => {
      let relevantItems = [
        // Regulations
        { id: 'gdpr', name: 'GDPR', type: 'regulation', tier: 'critical', applies: ['all'] },
        { id: 'ccpa', name: 'CCPA', type: 'regulation', tier: 'high', applies: ['all'] },
        { id: 'hipaa', name: 'HIPAA', type: 'regulation', tier: 'critical', applies: ['healthcare', 'medtech'] },
        { id: 'fda21cfr', name: 'FDA 21 CFR Part 11', type: 'regulation', tier: 'critical', applies: ['healthcare', 'pharmaceutical', 'biotech', 'medtech'] },
        
        // Standards
        { id: 'iso37301', name: 'ISO 37301 (Compliance Management Systems)', type: 'standard', tier: 'critical', applies: ['all'] },
        { id: 'iso9001', name: 'ISO 9001 (Quality Management)', type: 'standard', tier: 'medium', applies: ['all'] },
        { id: 'iso13485', name: 'ISO 13485 (Medical Devices)', type: 'standard', tier: 'high', applies: ['medtech'] },
        
        // Guidelines and Frameworks
        { id: 'doj_compliance', name: 'DOJ Evaluation of Corporate Compliance Programs', type: 'guideline', tier: 'high', applies: ['all'] },
        { id: 'coso', name: 'COSO Framework', type: 'framework', tier: 'medium', applies: ['all'] },
        { id: 'nist_ai', name: 'NIST AI Risk Management Framework', type: 'framework', tier: 'high', applies: ['all'] },
      ];

      // Industry-specific items
      if (['healthcare', 'pharmaceutical', 'biotech', 'medtech'].includes(companyProfile.industry)) {
        relevantItems = [
          ...relevantItems,
          { id: 'gxp', name: 'GxP', type: 'guideline', tier: 'high', applies: ['pharmaceutical', 'biotech'] },
          { id: 'ich_gcp', name: 'ICH GCP', type: 'guideline', tier: 'high', applies: ['pharmaceutical', 'biotech'] },
        ];
      }

      // Region-specific items
      if (companyProfile.region === 'Europe' || ['UK', 'Germany', 'France'].includes(companyProfile.country)) {
        relevantItems = [
          ...relevantItems,
          { id: 'eu_mdr', name: 'EU Medical Device Regulation (MDR)', type: 'regulation', tier: 'high', applies: ['medtech'] },
          { id: 'eudralex', name: 'EudraLex', type: 'regulation', tier: 'high', applies: ['pharmaceutical'] },
        ];
      }

      if (companyProfile.region === 'North America' || companyProfile.country === 'US') {
        relevantItems = [
          ...relevantItems,
          { id: 'fda_qsr', name: 'FDA Quality System Regulation', type: 'regulation', tier: 'high', applies: ['medtech'] },
        ];
      }

      setComplianceItems(
        relevantItems.filter(
          (item) => item.applies.includes('all') || item.applies.includes(companyProfile.industry)
        )
      );
    };

    fetchComplianceItems();
  }, [companyProfile]);

  const handleCheckboxChange = (itemId) => {
    setSelectedItems((prev) =>
      prev.includes(itemId)
        ? prev.filter((id) => id !== itemId)
        : [...prev, itemId]
    );
  };

  const getTierColor = (tier) => {
    switch (tier) {
      case 'critical':
        return 'text-blue-600';
      case 'high':
        return 'text-blue-500';
      case 'medium':
        return 'text-blue-400';
      default:
        return 'text-gray-400';
    }
  };

  const getTypeIcon = (type) => {
    switch (type) {
      case 'regulation':
        return <Shield className="h-4 w-4 text-blue-600 mr-2" />;
      case 'standard':
        return <BookOpen className="h-4 w-4 text-green-600 mr-2" />;
      default:
        return <FileText className="h-4 w-4 text-purple-600 mr-2" />;
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    onComplete({ applicableItems: selectedItems }, complianceItems);
  };

  return (
    <div className="max-w-3xl mx-auto">
      <h2 className="text-2xl font-bold text-center text-purple-800 mb-4">
        Regulatory Landscape Analysis
      </h2>
      <p className="text-sm text-center text-gray-600 mb-8">
        Based on your company profile, the following regulations, standards, and guidelines may be relevant:
      </p>

      <div className="bg-white p-8 rounded-lg shadow-md">
        <form onSubmit={handleSubmit}>
          <div className="grid grid-cols-1 gap-4">
            {complianceItems.map((item) => (
              <div
                key={item.id}
                className="flex items-center justify-between p-3 bg-white rounded-lg transition-all duration-300 hover:bg-blue-50 hover:shadow-md"
              >
                <div className="flex items-center">
                  <Checkbox
                    id={item.id}
                    checked={selectedItems.includes(item.id)}
                    onCheckedChange={() => handleCheckboxChange(item.id)}
                    className="mr-3 text-gray-700" // Updated checkbox color
                  />
                  <div className="flex items-center">
                    {getTypeIcon(item.type)}
                    <Label htmlFor={item.id} className="text-sm font-medium text-gray-800">
                      {item.name}
                    </Label>
                  </div>
                </div>
                <div className="flex items-center">
                  {item.tier === 'critical' && (
                    <Star
                      className={`h-4 w-4 ${getTierColor(item.tier)} mr-1`}
                      fill="currentColor"
                    />
                  )}
                  {item.tier === 'high' && (
                    <Star className={`h-4 w-4 ${getTierColor(item.tier)} mr-1`} />
                  )}
                  {item.tier === 'medium' && (
                    <Star className={`h-4 w-4 ${getTierColor(item.tier)} mr-1`} />
                  )}
                  <span className={`text-xs font-semibold ${getTierColor(item.tier)}`}>
                    {item.tier.charAt(0).toUpperCase() + item.tier.slice(1)}
                  </span>
                </div>
              </div>
            ))}
          </div>
          <div className="flex justify-end mt-4">
            <Button type="submit" variant="primary" className="flex items-center">
              Next <ArrowRight className="ml-2" />
            </Button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default RegulatoryLandscapeAnalysis;
