import React from 'react';

export const Input = React.forwardRef(
  (
    {
      type = 'text',
      placeholder = '',
      className = '',
      value,
      onChange,
      onFocus,
      onBlur,
      disabled = false,
      ...props
    },
    ref
  ) => {
    const baseStyles = `
      w-full
      px-3
      py-2
      text-sm
      border
      rounded-md
      transition
      duration-300
      ease-in-out
      focus:outline-none
      focus:ring-2
      focus:ring-offset-2
      focus:ring-purple-600
      focus:ring-opacity-50
      disabled:opacity-50
      disabled:cursor-not-allowed
      `;

    const variantStyles = `
      bg-white
      border-gray-300
      text-gray-900
      focus:border-purple-600
      placeholder-gray-400
      hover:bg-gray-50
    `;

    return (
      <input
        ref={ref}
        type={type}
        value={value}
        onChange={onChange}
        onFocus={onFocus}
        onBlur={onBlur}
        placeholder={placeholder}
        className={`${baseStyles} ${variantStyles} ${className}`}
        disabled={disabled}
        {...props}
      />
    );
  }
);

export default Input;
