import React, { useState, useEffect } from 'react';
import { Activity, AlertTriangle, Clock, Database, Eye, HelpCircle, X, ChevronDown, ChevronUp, Info } from 'lucide-react';
import { LineChart, Line, BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';

// Mock function to simulate fetching observability data
const fetchObservabilityData = () => {
  return new Promise((resolve) => {
    setTimeout(() => {
      resolve({
        performanceMetrics: [
          { timestamp: '2024-08-01 09:00', accuracy: 0.95, latency: 150, dataDrift: 0.02 },
          { timestamp: '2024-08-01 10:00', accuracy: 0.94, latency: 155, dataDrift: 0.03 },
          { timestamp: '2024-08-01 11:00', accuracy: 0.96, latency: 148, dataDrift: 0.02 },
          { timestamp: '2024-08-01 12:00', accuracy: 0.93, latency: 160, dataDrift: 0.04 },
          { timestamp: '2024-08-01 13:00', accuracy: 0.95, latency: 152, dataDrift: 0.03 },
        ],
        alerts: [
          { id: 1, severity: 'high', message: 'Significant data drift detected in patient risk model', timestamp: '2024-08-01 11:45' },
          { id: 2, severity: 'medium', message: 'Latency spike in drug interaction prediction service', timestamp: '2024-08-01 10:30' },
          { id: 3, severity: 'low', message: 'Minor accuracy fluctuation in diagnosis suggestion model', timestamp: '2024-08-01 09:15' },
        ],
        systemLogs: [
          { id: 1, level: 'INFO', message: 'Patient risk model v2.3 deployed successfully', timestamp: '2024-08-01 08:00' },
          { id: 2, level: 'WARN', message: 'High CPU usage detected on ML inference cluster', timestamp: '2024-08-01 10:15' },
          { id: 3, level: 'ERROR', message: 'Failed to fetch latest training data from EHR system', timestamp: '2024-08-01 11:30' },
          { id: 4, level: 'INFO', message: 'Automated model retraining initiated for drug interaction prediction', timestamp: '2024-08-01 12:45' },
          { id: 5, level: 'DEBUG', message: 'Cache cleared for diagnosis suggestion model', timestamp: '2024-08-01 13:30' },
        ],
      });
    }, 1000);
  });
};

const Alert = ({ severity, children }) => {
  const bgColor = severity === 'high' ? 'bg-red-100' : severity === 'medium' ? 'bg-yellow-100' : 'bg-blue-100';
  const textColor = severity === 'high' ? 'text-red-800' : severity === 'medium' ? 'text-yellow-800' : 'text-blue-800';
  return (
    <div className={`p-4 ${bgColor} ${textColor} rounded-lg`}>
      {children}
    </div>
  );
};

const AIObservabilitySuite = () => {
  const [observabilityData, setObservabilityData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [showFrameworkInfo, setShowFrameworkInfo] = useState(false);
  const [showMetricsInfo, setShowMetricsInfo] = useState(false);
  const [expandedSections, setExpandedSections] = useState({
    performanceMetrics: true,
    alerts: true,
    systemLogs: true,
  });

  useEffect(() => {
    const fetchData = async () => {
      const data = await fetchObservabilityData();
      setObservabilityData(data);
      setLoading(false);
    };
    fetchData();
  }, []);

  if (loading) {
    return <div className="text-center text-gray-400">Loading AI observability data...</div>;
  }

  const toggleSection = (section) => {
    setExpandedSections(prev => ({ ...prev, [section]: !prev[section] }));
  };

  const metricsInfo = {
    accuracy: {
      description: "Measures how often the model's predictions are correct.",
      ideal: "As close to 1.0 as possible, typically >0.95 for healthcare applications.",
    },
    latency: {
      description: "The time taken for the model to make a prediction.",
      ideal: "Lower is better. For real-time healthcare applications, <200ms is often desirable.",
    },
    dataDrift: {
      description: "Measures how much the input data distribution has changed over time.",
      ideal: "Closer to 0 is better. Values consistently above 0.1 may indicate significant drift.",
    },
  };

  const renderMetricsInfoBox = () => (
    <div className="bg-gray-100 p-4 rounded-md mb-4">
      <h4 className="font-semibold mb-2">Metric Explanations:</h4>
      {Object.entries(metricsInfo).map(([metric, info]) => (
        <div key={metric} className="mb-2">
          <p><strong>{metric.charAt(0).toUpperCase() + metric.slice(1)}:</strong> {info.description}</p>
          <p className="text-sm text-gray-600">Ideal value: {info.ideal}</p>
        </div>
      ))}
    </div>
  );

  const frameworkInfo = (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
      <div className="bg-white p-6 rounded-lg max-w-3xl max-h-[80vh] overflow-y-auto">
        <div className="flex justify-between items-center mb-4">
          <h3 className="text-2xl font-bold text-gray-600">LOKATIAL AI Observability Framework</h3>
          <button onClick={() => setShowFrameworkInfo(false)}>
            <X size={24} />
          </button>
        </div>
        <p className="mb-4 text-gray-500 ">
          LOKATIAL's AI Observability Suite is a cutting-edge solution designed specifically for the healthcare and pharmaceutical industries. Our framework goes beyond traditional AI monitoring to provide a comprehensive, compliance-focused approach to AI governance and performance management.
        </p>
        <h4 className="text-xl font-semibold mb-2 text-gray-600">Key Features:</h4>
        <ul className="list-disc pl-5 mb-4 space-y-2">
          <li className='text-gray-500'><strong>Healthcare-Specific Metrics:</strong> Tailored monitoring for critical healthcare AI applications, including patient risk assessment, drug discovery models, and clinical decision support systems.</li>
          <li className='text-gray-500'><strong>Regulatory Compliance:</strong> Built-in tracking and reporting for HIPAA, GDPR, FDA, and other healthcare-specific AI regulations.</li>
          <li className='text-gray-500'><strong>Ethical AI Monitoring:</strong> Continuous assessment of AI fairness, bias, and transparency in healthcare contexts.</li>
          <li className='text-gray-500'><strong>Integration Hub:</strong> Seamless aggregation of data from popular enterprise AI tools, providing a unified view of your AI ecosystem.</li>
          <li className='text-gray-500'><strong>Audit Trail:</strong> Comprehensive logging and versioning of AI models, data, and decisions for healthcare audits and accountability.</li>
          <li className='text-gray-500'><strong>Real-time Alerts:</strong> Proactive notification system tailored to healthcare risk thresholds and patient safety considerations.</li>
        </ul>
        <h4 className="text-xl font-semibold mb-2 text-gray-600">What Sets Us Apart:</h4>
        <ol className="list-decimal pl-5 mb-4 space-y-2 text-gray-500">
          <li className='text-gray-500'><strong>Healthcare Focus:</strong> Unlike general-purpose observability tools, LOKATIAL is built from the ground up for healthcare and pharma AI applications, ensuring relevance and compliance in these highly regulated industries.</li>
          <li className='text-gray-500'><strong>Comprehensive Solution:</strong> We offer a full suite of observability tools specifically designed for healthcare AI, reducing the need for multiple disparate systems.</li>
          <li className='text-gray-500'><strong>Aggregation Capabilities:</strong> While robust as a standalone solution, our suite also integrates with existing tools like Arize AI, WhyLabs, and Fiddler AI, providing a centralized dashboard for all your AI observability needs.</li>
          <li className='text-gray-500'><strong>Compliance-Centric:</strong> We prioritize healthcare compliance, with features tailored to meet the stringent requirements of medical AI applications and drug development processes.</li>
          <li className='text-gray-500'><strong>Ethical AI Governance:</strong> Our suite includes advanced tools for monitoring and ensuring ethical AI practices, crucial for maintaining trust in healthcare AI systems.</li>
        </ol>
        <p className="mb-4 text-gray-500">
          By choosing LOKATIAL's AI Observability Suite, you're not just getting a monitoring tool – you're investing in a comprehensive AI governance solution that understands the unique challenges and requirements of AI in healthcare and pharmaceuticals. Whether you're developing AI for clinical decision support, drug discovery, or patient care optimization, our suite provides the visibility, control, and compliance assurance you need to innovate with confidence.
        </p>
      </div>
    </div>
  );

  return (
    <div className="bg-white p-6 rounded-lg shadow-md">
      <div className="flex items-center justify-between mb-6">
        <div className="flex items-center">
          <Eye className="text-blue-500 mr-3" size={32} />
          <h2 className="text-2xl font-bold text-gray-600">AI Observability Suite</h2>
        </div>
        <button 
          onClick={() => setShowFrameworkInfo(true)} 
          className="flex items-center text-blue-500 hover:text-blue-600"
        >
          <HelpCircle size={20} className="mr-1" />
          About Our Framework
        </button>
      </div>

      {showFrameworkInfo && frameworkInfo}

      <div className="space-y-6">
        <div className="border rounded-lg overflow-hidden">
          <div 
            className="flex items-center justify-between p-3 bg-gray-50 cursor-pointer"
            onClick={() => toggleSection('performanceMetrics')}
          >
            <h3 className="text-xl font-semibold text-gray-500">Performance Metrics</h3>
            <div className="flex items-center">
              <button
                className="mr-2 text-gray-500 hover:text-gray-700"
                onClick={(e) => {
                  e.stopPropagation();
                  setShowMetricsInfo(!showMetricsInfo);
                }}
              >
                <Info size={18} />
              </button>
              {expandedSections.performanceMetrics ? <ChevronUp size={20} /> : <ChevronDown size={20} />}
            </div>
          </div>
          {expandedSections.performanceMetrics && (
            <div className="p-4">
              {showMetricsInfo && renderMetricsInfoBox()}
              <ResponsiveContainer width="100%" height={300}>
                <LineChart data={observabilityData.performanceMetrics}>
                  <CartesianGrid strokeDasharray="3 3" />
                  <XAxis dataKey="timestamp" />
                  <YAxis yAxisId="left" />
                  <YAxis yAxisId="right" orientation="right" />
                  <Tooltip />
                  <Legend />
                  <Line yAxisId="left" type="monotone" dataKey="accuracy" stroke="#8884d8" name="Accuracy" />
                  <Line yAxisId="right" type="monotone" dataKey="latency" stroke="#82ca9d" name="Latency (ms)" />
                  <Line yAxisId="left" type="monotone" dataKey="dataDrift" stroke="#ffc658" name="Data Drift" />
                </LineChart>
              </ResponsiveContainer>
            </div>
          )}
        </div>

        <div className="border rounded-lg overflow-hidden">
          <div 
            className="flex items-center justify-between p-3 bg-gray-50 cursor-pointer"
            onClick={() => toggleSection('alerts')}
          >
            <h3 className="text-xl font-semibold text-gray-500">Alerts</h3>
            {expandedSections.alerts ? <ChevronUp size={20} /> : <ChevronDown size={20} />}
          </div>
          {expandedSections.alerts && (
            <div className="p-4 space-y-4">
              {observabilityData.alerts.map((alert) => (
                <Alert key={alert.id} severity={alert.severity}>
                  <div className="flex items-start">
                    <AlertTriangle className="h-5 w-5 mr-2 mt-0.5" />
                    <div>
                      <h4 className="font-semibold">{alert.severity.charAt(0).toUpperCase() + alert.severity.slice(1)} Severity Alert</h4>
                      <p>{alert.message}</p>
                      <div className="text-sm text-gray-500 mt-1">
                        <Clock className="inline-block mr-1" size={14} />
                        {alert.timestamp}
                      </div>
                    </div>
                  </div>
                </Alert>
              ))}
            </div>
          )}
        </div>

        <div className="border rounded-lg overflow-hidden">
          <div 
            className="flex items-center justify-between p-3 bg-gray-50 cursor-pointer"
            onClick={() => toggleSection('systemLogs')}
          >
            <h3 className="text-xl font-semibold text-gray-500 ">System Logs</h3>
            {expandedSections.systemLogs ? <ChevronUp size={20} /> : <ChevronDown size={20} />}
          </div>
          {expandedSections.systemLogs && (
            <div className="p-4">
              <table className="w-full">
                <thead>
                  <tr className="bg-gray-100">
                    <th className="text-left p-2 text-gray-500 ">Timestamp</th>
                    <th className="text-left p-2 text-gray-500 ">Level</th>
                    <th className="text-left p-2 text-gray-500 ">Message</th>
                  </tr>
                </thead>
                <tbody>
                  {observabilityData.systemLogs.map((log) => (
                    <tr key={log.id} className="border-b">
                      <td className="p-2 text-gray-500">{log.timestamp}</td>
                      <td className="p-2 text-gray-500">
                        <span className={`px-2 py-1 rounded ${
                          log.level === 'ERROR' ? 'bg-red-200 text-red-800' :
                          log.level === 'WARN' ? 'bg-yellow-200 text-yellow-800' :
                          'bg-green-200 text-green-800'
                        }`}>
                          {log.level}
                        </span>
                      </td>
                      <td className="p-2 text-gray-500">{log.message}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          )}
        </div>
      </div>

      <div className="mt-8">
        <h3 className="text-xl font-semibold mb-4 text-gray-500 ">External Integrations</h3>
        <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
          <div className="border p-4 rounded-lg">
            <h4 className="font-semibold mb-2 text-gray-500 ">Arize AI</h4>
            <p className="text-sm mb-2 text-gray-500 ">Deep model performance monitoring</p>
            <button className="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600 transition duration-300">Connect</button>
          </div>
          <div className="border p-4 rounded-lg">
            <h4 className="font-semibold mb-2 text-gray-500 ">WhyLabs</h4>
            <p className="text-sm mb-2 text-gray-500 ">AI observability and data monitoring</p>
            <button className="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600 transition duration-300">Connect</button>
          </div>
          <div className="border p-4 rounded-lg">
            <h4 className="font-semibold mb-2 text-gray-500 ">Fiddler AI</h4>
            <p className="text-sm mb-2 text-gray-500 ">Explainable AI monitoring platform</p>
            <button className="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600 transition duration-300">Connect</button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AIObservabilitySuite;