import React, { useState, useEffect, useCallback, useRef } from 'react';
import { X, ArrowRight, ArrowLeft, Save, Brain, Zap, Shield } from 'lucide-react';
import { useNavigate } from 'react-router-dom';
import CompanyProfileSetup from './CompanyProfileSetup';
import RegulatoryLandscapeAnalysis from './RegulatoryLandscapeAnalysis';
import ComplianceMaturityAssessment from './ComplianceMaturityAssessment';
import ProcessSelectionStep from './ProcessSelectionStep';
import ProcessDesignStep from './ProcessDesignStep';
import ProcessSummaryVisualization from './ui/ProcessSummaryVisualization';
import Notification from './Notification';
import useAISuggestions from './useAISuggestions';
import toast, { Toaster } from 'react-hot-toast';
import { Transition } from '@headlessui/react';
import { Button } from './ui/Button';
import { Progress } from './ui/Progress';

const steps = [
  { name: 'Landing', subSteps: [] },
  { name: 'Risk Profiling', subSteps: ['Company Profile', 'Regulatory Landscape', 'Maturity Assessment'] },
  { name: 'Process Selection' },
  { name: 'Process Design', subSteps: ['Design', 'Review & Save'] }
];

const ComplianceProcessWizard = ({ onSave, onDraftSaved }) => {
  const navigate = useNavigate();
  const [currentStep, setCurrentStep] = useState(0);
  const [currentSubStep, setCurrentSubStep] = useState(0);
  const [riskProfile, setRiskProfile] = useState('');
  const [maturityLevel, setMaturityLevel] = useState('');
  const [complianceItems, setComplianceItems] = useState([]);
  const [processName, setProcessName] = useState('');
  const [processType, setProcessType] = useState('');
  const [companyProfile, setCompanyProfile] = useState({});
  const [regulatoryLandscape, setRegulatoryLandscape] = useState({});
  const [processData, setProcessData] = useState({
    objectives: [],
    regulatoryRequirements: [],
    processScope: '',
    processExclusions: '',
    stakeholders: [],
    involvedDepartments: [],
    risks: [],
    controls: {},
    complianceActivities: [],
    performanceMetrics: [],
  });
  const { aiSuggestions, updateAISuggestions } = useAISuggestions();
  const [notification, setNotification] = useState(null);
  const [isTransitioning, setIsTransitioning] = useState(false);
  const isMounted = useRef(false);
  
  const shouldShowSaveDraftButton = () => {
    return currentStep > 0;
  };
  
  useEffect(() => {
    isMounted.current = true;
    return () => {
      isMounted.current = false;
    };
  }, []);

  const fetchAISuggestions = useCallback(async () => {
    if (!isMounted.current) return;
    try {
      await updateAISuggestions({
        processType,
        riskProfile,
        maturityLevel,
      });
    } catch (error) {
      console.error('Error fetching AI suggestions:', error);
      toast.error('Failed to fetch AI suggestions. Please try again.');
    }
  }, [processType, riskProfile, maturityLevel, updateAISuggestions]);

  useEffect(() => {
    if (currentStep === 2 || (currentStep === 3 && currentSubStep === 0)) {
      fetchAISuggestions();
    }
  }, [currentStep, currentSubStep, fetchAISuggestions]);

  const handleNext = () => {
    setIsTransitioning(true);
    setTimeout(() => {
      const currentMainStep = steps[currentStep];
      if (currentMainStep.subSteps && currentSubStep < currentMainStep.subSteps.length - 1) {
        setCurrentSubStep(currentSubStep + 1);
      } else if (currentStep < steps.length - 1) {
        setCurrentStep(currentStep + 1);
        setCurrentSubStep(0);
      } else {
        handleFinishAndSave();
      }
      setIsTransitioning(false);
    }, 500);
  };

  const handleBack = () => {
    setIsTransitioning(true);
    setTimeout(() => {
      if (currentSubStep > 0) {
        setCurrentSubStep(currentSubStep - 1);
      } else if (currentStep > 0) {
        setCurrentStep(currentStep - 1);
        const previousStep = steps[currentStep - 1];
        setCurrentSubStep(previousStep.subSteps ? previousStep.subSteps.length - 1 : 0);
      }
      setIsTransitioning(false);
    }, 500);
  };

  const handleFinishAndSave = useCallback(() => {
    const draftProcess = {
      id: Date.now(),
      name: processName,
      type: processType,
      status: 'draft',
      createdAt: new Date().toISOString(),
      ...processData,
      companyProfile,
      regulatoryLandscape,
      riskProfile,
      maturityLevel,
    };

    if (onSave) onSave(draftProcess);
    if (onDraftSaved) onDraftSaved(draftProcess);
    navigate('/cms-dashboard');
  }, [processName, processType, processData, companyProfile, regulatoryLandscape, riskProfile, maturityLevel, onSave, onDraftSaved, navigate]);

  const handleCompanyProfileUpdate = (profileData) => {
    setCompanyProfile(profileData);
    handleNext();
  };

  const handleRegulatoryLandscapeUpdate = (landscapeData, complianceItemsData) => {
    setRegulatoryLandscape(landscapeData);
    setComplianceItems(complianceItemsData);
    handleNext();
  };

  const handleAssessmentComplete = (profile, maturity) => {
    setRiskProfile(profile);
    setMaturityLevel(maturity);
    handleNext();
  };

  const handleProcessSelection = (selectedProcessType) => {
    setProcessType(selectedProcessType);
    handleNext();
  };

  const handleProcessDesignUpdate = (updatedData) => {
    setProcessData(updatedData);
  };

  const renderLandingPage = () => (

    <div className="space-y-12">
      <div className="text-center space-y-4">
      <h1 className="wizard-heading text-center">
          Accelerate Your Compliance Process Design with AI
        </h1>
        <p className="text-body-lg max-w-2xl mx-auto">
          Our AI-powered wizard helps you create, visualize, and optimize compliance processes tailored to your organization's needs in minutes.
        </p>
        <Button
          onClick={handleNext}
          variant="primary"
          size="lg"
          className="mt-6"
        >
          Get Started
        </Button>
      </div>

      <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
        <div className="text-center space-y-4">
          <Brain size={48} className="text-purple-500 mx-auto" />
          <h3 className="wizard-subheading font-bold">AI-Powered Insights</h3>
          <p className="text-body">
            Leverage AI to generate compliance processes and suggestions tailored to your organization.
          </p>
        </div>
        <div className="text-center space-y-4">
          <Zap size={48} className="text-blue-500 mx-auto" />
          <h3 className="wizard-subheading font-bold">Rapid Process Creation</h3>
          <p className="text-body">
            Design and implement compliance processes swiftly with our guided wizard.
          </p>
        </div>
        <div className="text-center space-y-4">
          <Shield size={48} className="text-green-500 mx-auto" />
          <h3 className="wizard-subheading font-bold">Regulatory Compliance</h3>
          <p className="text-body">
            Ensure adherence to industry regulations and standards effortlessly.
          </p>
        </div>
      </div>

      <div className="space-y-6">
        <h2 className="wizard-heading text-center">How It Works</h2>
        <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
          <div className="space-y-2">
            <div className="flex items-center justify-center bg-purple-100 rounded-full h-16 w-16 mx-auto">
              <span className="text-h3 text-purple-600">1</span>
            </div>
            <h3 className="wizard-subheading font-bold text-center space-y-4">Assess Your Needs</h3>
            <p className="text-body text-center">
              Begin by understanding your compliance maturity level and organizational requirements.
            </p>
          </div>
          <div className="space-y-2">
            <div className="flex items-center justify-center bg-blue-100 rounded-full h-16 w-16 mx-auto">
              <span className="text-h3 text-blue-600">2</span>
            </div>
            <h3 className="wizard-subheading font-bold text-center space-y-4">Customize Processes</h3>
            <p className="text-body text-center">
              Select and tailor compliance processes with AI-generated recommendations.
            </p>
          </div>
          <div className="space-y-2">
            <div className="flex items-center justify-center bg-green-100 rounded-full h-16 w-16 mx-auto">
              <span className="text-h3 text-green-600">3</span>
            </div>
            <h3 className="wizard-subheading font-bold text-center space-y-4d">Implement & Optimize</h3>
            <p className="text-body text-center">
              Save your processes and integrate them into your compliance program seamlessly.
            </p>
          </div>
        </div>
      </div>
    </div>
  );

  const renderStep = () => {
    switch (currentStep) {
      case 0:
        return renderLandingPage();
      case 1:
        switch (currentSubStep) {
          case 0:
            return <CompanyProfileSetup onComplete={handleCompanyProfileUpdate} />;
            case 1:
              return <RegulatoryLandscapeAnalysis 
                onComplete={(landscapeData, items) => handleRegulatoryLandscapeUpdate(landscapeData, items)} 
                companyProfile={companyProfile} 
              />;
          case 2:
          return <ComplianceMaturityAssessment 
            onComplete={handleAssessmentComplete}
            companyProfile={companyProfile}
            regulatoryLandscape={regulatoryLandscape}
            complianceItems={complianceItems}
          />;
          default:
            return null;
        }
      case 2: // Process Selection
        return (
          <ProcessSelectionStep
            onSelectProcess={handleProcessSelection}
            riskProfile={riskProfile}
            maturityLevel={maturityLevel}
            companyProfile={companyProfile}
            regulatoryLandscape={regulatoryLandscape}
          />
        );
      case 3: // Process Design
        return currentSubStep === 0 ? (
          <ProcessDesignStep
            selectedProcess={processType}
            riskProfile={riskProfile}
            maturityLevel={maturityLevel}
            aiSuggestions={aiSuggestions}
            onNext={handleProcessDesignUpdate}
            onBack={handleBack}
            initialData={processData}
            companyProfile={companyProfile}
            regulatoryLandscape={regulatoryLandscape}
          />
        ) : (
          <div className="space-y-4">
            <h2 className="text-h2">Review & Save</h2>
            <ProcessSummaryVisualization
              processName={processName}
              processType={processType}
              {...processData}
              onFinishAndSave={handleFinishAndSave}
              companyProfile={companyProfile}
              regulatoryLandscape={regulatoryLandscape}
            />
          </div>
        );
      default:
        return null;
    }
  };

  const calculateProgress = () => {
    const totalSteps = steps.reduce((acc, step) => acc + (step.subSteps?.length || 1), 0);
    const completedSteps = steps.slice(0, currentStep).reduce((acc, step) => acc + (step.subSteps?.length || 1), 0) + currentSubStep;
    return (completedSteps / totalSteps) * 100;
  };

  // Helper functions to control button visibility
  const isLastStep = () => {
    return currentStep === steps.length - 1 && (!steps[currentStep].subSteps || currentSubStep === steps[currentStep].subSteps.length - 1);
  };

  const shouldShowNextButton = () => {
    // Hide Next button during CompanyProfileSetup and RegulatoryLandscapeAnalysis steps
    if (currentStep === 1 && (currentSubStep === 0 || currentSubStep === 1)) return false;
    // Hide Next button if it's the last step
    if (isLastStep()) return false;
    return true;
  };

  const shouldShowFinishButton = () => {
    // Show Finish button if it's the last step
    if (isLastStep()) return true;
    return false;
  };

  return (
    <div className="min-h-screen bg-gray-100">
      <div className="container mx-auto py-8 px-4">
        <header className="bg-white shadow-sm mb-6">
          <div className="container mx-auto py-4 px-4 flex justify-between items-center">
            <h1 className="wizard-heading text-purple-800">
              <a href="/">Compliance Wizard</a>
            </h1>
            <button
              onClick={() => navigate('/cms-dashboard')}
              className="text-gray-500 hover:text-gray-700"
            >
              <X size={24} />
            </button>
          </div>
        </header>

        {currentStep > 0 && (
          <div className="mb-8">
            <Progress value={calculateProgress()} className="mb-4" />
            <div className="flex justify-between">
              {steps.map((step, index) => (
                <div key={index} className={`text-center ${index === currentStep ? 'text-purple-600 font-bold' : 'text-gray-400'}`}>
                  <div className="text-sm mb-1">{step.name}</div>
                  {step.subSteps && (
                    <div className="text-xs">
                      {step.subSteps[currentSubStep] || ''}
                    </div>
                  )}
                </div>
              ))}
            </div>
          </div>
        )}

        <div className="mb-6">
          <Transition
            show={!isTransitioning}
            enter="transition-opacity duration-500"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="transition-opacity duration-500"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
            unmount={false}
          >
            {renderStep()}
          </Transition>
        </div>

        <div className="flex justify-between mt-6">
          {currentStep > 0 || currentSubStep > 0 ? (
            <Button
              onClick={handleBack}
              variant="secondary"
              className="flex items-center"
              disabled={isTransitioning}
            >
              <ArrowLeft className="mr-2" /> Back
            </Button>
          ) : (
            <div></div>
          )}
          {shouldShowSaveDraftButton() && (
            <Button
              onClick={() => toast.success('Draft saved successfully!')}
              variant="outline"
              className="flex items-center"
            >
              <Save className="mr-2" /> Save Draft
            </Button>
          )}
          {shouldShowNextButton() && (
            <Button
              onClick={handleNext}
              variant="primary"
              className="flex items-center"
              disabled={isTransitioning}
            >
              Next <ArrowRight className="ml-2" />
            </Button>
          )}
          {shouldShowFinishButton() && (
            <Button
              onClick={handleFinishAndSave}
              variant="success"
              className="flex items-center"
            >
              Finish and Save
            </Button>
          )}
        </div>

        {isTransitioning && (
          <div className="fixed inset-0 bg-black bg-opacity-25 flex items-center justify-center">
            <div className="bg-white p-6 rounded-md shadow-xl">
              <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-purple-600 mx-auto mb-4"></div>
              <p className="text-body">Processing...</p>
            </div>
          </div>
        )}

        {notification && (
          <Notification
            title={notification.title}
            message={notification.message}
            type={notification.type}
            onClose={() => setNotification(null)}
          />
        )}
        <Toaster />
      </div>
    </div>
  );
};

export default ComplianceProcessWizard;