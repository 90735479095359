import React from 'react';
import { motion } from 'framer-motion';
import { Shield, Brain, Sparkles, ArrowRight, Zap, Target } from 'lucide-react';
import { Button } from './ui/Button';
import ShinyButton from './ui/ShinyButton';

const CallToAction = () => {
  const typeformUrl = "https://adi18k4liww.typeform.com/to/DYf77usz";

  return (
    <div className="bg-gradient-to-b from-white to-gray-50 py-24">
      <div className="max-w-7xl mx-auto px-6 lg:px-8 text-center">
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          whileInView={{ opacity: 1, y: 0 }}
          viewport={{ once: true }}
          className="max-w-3xl mx-auto"
        >
          {/* Trust Indicators */}
          <div className="flex flex-wrap justify-center gap-4 mb-8">
            <div className="inline-flex items-center px-3 py-1 rounded-full bg-purple-50 text-purple-600 text-sm">
              <Shield className="w-4 h-4 mr-2" />
              Risk-Based by Design
            </div>
            <div className="inline-flex items-center px-3 py-1 rounded-full bg-blue-50 text-blue-600 text-sm">
              <Zap className="w-4 h-4 mr-2" />
              5x Faster Implementation
            </div>
            <div className="inline-flex items-center px-3 py-1 rounded-full bg-green-50 text-green-600 text-sm">
              <Target className="w-4 h-4 mr-2" />
              Audit-Ready Always
            </div>
          </div>

          <h2 className="text-3xl font-bold text-gray-600 mb-6">
            Ready to Simplify Your
            <span className="text-transparent bg-clip-text bg-gradient-to-r from-purple-600 to-blue-600">
              {" "}Compliance Journey?
            </span>
          </h2>
          
          <p className="text-xl text-gray-600 mb-8">
            Join forward-thinking healthcare and pharmaceutical companies using LOKATIAL 
            to transform compliance into a strategic advantage.
          </p>

          <a 
            href={typeformUrl} 
            target="_blank" 
            rel="noopener noreferrer"
            className="inline-block"
          >
            <ShinyButton className="flex items-center gap-2">
              Get Early Access 
            </ShinyButton>
          </a>
        </motion.div>
      </div>
    </div>
  );
};

export default CallToAction;