  // src/components/ui/Form.js
  import React from 'react'
  import { cn } from "../../utils/cn";
export const Form = React.forwardRef(({ ...props }, ref) => (
  <form ref={ref} {...props} />
))

export const FormItem = React.forwardRef(({ ...props }, ref) => (
  <div ref={ref} {...props} />
))

export const FormLabel = React.forwardRef(({ className, ...props }, ref) => (
  <label 
    ref={ref} 
    className={cn(
      "text-sm font-medium text-gray-600", // Default styles including the color
      className // Allow overriding if needed
    )}
    {...props} 
  />
))

export const FormControl = React.forwardRef(({ ...props }, ref) => (
  <div ref={ref} {...props} />
))

export const FormDescription = React.forwardRef(({ className, ...props }, ref) => (
  <p 
    ref={ref} 
    className={cn(
      "text-sm text-gray-500",
      className
    )}
    {...props} 
  />
))

export const FormMessage = React.forwardRef(({ className, ...props }, ref) => (
  <p 
    ref={ref}
    className={cn(
      "text-sm font-medium text-red-500",
      className
    )}
    {...props}
  />
))

export const FormField = ({ name, ...props }) => (
  <div {...props} />
)

Form.displayName = "Form"
FormItem.displayName = "FormItem"
FormLabel.displayName = "FormLabel"
FormControl.displayName = "FormControl"
FormDescription.displayName = "FormDescription"
FormMessage.displayName = "FormMessage"
FormField.displayName = "FormField"