import React from 'react';
import { BarChart2, Upload } from 'lucide-react';

const AIModelList = ({ models }) => {
  return (
    <div className="bg-white p-4 rounded shadow">
      <div className="flex justify-between items-center mb-4">
        <h2 className="text-lg font-semibold text-gray-600">AI Models</h2>
        <button className="bg-blue-500 text-white px-3 py-1 rounded flex items-center text-sm">
          <Upload size={16} className="mr-1" />
          Add New Model
        </button>
      </div>
      <ul className="space-y-2">
        {models.map((model) => (
          <li key={model.id} className="flex justify-between items-center p-2 hover:bg-gray-100 rounded">
            <div>
              <span className="font-medium text-gray-500">{model.name}</span>
              <p className="text-sm text-gray-500">Last updated: {model.lastUpdated}</p>
            </div>
            <div className="flex items-center">
              <span className={`px-2 py-1 rounded text-xs ${
                model.status === 'Production' ? 'bg-green-200 text-green-800' :
                model.status === 'Testing' ? 'bg-yellow-200 text-yellow-800' :
                'bg-blue-200 text-blue-800'
              }`}>
                {model.status}
              </span>
              <button className="ml-2 text-blue-500 hover:text-blue-600">
                <BarChart2 size={16} />
              </button>
            </div>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default AIModelList;