import React, { useState, useEffect, useCallback } from 'react';
import { Input } from '../ui/Input';
import { Button } from '../ui/Button';
import { PlusCircle, Trash2, Bot } from 'lucide-react';
import AIThinkingAnimation from '../AIThinkingAnimation';

const ObjectivesStep = ({ data, onChange, aiSuggestions, isLoading }) => {
  const [newObjective, setNewObjective] = useState('');
  const [userObjectives, setUserObjectives] = useState(data || []);
  const [suggestedObjectives, setSuggestedObjectives] = useState([]);

  useEffect(() => {
    if (Array.isArray(aiSuggestions)) {
      // Filter out suggestions that are already in userObjectives
      const newSuggestions = aiSuggestions.filter(
        suggestion => !userObjectives.includes(suggestion)
      );
      setSuggestedObjectives(newSuggestions);
    }
  }, [aiSuggestions, userObjectives]);

  const handleAddObjective = useCallback(() => {
    if (newObjective.trim()) {
      const updatedObjectives = [...userObjectives, newObjective.trim()];
      setUserObjectives(updatedObjectives);
      onChange(updatedObjectives);
      setNewObjective('');
    }
  }, [newObjective, userObjectives, onChange]);

  const handleRemoveObjective = useCallback((index) => {
    const updatedObjectives = userObjectives.filter((_, i) => i !== index);
    setUserObjectives(updatedObjectives);
    onChange(updatedObjectives);
  }, [userObjectives, onChange]);

  const handleAcceptSuggestion = useCallback((index) => {
    const acceptedObjective = suggestedObjectives[index];
    const updatedUserObjectives = [...userObjectives, acceptedObjective];
    setUserObjectives(updatedUserObjectives);
    onChange(updatedUserObjectives);

    const updatedSuggestions = suggestedObjectives.filter((_, i) => i !== index);
    setSuggestedObjectives(updatedSuggestions);
  }, [suggestedObjectives, userObjectives, onChange]);

  return (
    <div className="space-y-6">
      {/* Suggested Objectives */}
      {suggestedObjectives.length > 0 && (
        <div>
          <h3 className="text-lg font-medium flex items-center text-blue-600">
            <Bot className="w-5 h-5 mr-2 text-blue-500" />
            AI-Suggested Objectives
          </h3>
          <div className="space-y-2 mt-2">
            {isLoading ? (
              <div className="my-8 p-4 bg-blue-50 rounded-lg shadow-inner">
                <AIThinkingAnimation />
                <p className="text-center text-blue-700 mt-4 font-medium">
                  AI is generating objectives...
                </p>
              </div>
            ) : (
              suggestedObjectives.map((objective, index) => (
                <div key={index} className="flex items-center bg-blue-50 p-2 rounded">
                  <span className="flex-grow text-blue-700">{objective}</span>
                  <Button
                    variant="outline"
                    size="sm"
                    className="ml-2"
                    onClick={() => handleAcceptSuggestion(index)}
                  >
                    Accept
                  </Button>
                </div>
              ))
            )}
          </div>
        </div>
      )}

      {/* User Objectives */}
      <div>
        <h3 className="text-lg font-medium text-gray-800">Your Objectives</h3>
        {userObjectives.map((objective, index) => (
          <div key={index} className="flex items-center mt-2">
            <Input
              value={objective}
              onChange={(e) => {
                const updatedObjectives = [...userObjectives];
                updatedObjectives[index] = e.target.value;
                setUserObjectives(updatedObjectives);
                onChange(updatedObjectives);
              }}
              className="flex-grow text-gray-800"
            />
            <Button
              variant="ghost"
              size="icon"
              className="text-red-500 hover:text-red-700 hover:bg-red-100"
              onClick={() => handleRemoveObjective(index)}
            >
              <Trash2 className="w-4 h-4" />
            </Button>
          </div>
        ))}

        {/* Add New Objective */}
        <div className="flex items-center mt-4">
          <Input
            value={newObjective}
            onChange={(e) => setNewObjective(e.target.value)}
            placeholder="Enter a new objective"
            className="flex-grow text-gray-800"
          />
          <Button 
            variant="outline" 
            onClick={handleAddObjective} 
            className="ml-2 bg-purple-100 text-purple-700 hover:bg-purple-200"
          >
            <PlusCircle className="w-4 h-4 mr-1" />
            Add
          </Button>
        </div>
      </div>
    </div>
  );
};

export default ObjectivesStep;