import React, { useState } from 'react';
import { Tab, TabGroup, TabList, TabPanel, TabPanels } from '@headlessui/react';
import { ExternalLink } from 'lucide-react';

function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

const PlanningContent = () => {
  const [quizStarted, setQuizStarted] = useState(false);
  const [currentQuestion, setCurrentQuestion] = useState(0);
  const [score, setScore] = useState(0);
  const [showResults, setShowResults] = useState(false);

  const content = {
    overview: {
      introduction: "Planning is a critical phase in compliance management that involves identifying risks, setting objectives, and developing strategies to achieve compliance goals.",
      keyPoints: [
        "Conducting comprehensive risk assessments",
        "Identifying and documenting compliance obligations",
        "Setting measurable compliance objectives",
        "Developing action plans to address risks and opportunities",
        "Allocating resources for compliance activities"
      ],
      importance: "Effective planning ensures that compliance efforts are proactive, targeted, and aligned with organizational goals. It helps prioritize compliance activities and resource allocation, reducing risks and aligning with strategic objectives.",
      bestPractices: [
        "Use data-driven methods for risk assessment",
        "Involve stakeholders from various departments in the planning process",
        "Align compliance objectives with overall business strategy",
        "Regularly review and update compliance plans",
        "Develop key performance indicators (KPIs) for compliance objectives",
        "Create contingency plans for potential compliance breaches"
      ]
    },
    quiz: [
      {
        question: "What is the primary purpose of compliance risk assessment?",
        options: [
          "To satisfy regulators",
          "To identify and evaluate potential compliance risks",
          "To punish non-compliant employees",
          "To increase company profits"
        ],
        correctAnswer: 1
      },
      {
        question: "How often should compliance obligations be reviewed and updated?",
        options: ["Only when regulations change", "Annually", "Every 5 years", "Continuously"],
        correctAnswer: 3
      },
      {
        question: "What is a key characteristic of well-defined compliance objectives?",
        options: ["They are vague and general", "They are measurable and specific", "They focus only on financial metrics", "They are set by external regulators"],
        correctAnswer: 1
      },
      {
        question: "Who should be involved in the compliance planning process?",
        options: ["Only the compliance department", "Only top management", "Stakeholders from various departments", "External consultants only"],
        correctAnswer: 2
      },
      {
        question: "What is the purpose of having KPIs for compliance objectives?",
        options: ["To impress stakeholders", "To measure and track progress", "To comply with regulations", "To increase workload"],
        correctAnswer: 1
      }
    ],
    resources: [
      { title: "ISO 31000:2018 Risk Management Guidelines", url: "https://www.iso.org/standard/65694.html" },
      { title: "COSO Enterprise Risk Management Framework", url: "https://www.coso.org/pages/erm-framework.aspx" },
      { title: "Compliance Risk Assessments: The Third Line of Defense", url: "https://www2.deloitte.com/us/en/pages/risk/articles/compliance-risk-assessments-the-third-line-of-defense.html" },
      { title: "Strategic Planning in Compliance", url: "https://www.corporatecomplianceinsights.com/strategic-planning-compliance/" },
      { title: "OCC Compliance Management Systems Handbook", url: "https://www.occ.treas.gov/publications-and-resources/publications/comptrollers-handbook/files/compliance-mgmt-systems/index-compliance-management-systems.html" }
    ]
  };

  // Logic for handling quiz submission and scoring
  const handleAnswerClick = (index) => {
    if (!quizStarted) return;
    if (index === content.quiz[currentQuestion].correctAnswer) {
      setScore(score + 1);
    }
    const nextQuestion = currentQuestion + 1;
    if (nextQuestion < content.quiz.length) {
      setCurrentQuestion(nextQuestion);
    } else {
      setShowResults(true);
    }
  };

  const startQuiz = () => {
    setQuizStarted(true);
    setCurrentQuestion(0);
    setScore(0);
    setShowResults(false);
  };

  return (
    <TabGroup>
      <TabList className="flex space-x-1 rounded-xl bg-blue-900/20 p-1">
        {['Overview', 'Key Points', 'Quiz', 'Resources'].map((tab) => (
          <Tab
            key={tab}
            className={({ selected }) =>
              classNames(
                'w-full rounded-lg py-2.5 text-sm font-medium leading-5 text-blue-700',
                'ring-white ring-opacity-60 ring-offset-2 ring-offset-blue-400 focus:outline-none focus:ring-2',
                selected
                  ? 'bg-white shadow'
                  : 'text-blue-100 hover:bg-white/[0.12] hover:text-white'
              )
            }
          >
            {tab}
          </Tab>
        ))}
      </TabList>
      <TabPanels className="mt-2">
        {/* Overview */}
        <TabPanel className="rounded-xl bg-white p-3">
          <p className="text-gray-700">{content.overview.introduction}</p>
          <h3 className="font-semibold mt-4 mb-2">Importance:</h3>
          <p className="text-gray-700">{content.overview.importance}</p>
        </TabPanel>
        {/* Key Points */}
        <TabPanel className="rounded-xl bg-white p-3">
          <h3 className="font-semibold mb-2">Key Points:</h3>
          <ul className="list-disc pl-5 space-y-2">
            {content.overview.keyPoints.map((point, index) => (
              <li key={index} className="text-gray-700">{point}</li>
            ))}
          </ul>
          <h3 className="font-semibold mt-4 mb-2">Best Practices:</h3>
          <ul className="list-disc pl-5 space-y-2">
            {content.overview.bestPractices.map((practice, index) => (
              <li key={index} className="text-gray-700">{practice}</li>
            ))}
          </ul>
        </TabPanel>
        {/* Quiz */}
        <TabPanel className="rounded-xl bg-white p-3">
          {!quizStarted ? (
            <button onClick={startQuiz} className="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600 transition duration-300">
              Start Quiz
            </button>
          ) : showResults ? (
            <div>
              <h2 className="text-xl font-semibold mb-4">Your Score: {score}/{content.quiz.length}</h2>
              <button onClick={startQuiz} className="bg-green-500 text-white px-4 py-2 rounded hover:bg-green-600 transition duration-300 mt-2">
                Retry Quiz
              </button>
            </div>
          ) : (
            <div>
              <h3 className="text-lg font-semibold mb-4">{content.quiz[currentQuestion].question}</h3>
              <ul className="space-y-2">
                {content.quiz[currentQuestion].options.map((option, index) => (
                  <li 
                    key={index} 
                    onClick={() => handleAnswerClick(index)} 
                    className="cursor-pointer p-2 rounded bg-gray-100 hover:bg-gray-200 transition duration-300"
                  >
                    {option}
                  </li>
                ))}
              </ul>
            </div>
          )}
        </TabPanel>
        {/* Resources */}
        <TabPanel className="rounded-xl bg-white p-3">
          <ul className="space-y-2">
            {content.resources.map((resource, index) => (
              <li key={index} className="flex items-center">
                <ExternalLink size={18} className="mr-2 text-blue-500" /> 
                <a href={resource.url} target="_blank" rel="noreferrer" className="text-blue-500 hover:underline">
                  {resource.title}
                </a>
              </li>
            ))}
          </ul>
        </TabPanel>
      </TabPanels>
    </TabGroup>
  );
};

export default PlanningContent;
