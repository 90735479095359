// src/api/auth.js
import config from './config';

// Simplified createApiUrl function
const createApiUrl = (endpoint) => {
  // Since config.apiUrl is now an empty string due to the proxy, we can return the endpoint directly
  const url = `${config.apiUrl}${endpoint}`;
  console.log(`Constructed URL: ${url}`); // This will log the endpoint path
  return url;
};

const apiRequest = async (endpoint, options = {}) => {
  try {
    const url = createApiUrl(endpoint);
    console.log(`[API] Requesting: ${url}`);

    const response = await fetch(url, {
      ...options,
      credentials: 'include', // Ensure cookies are sent with the request
      headers: {
        'Content-Type': 'application/json',
        ...options.headers,
      },
    });

    if (!response.ok) {
      let errorData;
      try {
        errorData = await response.json();
      } catch {
        errorData = { message: response.statusText };
      }

      if (response.status === 401) {
        throw new AuthError(errorData?.message || 'Authentication failed');
      } else if (response.status === 403) {
        throw new AuthError(errorData?.message || 'Access denied');
      } else if (response.status === 404) {
        throw new NetworkError('Service not found');
      } else if (response.status >= 500) {
        throw new NetworkError('Server error');
      } else {
        throw new Error(errorData?.message || 'Request failed');
      }
    }

    return response.json();
  } catch (error) {
    console.error(`API Error (${endpoint}):`, error);

    if (error instanceof TypeError && error.message.includes('fetch')) {
      throw new NetworkError('Unable to connect to server');
    }

    throw error;
  }
};

export const authApi = {
  login: async (credentials) => {
    return apiRequest('/auth/login', {
      method: 'POST',
      body: JSON.stringify(credentials),
    });
  },

  verify: async () => {
    return apiRequest('/auth/verify', {
      method: 'GET',
    });
  },

  logout: async () => {
    return apiRequest('/auth/logout', {
      method: 'POST',
    });
  },
};

// Error classes (unchanged)
export class AuthError extends Error {
  constructor(message) {
    super(message || 'Authentication failed');
    this.name = 'AuthError';
  }
}

export class NetworkError extends Error {
  constructor(message) {
    super(message || 'Network request failed');
    this.name = 'NetworkError';
  }
}

export class ValidationError extends Error {
  constructor(message) {
    super(message || 'Invalid input');
    this.name = 'ValidationError';
  }
}

export default authApi;
