import React, { useState, useEffect } from 'react';
import { ArrowLeft, ArrowRight, Brain, X } from 'lucide-react';
import { Button } from './ui/Button';
import { Progress } from './ui/Progress';
import AuditSteps from './AuditSteps';
import { useAuditContext } from '../contexts/AuditContext';
import LokCO from './LokCO';
import ErrorBoundary from './ErrorBoundary';

const MockAuditWizard = ({ 
  onClose, 
  isInline = false, // New prop to determine rendering style
  className = "" // Allow custom className to be passed
}) => {
  const { currentStep, setCurrentStep, auditData, updateAuditData } = useAuditContext();
  const [showLokCO, setShowLokCO] = useState(false);
  const [initiateRiskAnalysis, setInitiateRiskAnalysis] = useState(false);
  const [stepValidity, setStepValidity] = useState(false);
  const [evidenceCollectionActiveTab, setEvidenceCollectionActiveTab] = useState('upload');

  useEffect(() => {
    setStepValidity(false);
    if (currentStep === 3) { // Reset the Evidence Collection active tab when entering the step
      setEvidenceCollectionActiveTab('upload');
    }
  }, [currentStep]);

  useEffect(() => {
    console.log('Step validity changed:', stepValidity);
  }, [stepValidity]);

  const handleNext = () => {
    if (currentStep < AuditSteps.length - 1) {
      setCurrentStep(currentStep + 1);
    } else {
      console.log('Audit completed', auditData);
      onClose();
    }
  };

  const handleBack = () => {
    if (currentStep > 0) {
      setCurrentStep(currentStep - 1);
    }
  };

  const toggleLokCO = () => {
    setShowLokCO(!showLokCO);
    if (!showLokCO) {
      setInitiateRiskAnalysis(true);
    }
  };

  const CurrentStepComponent = AuditSteps[currentStep].component;

  const handleStepValidityChange = (isValid) => {
    console.log('Setting step validity to:', isValid);
    setStepValidity(isValid);
  };

   // Base container classes
   const containerClasses = `
   bg-white rounded-lg
   ${isInline ? 'border' : 'shadow-lg'}
   p-6 relative
   ${isInline ? 'animate-slide-down' : ''}
   ${className}
 `.trim();

  return (
    <div className={containerClasses}>
      <div className="flex justify-between items-center mb-4">
        <h2 className="text-2xl font-bold text-gray-600">Compliance Audit Wizard</h2>
        {isInline && (
          <Button
            variant="outline"
            size="sm"
            onClick={onClose}
            className="hover:bg-gray-100"
          >
            <X className="w-4 h-4" />
          </Button>
        )}
      </div>

      <Progress 
        value={(currentStep / (AuditSteps.length - 1)) * 100} 
        className="mb-4"
      />
      
      <ErrorBoundary>
        <div className={`${isInline ? 'max-h-[60vh] overflow-y-auto pr-2' : ''}`}>
        <CurrentStepComponent 
          data={auditData} 
          updateData={updateAuditData}
          setStepValidity={handleStepValidityChange}
          currentStep={currentStep}
          totalSteps={AuditSteps.length}
          activeTab={evidenceCollectionActiveTab}
          setActiveTab={setEvidenceCollectionActiveTab}
        />
        </div>
      </ErrorBoundary>

      
      <div className="flex justify-between items-center mt-6 pt-4 border-t">
        <Button
          onClick={handleBack}
          disabled={currentStep === 0}
          variant="secondary"
        >
          <ArrowLeft className="mr-2" /> Back
        </Button>
        
        <div className="flex items-center gap-4">
          <Button 
            onClick={toggleLokCO} 
            variant="secondary"
            className="rounded-full p-2 bg-purple-100 hover:bg-purple-200"
          >
            <Brain className="w-6 h-6 text-purple-600" />
          </Button>
          <Button 
            onClick={handleNext} 
            disabled={!stepValidity}
            variant="primary"
          >
            {currentStep === AuditSteps.length - 1 ? 'Finish' : 'Next'} 
            <ArrowRight className="ml-2" />
          </Button>
        </div>
      </div>

      {showLokCO && (
        <LokCO
          message={`Assisting with ${AuditSteps[currentStep].name}`}
          onClose={() => setShowLokCO(false)}
          initiateRiskAnalysis={initiateRiskAnalysis}
        />
      )}
    </div>
  );
};

export default MockAuditWizard;