import React, { useState, useEffect, useCallback, useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { Button } from '../components/ui/Button';
import { Card, CardHeader, CardContent } from '../components/ui/Card';
import { Tabs, TabsList, TabsTrigger, TabsContent } from '../components/ui/Tabs';
import { Progress } from '../components/ui/Progress';
import useAISuggestions from '../components/useAISuggestions';
import { CheckCircle, Upload, Bot, File, PlayCircle } from 'lucide-react';
import { motion } from 'framer-motion';
import {
  AlertDialog,
  AlertDialogContent,
  AlertDialogHeader,
  AlertDialogTitle,
  AlertDialogDescription,
  AlertDialogAction,
  AlertDialogCloseButton,
} from '../components/ui/AlertDialog';
import AnalysisTab from '../components/AnalysisTab';
import { useDropzone } from 'react-dropzone';

const UploadCard = ({ category, requests, uploadedFiles, analysisProgress, onUpload }) => {
  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop: (acceptedFiles) => onUpload(acceptedFiles, category)
  });

  return (
    <Card className="mb-4">
      <CardHeader>
        <h5 className="font-semibold flex items-center">
          <File className="mr-2" />
          {category} ({uploadedFiles[category]?.uploaded?.length || 0}/{requests.length} required)
        </h5>
      </CardHeader>
      <CardContent>
        <div 
          {...getRootProps()} 
          className={`border-2 border-dashed rounded-md p-4 text-center cursor-pointer ${
            isDragActive ? 'border-blue-500 bg-blue-50' : 'border-gray-300'
          }`}
        >
          <input {...getInputProps()} />
          {isDragActive ? (
            <p>Drop the files here ...</p>
          ) : (
            <p>Drag 'n' drop some files here, or click to select files</p>
          )}
          <Upload className="mx-auto mt-2" size={24} />
        </div>
        {requests.map((request, requestIndex) => (
          <div key={requestIndex} className="flex items-center mt-2">
            <span>{request.description || request}</span>
            {uploadedFiles[category]?.uploaded?.[requestIndex] && (
              <CheckCircle className="ml-2 text-green-500" size={16} />
            )}
          </div>
        ))}
        {analysisProgress[category] > 0 && analysisProgress[category] < 100 && (
          <Progress value={analysisProgress[category]} className="mt-2" />
        )}
      </CardContent>
    </Card>
  );
};

const EvidenceCollection = ({ data, updateData, setStepValidity, activeTab, setActiveTab }) => {
  const { handleSubmit, formState: { isValid } } = useForm({
    defaultValues: data
  });
  const [uploadedFiles, setUploadedFiles] = useState({});
  const [analysisProgress, setAnalysisProgress] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const { aiSuggestions, updateAISuggestions, analyzeDocument } = useAISuggestions();
  const [isAlertOpen, setIsAlertOpen] = useState(false);
  const [alertMessage, setAlertMessage] = useState('');
  const [isAnalysisComplete, setIsAnalysisComplete] = useState(false);

  const handleFilesUpload = useCallback((acceptedFiles, category) => {
    setUploadedFiles(prevFiles => ({
      ...prevFiles,
      [category]: {
        ...prevFiles[category],
        uploaded: [...(prevFiles[category]?.uploaded || []), ...acceptedFiles]
      }
    }));
    
    // Simulate file analysis
    setAnalysisProgress(prev => ({ ...prev, [category]: 0 }));
    const interval = setInterval(() => {
      setAnalysisProgress(prev => {
        const newProgress = (prev[category] || 0) + 10;
        if (newProgress >= 100) {
          clearInterval(interval);
          acceptedFiles.forEach(file => analyzeDocument(file));
        }
        return { ...prev, [category]: newProgress };
      });
    }, 200);
  }, [analyzeDocument]);

  const triggerAnalysis = useCallback(() => {
    setIsAnalysisComplete(true);
    setActiveTab('analysis');
  }, [setActiveTab]);

  useEffect(() => {
    if (data) {
      setIsLoading(true);
      updateAISuggestions(data).finally(() => setIsLoading(false));
    }
  }, [data, updateAISuggestions]);

  useEffect(() => { 
    const allFilesUploaded = Object.values(uploadedFiles).every(category => 
      (category.required || 0) <= (category.uploaded?.length || 0)
    );
    setStepValidity(isValid && allFilesUploaded);
  }, [isValid, uploadedFiles, setStepValidity]);

  const onSubmit = (formData) => {
    updateData({ ...formData, uploadedFiles });
  };

  const handleRequestDocument = (request) => {
    const stakeholders = data.stakeholders?.map(s => s.label).join(', ') || 'relevant stakeholders';
    const message = `Request for "${request.description}" has been sent to ${stakeholders} as selected in the audit scope. You'll be notified once the document(s) are uploaded. The system will automatically analyze the documents upon receipt.`;
    
    setAlertMessage(message);
    setIsAlertOpen(true);

    setTimeout(() => {
      console.log(`Document requested: ${request.description}`);
    }, 1000);
  };

  const renderUploadSection = useMemo(() => {
    let evidenceRequestsArray = [];
    
    if (Array.isArray(aiSuggestions?.evidenceRequests)) {
      evidenceRequestsArray = aiSuggestions.evidenceRequests;
    } else if (typeof aiSuggestions?.evidenceRequests === 'object') {
      evidenceRequestsArray = Object.entries(aiSuggestions.evidenceRequests).map(([category, requests]) => ({
        category,
        requests: Array.isArray(requests) ? requests : [requests]
      }));
    }
  
    return (
      <div className="space-y-6">
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
          {evidenceRequestsArray.map((item, index) => (
            <UploadCard
              key={item.category || index}
              category={item.category || `Document ${index + 1}`}
              requests={item.requests || [item]}
              uploadedFiles={uploadedFiles}
              analysisProgress={analysisProgress}
              onUpload={handleFilesUpload}
            />
          ))}
        </div>
        <Button 
          onClick={triggerAnalysis} 
          className="w-full"
          disabled={!Object.keys(uploadedFiles).length}
        >
          <PlayCircle className="mr-2" />
          Generate Analysis
        </Button>
      </div>
    );
  }, [aiSuggestions, uploadedFiles, analysisProgress, handleFilesUpload, triggerAnalysis]);

  return (
    <form onSubmit={handleSubmit(onSubmit)} className="space-y-6">
      <h3 className="text-2xl font-semibold mb-4 flex items-center">
        Evidence Collection and Analysis
        <motion.span
          animate={{ rotate: [0, 360] }}
          transition={{ duration: 2, repeat: Infinity }}
          className="ml-2"
        >
          <Bot className="text-purple-500" />
        </motion.span>
      </h3>

      <Tabs value={activeTab} onValueChange={setActiveTab}>
        <TabsList>
          <TabsTrigger value="upload">Upload Evidence</TabsTrigger>
          <TabsTrigger value="requests">Evidence Requests</TabsTrigger>
          <TabsTrigger value="analysis">AI Analysis</TabsTrigger>
        </TabsList>

        <TabsContent value="upload">
          {isLoading ? (
            <div className="flex justify-center items-center h-64">
              <motion.div
                animate={{ rotate: 360 }}
                transition={{ duration: 1, repeat: Infinity, ease: "linear" }}
              >
                <Bot className="text-purple-500 w-12 h-12" />
              </motion.div>
            </div>
          ) : (
            renderUploadSection
          )}
        </TabsContent>

        <TabsContent value="requests">
          <Card>
            <CardHeader>
              <h4 className="text-xl font-semibold flex items-center">
                Evidence Requests
                <Bot className="ml-2 text-purple-500" />
              </h4>
            </CardHeader>
            <CardContent>
              {aiSuggestions?.evidenceRequests?.map((request, index) => (
                <div key={index} className="mb-2">
                  <p>{request.description}</p>
                  <Button size="sm" onClick={() => handleRequestDocument(request)}>
                    Request Document
                  </Button>
                </div>
              ))}
            </CardContent>
          </Card>
        </TabsContent>

        <TabsContent value="analysis">
          <AnalysisTab 
            uploadedFiles={uploadedFiles}
            aiSuggestions={aiSuggestions || {}}
            auditStartDate={data.auditStartDate}
            auditObjective={data.auditObjective}
            isAnalysisComplete={isAnalysisComplete}
          />
        </TabsContent>
      </Tabs>

      <Button type="submit">Save and Continue</Button>

      <AlertDialog open={isAlertOpen} onOpenChange={setIsAlertOpen}>
        <AlertDialogContent>
          <AlertDialogCloseButton />
          <AlertDialogHeader>
            <AlertDialogTitle>Document Request Sent</AlertDialogTitle>
            <AlertDialogDescription>{alertMessage}</AlertDialogDescription>
          </AlertDialogHeader>
          <AlertDialogAction onClick={() => setIsAlertOpen(false)}>OK</AlertDialogAction>
        </AlertDialogContent>
      </AlertDialog>
    </form>
  );
};

export default EvidenceCollection;