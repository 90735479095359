import React from "react";
import { cn } from "../../utils/cn";

const Ripple = React.memo(function Ripple({
  mainCircleSize = 210,
  mainCircleOpacity = 0.3,
  numCircles = 8,
  className,
  customColors = {
    from: 'rgba(139, 92, 246, 0.25)',
    to: 'rgba(59, 130, 246, 0.25)'
  }
}) {
  return (
    <div
      className={cn(
        "pointer-events-none select-none absolute inset-0 w-full h-full overflow-hidden m-0 p-0",
        className
      )}
    >
      {Array.from({ length: numCircles }, (_, i) => {
        const size = mainCircleSize + i * 70;
        const opacity = mainCircleOpacity - i * 0.015;
        const animationDelay = `${i * 0.08}s`;
        const borderOpacity = 25 + i * 8;

        return (
          <div
            key={i}
            className="absolute rounded-full"
            style={{
              width: `${size}px`,
              height: `${size}px`,
              opacity,
              animationDelay,
              background: `linear-gradient(to right, ${customColors.from}, ${customColors.to})`,
              borderStyle: 'solid',
              borderWidth: "2px",
              borderColor: `rgba(139, 92, 246, ${borderOpacity / 100})`,
              boxShadow: `0 0 20px rgba(139, 92, 246, 0.15)`,
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%) scale(1)",
              animation: "ripple 4s ease infinite",
              margin: 0,
              padding: 0,
            }}
          />
        );
      })}
    </div>
  );
});

Ripple.displayName = "Ripple";

export default Ripple;