import React from 'react';
import { useForm, useFieldArray } from 'react-hook-form';
import { Input } from '../components/ui/Input';
import { Textarea } from '../components/ui/Textarea';
import { Button } from '../components/ui/Button';

const FollowUpActions = ({ data, updateData }) => {
  const { register, control, handleSubmit, formState: { errors } } = useForm({
    defaultValues: data
  });
  const { fields, append, remove } = useFieldArray({
    control,
    name: "actions"
  });

  const onSubmit = (formData) => {
    updateData(formData);
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)} className="space-y-4">
      <h3 className="text-xl font-semibold mb-4">Follow-up Actions</h3>
      
      {fields.map((field, index) => (
        <div key={field.id} className="border p-4 rounded-md">
          <Input
            {...register(`actions.${index}.title`, { required: 'Action title is required' })}
            placeholder="Action Title"
          />
          {errors.actions?.[index]?.title && <p className="text-red-500 text-sm mt-1">{errors.actions[index].title.message}</p>}
          
          <Textarea
            {...register(`actions.${index}.description`)}
            placeholder="Action Description"
            className="mt-2"
          />
          
          <Input
            type="date"
            {...register(`actions.${index}.dueDate`)}
            className="mt-2"
          />
          
          <Input
            {...register(`actions.${index}.assignee`)}
            placeholder="Assignee"
            className="mt-2"
          />
          
          <Button type="button" onClick={() => remove(index)} className="mt-2">Remove Action</Button>
        </div>
      ))}
      
      <Button type="button" onClick={() => append({ title: '', description: '', dueDate: '', assignee: '' })}>
        Add Follow-up Action
      </Button>

      <Button type="submit">Finalize Audit</Button>
    </form>
  );
};

export default FollowUpActions;