import React, { useState, useEffect } from 'react';
import { Link, Navigate, useNavigate } from 'react-router-dom';
import {
  Users,
  Shield,
  Settings,
  Bell,
  User,
  Activity,
  Cog,
  BarChart2,
  TrendingUp,
  Lock,
  AlertTriangle,
  LifeBuoy,
  XCircle,
  Sparkles,
  CheckSquare,
  Cpu,
  Database,
  WorkflowIcon,
  } from 'lucide-react';

import EnhancedOverview from './EnhancedOverview';
import LeadershipContent from './LeadershipContent';
import OperationContent from './OperationContent';
import PerformanceContent from './PerformanceContent';
import ImprovementContent from './ImprovementContent';
import PlanningContent from './PlanningContent';
import MockAuditWizard from './MockAuditWizard';
import WorkflowBuilder from './WorkflowBuilder';

const appVersion = process.env.REACT_APP_VERSION || '0.4';

const fetchDashboardData = () => {
  return new Promise((resolve) => {
    setTimeout(() => {
      resolve({
        overallScore: 85,
        thirdPartyRiskScore: 72,
        dataPrivacyScore: 91,
        aiComplianceScore: 78,
        informationSecurityScore: 88,
        antiCorruptionScore: 82,
        complianceTrend: [
          { name: 'Jan', score: 65 },
          { name: 'Feb', score: 68 },
          { name: 'Mar', score: 75 },
          { name: 'Apr', score: 78 },
          { name: 'May', score: 82 },
          { name: 'Jun', score: 85 },
        ],
        policies: [
          { id: 1, name: 'Data Protection Policy', lastUpdated: '2023-05-15' },
          { id: 2, name: 'Third-Party Risk Management Policy', lastUpdated: '2023-04-22' },
          { id: 3, name: 'Information Security Policy', lastUpdated: '2023-06-01' },
          { id: 4, name: 'AI Ethics Policy', lastUpdated: '2023-07-10' },
          { id: 5, name: 'Anti-Corruption Policy', lastUpdated: '2023-06-20' },
        ],
        workflows: [
          { id: 1, name: 'Data Breach Response Workflow', lastUpdated: '2023-05-20' },
          { id: 2, name: 'Vendor Onboarding Workflow', lastUpdated: '2023-04-30' },
          { id: 3, name: 'AI Model Approval Workflow', lastUpdated: '2023-07-05' },
          { id: 4, name: 'Information Security Incident Response', lastUpdated: '2023-06-15' },
          { id: 5, name: 'Anti-Corruption Due Diligence', lastUpdated: '2023-06-25' },
        ],
        riskHeatMapData: [
          { area: 'Data Privacy', value: 80 },
          { area: 'Third-Party Risk', value: 65 },
          { area: 'AI Ethics', value: 70 },
          { area: 'Regulatory Compliance', value: 85 },
          { area: 'Information Security', value: 75 },
          { area: 'Financial Compliance', value: 90 },
        ],
        aiComplianceTrendData: [
          { month: 'Jan', predicted: 75, actual: 72 },
          { month: 'Feb', predicted: 78, actual: 76 },
          { month: 'Mar', predicted: 82, actual: 80 },
          { month: 'Apr', predicted: 85, actual: 83 },
          { month: 'May', predicted: 88, actual: 87 },
          { month: 'Jun', predicted: 92, actual: 90 },
        ],
        thirdPartyRiskAnalytics: {
          vendorRiskDistribution: [
            { risk: 'Low', count: 150 },
            { risk: 'Medium', count: 80 },
            { risk: 'High', count: 20 },
          ],
          assessmentsCompleted: 250,
        },
        dataPrivacyAnalytics: {
          subjectRequests: [
            { month: 'Jan', requests: 12 },
            { month: 'Feb', requests: 19 },
            { month: 'Mar', requests: 15 },
            { month: 'Apr', requests: 21 },
            { month: 'May', requests: 25 },
          ],
          breachRiskScore: 75,
        },
        aiComplianceAnalytics: {
          modelPerformance: [
            { model: 'Model A', accuracy: 92, fairness: 88 },
            { model: 'Model B', accuracy: 89, fairness: 91 },
            { model: 'Model C', accuracy: 95, fairness: 87 },
          ],
          ethicalConcerns: 5,
        },
        integrations: [
          { name: 'OneTrust', status: 'Connected', type: 'Data Privacy' },
          { name: 'Aravo', status: 'Connected', type: 'Third Party Risk' },
          { name: 'IBM OpenPages', status: 'Disconnected', type: 'GRC' },
          { name: 'Archer', status: 'Connected', type: 'Integrated Risk Management' },
        ],
      });
    }, 1000);
  });
};

const DashboardWireframe = ({ onNewProcess, draftProcesses }) => {
  const navigate = useNavigate();
  const [dashboardData, setDashboardData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [activeSection, setActiveSection] = useState('overview');
  const [modalOpen, setModalOpen] = useState(false);
  const [modalContent, setModalContent] = useState(null);
  const [newProcesses, setNewProcesses] = useState([]);
  const [showMockAuditWizard, setShowMockAuditWizard] = useState(false);
  const [mockAuditSection, setMockAuditSection] = useState('');
  const [generatedWorkflow, setGeneratedWorkflow] = useState(null);

  const handleNewProcess = () => {
    navigate('/process-wizard');
    if (onNewProcess) {
      onNewProcess();
    } else {
      console.warn('onNewProcess prop is not provided');
    }
  };

  useEffect(() => {
    if (process.env.REACT_APP_ENABLE_DASHBOARDS !== 'true') {
      return;
    }
    fetchDashboardData().then((data) => {
      setDashboardData(data);
      setLoading(false);
    });
  }, []);

  if (process.env.REACT_APP_ENABLE_DASHBOARDS !== 'true') {
    return <Navigate to="/" />;
  }

  if (loading) {
    return <div className="flex items-center justify-center h-screen">Loading...</div>;
  }

  const handleNavigation = (name, data) => {
    if (name === 'newProcess') {
      handleNewProcess();
    } else if (name === 'mockAudit') {
      setShowMockAuditWizard(true);
      setMockAuditSection(activeSection);
    } else if (name === 'workflowBuilder') {
      setActiveSection('workflowBuilder');
      if (data) {
        setGeneratedWorkflow(data);
      }
    } else {
      setActiveSection(name);
      setShowMockAuditWizard(false);
    }
  };

  const navigationItems = [
    { name: 'overview', icon: Users, category: 'main', status: 'active' },
    {
      name: 'newProcess',
      icon: WorkflowIcon,
      category: 'main',
      status: 'active',
      children: [
        {
          name: 'mockAudit',
          icon: CheckSquare,
          status: 'active',
          displayName: 'Mock Audit',
          isNew: true,
        },
      ],
      render: ({ onClick }) => (
        <div className="relative group">
          <button
            onClick={handleNewProcess}
            className="flex items-center w-full px-4 py-2 text-left text-purple-700 font-semibold hover:bg-purple-100 transition-all duration-300 pulse-animation"
          >
            <WorkflowIcon className="mr-3 flex-shrink-0" size={24} />
            <span className="flex-grow truncate">New Process</span>
            <Sparkles className="ml-2 flex-shrink-0" size={16} />
          </button>
          <div className="absolute left-full ml-2 p-2 bg-gray-800 text-white text-sm rounded-md opacity-0 group-hover:opacity-100 transition-opacity duration-300 pointer-events-none">
            Create a new compliance process with AI assistance
          </div>
        </div>
      ),
    },
    { name: 'leadership', icon: Shield, category: 'iso', status: 'active' },
    { name: 'planning', icon: Activity, category: 'iso', status: 'active' },
    { name: 'operation', icon: Cog, category: 'iso', status: 'active' },
    { name: 'performance', icon: BarChart2, category: 'iso', status: 'active' },
    { name: 'improvement', icon: TrendingUp, category: 'iso', status: 'active' },
    { name: 'thirdPartyRisk', icon: Users, category: 'processes', status: 'active' },
    { name: 'dataPrivacy', icon: Lock, category: 'processes', status: 'active' },
    { name: 'informationSecurity', icon: Shield, category: 'processes', status: 'in_development' },
    { name: 'antiCorruption', icon: AlertTriangle, category: 'processes', status: 'in_development' },
    { name: 'aiCompliance', icon: Cpu, category: 'processes', status: 'active' },
    { name: 'integrations', icon: Database, category: 'support', status: 'active' },
    { name: 'support', icon: LifeBuoy, category: 'support', status: 'active' },
    { name: 'settings', icon: Settings, category: 'support', status: 'active' },
    ...newProcesses.map((process, index) => ({
      name: process.name,
      icon: process.icon,
      category: 'newProcesses',
      status: 'draft',
      key: `newProcess-${index}`,
    })),
  ];

  const handleUpload = (type) => {
    console.log(`Uploading new ${type}`);
    const uploadContent = (
      <div className="text-center">
        <h3 className="text-xl font-semibold mb-4">
          Upload {type.charAt(0).toUpperCase() + type.slice(1)}
        </h3>
        <p className="mb-4">Upload functionality will be implemented here.</p>
        <div className="text-sm text-gray-600 mb-4 text-left">
          <p className="mb-2">Your data security is our top priority:</p>
          <ul className="list-disc pl-5 space-y-1">
            <li>
              All uploads and AI analyses occur on dedicated, encrypted servers specific to your
              organization.
            </li>
            <li>We employ end-to-end encryption for data in transit and at rest.</li>
            <li>
              Our AI models are trained exclusively on proprietary data, never on customer
              information.
            </li>
            <li>We maintain strict data isolation using advanced containerization technologies.</li>
            <li>
              Regular third-party security audits ensure the highest standards of data protection.
            </li>
          </ul>
        </div>
        <button className="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600">
          Placeholder Upload Button
        </button>
      </div>
    );
    setModalContent(uploadContent);
    setModalOpen(true);
  };

  const handleAnalyze = (item) => {
    console.log(`Analyzing ${item.name}`);
    let analysisData = {
      name: item.name,
      type: item.name.includes('Workflow') ? 'workflow' : 'policy',
      gapAnalysis: [],
      standardAlignment: {},
      aiInsights: {},
    };

    if (item.name.includes('Workflow')) {
      analysisData.gapAnalysis = [
        'Reduce approval steps from 4 to 3 to streamline the process',
        'Implement automated risk scoring for initial vendor assessment',
        'Add a step for periodic re-assessment of long-term vendors',
      ];
      analysisData.aiInsights = {
        processEfficiency: '78%',
        suggestedKPIs: [
          'Average time to onboard a new vendor',
          'Percentage of vendors passing initial risk assessment',
          'Number of compliance incidents related to third-party vendors',
        ],
      };
    } else {
      analysisData.gapAnalysis = [
        'Section 3.2 needs updating to reflect latest regulations',
        'Consider adding a clause about data retention periods',
      ];
      analysisData.aiInsights = {
        readinessCheck: '85% compliant',
        suggestedImprovements: 'AI-suggested improvements would be listed here.',
      };
    }

    analysisData.standardAlignment = {
      iso37301: 'Sections 4.5, 6.2, and 7.4 need attention',
      idwPs980: 'Aligns well, minor updates needed in risk assessment process',
    };

    const analysisContent = (
      <div className="text-left">
        <h3 className="text-xl font-semibold mb-4">AI Analysis: {analysisData.name}</h3>

        <div className="mb-4">
          <h4 className="font-semibold mb-2">Gap Analysis:</h4>
          <ul className="list-disc pl-5 space-y-1">
            {analysisData.gapAnalysis.map((gap, index) => (
              <li key={index}>{gap}</li>
            ))}
          </ul>
        </div>

        <div className="mb-4">
          <h4 className="font-semibold mb-2">Standard Alignment:</h4>
          <ul className="list-disc pl-5 space-y-1">
            {Object.entries(analysisData.standardAlignment).map(([standard, alignment]) => (
              <li key={standard}>
                <strong>{standard.toUpperCase()}:</strong> {alignment}
              </li>
            ))}
          </ul>
        </div>

        <div className="mb-4">
          <h4 className="font-semibold mb-2">AI Insights:</h4>
          {analysisData.type === 'workflow' ? (
            <>
              <p>Process Efficiency: {analysisData.aiInsights.processEfficiency}</p>
              <p>Suggested KPIs:</p>
              <ul className="list-disc pl-5 space-y-1">
                {analysisData.aiInsights.suggestedKPIs.map((kpi, index) => (
                  <li key={index}>{kpi}</li>
                ))}
              </ul>
            </>
          ) : (
            <>
              <p>Readiness Check: {analysisData.aiInsights.readinessCheck}</p>
              <p>Suggested Improvements: {analysisData.aiInsights.suggestedImprovements}</p>
            </>
          )}
        </div>

        <div className="text-sm text-gray-600 mb-4">
          <p className="mb-2">Our AI analysis ensures:</p>
          <ul className="list-disc pl-5 space-y-1">
            <li>Real-time analysis using our proprietary AI models</li>
            <li>
              Secure processing on isolated, encrypted servers dedicated to your organization
            </li>
            <li>No data retention post-analysis for maximum privacy</li>
            <li>Compliance with GDPR, CCPA, and other data protection regulations</li>
          </ul>
        </div>

        <button className="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600">
          Export Full Analysis (Placeholder)
        </button>
      </div>
    );

    setModalContent(analysisContent);
    setModalOpen(true);
  };

  const formatNavItemName = (name) => {
    if (!name) return '';
    return name
      .split(/(?=[A-Z])/)
      .map((word) => {
        if (word.toLowerCase() === 'ai') {
          return 'AI';
        } else {
          return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
        }
      })
      .join(' ');
  };

  return (
    <div className="flex h-screen bg-gray-100">
      {/* Left Sidebar */}
      <div className="w-64 bg-white shadow-md">
        <div className="p-4">
          <Link
            to="/"
            className="text-xl font-bold text-gray-600 hover:text-blue-600 transition duration-300"
          >
            LOKATIAL CMS
          </Link>
        </div>
        <nav className="mt-4">
          {['main', 'iso', 'processes', 'support', 'newProcesses'].map((category) => (
            <div key={category}>
              {category !== 'main' && <hr className="my-2 border-gray-200" />}
              {category === 'newProcesses' && newProcesses.length > 0 && (
                <div className="px-4 py-2 text-sm font-semibold text-gray-600">
                  New Processes (Draft)
                </div>
              )}
              {navigationItems
                .filter((item) => item.category === category)
                .map((item) => {
                  const { name, icon: Icon, status, render, displayName, children } = item;
                  if (children && children.length > 0) {
                    // Render parent item
                    return (
                      <div key={name}>
                        {render ? (
                          render({
                            onClick: () => status === 'active' && handleNavigation(name),
                          })
                        ) : (
                          <button
                            className={`flex items-center w-full px-4 py-2 text-left ${
                              activeSection === name
                                ? 'bg-blue-100 text-blue-600'
                                : 'text-gray-700 hover:bg-gray-100'
                            } ${status === 'in_development' ? 'text-gray-400' : ''} ${
                              status === 'draft' ? 'text-orange-500' : ''
                            }`}
                            onClick={() => status === 'active' && handleNavigation(name)}
                            disabled={status !== 'active'}
                          >
                            <Icon className="mr-3 flex-shrink-0" size={20} />
                            <span className="flex-grow truncate">
                              {displayName || formatNavItemName(name)}
                            </span>
                          </button>
                        )}
                        {/* Render children */}
                        <div className="ml-6">
                          {children.map((child) => {
                            const {
                              name: childName,
                              icon: ChildIcon,
                              status: childStatus,
                              displayName: childDisplayName,
                              isNew,
                            } = child;
                            return (
                              <button
                                key={childName}
                                className={`flex items-center w-full px-4 py-2 text-left ${
                                  activeSection === childName
                                    ? 'bg-blue-100 text-blue-600'
                                    : 'text-gray-700 hover:bg-gray-100'
                                } ${childStatus === 'in_development' ? 'text-gray-400' : ''} ${
                                  childStatus === 'draft' ? 'text-orange-500' : ''
                                }`}
                                onClick={() => childStatus === 'active' && handleNavigation(childName)}
                                disabled={childStatus !== 'active'}
                              >
                                <ChildIcon className="mr-3 flex-shrink-0" size={20} />
                                <span className="flex-grow truncate">
                                  {childDisplayName || formatNavItemName(childName)}
                                </span>
                                {isNew && (
                                  <span className="ml-2 px-2 py-1 text-xs font-semibold text-purple-700 bg-purple-100 rounded-full">
                                    New
                                  </span>
                                )}
                              </button>
                            );
                          })}
                        </div>
                      </div>
                    );
                  } else {
                    return render
                      ? render({
                          onClick: () => status === 'active' && handleNavigation(name),
                        })
                      : (
                        <button
                          key={name}
                          className={`flex items-center w-full px-4 py-2 text-left ${
                            activeSection === name
                              ? 'bg-blue-100 text-blue-600'
                              : 'text-gray-700 hover:bg-gray-100'
                          } ${status === 'in_development' ? 'text-gray-400' : ''} ${
                            status === 'draft' ? 'text-orange-500' : ''
                          }`}
                          onClick={() => status === 'active' && handleNavigation(name)}
                          disabled={status !== 'active'}
                        >
                          <Icon className="mr-3 flex-shrink-0" size={20} />
                          <span className="flex-grow truncate">
                            {displayName || formatNavItemName(name)}
                          </span>
                        </button>
                      );
                  }
                })}
            </div>
          ))}
        </nav>
      </div>

      {/* Main Content Area */}
      <div className="flex-1 overflow-hidden">
        {/* Header */}
        <header className="bg-white shadow-sm">
          <div className="flex items-center justify-between px-4 py-3">
            <h1 className="text-xl font-semibold text-gray-500 ">
              Continuous Compliance Management
            </h1>
            <div className="flex items-center">
              <Link
                to="/aims-dashboard"
                className="mr-4 text-blue-500 hover:text-blue-600 flex items-center"
              >
                <Cpu size={16} className="mr-1" />
                AIMS Dashboard
              </Link>
              <span className="mr-4 text-sm text-gray-500">v{appVersion}</span>
              <Bell className="mr-4 cursor-pointer text-gray-500" size={20} />
              <User className="mr-2 text-gray-500" size={20} />
              <span className="text-gray-500">Tarun S.</span>
            </div>
          </div>
        </header>

        {/* Dashboard Content */}
        <main
          className="p-6 overflow-auto text-gray-800"
          style={{ height: 'calc(100vh - 64px)' }}
        >
          {showMockAuditWizard ? (
            <MockAuditWizard
              section={mockAuditSection}
              onClose={() => setShowMockAuditWizard(false)}
            />
          ) : (
            <>
              {activeSection === 'overview' && dashboardData && (
                <EnhancedOverview 
                  dashboardData={dashboardData} 
                  onNavigate={handleNavigation}
                />
              )}

              {activeSection === 'workflowBuilder' && (
                <WorkflowBuilder initialWorkflow={generatedWorkflow} />
              )}

              {activeSection === 'leadership' && <LeadershipContent />}
              {activeSection === 'planning' && <PlanningContent />}
              {activeSection === 'operation' && <OperationContent />}
              {activeSection === 'performance' && <PerformanceContent />}
              {activeSection === 'improvement' && <ImprovementContent />}

              {['informationSecurity', 'antiCorruption'].includes(activeSection) && (
                <div className="p-4 bg-white rounded shadow">
                  <h2 className="text-xl font-semibold mb-4">
                    {activeSection.charAt(0).toUpperCase() +
                      activeSection.slice(1).replace(/([A-Z])/g, ' $1')}
                  </h2>
                  <p>
                    This section would contain specific information and tools related to{' '}
                    {activeSection.replace(/([A-Z])/g, ' $1').toLowerCase()}.
                  </p>
                </div>
              )}

              {/* Include other sections as per your original code */}
              {/* For example, thirdPartyRisk, dataPrivacy, aiCompliance, integrations, support, settings */}
              {/* ... */}
            </>
          )}
        </main>
      </div>

      {modalOpen && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center p-4 z-50">
          <div className="bg-white rounded-lg max-w-2xl w-full">
            <div className="p-6">
              <div className="flex justify-end">
                <button
                  onClick={() => setModalOpen(false)}
                  className="text-gray-500 hover:text-gray-700"
                >
                  <XCircle size={24} />
                </button>
              </div>
              {modalContent}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default DashboardWireframe;
