import React, { useState } from 'react';
import { Tab, TabGroup, TabList, TabPanel, TabPanels } from '@headlessui/react';
import { CheckCircle, XCircle, ExternalLink } from 'lucide-react';

function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

const ImprovementContent = () => {
  const [quizStarted, setQuizStarted] = useState(false);
  const [currentQuestion, setCurrentQuestion] = useState(0);
  const [score, setScore] = useState(0);
  const [showResults, setShowResults] = useState(false);

  const content = {
    overview: {
      introduction: "Improvement is a key aspect of compliance management that involves continuously evaluating and enhancing compliance processes. It ensures that the organization adapts to regulatory changes and emerging risks, maintaining an effective compliance framework over time.",
      keyPoints: [
        "Conducting regular compliance audits and assessments",
        "Implementing corrective actions and process improvements",
        "Monitoring and measuring compliance performance",
        "Identifying new risks and updating compliance procedures",
        "Engaging in continuous feedback and learning loops"
      ],
      importance: "Continuous improvement ensures that the compliance management system evolves with the changing regulatory environment and organizational objectives. It helps in reducing risks, enhancing operational efficiency, and fostering a culture of compliance.",
      bestPractices: [
        "Regularly review and update compliance policies and procedures",
        "Encourage feedback from employees and stakeholders",
        "Use data analytics to monitor compliance trends and performance",
        "Establish a system for tracking corrective actions and improvements",
        "Conduct post-incident reviews to prevent recurrence of compliance breaches"
      ]
    },
    quiz: [
      {
        question: "What is a key activity in the improvement phase of compliance management?",
        options: [
          "Developing new business strategies",
          "Regularly reviewing and updating compliance procedures",
          "Conducting quarterly financial reviews",
          "None of the above"
        ],
        correctAnswer: 1
      },
      {
        question: "Why is continuous feedback important in compliance improvement?",
        options: [
          "To create more paperwork",
          "To identify areas for improvement and enhance compliance processes",
          "To satisfy external auditors",
          "To reduce employee workload"
        ],
        correctAnswer: 1
      },
      {
        question: "Which of the following is a best practice for compliance improvement?",
        options: [
          "Only review policies when incidents occur",
          "Ignore employee feedback to avoid unnecessary changes",
          "Use data analytics to monitor trends and performance",
          "Wait for regulatory bodies to mandate updates"
        ],
        correctAnswer: 2
      }
    ],
    resources: [
      { title: "Continuous Improvement in Compliance Programs", url: "https://www.corporatecomplianceinsights.com/continuous-improvement-in-compliance-programs/" },
      { title: "ISO 19600:2014 Compliance Management Systems Guidelines", url: "https://www.iso.org/standard/62342.html" },
      { title: "Best Practices for Monitoring Compliance Programs", url: "https://www2.deloitte.com/global/en/pages/risk/articles/best-practices-for-monitoring-compliance-programs.html" }
    ]
  };

  // Logic for handling quiz submission and scoring
  const handleAnswerClick = (index) => {
    if (!quizStarted) return;
    if (index === content.quiz[currentQuestion].correctAnswer) {
      setScore(score + 1);
    }
    const nextQuestion = currentQuestion + 1;
    if (nextQuestion < content.quiz.length) {
      setCurrentQuestion(nextQuestion);
    } else {
      setShowResults(true);
    }
  };

  const startQuiz = () => {
    setQuizStarted(true);
    setCurrentQuestion(0);
    setScore(0);
    setShowResults(false);
  };

  return (
    <TabGroup>
      <TabList className="flex space-x-1 rounded-xl bg-blue-900/20 p-1">
        {['Overview', 'Key Points', 'Quiz', 'Resources'].map((tab) => (
          <Tab
            key={tab}
            className={({ selected }) =>
              classNames(
                'w-full rounded-lg py-2.5 text-sm font-medium leading-5 text-blue-700',
                'ring-white ring-opacity-60 ring-offset-2 ring-offset-blue-400 focus:outline-none focus:ring-2',
                selected
                  ? 'bg-white shadow'
                  : 'text-blue-100 hover:bg-white/[0.12] hover:text-white'
              )
            }
          >
            {tab}
          </Tab>
        ))}
      </TabList>
      <TabPanels className="mt-2">
        {/* Overview */}
        <TabPanel className="rounded-xl bg-white p-3">
          <p className="text-gray-700">{content.overview.introduction}</p>
          <h3 className="font-semibold mt-4 mb-2">Importance:</h3>
          <p className="text-gray-700">{content.overview.importance}</p>
        </TabPanel>
        {/* Key Points */}
        <TabPanel className="rounded-xl bg-white p-3">
          <h3 className="font-semibold mb-2">Key Points:</h3>
          <ul className="list-disc pl-5 space-y-2">
            {content.overview.keyPoints.map((point, index) => (
              <li key={index} className="text-gray-700">{point}</li>
            ))}
          </ul>
          <h3 className="font-semibold mt-4 mb-2">Best Practices:</h3>
          <ul className="list-disc pl-5 space-y-2">
            {content.overview.bestPractices.map((practice, index) => (
              <li key={index} className="text-gray-700">{practice}</li>
            ))}
          </ul>
        </TabPanel>
        {/* Quiz */}
        <TabPanel className="rounded-xl bg-white p-3">
          {!quizStarted ? (
            <button onClick={startQuiz} className="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600 transition duration-300">
              Start Quiz
            </button>
          ) : showResults ? (
            <div>
              <h2 className="text-xl font-semibold mb-4">Your Score: {score}/{content.quiz.length}</h2>
              <button onClick={startQuiz} className="bg-green-500 text-white px-4 py-2 rounded hover:bg-green-600 transition duration-300 mt-2">
                Retry Quiz
              </button>
            </div>
          ) : (
            <div>
              <h3 className="text-lg font-semibold mb-4">{content.quiz[currentQuestion].question}</h3>
              <ul className="space-y-2">
                {content.quiz[currentQuestion].options.map((option, index) => (
                  <li 
                    key={index} 
                    onClick={() => handleAnswerClick(index)} 
                    className="cursor-pointer p-2 rounded bg-gray-100 hover:bg-gray-200 transition duration-300"
                  >
                    {option}
                  </li>
                ))}
              </ul>
            </div>
          )}
        </TabPanel>
        {/* Resources */}
        <TabPanel className="rounded-xl bg-white p-3">
          <ul className="space-y-2">
            {content.resources.map((resource, index) => (
              <li key={index} className="flex items-center">
                <ExternalLink size={18} className="mr-2 text-blue-500" /> 
                <a href={resource.url} target="_blank" rel="noreferrer" className="text-blue-500 hover:underline">
                  {resource.title}
                </a>
              </li>
            ))}
          </ul>
        </TabPanel>
      </TabPanels>
    </TabGroup>
  );
};

export default ImprovementContent;
