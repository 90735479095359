import React, { useState } from 'react';
import { CheckSquare, FileText, Clock, AlertTriangle } from 'lucide-react';

const ConformityAssessment = () => {
  const [assessmentSteps, setAssessmentSteps] = useState([
    { id: 1, name: 'Initial Risk Assessment', status: 'Completed', date: '2023-07-10' },
    { id: 2, name: 'Technical Documentation Review', status: 'In Progress', date: '2023-08-01' },
    { id: 3, name: 'Quality Management System Audit', status: 'Scheduled', date: '2023-08-15' },
    { id: 4, name: 'Conformity Declaration', status: 'Pending', date: '-' },
  ]);

  const [documents, setDocuments] = useState([
    { id: 1, name: 'Risk Management Report', status: 'Approved' },
    { id: 2, name: 'Technical Documentation Package', status: 'Under Review' },
    { id: 3, name: 'Test Results and Validation Data', status: 'Pending Upload' },
  ]);

  return (
    <div className="p-4 bg-white rounded shadow">
      <h2 className="text-xl font-semibold mb-4">Conformity Assessment</h2>
      <p className="mb-4 text-gray-600">
        Evaluation process to ensure AI systems meet EU AI Act requirements before market placement. 
        Includes risk assessment, technical documentation review, and quality management system verification.
      </p>
      <div className="grid grid-cols-2 gap-4">
        <div>
          <h3 className="text-lg font-medium mb-2">Assessment Procedure</h3>
          <ul className="space-y-2">
            {assessmentSteps.map(step => (
              <li key={step.id} className="flex items-center justify-between">
                <span className="flex items-center">
                  {step.status === 'Completed' ? (
                    <CheckSquare size={16} className="mr-2 text-green-500" />
                  ) : step.status === 'In Progress' ? (
                    <Clock size={16} className="mr-2 text-yellow-500" />
                  ) : (
                    <AlertTriangle size={16} className="mr-2 text-gray-500" />
                  )}
                  {step.name}
                </span>
                <span className="text-sm text-gray-500">{step.date}</span>
              </li>
            ))}
          </ul>
          <button className="mt-4 bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600">
            Update Assessment Status
          </button>
        </div>
        <div>
          <h3 className="text-lg font-medium mb-2">Documentation</h3>
          <ul className="space-y-2">
            {documents.map(doc => (
              <li key={doc.id} className="flex items-center justify-between">
                <span className="flex items-center">
                  <FileText size={16} className="mr-2 text-gray-500" />
                  {doc.name}
                </span>
                <span className={`text-sm ${
                  doc.status === 'Approved' ? 'text-green-500' :
                  doc.status === 'Under Review' ? 'text-yellow-500' :
                  'text-red-500'
                }`}>
                  {doc.status}
                </span>
              </li>
            ))}
          </ul>
          <button className="mt-4 bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600">
            Upload New Document
          </button>
        </div>
      </div>
    </div>
  );
};

export default ConformityAssessment;