import React, { useState, useEffect, useRef } from 'react';
import { Brain, Send, Loader, X, Paperclip, Lightbulb } from 'lucide-react';
import { Button } from './ui/Button';

const AITaskSuggestions = [
  { task: "Analyze AI model performance", description: "Review the latest metrics for our deployed AI models." },
  { task: "Check EU AI Act compliance", description: "Assess our current compliance status with the EU AI Act." },
  { task: "Review ethical AI guidelines", description: "Examine our ethical AI practices against industry standards." },
  { task: "Investigate AI incident", description: "Look into recent anomalies or issues with our AI systems." },
];

const LokCOIntegration = ({ onClose, dashboardState }) => {

  const [input, setInput] = useState('');
  const [messages, setMessages] = useState([
    { text: "Hello! I'm LokCO, your AI Management Assistant. How can I help you today?", sender: 'LokCO' }
  ]);
  const [isAnalyzing, setIsAnalyzing] = useState(false);
  const fileInputRef = useRef(null);
  const chatContainerRef = useRef(null);
  const [proactiveRecommendation, setProactiveRecommendation] = useState(null);

  useEffect(() => {
    if (chatContainerRef.current) {
      chatContainerRef.current.scrollTop = chatContainerRef.current.scrollHeight;
    }
  }, [messages]);

  useEffect(() => {
    // Simulate proactive recommendations based on dashboard state
    const interval = setInterval(() => {
      const recommendation = generateProactiveRecommendation(dashboardState);
      if (recommendation) {
        setProactiveRecommendation(recommendation);
      }
    }, 60000); // Check for new recommendations every minute

    return () => clearInterval(interval);
  }, [dashboardState]);

  const handleSend = () => {
    if (input.trim()) {
      sendMessage(input);
    }
  };

  const sendMessage = (text) => {
    setMessages(prev => [...prev, { text, sender: 'User' }]);
    setInput('');
    setIsAnalyzing(true);
    
    // Simulate AI response
    setTimeout(() => {
      setIsAnalyzing(false);
      const aiResponse = generateAIResponse(text, dashboardState);
      setMessages(prev => [...prev, { text: aiResponse, sender: 'LokCO' }]);
    }, 1500);
  };

  const generateAIResponse = (userInput, dashboardState) => {
    // This is a placeholder. In a real application, this would be connected to an AI service.
    const lowerInput = userInput.toLowerCase();
    if (lowerInput.includes('model performance')) {
      return `Based on the current dashboard state, our AI model performance is at ${dashboardState.aiPerformanceScore}%. Would you like me to suggest some optimization strategies?`;
    } else if (lowerInput.includes('compliance') || lowerInput.includes('eu ai act')) {
      return `Our current EU AI Act compliance score is ${dashboardState.euAiActComplianceScore}%. The main areas needing attention are: ${dashboardState.complianceGaps.join(', ')}. Shall we review these in detail?`;
    } else if (lowerInput.includes('ethical') || lowerInput.includes('guidelines')) {
      return `Our ethical AI score is currently at ${dashboardState.ethicalAiScore}%. I've noticed some potential improvements in our fairness metrics. Would you like me to generate a report on this?`;
    } else if (lowerInput.includes('incident') || lowerInput.includes('issue')) {
      return `I've detected ${dashboardState.aiIncidents.length} recent AI incidents. The most critical one is related to ${dashboardState.aiIncidents[0].description}. Should we start an investigation process?`;
    } else {
      return "I understand you're interested in " + userInput + ". Could you please provide more context or specifics about what you'd like to know?";
    }
  };

  const generateProactiveRecommendation = (dashboardState) => {
    // This is a placeholder. In a real application, this would use more sophisticated logic.
    if (dashboardState.aiPerformanceScore < 80) {
      return "I've noticed our AI performance score has dropped below 80%. Would you like me to analyze potential causes?";
    } else if (dashboardState.euAiActComplianceScore < 90) {
      return "Our EU AI Act compliance score is below 90%. Shall I prepare a report on areas needing immediate attention?";
    } else if (dashboardState.aiIncidents.length > 0) {
      return `There are ${dashboardState.aiIncidents.length} unresolved AI incidents. Would you like to review them?`;
    }
    return null;
  };

  const handleKeyPress = (e) => {
    if (e.key === 'Enter' && !e.shiftKey) {
      e.preventDefault();
      handleSend();
    }
  };

  const handleRecommendationClick = () => {
    if (proactiveRecommendation) {
      sendMessage(proactiveRecommendation);
      setProactiveRecommendation(null);
    }
  };

  return (
    <div className="fixed bottom-4 right-4 w-96 bg-white rounded-lg shadow-xl border border-gray-200 z-50">
      <div className="flex justify-between items-center p-4 bg-blue-600 text-white rounded-t-lg">
        <div className="flex items-center">
          <Brain className="w-6 h-6 mr-2" />
          <h4 className="text-lg font-semibold">LokCO AI Assistant</h4>
        </div>
        <button onClick={onClose} className="text-white hover:text-gray-200 transition-colors duration-200">
          <X className="w-5 h-5" />
        </button>
      </div>
      <div ref={chatContainerRef} className="h-90 overflow-auto p-4 space-y-4">
        {messages.map((msg, index) => (
          <div key={index} className={`flex ${msg.sender === 'User' ? 'justify-end' : 'justify-start'}`}>
            <div className={`max-w-[75%] p-3 rounded-lg ${msg.sender === 'User' ? 'bg-blue-500 text-white' : 'bg-gray-100 text-gray-500'}`}>
              <p className="text-sm">{msg.text}</p>
            </div>
          </div>
        ))}
        {isAnalyzing && (
          <div className="flex items-center justify-center">
            <Loader className="w-5 h-5 text-blue-500 animate-spin" />
            <span className="ml-2 text-sm text-gray-600">LokCO is analyzing...</span>
          </div>
        )}
      </div>
      {proactiveRecommendation && (
        <div className="px-4 py-2 bg-yellow-100 border-t border-yellow-200">
          <button 
            onClick={handleRecommendationClick}
            className="flex items-center text-sm text-yellow-800 hover:text-yellow-900"
          >
            <Lightbulb className="w-4 h-4 mr-2" />
            {proactiveRecommendation}
          </button>
        </div>
      )}
      <div className="p-4 border-t">
        <div className="mb-2">
          <h5 className="text-sm font-semibold mb-1 text-gray-400">Suggested tasks:</h5>
          <div className="flex flex-wrap gap-2">
            {AITaskSuggestions.map((suggestion, index) => (
              <button
                key={index}
                onClick={() => sendMessage(suggestion.task)}
                className="text-xs bg-blue-100 text-blue-700 px-2 py-1 rounded hover:bg-blue-200 transition-colors duration-200"
                title={suggestion.description}
              >
                {suggestion.task}
              </button>
            ))}
          </div>
        </div>
        <div className="flex items-center">
          <textarea
            value={input}
            onChange={(e) => setInput(e.target.value)}
            onKeyDown={handleKeyPress}
            placeholder="Ask LokCO..."
            className="flex-1 border rounded-l-md p-2 focus:outline-none focus:ring-2 focus:ring-blue-500 resize-none"
            rows="2"
          />
          <Button onClick={() => fileInputRef.current.click()} className="px-3 py-2 bg-gray-200 hover:bg-gray-300 text-gray-700">
            <Paperclip className="w-5 h-5" />
          </Button>
          <input 
            type="file"
            ref={fileInputRef}
            onChange={(e) => {/* Handle file upload */}}
            className="hidden"
          />
          <Button onClick={handleSend} className="px-4 py-2 bg-blue-600 hover:bg-blue-700 text-white rounded-r-md">
            <Send className="w-5 h-5" />
          </Button>
        </div>
      </div>
    </div>
  );
};

export default LokCOIntegration;