import React, { forwardRef } from 'react';

export const Textarea = forwardRef(({
  className = '',
  error = false,
  disabled = false,
  rows = 4,
  ...props
}, ref) => {
  const baseStyles =
    'w-full px-4 py-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-purple-500 transition duration-200 ease-in-out resize-y';
  
  const errorStyles = error
    ? 'border-red-500 focus:ring-red-500'
    : 'border-gray-300 focus:border-purple-500';

  const disabledStyles = disabled ? 'bg-gray-100 opacity-50 cursor-not-allowed' : '';

  return (
    <textarea
      ref={ref}
      className={`${baseStyles} ${errorStyles} ${disabledStyles} ${className}`}
      rows={rows}
      disabled={disabled}
      aria-invalid={error}
      {...props}
    />
  );
});

Textarea.displayName = 'Textarea';