import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';

const GDPRNotice = () => {
  const [showNotice, setShowNotice] = useState(false);

  useEffect(() => {
    const consent = localStorage.getItem('gdprConsent');
    if (consent === null) {
      setShowNotice(true);
    } else if (consent === 'true') {
      enableAnalytics();
    }
  }, []);

  const enableAnalytics = () => {
    // Enable Google Analytics or your chosen analytics platform
    window.gtag('config', 'G-NJJJB5WNYT', { 'send_page_view': true });
  };

  const disableAnalytics = () => {
    // Disable Google Analytics or your chosen analytics platform
    window.gtag('config', 'G-NJJJB5WNYT', { 'send_page_view': false });
  };

  const handleAccept = () => {
    localStorage.setItem('gdprConsent', 'true');
    setShowNotice(false);
    enableAnalytics();
  };

  const handleDecline = () => {
    localStorage.setItem('gdprConsent', 'false');
    setShowNotice(false);
    disableAnalytics();
  };

  if (!showNotice) return null;

  return (
    <div className="fixed bottom-0 left-0 right-0 bg-gray-800 text-white p-4 z-50">
      <div className="max-w-4xl mx-auto">
        <p className="text-sm mb-4">
          We use cookies to analyze our traffic and improve your experience. 
          You can choose to accept or decline these cookies. For more information, please see our{' '}
          <Link to="/privacy-policy" className="underline hover:text-blue-300">
            Privacy Policy
          </Link>
          .
        </p>
        <div className="flex justify-end space-x-4">
          <button 
            onClick={handleDecline}
            className="bg-gray-600 hover:bg-gray-700 text-white font-bold py-2 px-4 rounded"
          >
            Decline
          </button>
          <button 
            onClick={handleAccept}
            className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
          >
            Accept
          </button>
        </div>
      </div>
    </div>
  );
};

export default GDPRNotice;