import React, { useState, useEffect, useCallback } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer, PieChart, Pie, Cell } from 'recharts';
import { Card, CardHeader, CardContent } from '../components/ui/Card';
import { AlertTriangle, CheckCircle, Clock, FileText, Bot, AlertCircle, Info } from 'lucide-react';
import { Badge } from '../components/ui/Badge';
import { Progress } from '../components/ui/Progress';
import { Button } from '../components/ui/Button';
import {
  AlertDialog,
  AlertDialogContent,
  AlertDialogHeader,
  AlertDialogTitle,
  AlertDialogDescription,
  AlertDialogAction,
  AlertDialogCloseButton,
} from '../components/ui/AlertDialog';

const COLORS = ['#0088FE', '#00C49F', '#FFBB28', '#FF8042', '#8884D8'];

const AnalysisTab = ({ uploadedFiles, aiSuggestions, auditStartDate, auditObjective, isAnalysisComplete }) => {
  const [simulatedAnalysis, setSimulatedAnalysis] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [isDisclaimerOpen, setIsDisclaimerOpen] = useState(true);
  const [showAnalysisContent, setShowAnalysisContent] = useState(false);

  const generateITSecurityAnalysis = () => ({
    overallRiskScore: 72,
    keyFindings: [
      { severity: 'high', description: 'Outdated encryption protocols in use for sensitive data transmission' },
      { severity: 'medium', description: 'Inconsistent application of access controls across cloud services' },
      { severity: 'high', description: 'Lack of regular security awareness training for employees' },
      { severity: 'low', description: 'Incomplete asset inventory for IoT devices' },
    ],
    riskCategories: [
      { name: 'Data Protection', value: 35 },
      { name: 'Access Management', value: 25 },
      { name: 'Network Security', value: 20 },
      { name: 'Employee Training', value: 15 },
      { name: 'Incident Response', value: 5 },
    ],
    complianceScores: [
      { name: 'GDPR', score: 85 },
      { name: 'ISO 27001', score: 78 },
      { name: 'NIST', score: 72 },
      { name: 'PCI DSS', score: 90 },
    ],
    recommendedActions: [
      'Upgrade encryption protocols to industry-standard algorithms',
      'Implement a centralized Identity and Access Management (IAM) solution',
      'Conduct monthly security awareness training sessions for all employees',
      'Perform a comprehensive IoT device audit and implement a management system',
    ],
  });

  const generateFinancialComplianceAnalysis = () => ({
    overallRiskScore: 65,
    keyFindings: [
      { severity: 'high', description: 'Inconsistencies in revenue recognition practices across departments' },
      { severity: 'medium', description: 'Delayed reconciliation of inter-company transactions' },
      { severity: 'high', description: 'Inadequate segregation of duties in the accounts payable process' },
      { severity: 'low', description: 'Minor discrepancies in foreign currency translation' },
    ],
    riskCategories: [
      { name: 'Financial Reporting', value: 40 },
      { name: 'Internal Controls', value: 30 },
      { name: 'Regulatory Compliance', value: 20 },
      { name: 'Fraud Risk', value: 10 },
    ],
    complianceScores: [
      { name: 'SOX', score: 82 },
      { name: 'IFRS', score: 88 },
      { name: 'GAAP', score: 85 },
      { name: 'Local Regulations', score: 79 },
    ],
    recommendedActions: [
      'Standardize revenue recognition practices and provide training to relevant staff',
      'Implement an automated system for inter-company transaction reconciliation',
      'Revise the accounts payable process to ensure proper segregation of duties',
      'Conduct a detailed review of foreign currency translation procedures and update as necessary',
    ],
  });

  const generateDefaultAnalysis = () => ({
    overallRiskScore: 68,
    keyFindings: [
      { severity: 'high', description: 'Inadequate segregation of duties in financial reporting processes' },
      { severity: 'medium', description: 'Inconsistent application of data retention policies across departments' },
      { severity: 'high', description: 'Lack of formal third-party risk assessment procedures' },
      { severity: 'low', description: 'Incomplete documentation of change management processes' },
      { severity: 'medium', description: 'Insufficient employee training on new compliance requirements' }
    ],
    riskCategories: [
      { name: 'Operational Risk', value: 35 },
      { name: 'Compliance Risk', value: 25 },
      { name: 'Financial Risk', value: 20 },
      { name: 'Reputational Risk', value: 12 },
      { name: 'Strategic Risk', value: 8 }
    ],
    complianceScores: [
      { name: 'ISO 27001', score: 72 },
      { name: 'GDPR', score: 85 },
      { name: 'SOX', score: 78 },
      { name: 'Industry-Specific Regulations', score: 80 }
    ],
    recommendedActions: [
      'Implement robust segregation of duties in financial processes, particularly in areas of cash management and financial reporting',
      'Develop and enforce a standardized data retention policy across all departments, ensuring compliance with relevant regulations',
      'Establish a formal third-party risk assessment program, including regular audits and continuous monitoring of critical vendors',
      'Enhance the change management documentation process, including impact assessments and rollback procedures',
      'Conduct comprehensive compliance training programs for all employees, with focus on role-specific compliance requirements',
      'Perform a gap analysis of current practices against ISO 27001 standards and develop an improvement plan',
      'Review and update internal controls to ensure full compliance with SOX requirements, particularly in IT general controls'
    ],
  });

  const simulateAIAnalysis = useCallback((objective) => {
    if (objective.toLowerCase().includes('it security')) {
      return generateITSecurityAnalysis();
    } else if (objective.toLowerCase().includes('financial compliance')) {
      return generateFinancialComplianceAnalysis();
    } else {
      return generateDefaultAnalysis();
    }
  }, []);

  useEffect(() => {
    if (isAnalysisComplete && !isDisclaimerOpen) {
      setIsLoading(true);
      setError(null);
      try {
        const analysis = simulateAIAnalysis(auditObjective);
        setSimulatedAnalysis(analysis);
      } catch (err) {
        setError('Failed to generate analysis. Please try again.');
      } finally {
        setIsLoading(false);
      }
    }
  }, [isAnalysisComplete, auditObjective, simulateAIAnalysis, isDisclaimerOpen]);
  
  const handleDisclaimerClose = () => {
    setIsDisclaimerOpen(false);
    setShowAnalysisContent(true);
  };
  
  const renderDisclaimer = () => (
    <AlertDialog open={isDisclaimerOpen} onOpenChange={setIsDisclaimerOpen}>
      <AlertDialogContent className="max-w-2xl">
        <AlertDialogHeader>
          <AlertDialogTitle className="text-2xl font-bold mb-4">About this AI-Generated Analysis</AlertDialogTitle>
          <AlertDialogDescription className="text-base">
            <p className="mb-4">This analysis is generated by an AI model based on the provided audit data and is intended to serve as a starting point for your audit process. It should not be considered as absolute fact or a replacement for professional judgment.</p>
            <p className="mb-2 font-semibold">Key points to consider:</p>
            <ul className="list-disc pl-5 mb-4 space-y-2">
              <li>The analysis helps prioritize areas for further investigation</li>
              <li>All findings and recommendations should be verified by qualified auditors</li>
              <li>The risk scores and distributions are estimates based on available data</li>
              <li>This tool complements, but does not replace, thorough audit procedures</li>
            </ul>
            <p className="font-semibold">Always apply professional skepticism and expertise when interpreting these results.</p>
          </AlertDialogDescription>
        </AlertDialogHeader>
        <AlertDialogAction onClick={handleDisclaimerClose} className="mt-4 w-full">
          Understood
        </AlertDialogAction>
      </AlertDialogContent>
    </AlertDialog>
  );

  const getRiskLevel = (score) => {
    if (score >= 80) return 'Critical';
    if (score >= 60) return 'High';
    if (score >= 40) return 'Moderate';
    if (score >= 20) return 'Low';
    return 'Negligible';
  };

  const renderWaitingState = () => (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 0.5 }}
      className="flex flex-col items-center justify-center h-64"
    >
      <motion.div
        animate={{ rotate: 360, scale: [1, 1.1, 1] }}
        transition={{ duration: 2, repeat: Infinity, ease: "easeInOut" }}
      >
        <Bot className="text-purple-500 w-16 h-16 mb-4" />
      </motion.div>
      <h3 className="text-xl font-semibold mb-2">LokCO Co-pilot: Audit Ninja in Training!</h3>
      <p className="text-gray-600 mb-4 text-center max-w-md">
        "Feed me documents, and I'll crunch compliance faster than you can say 'regulatory requirements'! 🚀📊"
      </p>
      <p className="text-sm text-gray-500 italic">
        (LokCO is doing digital pushups while waiting. Upload those docs to see it in action!)
      </p>
      <div className="flex items-center mt-4">
        <Clock className="mr-2 text-blue-500" />
        <span>Audit Start Date: {auditStartDate ? new Date(auditStartDate).toLocaleDateString() : 'Not set'}</span>
      </div>
    </motion.div>
  );

  const renderAnalysis = () => (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 0.5 }}
      className="space-y-6"
    >
      <div className="flex items-center justify-between mb-4">
        <h2 className="text-2xl font-bold">AI-Generated Audit Analysis</h2>
        <Button onClick={() => setIsDisclaimerOpen(true)} variant="outline" size="sm">
          <Info className="mr-2" size={16} />
          About this analysis
        </Button>
      </div>

      <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
      <Card>
        <CardHeader>
          <h3 className="text-xl font-semibold flex items-center">
            Overall Risk Score
            <Bot className="ml-2 text-purple-500" size={20} />
          </h3>
        </CardHeader>
        <CardContent className="flex flex-col items-center justify-center">
          <div className="relative w-48 h-48">
            <Progress
              circular
              value={simulatedAnalysis.overallRiskScore}
              size={192}
              strokeWidth={16}
              showPercentage={false}
              color="bg-purple-600"
              bgColor="bg-gray-200"
            />
            <div className="absolute inset-0 flex flex-col items-center justify-center">
              <span className="text-4xl font-bold text-purple-600">
                {simulatedAnalysis.overallRiskScore}%
              </span>
              <span className="text-sm text-gray-500 mt-1">Risk Score</span>
            </div>
          </div>
          <div className="mt-4 text-center">
            <p className="font-semibold">Risk Level: {getRiskLevel(simulatedAnalysis.overallRiskScore)}</p>
            <p className="text-sm text-gray-500">Based on {simulatedAnalysis.keyFindings.length} key findings</p>
          </div>
        </CardContent>
      </Card>
        <Card>
          <CardHeader>
            <h3 className="text-xl font-semibold">Risk Distribution</h3>
          </CardHeader>
          <CardContent>
            <div className="h-64">
              <ResponsiveContainer width="100%" height="100%">
                <PieChart>
                  <Pie
                    data={simulatedAnalysis.riskCategories}
                    dataKey="value"
                    nameKey="name"
                    cx="50%"
                    cy="50%"
                    outerRadius={80}
                    fill="#8884d8"
                    label
                    aria-label="Risk distribution chart"
                  >
                    {simulatedAnalysis.riskCategories.map((entry, index) => (
                      <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                    ))}
                  </Pie>
                  <Tooltip />
                  <Legend />
                </PieChart>
              </ResponsiveContainer>
            </div>
          </CardContent>
        </Card>
      </div>

      <Card>
        <CardHeader>
          <h3 className="text-xl font-semibold">Key Findings</h3>
        </CardHeader>
        <CardContent>
          <ul className="space-y-2">
            {simulatedAnalysis.keyFindings.map((finding, index) => (
              <li key={index} className="flex items-start">
                {finding.severity === 'high' ? (
                  <AlertTriangle className="mr-2 text-red-500 flex-shrink-0" />
                ) : finding.severity === 'medium' ? (
                  <AlertCircle className="mr-2 text-yellow-500 flex-shrink-0" />
                ) : (
                  <CheckCircle className="mr-2 text-green-500 flex-shrink-0" />
                )}
                <span>{finding.description}</span>
                <Badge variant={finding.severity} className="ml-2">
                  {finding.severity}
                </Badge>
              </li>
            ))}
          </ul>
        </CardContent>
      </Card>

      <Card>
        <CardHeader>
          <h3 className="text-xl font-semibold">Compliance Scores</h3>
        </CardHeader>
        <CardContent>
          <div className="h-64">
            <ResponsiveContainer width="100%" height="100%">
              <BarChart data={simulatedAnalysis.complianceScores} aria-label="Compliance scores chart">
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="name" />
                <YAxis />
                <Tooltip />
                <Legend />
                <Bar dataKey="score" fill="#8884d8" />
              </BarChart>
            </ResponsiveContainer>
          </div>
        </CardContent>
      </Card>

      <Card>
        <CardHeader>
          <h3 className="text-xl font-semibold">Recommended Actions</h3>
        </CardHeader>
        <CardContent>
          <ul className="space-y-2">
            {simulatedAnalysis.recommendedActions.map((action, index) => (
              <li key={index} className="flex items-center">
                <FileText className="mr-2 text-blue-500" />
                <span>{action}</span>
              </li>
            ))}
          </ul>
        </CardContent>
      </Card>


  <AlertDialog open={isDisclaimerOpen} onOpenChange={setIsDisclaimerOpen}>
  <AlertDialogContent>
    <AlertDialogCloseButton />
    <AlertDialogHeader>
      <AlertDialogTitle>About this AI-Generated Analysis</AlertDialogTitle>
      <AlertDialogDescription>
        <p className="mb-2">This analysis is generated by an AI model based on the provided audit data and is intended to serve as a starting point for your audit process. It should not be considered as absolute fact or a replacement for professional judgment.</p>
        <p className="mb-2">Key points to consider:</p>
        <ul className="list-disc pl-5 mb-2">
          <li>The analysis helps prioritize areas for further investigation</li>
          <li>All findings and recommendations should be verified by qualified auditors</li>
          <li>The risk scores and distributions are estimates based on available data</li>
          <li>This tool complements, but does not replace, thorough audit procedures</li>
        </ul>
        <p>Always apply professional skepticism and expertise when interpreting these results.</p>
      </AlertDialogDescription>
    </AlertDialogHeader>
    <AlertDialogAction onClick={() => setIsDisclaimerOpen(false)}>Understood</AlertDialogAction>
  </AlertDialogContent>
</AlertDialog>
</motion.div>
);

if (isLoading) {
return (
<div className="flex items-center justify-center h-64">
  <Bot className="animate-spin h-16 w-16 text-purple-500" />
  <p className="ml-4 text-lg">Generating analysis...</p>
</div>
);
}

if (error) {
return (
<div className="flex items-center justify-center h-64 text-red-500">
  <AlertTriangle className="mr-2" />
  <p>{error}</p>
</div>
);
}

return (
  <div>
    <AnimatePresence>
      {isDisclaimerOpen && renderDisclaimer()}
      {showAnalysisContent && (
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          transition={{ duration: 0.5 }}
        >
          {!isAnalysisComplete || !simulatedAnalysis ? renderWaitingState() : renderAnalysis()}
        </motion.div>
      )}
    </AnimatePresence>
  </div>
);
};

export default AnalysisTab;