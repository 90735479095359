import React from 'react';
import { cn } from "../../utils/cn";
import { AlertCircle, CheckCircle, Info, X } from 'lucide-react';

const Alert = React.forwardRef(({ className, variant = "default", children, ...props }, ref) => {
  const variants = {
    default: "bg-gray-100 text-gray-800",
    destructive: "bg-red-50 text-red-800",
    success: "bg-green-50 text-green-800",
    warning: "bg-yellow-50 text-yellow-800",
    info: "bg-blue-50 text-blue-800",
  };

  const icons = {
    default: Info,
    destructive: AlertCircle,
    success: CheckCircle,
    warning: AlertCircle,
    info: Info,
  };

  const Icon = icons[variant];

  return (
    <div
      ref={ref}
      role="alert"
      className={cn(
        "relative w-full rounded-lg border p-4",
        variants[variant],
        className
      )}
      {...props}
    >
      <div className="flex items-start gap-4">
        {Icon && <Icon className="h-5 w-5 mt-0.5 flex-shrink-0" />}
        <div className="flex-1">{children}</div>
      </div>
    </div>
  );
});
Alert.displayName = "Alert";

const AlertDescription = React.forwardRef(({ className, ...props }, ref) => (
  <div
    ref={ref}
    className={cn("text-sm [&_p]:leading-relaxed", className)}
    {...props}
  />
));
AlertDescription.displayName = "AlertDescription";

export { Alert, AlertDescription };