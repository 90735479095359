import React, { forwardRef } from 'react';
import ReactDatePicker from 'react-datepicker';
import { Input } from './Input';
import { CalendarIcon } from 'lucide-react';
import 'react-datepicker/dist/react-datepicker.css';

const CustomInput = forwardRef(({ value, onClick, placeholder }, ref) => (
  <div className="relative" onClick={onClick}>
    <Input
      value={value}
      placeholder={placeholder}
      ref={ref}
      readOnly
      className="w-full pr-10 cursor-pointer"
    />
    <CalendarIcon className="absolute right-3 top-1/2 transform -translate-y-1/2 text-gray-400 w-5 h-5 pointer-events-none" />
  </div>
));

CustomInput.displayName = 'CustomInput';

const DatePicker = ({
  selected,
  onChange,
  placeholder,
  minDate,
  maxDate,
  filterDate,
  dateFormat = "MMM d, yyyy",
  isClearable = true,
  showYearDropdown = false,
  showMonthDropdown = false,
  dropdownMode = "select",
  ...props
}) => (
  <ReactDatePicker
    selected={selected}
    onChange={onChange}
    customInput={<CustomInput />}
    dateFormat={dateFormat}
    isClearable={isClearable}
    showYearDropdown={showYearDropdown}
    showMonthDropdown={showMonthDropdown}
    dropdownMode={dropdownMode}
    minDate={minDate}
    maxDate={maxDate}
    filterDate={filterDate}
    placeholderText={placeholder}
    {...props}
  />
);

export default DatePicker;
