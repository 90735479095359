import React, { useState } from 'react';

export const Accordion = ({ children, className, ...props }) => (
  <div className={`space-y-2 ${className}`} {...props}>
    {children}
  </div>
);

export const AccordionItem = ({ children, className, ...props }) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <div className={`border rounded-md ${className}`} {...props}>
      {React.Children.map(children, child =>
        React.cloneElement(child, { isOpen, setIsOpen })
      )}
    </div>
  );
};

export const AccordionTrigger = ({ children, isOpen, setIsOpen, className, ...props }) => (
  <button
    onClick={() => setIsOpen(!isOpen)}
    className={`flex justify-between w-full px-4 py-2 text-left font-medium ${className}`}
    {...props}
  >
    {children}
    <span className={`transform transition-transform duration-200 ${isOpen ? 'rotate-180' : ''}`}>
      ▼
    </span>
  </button>
);

export const AccordionContent = ({ children, isOpen, className, ...props }) => (
  <div className={`px-4 py-2 ${isOpen ? '' : 'hidden'} ${className}`} {...props}>
    {children}
  </div>
);