import React, { useState } from 'react';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { Input } from './ui/Input';
import { Select } from './ui/Select';
import { Checkbox } from './ui/Checkbox';
import { Button } from './ui/Button';

const FormBuilder = ({ fields, onUpdate }) => {
  const [formFields, setFormFields] = useState(fields || []);

  const onDragEnd = (result) => {
    if (!result.destination) return;

    const items = Array.from(formFields);
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);

    setFormFields(items);
    onUpdate(items);
  };

  const addField = (type) => {
    const newField = {
      id: Date.now().toString(),
      type,
      label: `New ${type} field`,
      required: false,
    };
    const updatedFields = [...formFields, newField];
    setFormFields(updatedFields);
    onUpdate(updatedFields);
  };

  const updateField = (id, updates) => {
    const updatedFields = formFields.map(field =>
      field.id === id ? { ...field, ...updates } : field
    );
    setFormFields(updatedFields);
    onUpdate(updatedFields);
  };

  const removeField = (id) => {
    const updatedFields = formFields.filter(field => field.id !== id);
    setFormFields(updatedFields);
    onUpdate(updatedFields);
  };

  const renderField = (field) => {
    switch (field.type) {
      case 'text':
        return <Input placeholder={field.label} />;
      case 'select':
        return (
          <Select>
            {field.options?.map(option => (
              <option key={option} value={option}>{option}</option>
            ))}
          </Select>
        );
      case 'checkbox':
        return <Checkbox label={field.label} />;
      default:
        return null;
    }
  };

  return (
    <div className="form-builder">
      <div className="form-fields">
        <DragDropContext onDragEnd={onDragEnd}>
          <Droppable droppableId="form-fields">
            {(provided) => (
              <div {...provided.droppableProps} ref={provided.innerRef}>
                {formFields.map((field, index) => (
                  <Draggable key={field.id} draggableId={field.id} index={index}>
                    {(provided) => (
                      <div
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                        className="form-field p-2 mb-2 bg-white rounded shadow"
                      >
                        <Input
                          value={field.label}
                          onChange={(e) => updateField(field.id, { label: e.target.value })}
                          className="mb-2"
                        />
                        <Button onClick={() => removeField(field.id)} variant="destructive" size="sm">Remove</Button>
                      </div>
                    )}
                  </Draggable>
                ))}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>
      </div>
      <div className="field-types mt-4">
        <Button onClick={() => addField('text')} variant="outline" className="mr-2">Add Text Field</Button>
        <Button onClick={() => addField('select')} variant="outline" className="mr-2">Add Select Field</Button>
        <Button onClick={() => addField('checkbox')} variant="outline">Add Checkbox</Button>
      </div>
      <div className="form-preview mt-8">
        <h3 className="text-lg font-semibold mb-4">Form Preview</h3>
        {formFields.map(field => (
          <div key={field.id} className="preview-field mb-4">
            <label className="block mb-2 font-medium">{field.label}</label>
            {renderField(field)}
          </div>
        ))}
      </div>
    </div>
  );
};

export default FormBuilder;