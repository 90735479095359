import React, { useState, useEffect, useCallback, useMemo, useRef } from 'react';
import { Card, CardHeader, CardTitle, CardContent } from './ui/Card';
import { Badge } from './ui/Badge';
import { Progress } from './ui/Progress';
import { MessageSquare, Info, Brain, Send, Loader, X, Paperclip } from 'lucide-react';
import { motion, AnimatePresence } from 'framer-motion';
import { Button } from './ui/Button';
import BaseProcessDesign from './BaseProcessDesign';
import RiskAndControlAssessmentSection from './sections/RiskAndControlAssessmentSection';
import ComplianceActivitiesSection from './sections/ComplianceActivitiesSection';
import PerformanceMetricsSection from './sections/PerformanceMetricsSection';
import useAISuggestions from './useAISuggestions';

const ProcessDesignStep = ({
  selectedProcess,
  riskProfile,
  maturityLevel,
  initialData = {},
  onUpdate,
}) => {
  const [activeTab, setActiveTab] = useState('base');
  const [baseStep, setBaseStep] = useState(0);
  const [processData, setProcessData] = useState(initialData);
  const [showLokCO, setShowLokCO] = useState(false);
  const [showTip, setShowTip] = useState(false);
  const [input, setInput] = useState('');
  const [messages, setMessages] = useState([
    {
      text: `Hello! I'm LokCO, your Compliance Co-pilot. How can I assist you with designing your ${selectedProcess} process?`,
      sender: 'LokCO',
    },
  ]);
  const [isAnalyzing, setIsAnalyzing] = useState(false);
  const fileInputRef = useRef(null);
  const chatContainerRef = useRef(null);

  const { aiSuggestions, updateAISuggestions, isLoading, error } = useAISuggestions();

  const baseSteps = useMemo(
    () => [
      'Objectives',
      'Regulatory Requirements',
      'Process Scope',
      'Process Exclusions',
      'Key Stakeholders',
      'Involved Departments',
    ],
    []
  );

  useEffect(() => {
    const savedData = localStorage.getItem('processDesignData');
    if (savedData) {
      setProcessData(JSON.parse(savedData));
    } else if (Object.keys(initialData).length > 0) {
      setProcessData(initialData);
    } else {
      setProcessData({
        base: {},
        risksAndControls: { risks: [], controls: [] },
        activities: [],
        metrics: [],
      });
    }
  }, [initialData]);

  useEffect(() => {
    localStorage.setItem('processDesignData', JSON.stringify(processData));
  }, [processData]);

  useEffect(() => {
    if (chatContainerRef.current) {
      chatContainerRef.current.scrollTop = chatContainerRef.current.scrollHeight;
    }
  }, [messages]);

  const fetchSuggestions = useCallback(async () => {
    await updateAISuggestions({
      processType: selectedProcess,
      riskProfile,
      maturityLevel,
      additionalData: {
        currentStep: activeTab,
        baseStep: activeTab === 'base' ? baseSteps[baseStep] : null,
        currentData: processData,
      },
    });
  }, [
    updateAISuggestions,
    selectedProcess,
    riskProfile,
    maturityLevel,
    activeTab,
    baseStep,
    baseSteps,
    processData,
  ]);

  useEffect(() => {
    fetchSuggestions();
  }, [fetchSuggestions]);

  const calculateCompletionPercentage = useCallback(
    (section) => {
      const data = processData[section];
      if (!data) return 0;

      const requiredFields =
        section === 'base'
          ? [
              'ObjectivesStep',
              'RegulatoryRequirementsStep',
              'ProcessScopeStep',
              'ProcessExclusionsStep',
              'StakeholdersStep',
              'DepartmentsStep',
            ]
          : [];

      const totalFields = requiredFields.length || Object.keys(data).length;
      const filledFields = requiredFields.length
        ? requiredFields.filter((field) => data[field] && data[field].length > 0).length
        : Object.values(data).filter((value) => value && value.length > 0).length;

      return Math.floor((filledFields / totalFields) * 100);
    },
    [processData]
  );

  const handleDataUpdate = useCallback(
    (sectionName, data) => {
      setProcessData((prevData) => {
        const updatedData = {
          ...prevData,
          [sectionName]: data,
        };

        localStorage.setItem('processDesignData', JSON.stringify(updatedData));

        if (sectionName === 'risksAndControls' || sectionName === 'base') {
          updateAISuggestions({
            processType: selectedProcess,
            riskProfile,
            maturityLevel,
            additionalData: {
              currentStep: sectionName,
              currentData: updatedData,
            },
          });
        }

        if (onUpdate) {
          onUpdate(updatedData);
        }

        return updatedData;
      });
    },
    [selectedProcess, riskProfile, maturityLevel, updateAISuggestions, onUpdate]
  );

  const handleRisksAndControlsUpdate = useCallback(
    (data) => handleDataUpdate('risksAndControls', data),
    [handleDataUpdate]
  );

  const handleActivitiesUpdate = useCallback(
    (data) => handleDataUpdate('activities', data),
    [handleDataUpdate]
  );

  const handleMetricsUpdate = useCallback(
    (data) => handleDataUpdate('metrics', data),
    [handleDataUpdate]
  );

  const handleNextBase = useCallback(() => {
    if (baseStep < baseSteps.length - 1) {
      setBaseStep(baseStep + 1);
    } else {
      setActiveTab('risks');
    }
  }, [baseStep, baseSteps.length]);

  const handlePreviousBase = useCallback(() => {
    if (baseStep > 0) {
      setBaseStep(baseStep - 1);
    }
  }, [baseStep]);

  const sendMessage = useCallback(
    (text) => {
      setMessages((prev) => [...prev, { text, sender: 'User' }]);
      setInput('');
      setIsAnalyzing(true);

      setTimeout(() => {
        setIsAnalyzing(false);
        setMessages((prev) => [
          ...prev,
          {
            text: `I understand you're asking about "${text}" in the context of ${selectedProcess}. How can I provide more specific guidance?`,
            sender: 'LokCO',
          },
        ]);
      }, 2000);
    },
    [selectedProcess]
  );

  const handleSend = useCallback(() => {
    if (input.trim()) {
      sendMessage(input);
    }
  }, [input, sendMessage]);

  const handleKeyPress = useCallback(
    (e) => {
      if (e.key === 'Enter' && !e.shiftKey) {
        e.preventDefault();
        handleSend();
      }
    },
    [handleSend]
  );

  const handleBaseUpdate = useCallback(
    (data) => handleDataUpdate('base', data),
    [handleDataUpdate]
  );

  const tabComponents = useMemo(
    () => ({
      base: (
        <BaseProcessDesign
          step={baseStep}
          steps={baseSteps}
          selectedProcess={selectedProcess}
          riskProfile={riskProfile}
          maturityLevel={maturityLevel}
          aiSuggestions={aiSuggestions?.stepSpecific}
          onUpdate={handleBaseUpdate}
          initialData={processData.base || {}}
          onNext={handleNextBase}
          onPrevious={handlePreviousBase}
          isLoading={isLoading}
        />
      ),
      risks: (
        <RiskAndControlAssessmentSection
          onUpdate={handleRisksAndControlsUpdate}
          initialRisks={processData.risksAndControls?.risks || []}
          initialControls={processData.risksAndControls?.controls || []}
          aiSuggestions={aiSuggestions}
          isLoading={isLoading}
          riskProfile={riskProfile}
          maturityLevel={maturityLevel}
        />
      ),
      activities: (
        <ComplianceActivitiesSection
          activities={processData.activities || []}
          onUpdate={handleActivitiesUpdate}
          aiSuggestions={aiSuggestions?.complianceActivities}
          processType={selectedProcess}
          isLoading={isLoading}
        />
      ),
      metrics: (
        <PerformanceMetricsSection
          metrics={processData.metrics || []}
          onUpdate={handleMetricsUpdate}
          aiSuggestions={aiSuggestions?.kpis}
          processType={selectedProcess}
          isLoading={isLoading}
        />
      ),
    }),
    [
      baseStep,
      baseSteps,
      selectedProcess,
      riskProfile,
      maturityLevel,
      aiSuggestions,
      handleBaseUpdate,
      handleRisksAndControlsUpdate,
      handleActivitiesUpdate,
      handleMetricsUpdate,
      processData,
      handleNextBase,
      handlePreviousBase,
      isLoading,
    ]
  );

  return (
    <div className="flex flex-col h-screen">
      {/* Header */}
      <header className="bg-purple-700 text-white p-4">
        <h1 className="text-2xl font-bold">Design Your {selectedProcess} Process</h1>
        <p className="text-sm mt-2">
          AI-powered suggestions based on {riskProfile} risk profile and {maturityLevel} maturity level
        </p>
      </header>

      {/* Main content */}
      <div className="flex flex-1 overflow-hidden">
        {/* Side Navigation Panel */}
        <nav className="w-1/4 bg-gray-100 p-4">
          <div className="space-y-2">
            {Object.keys(tabComponents).map((tab) => (
              <button
                key={tab}
                onClick={() => setActiveTab(tab)}
                className={`w-full text-left px-4 py-2 rounded-md transition-all duration-200 ease-in-out ${
                  activeTab === tab
                    ? 'bg-purple-100 text-purple-700 font-semibold'
                    : 'text-gray-700 hover:bg-gray-100'
                }`}
              >
                {tab.charAt(0).toUpperCase() + tab.slice(1)}
                <div className="mt-1">
                  <Progress value={calculateCompletionPercentage(tab)} />
                </div>
              </button>
            ))}
          </div>
        </nav>

        {/* Main panel */}
        <main className="flex-1 p-6 overflow-y-auto">
          <Card>
            <CardHeader>
              <CardTitle className="text-2xl font-bold text-purple-700">
                {activeTab === 'base'
                  ? baseSteps[baseStep]
                  : activeTab.charAt(0).toUpperCase() + activeTab.slice(1)}
              </CardTitle>
              <p className="text-sm text-gray-600 mt-2">
                Our AI-powered system has generated tailored suggestions based on your risk profile (
                <Badge variant="outline">{riskProfile}</Badge>) and maturity level (
                <Badge variant="outline">{maturityLevel}</Badge>). These recommendations are derived
                from industry best practices, regulatory requirements, and a risk-based approach to
                compliance.
              </p>
            </CardHeader>
            <CardContent>
              {error ? (
                <div className="text-red-500">Error: {error}</div>
              ) : (
                tabComponents[activeTab]
              )}
            </CardContent>
          </Card>

          {/* Floating LokCO button */}
          <button
            onClick={() => setShowLokCO(true)}
            className="fixed bottom-4 right-4 bg-purple-600 text-white p-3 rounded-full shadow-lg hover:bg-purple-700 transition-colors duration-200"
          >
            <MessageSquare size={24} />
          </button>

          {/* Tip button */}
          <button
            onClick={() => setShowTip(true)}
            className="fixed bottom-4 left-4 bg-blue-500 text-white p-2 rounded-full shadow-lg hover:bg-blue-600 transition-colors duration-200"
          >
            <Info size={24} />
          </button>
        </main>
      </div>

      {/* LokCO Chat Interface */}
      <AnimatePresence>
        {showLokCO && (
          <motion.div
            initial={{ opacity: 0, y: 50 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: 50 }}
            className="fixed bottom-20 right-4 w-[350px] h-[450px] bg-white rounded-lg shadow-xl overflow-hidden"
          >
            <div className="flex flex-col h-full">
              <div className="flex justify-between items-center p-4 bg-gradient-to-r from-blue-600 to-blue-800 text-white">
                <div className="flex items-center">
                  <Brain className="w-6 h-6 mr-2" />
                  <h4 className="text-lg font-semibold">LokCO</h4>
                </div>
                <button
                  onClick={() => setShowLokCO(false)}
                  className="text-white hover:text-gray-200 transition-colors duration-200"
                >
                  <X className="w-5 h-5" />
                </button>
              </div>
              <div
                ref={chatContainerRef}
                className="flex-1 overflow-auto p-4 space-y-4 bg-gray-50"
              >
                {messages.map((msg, index) => (
                  <div
                    key={index}
                    className={`flex ${
                      msg.sender === 'User' ? 'justify-end' : 'justify-start'
                    } animate-fade-in-up`}
                  >
                    <div
                      className={`max-w-[75%] p-3 rounded-lg shadow-md ${
                        msg.sender === 'User'
                          ? 'bg-blue-500 text-white'
                          : 'bg-white text-gray-500'
                      }`}
                    >
                      <p className="text-sm">{msg.text}</p>
                    </div>
                  </div>
                ))}
                {isAnalyzing && (
                  <div className="flex items-center justify-center animate-pulse">
                    <Loader className="w-5 h-5 text-blue-500 animate-spin" />
                    <span className="ml-2 text-sm text-gray-500">LokCO is pondering...</span>
                  </div>
                )}
              </div>
              <div className="p-4 bg-white border-t flex items-center">
                <textarea
                  value={input}
                  onChange={(e) => setInput(e.target.value)}
                  onKeyPress={handleKeyPress}
                  placeholder="Ask LokCO..."
                  className="flex-1 border rounded-l-md px-3 py-2 focus:outline-none focus:ring-2 focus:ring-blue-500 resize-none text-sm"
                  rows="2"
                />
                <Button
                  onClick={() => fileInputRef.current.click()}
                  className="px-3 py-2 bg-gray-200 hover:bg-gray-300 text-gray-700 transition-colors duration-200"
                >
                  <Paperclip className="w-5 h-5" />
                </Button>
                <input
                  type="file"
                  ref={fileInputRef}
                  onChange={(e) => {
                    /* Handle file upload */
                  }}
                  className="hidden"
                />
                <Button
                  onClick={handleSend}
                  className="px-4 py-2 bg-blue-600 hover:bg-blue-700 text-white rounded-r-md transition-colors duration-200"
                >
                  <Send className="w-5 h-5" />
                </Button>
              </div>
            </div>
          </motion.div>
        )}
      </AnimatePresence>

      {/* Contextual Tip */}
      <AnimatePresence>
        {showTip && (
          <motion.div
            initial={{ opacity: 0, x: -50 }}
            animate={{ opacity: 1, x: 0 }}
            exit={{ opacity: 0, x: -50 }}
            className="fixed bottom-20 left-4 w-64 bg-blue-100 rounded-lg shadow-lg p-4"
          >
            <button
              onClick={() => setShowTip(false)}
              className="float-right text-gray-500"
            >
              &times;
            </button>
            <h4 className="font-semibold mb-2 text-gray-600">
              {activeTab === 'base'
                ? baseSteps[baseStep]
                : activeTab.charAt(0).toUpperCase() + activeTab.slice(1)}{' '}
              Tip
            </h4>
            <p className="text-sm text-gray-700">
              {activeTab === 'base' &&
                {
                  Objectives:
                    'Define clear, measurable objectives aligned with your compliance goals.',
                  'Regulatory Requirements':
                    'Identify and list all relevant regulations and standards applicable to your organization.',
                  'Process Scope':
                    "Clearly define the boundaries of your compliance process, including what is and isn't covered.",
                  'Process Exclusions':
                    'Specify any areas or activities that are intentionally excluded from this compliance process.',
                  'Key Stakeholders':
                    'Identify all internal and external parties who have a vested interest in or influence on the compliance process.',
                  'Involved Departments':
                    'List all departments that play a role in executing or supporting the compliance process.',
                }[baseSteps[baseStep]]}
              {activeTab === 'risks' &&
                'Identify and prioritize risks based on likelihood and potential impact. Associate controls directly with risks for effective risk management.'}
              {activeTab === 'activities' &&
                'Ensure your compliance activities cover all aspects of your regulatory requirements.'}
              {activeTab === 'metrics' &&
                'Choose metrics that provide actionable insights into your compliance performance.'}
            </p>
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
};

export default ProcessDesignStep;
