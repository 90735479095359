import React, { useState, forwardRef } from 'react';
import { 
  Bot, 
  Workflow, 
  Brain, 
  ChevronDown, 
  ChevronUp,
  Zap, 
  ShieldCheck, 
  Clock,
  CheckCircle,
} from 'lucide-react';
import { motion } from 'framer-motion';

const DetailedUseCase = ({ title, icon: Icon, challenge, howWeHelp, benefits, color }) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <motion.div 
      initial={{ opacity: 0, y: 20 }}
      whileInView={{ opacity: 1, y: 0 }}
      viewport={{ once: true }}
      className={`mb-8 rounded-xl overflow-hidden border border-${color}-100 hover:shadow-lg transition-all duration-300`}
    >
      <div 
        className={`flex items-center justify-between p-6 bg-${color}-50 cursor-pointer group`}
        onClick={() => setIsOpen(!isOpen)}
      >
        <div className="flex items-center">
          <div className={`p-2 rounded-lg bg-${color}-100 mr-4`}>
            <Icon className={`h-6 w-6 text-${color}-600`} />
          </div>
          <h3 className="text-xl font-semibold text-gray-900">{title}</h3>
        </div>
        <div className={`p-2 rounded-full bg-${color}-100/50 group-hover:bg-${color}-100 transition-colors duration-200`}>
          {isOpen ? <ChevronUp className={`w-5 h-5 text-${color}-600`} /> : <ChevronDown className={`w-5 h-5 text-${color}-600`} />}
        </div>
      </div>
      
      {isOpen && (
        <div className="p-6 text-gray-600 bg-white">
          <div className="space-y-6">
            <div>
              <h4 className="font-semibold mb-2 text-gray-800">Challenge:</h4>
              <p>{challenge}</p>
            </div>
            
            <div>
              <h4 className="font-semibold mb-2 text-gray-800">Solution:</h4>
              <div className="space-y-3">
                {howWeHelp.map((item, index) => (
                  <div key={index} className="flex items-start">
                    <CheckCircle className={`w-5 h-5 text-${color}-500 mr-2 flex-shrink-0 mt-0.5`} />
                    <span>{item}</span>
                  </div>
                ))}
              </div>
            </div>

            <div>
              <h4 className="font-semibold mb-3 text-gray-800">Key Benefits:</h4>
              <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
                {benefits.map((benefit, index) => (
                  <div key={index} className={`p-3 rounded-lg bg-${color}-50/50 flex items-center`}>
                    <benefit.icon className={`w-5 h-5 text-${color}-500 mr-2 flex-shrink-0`} />
                    <span className="text-sm">{benefit.text}</span>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      )}
    </motion.div>
  );
};

const UseCases = forwardRef((props, ref) => {
  const useCases = [
    {
      title: "Process Design & Implementation at Scale",
      icon: Workflow,
      color: "purple",
      challenge: "Global pharmaceutical companies face the challenge of implementing consistent compliance processes across multiple regions, leading to inefficiencies and potential regulatory risks.",
      howWeHelp: [
        "Leverage AI-powered Process Design Wizard for standardized templates",
        "Deploy visual workflow builder for rapid process implementation",
        "Enable real-time collaboration for process refinement",
        "Automate deployment across regional teams"
      ],
      benefits: [
        { icon: Clock, text: "Up to 85% faster process design" },
        { icon: ShieldCheck, text: "100% process consistency" },
        { icon: Zap, text: "60% reduced implementation time" }
      ]
    },
    {
      title: "Continuous Compliance with Mock Audits",
      icon: Bot,
      color: "blue",
      challenge: "Organizations implementing ISO 37301 compliance management systems while developing AI solutions face complex audit requirements across multiple standards (ISO 37301, GDPR, EU AI Act). Manual preparation and siloed assessments make it difficult to maintain continuous compliance.",
      howWeHelp: [
        "Generate ISO 37301-aligned mock audit scenarios with AI assistance",
        "Cross-reference compliance evidence across ISO 37301 and GDPR requirements",
        "Provide AI Act-specific conformity assessment preparation",
        "Create real-time compliance dashboards with integrated risk indicators",
        "Track audit findings and improvement actions across standards"
      ],
      benefits: [
        { icon: Clock, text: "95% audit readiness" },
        { icon: ShieldCheck, text: "Up to 70% faster audit preparation" },
        { icon: Zap, text: "Automated evidence collection" }
      ]
    },
    {
      title: "AI Drug Discovery: Complex Compliance Made Simple",
      icon: Brain,
      color: "green",
      challenge: "An AI drug discovery company developing large language models for protein folding and drug candidate screening needs to ensure compliance with EU AI Act while maintaining rapid innovation cycles.",
      howWeHelp: [
        "Automated classification of AI systems under EU AI Act criteria (High-risk assessment for drug discovery models)",
        "Built-in compliance workflows for required testing protocols and documentation",
        "Automated tracking of model development practices against regulatory requirements",
        "Real-time monitoring of bias and performance drift in production models",
        "Compliance-ready documentation for regulatory submissions and audits"
      ],
      benefits: [
        { icon: Clock, text: "Ready for EU AI Act enforcement" },
        { icon: ShieldCheck, text: "Compliant AI development pipeline" },
        { icon: Zap, text: "Automated technical documentation" }
      ]
    }
  ];

  return (
    <section ref={ref} className="py-24 bg-gradient-to-b from-gray-50 to-white">
      <div className="max-w-7xl mx-auto px-6 lg:px-8">
        <motion.div 
          className="text-center mb-16"
          initial={{ opacity: 0, y: 20 }}
          whileInView={{ opacity: 1, y: 0 }}
          viewport={{ once: true }}
        >
          <div className="text-center mb-16">
            <span className="inline-flex items-center px-3 py-1 rounded-full text-sm font-medium bg-blue-100 text-blue-800 mb-4">
              <Brain className="w-4 h-4 mr-2" />
              Lokatial In Action
            </span>
            <h2 className="text-4xl font-bold text-gray-900 mb-6">
            <span className="text-transparent bg-clip-text bg-gradient-to-r from-purple-600 to-blue-600">
              Transform
            </span>
            {" "} Your Compliance Operations
            </h2>
            <p className="text-xl text-gray-600 max-w-3xl mx-auto">
              Discover how LOKATIAL can help organizations like yours streamline compliance processes
            </p>
          </div>
        </motion.div>

        <div className="space-y-6">
          {useCases.map((useCase, index) => (
            <DetailedUseCase key={index} {...useCase} />
          ))}
        </div>
      </div>
    </section>
  );
});

UseCases.displayName = 'UseCases';

export default UseCases;