// RiskAssessmentForm.js
import React, { useState } from 'react';

const RiskAssessmentForm = ({ selectedRisk, onAddAssessment }) => {
  const [riskLevel, setRiskLevel] = useState('Low');
  const [strategies, setStrategies] = useState('');

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!selectedRisk) return;

    const mitigationStrategies = strategies
      .split('\n')
      .map((s) => s.trim())
      .filter((s) => s !== '');

    if (mitigationStrategies.length === 0) {
      alert('Please enter at least one mitigation strategy.');
      return;
    }

    onAddAssessment({
      riskCategory: selectedRisk,
      riskLevel,
      mitigationStrategies,
    });

    // Reset form
    setRiskLevel('Low');
    setStrategies('');
  };

  return (
    <form onSubmit={handleSubmit} className="mb-8">
      <h4 className="text-xl font-semibold mb-4 text-blue-700">Add Risk Assessment</h4>
      <div className="mb-4">
        <label className="block text-gray-700 mb-2">Risk Level</label>
        <select
          value={riskLevel}
          onChange={(e) => setRiskLevel(e.target.value)}
          className="w-full p-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
          required
        >
          <option>Low</option>
          <option>Medium</option>
          <option>High</option>
        </select>
      </div>
      <div className="mb-4">
        <label className="block text-gray-700 mb-2">Mitigation Strategies (one per line)</label>
        <textarea
          value={strategies}
          onChange={(e) => setStrategies(e.target.value)}
          className="w-full p-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
          rows="4"
          required
        ></textarea>
      </div>
      <button
        type="submit"
        className="px-4 py-2 bg-green-600 text-white rounded-lg hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-green-500"
      >
        Add Assessment
      </button>
    </form>
  );
};

export default RiskAssessmentForm;
