import React, { useState } from 'react';
import { Tab, TabGroup, TabList, TabPanel, TabPanels } from '@headlessui/react';
import { CheckCircle, XCircle, ExternalLink } from 'lucide-react';

function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

const OperationContent = () => {
  const [quizStarted, setQuizStarted] = useState(false);
  const [currentQuestion, setCurrentQuestion] = useState(0);
  const [score, setScore] = useState(0);
  const [showResults, setShowResults] = useState(false);

  const content = {
    overview: {
      introduction: "Operation is the phase where compliance plans are put into action. It involves implementing processes, controls, and procedures to ensure compliance with regulatory requirements and organizational policies.",
      keyPoints: [
        "Implementing operational controls and procedures",
        "Conducting training and awareness programs",
        "Managing documentation and record-keeping",
        "Establishing incident response and reporting mechanisms",
        "Ensuring effective communication of compliance requirements"
      ],
      importance: "Effective operation of compliance processes is crucial for maintaining regulatory compliance, mitigating risks, and fostering a culture of integrity within the organization.",
      bestPractices: [
        "Integrate compliance controls into daily operations",
        "Provide ongoing compliance training to all employees",
        "Implement robust documentation and record-keeping systems",
        "Establish clear escalation procedures for compliance issues",
        "Regularly test and update incident response plans"
      ]
    },
    quiz: [
      {
        question: "What is a key component of operational compliance?",
        options: [
          "Annual risk assessment",
          "Daily implementation of compliance controls",
          "Quarterly financial reporting",
          "Bi-annual board meetings"
        ],
        correctAnswer: 1
      },
      {
        question: "How often should compliance training be conducted?",
        options: ["Once at hiring", "Annually", "Only when regulations change", "Ongoing, with regular refreshers"],
        correctAnswer: 3
      },
      {
        question: "What is the primary purpose of compliance documentation?",
        options: ["To satisfy auditors", "To provide evidence of compliance activities", "To increase paperwork", "To keep employees busy"],
        correctAnswer: 1
      }
    ],
    resources: [
      { title: "OCEG Red Book GRC Capability Model", url: "https://www.oceg.org/resources/grc-capability-model-red-book/" },
      { title: "Compliance Program Operations Handbook", url: "https://www.corporatecomplianceinsights.com/compliance-program-operations-handbook/" },
      { title: "Operational Compliance in Regulated Industries", url: "https://www2.deloitte.com/us/en/pages/regulatory/articles/operational-compliance-in-regulated-industries.html" }
    ]
  };

  // Logic for handling quiz submission and scoring
  const handleAnswerClick = (index) => {
    if (!quizStarted) return;
    if (index === content.quiz[currentQuestion].correctAnswer) {
      setScore(score + 1);
    }
    const nextQuestion = currentQuestion + 1;
    if (nextQuestion < content.quiz.length) {
      setCurrentQuestion(nextQuestion);
    } else {
      setShowResults(true);
    }
  };

  const startQuiz = () => {
    setQuizStarted(true);
    setCurrentQuestion(0);
    setScore(0);
    setShowResults(false);
  };

  return (
    <TabGroup>
      <TabList className="flex space-x-1 rounded-xl bg-blue-900/20 p-1">
        {['Overview', 'Key Points', 'Quiz', 'Resources'].map((tab) => (
          <Tab
            key={tab}
            className={({ selected }) =>
              classNames(
                'w-full rounded-lg py-2.5 text-sm font-medium leading-5 text-blue-700',
                'ring-white ring-opacity-60 ring-offset-2 ring-offset-blue-400 focus:outline-none focus:ring-2',
                selected
                  ? 'bg-white shadow'
                  : 'text-blue-100 hover:bg-white/[0.12] hover:text-white'
              )
            }
          >
            {tab}
          </Tab>
        ))}
      </TabList>
      <TabPanels className="mt-2">
        {/* Overview */}
        <TabPanel className="rounded-xl bg-white p-3">
          <p className="text-gray-700">{content.overview.introduction}</p>
          <h3 className="font-semibold mt-4 mb-2">Importance:</h3>
          <p className="text-gray-700">{content.overview.importance}</p>
        </TabPanel>
        {/* Key Points */}
        <TabPanel className="rounded-xl bg-white p-3">
          <h3 className="font-semibold mb-2">Key Points:</h3>
          <ul className="list-disc pl-5 space-y-2">
            {content.overview.keyPoints.map((point, index) => (
              <li key={index} className="text-gray-700">{point}</li>
            ))}
          </ul>
          <h3 className="font-semibold mt-4 mb-2">Best Practices:</h3>
          <ul className="list-disc pl-5 space-y-2">
            {content.overview.bestPractices.map((practice, index) => (
              <li key={index} className="text-gray-700">{practice}</li>
            ))}
          </ul>
        </TabPanel>
        {/* Quiz */}
        <TabPanel className="rounded-xl bg-white p-3">
          {!quizStarted ? (
            <button onClick={startQuiz} className="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600 transition duration-300">
              Start Quiz
            </button>
          ) : showResults ? (
            <div>
              <h2 className="text-xl font-semibold mb-4">Your Score: {score}/{content.quiz.length}</h2>
              <button onClick={startQuiz} className="bg-green-500 text-white px-4 py-2 rounded hover:bg-green-600 transition duration-300 mt-2">
                Retry Quiz
              </button>
            </div>
          ) : (
            <div>
              <h3 className="text-lg font-semibold mb-4">{content.quiz[currentQuestion].question}</h3>
              <ul className="space-y-2">
                {content.quiz[currentQuestion].options.map((option, index) => (
                  <li 
                    key={index} 
                    onClick={() => handleAnswerClick(index)} 
                    className="cursor-pointer p-2 rounded bg-gray-100 hover:bg-gray-200 transition duration-300"
                  >
                    {option}
                  </li>
                ))}
              </ul>
            </div>
          )}
        </TabPanel>
        {/* Resources */}
        <TabPanel className="rounded-xl bg-white p-3">
          <ul className="space-y-2">
            {content.resources.map((resource, index) => (
              <li key={index} className="flex items-center">
                <ExternalLink size={18} className="mr-2 text-blue-500" /> 
                <a href={resource.url} target="_blank" rel="noreferrer" className="text-blue-500 hover:underline">
                  {resource.title}
                </a>
              </li>
            ))}
          </ul>
        </TabPanel>
      </TabPanels>
    </TabGroup>
  );
};

export default OperationContent;