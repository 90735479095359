// predefinedComplianceActivities.js

export const predefinedComplianceActivities = [
    {
      id: 'CA001',
      name: 'Regular compliance audits and assessments',
      description: 'Conduct periodic internal audits to evaluate compliance with regulations and internal policies.',
      category: 'Audits and Inspections',
      frequency: 'Quarterly',
      priority: 'High'
    },
    {
      id: 'CA002',
      name: 'Monitoring regulatory changes',
      description: 'Stay informed about changes in healthcare, pharmaceutical, and biotech regulations that affect the organization.',
      category: 'Regulatory Reporting',
      frequency: 'Monthly',
      priority: 'High'
    },
    {
      id: 'CA003',
      name: 'Employee compliance training programs',
      description: 'Develop and deliver regular training sessions on compliance topics relevant to the organization.',
      category: 'Training and Education',
      frequency: 'Bi-annually',
      priority: 'Medium'
    },
    {
      id: 'CA004',
      name: 'Incident reporting and management',
      description: 'Implement a system for reporting, investigating, and resolving compliance incidents.',
      category: 'Safety Monitoring',
      frequency: 'Ongoing',
      priority: 'High'
    },
    {
      id: 'CA005',
      name: 'Implementation of compliance software tools',
      description: 'Utilize specialized software to manage and track compliance activities across the organization.',
      category: 'Data Integrity',
      frequency: 'Annually',
      priority: 'Medium'
    },
    {
      id: 'CA006',
      name: 'Clinical trial protocol compliance checks',
      description: 'Ensure all clinical trials adhere to approved protocols and regulatory requirements.',
      category: 'Clinical Trial Management',
      frequency: 'Monthly',
      priority: 'High'
    },
    {
      id: 'CA007',
      name: 'Quality control checks on manufacturing processes',
      description: 'Conduct regular quality control checks on pharmaceutical or biotech product manufacturing processes.',
      category: 'Quality Control',
      frequency: 'Daily',
      priority: 'High'
    },
    {
      id: 'CA008',
      name: 'Pharmacovigilance and adverse event reporting',
      description: 'Monitor and report adverse events related to pharmaceutical products or medical devices.',
      category: 'Safety Monitoring',
      frequency: 'Ongoing',
      priority: 'High'
    },
    {
      id: 'CA009',
      name: 'Data integrity audits',
      description: 'Perform regular audits to ensure the integrity of research, clinical, and manufacturing data.',
      category: 'Data Integrity',
      frequency: 'Quarterly',
      priority: 'High'
    },
    {
      id: 'CA010',
      name: 'Supply chain compliance verification',
      description: 'Verify that all suppliers and partners in the supply chain meet regulatory requirements.',
      category: 'Supply Chain Management',
      frequency: 'Annually',
      priority: 'Medium'
    },
    {
      id: 'CA011',
      name: 'Environmental compliance monitoring',
      description: 'Monitor and ensure compliance with environmental regulations relevant to healthcare and pharmaceutical operations.',
      category: 'Environmental Compliance',
      frequency: 'Monthly',
      priority: 'Medium'
    },
    {
      id: 'CA012',
      name: 'Privacy and data protection assessments',
      description: 'Conduct regular assessments to ensure compliance with data privacy regulations such as HIPAA and GDPR.',
      category: 'Data Integrity',
      frequency: 'Quarterly',
      priority: 'High'
    },
    {
      id: 'CA013',
      name: 'Compliance risk assessments',
      description: 'Perform periodic assessments to identify and evaluate compliance risks across the organization.',
      category: 'Audits and Inspections',
      frequency: 'Annually',
      priority: 'High'
    },
    {
      id: 'CA014',
      name: 'Compliance committee meetings',
      description: 'Hold regular meetings with the compliance committee to review and address compliance issues.',
      category: 'Documentation and Record Keeping',
      frequency: 'Monthly',
      priority: 'Medium'
    },
    {
      id: 'CA015',
      name: 'Third-party due diligence',
      description: 'Conduct due diligence on third-party vendors and partners to ensure they meet compliance standards.',
      category: 'Supply Chain Management',
      frequency: 'Annually',
      priority: 'Medium'
    }
  ];
  
  export const activityCategories = [
    'Audits and Inspections',
    'Regulatory Reporting',
    'Training and Education',
    'Safety Monitoring',
    'Data Integrity',
    'Clinical Trial Management',
    'Quality Control',
    'Supply Chain Management',
    'Documentation and Record Keeping',
    'Environmental Compliance'
  ];
  
  export const activityFrequencies = [
    'Daily',
    'Weekly',
    'Monthly',
    'Quarterly',
    'Bi-annually',
    'Annually',
    'Ongoing'
  ];