import React, { useState } from 'react';
import { AlertTriangle, Shield, Activity, Download, Brain, Calendar, ChevronDown, ChevronUp, HelpCircle, Info, X} from 'lucide-react';
import { Card, CardHeader, CardTitle, CardContent } from './ui/Card';
import { Button } from './ui/Button';
import { Tooltip, TooltipContent, TooltipTrigger } from './ui/Tooltip';
import { Collapsible, CollapsibleContent, CollapsibleTrigger } from './ui/Collapsible';
import LokCOIntegration from './LokCOIntegration';
import MockAuditWizard from './MockAuditWizard';
import { black } from 'tailwindcss/colors';

const QuickActions = ({ onGenerateReport, onStartAudit }) => {
  return (
    <div className="flex justify-between items-center mt-6 space-x-4">
      <Tooltip>
        <TooltipTrigger>
          <Button 
            variant="primary"
            size="lg"
            onClick={onGenerateReport}
            className="w-full bg-purple-600 hover:bg-purple-700 text-white flex-1"
          >
            <Download className="mr-2" size={20} />
            Generate Compliance Report
          </Button>
        </TooltipTrigger>
        <TooltipContent>
          Generate a comprehensive compliance report in PDF format
        </TooltipContent>
      </Tooltip>

      <Tooltip>
        <TooltipTrigger>
          <Button
            variant="success"
            size="lg"
            onClick={onStartAudit}
            className="w-full bg-green-600 hover:bg-green-700 text-white flex-1"
          >
            <Activity className="mr-2" size={20} />
            Start Compliance Audit
          </Button>
        </TooltipTrigger>
        <TooltipContent>
          Start a new compliance audit process with guided steps
        </TooltipContent>
      </Tooltip>
    </div>
  );
};

const InlineAuditWizard = ({ onClose }) => {
  return (
    <div className="mt-6 bg-white rounded-lg border animate-fade-in">
      <div className="p-6 relative">
        <div className="flex justify-between items-center mb-4">
          <h2 className="text-xl font-semibold text-gray-700">Compliance Audit Wizard</h2>
          <Button
            variant="outline"
            size="sm"
            onClick={onClose}
            className="hover:bg-gray-100"
          >
            <X className="w-4 h-4" />
          </Button>
        </div>
        <MockAuditWizard onClose={onClose} />
      </div>
    </div>
  );
};

const EnhancedAIOverview = ({ dashboardData }) => {
  const [isTimelineOpen, setIsTimelineOpen] = useState(false);
  const [showLokCO, setShowLokCO] = useState(false);
  const [showAuditWizard, setShowAuditWizard] = useState(false);
  const handleGenerateReport = () => {
    // Implementation for report generation
    console.log('Generating compliance report...');
  };

  const handleLokCOOpen = (context) => {
    setShowLokCO(true);
    console.log('Opening LokCO with context:', context);
  };
  const brainButtonStyle = "bg-secondary-600 text-white hover:bg-secondary-700";

  const ActionButton = ({ icon: Icon, label, tooltip, onClick, variant = "primary" }) => (
    <Tooltip content={tooltip}>
      <Button
        variant={variant}
        size="lg"
        onClick={onClick}
        className="relative group"
      >
        <Icon className="mr-2" size={20} />
        {label}
        <HelpCircle 
          className="w-4 h-4 ml-2 opacity-0 group-hover:opacity-100 transition-opacity" 
          aria-hidden="true"
        />
      </Button>
    </Tooltip>
  );

  // Real-world simulated urgent tasks
  const urgentTasks = [
    {
      id: 1,
      title: "EU AI Act Documentation Due",
      description: "Complete technical documentation for Clinical Decision Support AI",
      deadline: "2024-11-15",
      type: "critical"
    },
    {
      id: 2,
      title: "High-Risk AI Assessment",
      description: "Conduct conformity assessment for Patient Risk Stratification System",
      deadline: "2024-11-20",
      type: "high"
    },
    {
      id: 3,
      title: "Quarterly Compliance Review",
      description: "Review and update AI governance documentation",
      deadline: "2024-12-01",
      type: "medium"
    }
  ];

  const complianceCategories = [
    {
      title: "High-Risk AI Systems",
      score: 82,
      status: "Needs Attention",
      details: "3 systems require immediate review",
      priority: "high",
      items: [
        { name: "Clinical Decision Support AI", status: "Review Required", dueDate: "2024-11-15" },
        { name: "Patient Risk Stratification", status: "Documentation Needed", dueDate: "2024-11-20" },
        { name: "Drug Discovery ML Model", status: "Conformity Assessment Due", dueDate: "2024-11-30" }
      ]
    },
    {
      title: "Data Governance & Quality",
      score: 78,
      status: "In Progress",
      details: "Data documentation updates needed",
      priority: "medium",
      items: [
        { name: "Training Data Documentation", status: "In Progress", dueDate: "2024-11-25" },
        { name: "Bias Assessment", status: "Pending", dueDate: "2024-12-01" },
        { name: "Data Quality Metrics", status: "Completed", dueDate: "2024-10-30" }
      ]
    },
    {
      title: "Technical Documentation",
      score: 85,
      status: "On Track",
      details: "Regular updates ongoing",
      priority: "medium",
      items: [
        { name: "System Architecture", status: "Updated", dueDate: "2024-11-10" },
        { name: "Risk Management Files", status: "In Review", dueDate: "2024-11-18" },
        { name: "Performance Reports", status: "Current", dueDate: "2024-11-22" }
      ]
    }
  ];

  // Regulatory timeline data with more realistic dates and requirements
  const regulatoryTimeline = [
    {
      regulation: "EU AI Act",
      key: "euaia",
      events: [
        { 
          date: "2024-11-15", 
          title: "Technical Documentation Due", 
          status: "upcoming",
          detail: "Complete documentation for high-risk AI systems"
        },
        { 
          date: "2024-12-01", 
          title: "Conformity Assessment", 
          status: "upcoming",
          detail: "Initial assessment for Class IIa+ medical devices"
        },
        { 
          date: "2025-01-15", 
          title: "Risk Management System", 
          status: "upcoming",
          detail: "Implementation deadline for continuous risk monitoring"
        }
      ]
    },
    {
      regulation: "FDA AI/ML Framework",
      key: "fda",
      events: [
        { 
          date: "2024-12-15", 
          title: "SaMD Documentation", 
          status: "upcoming",
          detail: "Software as Medical Device documentation update"
        }
      ]
    }
  ];

  const getPriorityColor = (priority) => {
    switch (priority) {
      case 'critical':
        return 'bg-red-100 text-red-800 border-red-300';
      case 'high':
        return 'bg-orange-100 text-orange-800 border-orange-300';
      case 'medium':
        return 'bg-yellow-100 text-yellow-800 border-yellow-300';
      default:
        return 'bg-green-100 text-green-800 border-green-300';
    }
  };


  // Contextual help trigger that appears on hover
  const ContextualHelp = ({ context }) => (
    <div className="absolute right-2 top-2 opacity-0 group-hover:opacity-100 transition-opacity">
      <Button
        variant="secondary"
        size="sm"
        onClick={() => handleLokCOOpen(context)}
        className="p-1"
      >
        <Info className="w-4 h-4" />
      </Button>
    </div>
  );

  return (
    <div className="space-y-6">
      {/* Urgent Tasks Section */}
      <div className="grid grid-cols-3 gap-4">
        {urgentTasks.map((task) => (
          <Card key={task.id} className={`border-l-4 ${getPriorityColor(task.type)} relative group`}>
            <CardContent className="pt-4">
              <h3 className="font-semibold">{task.title}</h3>
              <p className="text-sm text-gray-600 mb-2">{task.description}</p>
              <div className="flex items-center justify-between text-sm">
                <span className="text-gray-500">Due: {task.deadline}</span>
                <Button size="sm" variant="outline">Take Action</Button>
              </div>
              <ContextualHelp context={`urgent-task-${task.id}`} />
            </CardContent>
          </Card>
        ))}
      </div>

      {/* Collapsible Regulatory Timeline */}
        <Collapsible
        open={isTimelineOpen}
        onOpenChange={setIsTimelineOpen}
        className="border rounded-lg bg-white"
      >
        <CollapsibleTrigger className="w-full">
          <div className="flex items-center justify-between p-4 cursor-pointer hover:bg-gray-50">
            <div className="flex items-center">
              <Calendar className="mr-2 text-gray-500" size={20} />
              <h3 className="font-semibold text-gray-700">Key Regulatory Deadlines</h3>
            </div>
            {isTimelineOpen ? <ChevronUp color={black} size={20} /> : <ChevronDown color={black} size={20} />}
          </div>
        </CollapsibleTrigger>
        <CollapsibleContent>
        <div className="p-4 border-t">
          {regulatoryTimeline.map((reg) => (
            <div key={reg.key} className="mb-8 last:mb-0">
              <h4 className="font-semibold text-gray-700 mb-4">{reg.regulation}</h4>
              <div className="space-y-6">
                {reg.events.map((event, index) => {
                  const eventDate = new Date(event.date);
                  const daysRemaining = Math.ceil((eventDate - new Date()) / (1000 * 60 * 60 * 24));
                  
                  // Calculate progress bar width based on days remaining
                  const getProgressWidth = () => {
                    const maxDays = 90; // Assuming 90 days is the maximum timeline
                    const remainingPercentage = (daysRemaining / maxDays) * 100;
                    return Math.min(remainingPercentage, 100);
                  };

                  return (
                    <div key={index} className="relative group">
                      {/* Date column */}
                      <div className="flex items-start mb-1">
                        <div className="w-16 flex-shrink-0 text-sm text-gray-500">
                          {eventDate.toLocaleDateString('en-US', { month: 'short', day: 'numeric' })}
                        </div>
                      
                        {/* Content column */}
                        <div className="flex-1">
                          <div className="font-medium text-gray-700">{event.title}</div>
                          <p className="text-sm text-gray-500 mt-1">{event.detail}</p>
                          
                          {/* Days remaining with progress bar */}
                          {daysRemaining > 0 && (
                            <div className="mt-2">
                              <div className="flex items-center justify-between mb-1">
                                <span className="text-sm text-blue-600">{daysRemaining} days remaining</span>
                              </div>
                              <div className="h-1 w-full bg-gray-200 rounded-full overflow-hidden">
                                <div 
                                  className="h-full bg-blue-600 rounded-full transition-all duration-300"
                                  style={{ width: `${getProgressWidth()}%` }}
                                />
                              </div>
                            </div>
                          )}
                        </div>

                        {/* Help button - appears on hover */}
                        <div className="opacity-0 group-hover:opacity-100 transition-opacity">
                          <Tooltip content="Get guidance on this requirement">
                            <Button
                              variant="outline"
                              size="sm"
                              onClick={() => handleLokCOOpen(`regulatory-${reg.key}-${index}`)}
                              className="ml-4 p-1 h-8 w-8"
                            >
                              <Info className="w-4 h-4 text-gray-500" />
                            </Button>
                          </Tooltip>
                        </div>
                      </div>

                      {/* Vertical timeline line */}
                      {index !== reg.events.length - 1 && (
                        <div className="absolute left-8 top-8 bottom-0 w-px bg-gray-200 -mb-3" />
                      )}
                    </div>
                  );
                })}
              </div>
            </div>
          ))}
        </div>
      </CollapsibleContent>
      </Collapsible>

      {/* Main Compliance Categories */}
      <div className="grid grid-cols-1 gap-6">
        {complianceCategories.map((category, index) => (
          <Card key={index} className="overflow-hidden">
            <CardHeader className="bg-gray-50">
              <div className="flex justify-between items-center">
                <div>
                  <CardTitle className="flex items-center">
                    <Shield className="mr-2 text-gray-500" size={20} />
                    {category.title}
                  </CardTitle>
                  <p className={`mt-1 text-sm ${getPriorityColor(category.priority)} inline-block px-2 py-1 rounded`}>
                    {category.status}
                  </p>
                </div>
                <div className="text-right">
                  <div className="text-2xl font-bold text-gray-700">{category.score}%</div>
                  <div className="text-sm text-gray-500">{category.details}</div>
                </div>
              </div>
            </CardHeader>
            <CardContent>
              <div className="space-y-3">
                {category.items.map((item, itemIndex) => (
                  <div key={itemIndex} className="flex justify-between items-center p-2 hover:bg-gray-50 rounded relative group">
                    <div>
                      <div className="font-medium text-gray-700">{item.name}</div>
                      <div className="text-sm text-gray-500">Due: {item.dueDate}</div>
                    </div>
                    <span className={`px-3 py-1 rounded text-sm ${
                      item.status === 'Completed' ? 'bg-green-100 text-green-700' :
                      item.status === 'In Progress' ? 'bg-blue-100 text-blue-700' :
                      'bg-yellow-100 text-yellow-700'
                    }`}>
                      {item.status}
                    </span>
                    <ContextualHelp context={`compliance-${category.title}-${itemIndex}`} />
                  </div>
                ))}
              </div>
            </CardContent>
          </Card>
        ))}
      </div>

       {/* Quick Actions - Now positioned correctly */}
       <div id="actions-section" className="scroll-mt-6">
        <QuickActions 
          onGenerateReport={handleGenerateReport}
          onStartAudit={() => {
            setShowAuditWizard(true);
            // Smooth scroll to audit wizard after a brief delay
            setTimeout(() => {
              document.getElementById('audit-wizard-section')?.scrollIntoView({ 
                behavior: 'smooth',
                block: 'start'
              });
            }, 100);
          }}
        />
      </div>
      
      {/* Inline Audit Wizard Section */}
      <div id="audit-wizard-section">
        {showAuditWizard && (
          <InlineAuditWizard onClose={() => setShowAuditWizard(false)} />
        )}
      </div>

    {/* LokCO Integration */}
    {showLokCO && (
      <LokCOIntegration
        onClose={() => setShowLokCO(false)}
        dashboardState={{
          aiPerformanceScore: dashboardData.overallAIScore,
          euAiActComplianceScore: 85,
          ethicalAiScore: dashboardData.ethicalAIScore,
          aiIncidents: dashboardData.aiIncidents,
          complianceGaps: ['Data governance', 'Model documentation'],
        }}
      />
    )}

    
  </div>
);
};

export default EnhancedAIOverview;