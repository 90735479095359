import React, { useState } from 'react';
import {
  PlayIcon,
  ClipboardDocumentListIcon,
  ArrowsRightLeftIcon,
  FlagIcon,
  DocumentTextIcon,
  ChartBarIcon,
  ExclamationTriangleIcon,
  MagnifyingGlassIcon,
  InformationCircleIcon,
} from '@heroicons/react/24/outline';

const sidebarItems = [
  { id: 'start', content: 'Start', icon: <PlayIcon className="h-6 w-6 text-green-600" />, description: 'Initiates the workflow process' },
  { id: 'task', content: 'Task', icon: <ClipboardDocumentListIcon className="h-6 w-6 text-blue-600" />, description: 'Represents a specific action or task' },
  { id: 'decision', content: 'Decision', icon: <ArrowsRightLeftIcon className="h-6 w-6 text-yellow-600" />, description: 'Indicates a decision point in the workflow' },
  { id: 'end', content: 'End', icon: <FlagIcon className="h-6 w-6 text-red-600" />, description: 'Marks the end of the workflow' },
  { id: 'form', content: 'Form', icon: <DocumentTextIcon className="h-6 w-6 text-purple-600" />, description: 'Collects data through a form' },
  { id: 'calculation', content: 'Calculation', icon: <ChartBarIcon className="h-6 w-6 text-indigo-600" />, description: 'Performs calculations based on collected data' },
  { id: 'notification', content: 'Notification', icon: <ExclamationTriangleIcon className="h-6 w-6 text-orange-600" />, description: 'Sends notifications or alerts' },
];

const Sidebar = ({ onDragStart }) => {
  const [searchTerm, setSearchTerm] = useState('');

  const filteredItems = sidebarItems.filter(item =>
    item.content.toLowerCase().includes(searchTerm.toLowerCase()) ||
    item.description.toLowerCase().includes(searchTerm.toLowerCase())
  );

  return (
    <div className="w-72 bg-white shadow-md p-6 overflow-y-auto h-screen">
      <h2 className="text-2xl font-bold mb-6 text-gray-800">Workflow Elements</h2>
      <div className="mb-4 relative">
        <input
          type="text"
          placeholder="Search elements..."
          className="w-full p-2 pl-8 pr-4 rounded-md border border-gray-300 focus:outline-none focus:ring-2 focus:ring-blue-500"
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
        />
        <MagnifyingGlassIcon className="absolute left-2 top-1/2 transform -translate-y-1/2 h-5 w-5 text-gray-400" />
      </div>
      <div className="space-y-3">
        {filteredItems.map((item) => (
          <div
            key={item.id}
            className="bg-gray-50 p-3 rounded-lg shadow-sm cursor-move flex items-center hover:bg-gray-100 transition group"
            onDragStart={(event) => onDragStart(event, item.id)}
            draggable
          >
            <div className="mr-3">{item.icon}</div>
            <div>
              <span className="font-semibold text-gray-800">{item.content}</span>
              <p className="text-xs text-gray-600 mt-1 hidden group-hover:block">{item.description}</p>
            </div>
          </div>
        ))}
      </div>
      <div className="mt-8 p-4 bg-blue-50 rounded-lg">
        <h3 className="flex items-center text-lg font-semibold text-blue-800 mb-2">
          <InformationCircleIcon className="h-5 w-5 mr-2" />
          Quick Tips
        </h3>
        <ul className="text-sm text-blue-700 list-disc list-inside">
          <li>Drag elements to the canvas</li>
          <li>Double-click nodes to edit</li>
          <li>Connect nodes by dragging between handles</li>
          <li>Select & Backspace to delete</li>
        </ul>
      </div>
    </div>
  );
};

export default Sidebar;