import React, { useState, useCallback } from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import { ErrorBoundary } from "react-error-boundary";
import { Toaster } from "react-hot-toast";

// Context Providers
//import { AuthProvider } from './contexts/AuthContext';
import { AuditProvider } from "./contexts/AuditContext";

// Components
import ProtectedRoute from "./components/ProtectedRoute";
import LoginPage from "./components/LoginPage";
import LandingPage from "./components/LandingPage";
import DashboardWireframe from "./components/DashboardWireframe";
import AIMSDashboardWireframe from "./components/AIMSDashboardWireframe";
import PrivacyPolicy from "./components/PrivacyPolicy";
import GDPRNotice from "./components/GDPRNotice";
import ComplianceProcessWizard from "./components/ComplianceProcessWizard";
import WorkflowBuilder from "./components/WorkflowBuilder";

const ErrorFallback = ({ error }) => (
  <div role="alert" className="p-4 bg-red-50 border border-red-200 rounded-lg">
    <h2 className="text-lg font-semibold text-red-600 mb-2">
      Something went wrong
    </h2>
    <pre className="text-sm text-red-500 whitespace-pre-wrap">
      {error.message}
    </pre>
  </div>
);

function App() {
  const isDashboardEnabled = process.env.REACT_APP_ENABLE_DASHBOARDS === "true";
  const [draftProcesses, setDraftProcesses] = useState([]);

  const handleSaveDraft = useCallback((draftProcess) => {
    setDraftProcesses((prev) => [...prev, draftProcess]);
  }, []);

  const handleNewProcess = useCallback(() => {
    console.log("New process initiated");
  }, []);

  return (
    <div className="min-h-screen flex flex-col m-0 p-0 overflow-x-hidden">
      <ErrorBoundary FallbackComponent={ErrorFallback}>
        <AuditProvider>
          {/* Main Content Container */}
          <div className="flex-grow flex flex-col relative">
            <Routes>
              {/* Public Routes */}
              <Route path="/" element={<LandingPage />} />
              <Route path="/login" element={<LoginPage />} />
              <Route path="/privacy-policy" element={<PrivacyPolicy />} />

              {/* Protected Dashboard Routes */}
              {isDashboardEnabled && (
                <>
                  <Route
                    path="/cms-dashboard"
                    element={
                      <ProtectedRoute>
                        <DashboardWireframe
                          draftProcesses={draftProcesses}
                          onNewProcess={handleNewProcess}
                        />
                      </ProtectedRoute>
                    }
                  />
                  <Route
                    path="/process-wizard"
                    element={
                      <ProtectedRoute>
                        <ComplianceProcessWizard
                          onSave={handleSaveDraft}
                          onDraftSaved={handleSaveDraft}
                        />
                      </ProtectedRoute>
                    }
                  />
                  <Route
                    path="/workflow-builder"
                    element={
                      <ProtectedRoute>
                        <WorkflowBuilder />
                      </ProtectedRoute>
                    }
                  />
                  <Route
                    path="/aims-dashboard"
                    element={
                      <ProtectedRoute>
                        <AIMSDashboardWireframe />
                      </ProtectedRoute>
                    }
                  />
                </>
              )}

              {/* Redirects */}
              <Route
                path="/dashboard"
                element={<Navigate replace to="/cms-dashboard" />}
              />

              {/* Fallback route */}
              <Route path="*" element={<Navigate replace to="/" />} />
            </Routes>
          </div>

          {/* Overlay Components */}
          <GDPRNotice />

          {/* Toast Notifications */}
          <Toaster
            position="top-right"
            toastOptions={{
              className: "rounded-lg shadow-md",
              duration: 5000,
              style: {
                background: "#fff",
                color: "#363636",
              },
            }}
          />
        </AuditProvider>
      </ErrorBoundary>
    </div>
  );
}

export default App;
