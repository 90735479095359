import React from 'react';

export const Progress = ({
  value,
  max = 100,
  currentStep,
  totalSteps,
  stepMode = false,
  color = 'bg-purple-600',
  bgColor = 'bg-gray-200',
  height = 'h-2.5',
  className = '',
  showPercentage = false,
  circular = false,
  size = 100,
  strokeWidth = 8,
  textColor = 'text-purple-600',
  ...props
}) => {
  const percentage = stepMode
    ? Math.min((currentStep / totalSteps) * 100, 100)
    : Math.min((value / max) * 100, 100);

  if (circular) {
    const radius = (size - strokeWidth) / 2;
    const circumference = radius * 2 * Math.PI;
    const strokeDashoffset = circumference - (percentage / 100) * circumference;

    return (
      <div className={`relative ${className}`} style={{ width: size, height: size }} {...props}>
        <svg
          className="transform -rotate-90"
          width={size}
          height={size}
        >
          <circle
            className={bgColor}
            strokeWidth={strokeWidth}
            fill="transparent"
            r={radius}
            cx={size / 2}
            cy={size / 2}
          />
          <circle
            className={`${color.replace('bg-', 'stroke-')} transition-all duration-500`}
            strokeWidth={strokeWidth}
            strokeDasharray={circumference}
            strokeDashoffset={strokeDashoffset}
            strokeLinecap="round"
            fill="transparent"
            r={radius}
            cx={size / 2}
            cy={size / 2}
          />
        </svg>
        {showPercentage && (
          <div className={`absolute inset-0 flex items-center justify-center ${textColor}`}>
            <span className="text-xl font-semibold">
              {stepMode ? `${currentStep}/${totalSteps}` : `${Math.round(percentage)}%`}
            </span>
          </div>
        )}
      </div>
    );
  }

  return (
    <div className={`w-full ${bgColor} rounded-full ${height} ${className}`} {...props}>
      <div
        className={`${color} ${height} rounded-full transition-all duration-500`}
        style={{ width: `${percentage}%` }}
      ></div>
      {showPercentage && (
        <div className="text-right mt-1 text-sm text-gray-600">
          {stepMode ? `${currentStep} / ${totalSteps}` : `${Math.round(percentage)}%`}
        </div>
      )}
    </div>
  );
};