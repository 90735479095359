import React, { useState, useEffect } from 'react';
import { Brain, TrendingUp, AlertTriangle, BarChart2, Activity, FileText, X } from 'lucide-react';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer, RadarChart, PolarGrid, PolarAngleAxis, PolarRadiusAxis, Radar } from 'recharts';
import { Card, CardHeader, CardTitle, CardContent } from './ui/Card';
import { Button } from './ui/Button';
import { Progress } from './ui/Progress';
import LokCO from './LokCO'


const AIInsightCard = ({ icon: Icon, title, insight, action, onAction }) => (
  <Card className="bg-gradient-to-br from-blue-50 to-indigo-50 border-blue-200 hover:shadow-lg transition-all duration-300">
    <CardHeader className="flex flex-row items-center justify-between space-y-0 pb-2">
      <CardTitle className="text-sm font-medium">
        <Icon className="w-4 h-4 inline-block mr-2" />
        {title}
      </CardTitle>
    </CardHeader>
    <CardContent>
      <p className="text-sm text-gray-700 mb-2">{insight}</p>
      <Button variant="outline" size="sm" className="w-full" onClick={onAction}>
        {action}
      </Button>
    </CardContent>
  </Card>
);

const ComplianceScoreOverview = ({ scores }) => (
  <Card className="col-span-2">
    <CardHeader>
      <CardTitle>Compliance Score Overview</CardTitle>
    </CardHeader>
    <CardContent>
      <div className="space-y-4">
        {Object.entries(scores).map(([key, value]) => (
          <div key={key} className="flex items-center">
            <div className="w-40 font-medium">{key}</div>
            <Progress value={value} className="flex-1 mr-4" />
            <div className="w-10 text-right font-medium">{value}%</div>
          </div>
        ))}
      </div>
    </CardContent>
  </Card>
);

const RiskHeatMap = ({ data }) => {
  return (
    <Card>
      <CardHeader>
        <CardTitle>Risk Heat Map</CardTitle>
      </CardHeader>
      <CardContent>
        <ResponsiveContainer width="100%" height={300}>
          <RadarChart cx="50%" cy="50%" outerRadius="70%" data={data}>
            <PolarGrid />
            <PolarAngleAxis
              dataKey="area"
              tick={{ 
                fontSize: 10, // Reduced font size
                fill: '#666', // Darker color for better contrast
                dy: 3 // Slight vertical adjustment
              }}
              tickLine={false} // Hide tick lines
            />
            <PolarRadiusAxis angle={30} domain={[0, 100]} tick={{ fontSize: 8 }} />
            <Radar
              name="Risk Level"
              dataKey="value"
              stroke="#8884d8"
              fill="#8884d8"
              fillOpacity={0.6}
            />
            <Tooltip />
          </RadarChart>
        </ResponsiveContainer>
        <div className="mt-4 flex justify-between text-sm">
          <span className="flex items-center">
            <span className="w-3 h-3 bg-green-500 rounded-full mr-1"></span> Low Risk (0-33)
          </span>
          <span className="flex items-center">
            <span className="w-3 h-3 bg-yellow-500 rounded-full mr-1"></span> Medium Risk (34-66)
          </span>
          <span className="flex items-center">
            <span className="w-3 h-3 bg-red-500 rounded-full mr-1"></span> High Risk (67-100)
          </span>
        </div>
        <p className="mt-4 text-sm text-gray-600">
          This heat map visualizes risk levels across different compliance areas. 
          Higher scores indicate higher risk levels requiring immediate attention.
        </p>
      </CardContent>
    </Card>
  );
};

const AIComplianceTrend = ({ data }) => (
  <Card>
    <CardHeader>
      <CardTitle>AI-Predicted Compliance Trend</CardTitle>
    </CardHeader>
    <CardContent>
      <ResponsiveContainer width="100%" height={300}>
        <LineChart data={data}>
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="month" />
          <YAxis />
          <Tooltip />
          <Legend />
          <Line type="monotone" dataKey="predicted" stroke="#8884d8" activeDot={{ r: 8 }} />
          <Line type="monotone" dataKey="actual" stroke="#82ca9d" />
        </LineChart>
      </ResponsiveContainer>
    </CardContent>
  </Card>
);

const DetailModal = ({ title, content, onClose }) => (
  <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center p-4 z-50">
    <div className="bg-white rounded-lg max-w-2xl w-full max-h-[80vh] overflow-auto">
      <div className="p-6">
        <div className="flex justify-between items-center mb-4">
          <h3 className="text-xl font-semibold">{title}</h3>
          <button onClick={onClose} className="text-gray-500 hover:text-gray-700">
            <X size={24} />
          </button>
        </div>
        {content}
      </div>
    </div>
  </div>
);

const EnhancedOverview = ({ dashboardData, onNavigate }) => {
  const [aiInsights, setAiInsights] = useState([]);
  const [showLokCO, setShowLokCO] = useState(true);
  const [modalContent, setModalContent] = useState(null);
  const [initiateRiskAnalysis, setInitiateRiskAnalysis] = useState(false);

  const handleAnalyzeRisks = () => {
    setShowLokCO(true);
    setInitiateRiskAnalysis(true);
  };

  useEffect(() => {
    setAiInsights([
      {
        icon: AlertTriangle,
        title: "Emerging Data Privacy Risks",
        insight: "31% increase in data privacy risks detected due to global regulatory changes and data governance shifts.",
        action: "Analyze with LokCO"
      },
      {
        icon: TrendingUp,
        title: "Process Optimization",
        insight: "Smart insights recommend streamlining the vendor onboarding process to reduce compliance risks by 15%.",
        action: "Explore Recommendations"
      },
      {
        icon: BarChart2,
        title: "Predictive Compliance",
        insight: "Based on current trends, our predictive model forecasts a 92% compliance score by next quarter with suggested actions.",
        action: "See Prediction Model"
      }
    ]);
  }, []);

  const handleInsightAction = (index) => {
    if (index === 0) {
      setShowLokCO(true);
      setInitiateRiskAnalysis(true);
    } else {
      openModal(
        aiInsights[index].title,
        index === 1 ? processOptimizationContent : predictiveComplianceContent
      );
    }
  };

  const openModal = (title, content) => {
    setModalContent({ title, content });
  };

  const closeModal = () => {
    setModalContent(null);
  };


  const emergingRiskContent = (
    <div>
      <p className="mb-4">Our intelligent analysis has detected a 23% increase in data privacy risks due to recent regulatory changes. Here's a breakdown of the key factors:</p>
      <ul className="list-disc pl-5 mb-4">
        <li>New GDPR enforcement actions in the EU targeting healthcare data processors</li>
        <li>Proposed amendments to HIPAA regulations expanding the definition of Protected Health Information</li>
        <li>Increased scrutiny on cross-border data transfers affecting clinical trial data</li>
      </ul>
      <h4 className="font-semibold mb-2">Recommended Actions:</h4>
      <ol className="list-decimal pl-5">
        <li>Conduct an urgent review of data processing activities, especially those involving EU citizens</li>
        <li>Update privacy impact assessments for all systems handling patient data</li>
        <li>Enhance employee training on the latest data privacy regulations</li>
        <li>Evaluate and strengthen data transfer mechanisms for international clinical trials</li>
      </ol>
    </div>
  );

  const processOptimizationContent = (
    <div>
      <p className="mb-4">Our analysis suggests that streamlining the vendor onboarding process can reduce compliance risks by 15%. Here's how:</p>
      <h4 className="font-semibold mb-2">Current Process:</h4>
      <ol className="list-decimal pl-5 mb-4">
        <li>Initial vendor contact (2 days)</li>
        <li>Preliminary assessment (5 days)</li>
        <li>Detailed questionnaire (10 days)</li>
        <li>Internal review (7 days)</li>
        <li>Final approval (3 days)</li>
      </ol>
      <h4 className="font-semibold mb-2">Optimized Process:</h4>
      <ol className="list-decimal pl-5 mb-4">
        <li>Automated initial screening (1 day)</li>
        <li>Risk-based questionnaire (5 days)</li>
        <li>AI-assisted review (2 days)</li>
        <li>Final approval (2 days)</li>
      </ol>
      <p className="font-semibold">Potential Impact:</p>
      <ul className="list-disc pl-5">
        <li>Reduce onboarding time from 27 days to 10 days</li>
        <li>Increase accuracy of risk assessments by 30%</li>
        <li>Free up 40% of compliance team's time for high-value tasks</li>
      </ul>
    </div>
  );

  const predictiveComplianceContent = (
    <div>
      <p className="mb-4">Based on current trends and actions, we predict a 92% compliance score by next quarter. Here's the breakdown:</p>
      <div className="mb-4">
        <h4 className="font-semibold mb-2">Compliance Area Predictions:</h4>
        <ul className="space-y-2">
          <li>
            Data Privacy: 95% <Progress value={95} className="h-2" />
          </li>
          <li>
            Third Party Risk: 88% <Progress value={88} className="h-2" />
          </li>
          <li>
            AI Compliance: 94% <Progress value={94} className="h-2" />
          </li>
          <li>
            Information Security: 91% <Progress value={91} className="h-2" />
          </li>
          <li>
            Anti-Corruption: 90% <Progress value={90} className="h-2" />
          </li>
        </ul>
      </div>
      <h4 className="font-semibold mb-2">Key Factors Influencing Prediction:</h4>
      <ul className="list-disc pl-5 mb-4">
        <li>Completion of planned data privacy training (Impact: +3%)</li>
        <li>Implementation of new vendor risk assessment tool (Impact: +5%)</li>
        <li>Ongoing AI model documentation improvements (Impact: +4%)</li>
        <li>Scheduled security patches and updates (Impact: +2%)</li>
        <li>Roll-out of enhanced anti-bribery procedures (Impact: +3%)</li>
      </ul>
      <p className="font-semibold">Recommended Focus Areas:</p>
      <ol className="list-decimal pl-5">
        <li>Accelerate third-party risk management improvements</li>
        <li>Maintain momentum on AI compliance documentation efforts</li>
        <li>Consider additional resources for anti-corruption initiatives</li>
      </ol>
    </div>
  );

  const handleGenerateWorkflow = (workflow) => {
    // Instead of setting state here, we'll pass the generated workflow up to the parent
    onNavigate('workflowBuilder', workflow);
  };

  return (
    <div className="space-y-6">
      <h2 className="text-3xl font-bold text-gray-600">Compliance Command Center</h2>
      
      <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
      {aiInsights.map((insight, index) => (
        <AIInsightCard
          key={index}
          {...insight}
          onAction={() => handleInsightAction(index)}
        />
      ))}
    </div>

      <div className="grid grid-cols-1 md:grid-cols-3 gap-6 text-gray-400">
        <ComplianceScoreOverview 
          scores={{
            'Overall Compliance': dashboardData.overallScore,
            'Third Party Risk': dashboardData.thirdPartyRiskScore,
            'Data Privacy': dashboardData.dataPrivacyScore,
            'AI Compliance': dashboardData.aiComplianceScore,
            'Information Security': dashboardData.informationSecurityScore,
            'Anti-Corruption': dashboardData.antiCorruptionScore,
          }}
        />
        <RiskHeatMap data={dashboardData.riskHeatMapData} />
      </div>

      <AIComplianceTrend data={dashboardData.aiComplianceTrendData} />

      <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
        <Card>
          <CardHeader>
            <CardTitle>LokCO-Optimized Processes</CardTitle>
          </CardHeader>
          <CardContent>
            <ul className="space-y-2">
              {dashboardData.workflows.map((workflow, index) => (
                <li key={index} className="flex items-center justify-between">
                  <span className="flex items-center">
                    <Activity className="w-4 h-4 mr-2 text-blue-500" />
                    {workflow.name}
                  </span>
                  <Button variant="outline" size="sm">Optimize</Button>
                </li>
              ))}
            </ul>
          </CardContent>
        </Card>

        <Card>
          <CardHeader>
            <CardTitle>LokCO-Enhanced Policies</CardTitle>
          </CardHeader>
          <CardContent>
            <ul className="space-y-2">
              {dashboardData.policies.map((policy, index) => (
                <li key={index} className="flex items-center justify-between">
                  <span className="flex items-center">
                  <FileText className="w-4 h-4 mr-2 text-blue-500" />
                    {policy.name}
                  </span>
                  <Button variant="outline" size="sm">Review</Button>
                </li>
              ))}
            </ul>
          </CardContent>
        </Card>
      </div>

      <Card>
        <CardHeader>
          <CardTitle>LokCO - Your Compliance Co-pilot</CardTitle>
        </CardHeader>
        <CardContent>
          <div className="flex items-center space-x-4">
            <Brain className="w-12 h-12 text-blue-500" />
            <div className="flex-1">
              <p className="text-sm text-gray-500 mb-2">
                Hello! I'm LokCO, your Compliance Co-pilot. How can I assist you today? 
                I can help with risk assessments, policy reviews, or answer any compliance-related questions.
              </p>
            <Button onClick={() => setShowLokCO(prev => !prev)}>
                {showLokCO ? 'Hide LokCO' : 'Ask LokCO'}
            </Button>
            </div>
          </div>
        </CardContent>
      </Card>

      {modalContent && (
        <DetailModal
          title={modalContent.title}
          content={modalContent.content}
          onClose={closeModal}
        />
      )}

      {showLokCO && (
        <LokCO
          message="I've detected potential optimization opportunities in your vendor management process. Would you like me to analyze and suggest improvements?"
          onClose={() => {
            setShowLokCO(false);
            setInitiateRiskAnalysis(false);
          }}
          initiateRiskAnalysis={initiateRiskAnalysis}
          onGenerateWorkflow={handleGenerateWorkflow}
          navigateToWorkflowBuilder={() => onNavigate('workflowBuilder')}
        />
      )}
    </div>
  );
};

export default EnhancedOverview;