import React, { useState, useCallback, useRef, useEffect } from 'react';
import ReactFlow, {
  addEdge,
  MiniMap,
  Controls,
  Background,
  useNodesState,
  useEdgesState,
  Panel,  // Added
  ReactFlowProvider  // Added
} from 'reactflow';
import 'reactflow/dist/style.css';

// Component imports
import Sidebar from './Sidebar';
import CustomNode from './CustomNode';
import IntegrationNode from './IntegrationNode';
import NodeContextMenu from './NodeContextMenu';
import NodeEditor from './NodeEditor';
import FormBuilder from './FormBuilder';
import IntegrationConfigDialog from './IntegrationConfigDialog';
import LokCO from './LokCO';
import validateWorkflow from '../utils/workflowValidation';

// UI Components
import { Button } from './ui/Button';
import { Input } from './ui/Input';
import { Tabs, TabsList, TabsTrigger, TabsContent } from './ui/Tabs';
import {
  AlertDialog,
  AlertDialogContent,
  AlertDialogHeader,
  AlertDialogTitle,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogCancel,
  AlertDialogAction
} from './ui/AlertDialog';
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from './ui/Tooltip';

// Lucide Icons
import {
  ZoomIn,
  ZoomOut,
  Save,
  Upload,
  Trash,
  Database,
  GraduationCap,
  Box as BoxIcon,
  Server as ServerIcon,
  Terminal as ApiIcon,
  Webhook,
  Wifi as WifiIcon,
  ClipboardCopy,
  Info,
  Info as InformationCircleIcon,
  Wrench as Tool,
  FileQuestion,
  HelpCircle, 
  Users,
  Shield,
  CheckCircle,
  FileText,
  Radio,
  BarChart
} from 'lucide-react';

const nodeTypes = {
  custom: CustomNode,
  integration: IntegrationNode,
};

// Integration types configuration
const integrationTypes = [
  // Core Business Systems
  {
    type: 'ERP Systems',
    icon: ServerIcon,
    description: 'SAP, Oracle, Microsoft Dynamics',
    examples: ['Financial data', 'HR records', 'Asset management']
  },
  {
    type: 'CRM Systems',
    icon: Users,
    description: 'Salesforce, Microsoft Dynamics',
    examples: ['Customer data', 'Vendor records']
  },

  // Quality Management Systems
  {
    type: 'QMS',
    icon: CheckCircle,
    description: 'Quality management systems',
    examples: ['Document control', 'CAPA management']
  },

  // Risk & Compliance Systems
  {
    type: 'GRC Systems',
    icon: Shield,
    description: 'Governance, Risk & Compliance platforms',
    examples: ['Risk assessments', 'Audit findings']
  },

  // Documentation Systems
  {
    type: 'Document Management',
    icon: FileText,
    description: 'SharePoint, Documentum',
    examples: ['Policies', 'Procedures', 'Records']
  },

  // Communication & Training
  {
    type: 'LMS',
    icon: GraduationCap,
    description: 'Learning Management Systems',
    examples: ['Training records', 'Certifications']
  },

  // External Data Sources
  {
    type: 'Regulatory Feeds',
    icon: Radio,
    description: 'Thomson Reuters, Bloomberg',
    examples: ['Regulatory updates', 'Compliance alerts']
  },

  // Monitoring & Analytics 
  {
    type: 'Analytics Platforms',
    icon: BarChart,
    description: 'Tableau, Power BI',
    examples: ['Compliance metrics', 'Risk indicators']
  }
];

const IntegrationOptions = ({ onAddIntegration }) => (
  <div className="p-6 bg-white border-b">
    <div className="grid grid-cols-6 gap-4">
      {integrationTypes.map((integration) => (
        <button
          key={integration.type}
          onClick={() => onAddIntegration(integration.type)}
          className="group flex flex-col items-center p-4 rounded-lg border border-gray-100 hover:border-purple-200 hover:bg-purple-50 transition-all duration-200"
        >
          <div className="w-12 h-12 flex items-center justify-center rounded-full bg-gradient-to-br from-purple-500/10 to-blue-500/10 group-hover:from-purple-500/20 group-hover:to-blue-500/20 mb-3">
            <integration.icon className="w-6 h-6 text-purple-600" />
          </div>
          <span className="text-sm font-medium text-gray-700 group-hover:text-purple-700">
            {integration.type}
          </span>
          <span className="text-xs text-gray-500 mt-1 text-center">
            {integration.description}
          </span>
        </button>
      ))}
    </div>
  </div>
);

// Add this new toolbar component
const IntegrationToolbar = ({ onAddIntegration }) => (
  <div className="border-b bg-white">
    <Tabs defaultValue="workflow" className="w-full">
      <TabsList className="px-4 border-b">
        <TabsTrigger value="workflow">Workflow</TabsTrigger>
        <TabsTrigger value="integrations">Integrations</TabsTrigger>
        <TabsTrigger value="forms">Forms</TabsTrigger>
      </TabsList>
      
      <TabsContent value="integrations">
        <IntegrationOptions onAddIntegration={onAddIntegration} />
      </TabsContent>
    </Tabs>
  </div>
);

const TechTip = ({ title, children }) => (
  <div className="absolute bg-blue-50 p-4 rounded-lg shadow-lg border border-blue-200 max-w-xs z-50">
    <h4 className="text-blue-700 font-medium flex items-center gap-2 mb-2">
      <Info className="h-4 w-4" />
      {title}
    </h4>
    <p className="text-sm text-blue-600">{children}</p>
  </div>
);

const GuideStep = ({ step, title, children, position = 'right' }) => (
  <div className={`absolute ${position}-0 top-0 bg-purple-50 p-4 rounded-lg shadow-lg border border-purple-200 max-w-xs`}>
    <div className="flex items-center gap-2 mb-2">
      <span className="bg-purple-600 text-white rounded-full w-6 h-6 flex items-center justify-center text-sm">
        {step}
      </span>
      <h4 className="text-purple-700 font-medium">{title}</h4>
    </div>
    <p className="text-sm text-purple-600">{children}</p>
  </div>
);

const WorkflowBuilder = ({ initialWorkflow, complianceProcessData = {} }) => {
  const [nodes, setNodes, onNodesChange] = useNodesState(initialWorkflow || []);
  const [edges, setEdges, onEdgesChange] = useEdgesState([]);
  const [workflowName, setWorkflowName] = useState('Untitled Workflow');
  const [reactFlowInstance, setReactFlowInstance] = useState(null);
  const [selectedNode, setSelectedNode] = useState(null);
  const [contextMenuPosition, setContextMenuPosition] = useState(null);
  const [showNodeEditor, setShowNodeEditor] = useState(false);
  const [showFormBuilder, setShowFormBuilder] = useState(false);
  const [showLokCO, setShowLokCO] = useState(false);
  const [currentWorkflow, setCurrentWorkflow] = useState(null);
  const [validationErrors, setValidationErrors] = useState([]);
  const [showAlertDialog, setShowAlertDialog] = useState(false);
  const [alertDialogContent, setAlertDialogContent] = useState({ title: '', description: '' });
  const [formFields, setFormFields] = useState([]);
  const [showGuidedTour, setShowGuidedTour] = useState(false);
  const reactFlowWrapper = useRef(null);
  const [showIntegrationConfig, setShowIntegrationConfig] = useState(false);
  const [selectedIntegration, setSelectedIntegration] = useState(null);
  const [integrationConnections, setIntegrationConnections] = useState({});
  const [showTechTips, setShowTechTips] = useState(false);
  const [currentGuideStep, setCurrentGuideStep] = useState(1);

  useEffect(() => {
    if (initialWorkflow && initialWorkflow.length > 0) {
      const workflowType = determineWorkflowType(initialWorkflow);
      setCurrentWorkflow(workflowType);
      setWorkflowName(initialWorkflow[0].data.workflowName || 'Untitled Workflow');
    }
  }, [initialWorkflow]);

// Add new handler for integration nodes
const handleAddIntegration = useCallback(
  (type) => {
    const position = {
      x: Math.random() * 500,
      y: Math.random() * 500,
    };

    const newNode = {
      id: `integration-${Date.now()}`,
      type: 'integration',
      position,
      data: {
        label: `${type} Integration`,
        type,
        integrationType: type,
        connectionStatus: 'Not Connected',
        configuration: {
          connection: {},
          mapping: {},
          schedule: {},
        },
      },
    };

    setNodes((nds) => nds.concat(newNode));
  },
  [setNodes]
);

// Add new handler for integration configuration
const handleIntegrationConfig = useCallback((node) => {
  setSelectedIntegration(node);
  setShowIntegrationConfig(true);
}, []);

// Add handler for integration connection status update
const handleConnectionStatusUpdate = useCallback((nodeId, status) => {
  setNodes((nds) =>
    nds.map((node) =>
      node.id === nodeId
        ? { ...node, data: { ...node.data, connectionStatus: status } }
        : node
    )
  );
}, [setNodes]);

  const determineWorkflowType = (workflow) => {
    const startNode = workflow.find(node => node.data.type === 'start');
    if (startNode) {
      if (startNode.data.label.toLowerCase().includes('gdpr')) return 'gdpr';
      if (startNode.data.label.toLowerCase().includes('tprm')) return 'tprm';
    }
    return 'custom';
  };

  const onConnect = useCallback(
    (params) => setEdges((eds) => addEdge(params, eds)),
    [setEdges]
  );

  const onInit = useCallback((instance) => {
    setReactFlowInstance(instance);
  }, []);

  const onDragStart = (event, nodeType) => {
    event.dataTransfer.setData('application/reactflow', nodeType);
    event.dataTransfer.effectAllowed = 'move';
  };

  const onDragOver = useCallback((event) => {
    event.preventDefault();
    event.dataTransfer.dropEffect = 'move';
  }, []);

  const onDrop = useCallback(
    (event) => {
      event.preventDefault();

      const reactFlowBounds = reactFlowWrapper.current.getBoundingClientRect();
      const type = event.dataTransfer.getData('application/reactflow');

      if (typeof type === 'undefined' || !type) {
        return;
      }

      const position = reactFlowInstance.project({
        x: event.clientX - reactFlowBounds.left,
        y: event.clientY - reactFlowBounds.top,
      });

      const newNode = {
        id: `${type}-${Date.now()}`,
        type: 'custom',
        position,
        data: { 
          label: `${type.charAt(0).toUpperCase() + type.slice(1)} Node`, 
          type,
          description: `This is a ${type} node. Double-click to edit.`
        },
      };

      setNodes((nds) => {
        const updatedNodes = nds.concat(newNode);
        const workflowType = determineWorkflowType(updatedNodes);
        setCurrentWorkflow(workflowType);
        return updatedNodes;
      });
    },
    [reactFlowInstance, setNodes]
  );

  const handleSave = useCallback(() => {
    if (workflowName.trim() === '') {
      setAlertDialogContent({
        title: 'Workflow Name Required',
        description: 'Please enter a name for your workflow before saving.'
      });
      setShowAlertDialog(true);
      return;
    }

    const errors = validateWorkflow(nodes, edges);
    if (errors.length > 0) {
      setValidationErrors(errors);
      return;
    }
    
    if (nodes.length === 0) {
      setAlertDialogContent({
        title: 'Empty Workflow',
        description: 'Your workflow is empty. Please add some nodes before saving.'
      });
      setShowAlertDialog(true);
      return;
    }

    const workflowData = {
      name: workflowName,
      nodes,
      edges,
      integrationConnections,
    };

    const jsonData = JSON.stringify(workflowData, null, 2);
    console.log('Saving workflow:', jsonData);
    
    setAlertDialogContent({
      title: 'Workflow Saved',
      description: 'Your workflow has been successfully saved.'
    });
    setShowAlertDialog(true);
  }, [workflowName, nodes, edges, integrationConnections]);

  const handleDelete = useCallback(() => {
    setAlertDialogContent({
      title: 'Confirm Deletion',
      description: 'Are you sure you want to delete this workflow? This action cannot be undone.',
      action: () => {
        setNodes([]);
        setEdges([]);
        setWorkflowName('Untitled Workflow');
      }
    });
    setShowAlertDialog(true);
  }, [setNodes, setEdges]);

  const handleZoomIn = useCallback(() => {
    if (reactFlowInstance) {
      reactFlowInstance.zoomIn();
    }
  }, [reactFlowInstance]);

  const handleZoomOut = useCallback(() => {
    if (reactFlowInstance) {
      reactFlowInstance.zoomOut();
    }
  }, [reactFlowInstance]);

  const handleLoad = useCallback((event) => {
    const file = event.target.files[0];
    const reader = new FileReader();

    reader.onload = (e) => {
      try {
        const workflowData = JSON.parse(e.target.result);
        setWorkflowName(workflowData.name);
        setNodes(workflowData.nodes);
        setEdges(workflowData.edges);
        setAlertDialogContent({
          title: 'Workflow Loaded',
          description: 'Your workflow has been successfully loaded.'
        });
        setShowAlertDialog(true);
      } catch (error) {
        console.error('Error loading workflow:', error);
        setAlertDialogContent({
          title: 'Error Loading Workflow',
          description: 'There was an error loading the workflow. Please check the file format and try again.'
        });
        setShowAlertDialog(true);
      }
    };

    reader.readAsText(file);
  }, [setNodes, setEdges]);

  const onNodeDoubleClick = useCallback(
    (event, node) => {
      event.preventDefault();
      setSelectedNode(node);
      
      if (node.type === 'integration') {
        handleIntegrationConfig(node);
      } else if (node.data.type === 'form') {
        setFormFields(node.data.formFields || []);
        setShowFormBuilder(true);
      } else {
        setShowNodeEditor(true);
      }
    },
    [handleIntegrationConfig]
  );

  const onNodeContextMenu = useCallback(
    (event, node) => {
      event.preventDefault();
      setSelectedNode(node);
      setContextMenuPosition({
        x: event.clientX,
        y: event.clientY,
      });
    },
    []
  );

  const closeContextMenu = useCallback(() => {
    setSelectedNode(null);
    setContextMenuPosition(null);
  }, []);

  const handleNodeDelete = useCallback(() => {
    setNodes((nds) => nds.filter((nd) => nd.id !== selectedNode.id));
    setEdges((eds) => eds.filter((ed) => ed.source !== selectedNode.id && ed.target !== selectedNode.id));
    closeContextMenu();
  }, [selectedNode, setNodes, setEdges, closeContextMenu]);

  const handleNodeUpdate = useCallback((nodeId, updatedData) => {
    setNodes((nds) =>
      nds.map((node) =>
        node.id === nodeId ? { ...node, data: { ...node.data, ...updatedData } } : node
      )
    );
    setShowNodeEditor(false);
  }, [setNodes]);

  const handleFormUpdate = useCallback((updatedFields) => {
    setFormFields(updatedFields);
  }, []);

  const handleFormSave = useCallback(() => {
    if (selectedNode) {
      setNodes((nds) =>
        nds.map((node) =>
          node.id === selectedNode.id
            ? { ...node, data: { ...node.data, formFields: formFields } }
            : node
        )
      );
    }
    setShowFormBuilder(false);
  }, [selectedNode, formFields, setNodes]);

  const onPaneClick = useCallback(() => {
    closeContextMenu();
  }, [closeContextMenu]);

  const handleGenerateWorkflow = (generatedWorkflow) => {
    setNodes(generatedWorkflow);
    const workflowType = determineWorkflowType(generatedWorkflow);
    setCurrentWorkflow(workflowType);
  };

  const handleLokCOToggle = () => {
    setShowLokCO(prev => !prev);
  };

  const renderTopBar = () => (
    <div className="flex items-center justify-between px-6 py-4 bg-white border-b">
      <div className="flex items-center gap-4">
        <Input
          value={workflowName}
          onChange={(e) => setWorkflowName(e.target.value)}
          className="font-bold text-xl w-64"
          placeholder="Workflow Name"
        />
        <TooltipProvider>
          <Tooltip>
            <TooltipTrigger>
              <HelpCircle className="h-5 w-5 text-gray-400 hover:text-gray-600" />
            </TooltipTrigger>
            <TooltipContent>
              Name your workflow to identify it easily
            </TooltipContent>
          </Tooltip>
        </TooltipProvider>
      </div>
      
      <div className="flex items-center gap-2">
        <Button onClick={handleLokCOToggle}>
          Show LokCO
        </Button>
      </div>
      <Button
        variant="outline"
        onClick={() => setShowGuidedTour(true)}
        className="flex items-center gap-2"
      >
        <HelpCircle className="h-4 w-4" />
        Take Tour
      </Button>
      <div className="flex items-center gap-3">
      

        <div className="flex items-center gap-2">
          <Button onClick={handleSave} variant="primary" className="flex items-center gap-2">
            <Save className="h-4 w-4" />
            Save
          </Button>
          
          <label className="cursor-pointer">
            <Input type="file" className="hidden" onChange={handleLoad} accept=".json" />
            <Button as="span" variant="outline" className="flex items-center gap-2">
              <Upload className="h-4 w-4" />
              Load
            </Button>
          </label>
          
          <Button onClick={handleDelete} variant="destructive" className="flex items-center gap-2 text-gray-400">
            <Trash className="h-4 w-4 text-gray-400" />
            Delete
          </Button>
        </div>
      </div>
    </div>
  );

  const renderGuidedTour = () => (
    <div className="fixed inset-0 bg-black/50 flex items-center justify-center z-50">
      <div className="bg-white rounded-xl p-8 max-w-2xl">
        <header className="mb-6">
          <h2 className="text-2xl font-bold text-gray-900">Welcome to the Workflow Builder</h2>
          <p className="text-gray-600 mt-2">Let's walk through the key features to get you started.</p>
        </header>

        <div className="space-y-6 mb-8">
          {currentGuideStep === 1 && (
            <div className="flex items-start gap-4">
              <div className="bg-purple-100 p-3 rounded-lg">
                <ClipboardCopy className="h-6 w-6 text-purple-600" />
              </div>
              <div>
                <h3 className="font-semibold text-gray-900 mb-1">Design Your Workflow</h3>
                <p className="text-gray-600">
                  Drag and drop elements from the sidebar to build your workflow. 
                  Connect them using the handles to create your process flow.
                </p>
              </div>
            </div>
          )}

          {currentGuideStep === 2 && (
            <div className="flex items-start gap-4">
              <div className="bg-blue-100 p-3 rounded-lg">
                <Tool className="h-6 w-6 text-blue-600" />
              </div>
              <div>
                <h3 className="font-semibold text-gray-900 mb-1">Technical Integrations</h3>
                <p className="text-gray-600">
                  Connect your workflow to systems like SAP, databases, and APIs. 
                  Don't worry - we'll guide you through the technical setup!
                </p>
              </div>
            </div>
          )}

          {currentGuideStep === 3 && (
            <div className="flex items-start gap-4">
              <div className="bg-green-100 p-3 rounded-lg">
                <FileQuestion className="h-6 w-6 text-green-600" />
              </div>
              <div>
                <h3 className="font-semibold text-gray-900 mb-1">Help & Support</h3>
                <p className="text-gray-600">
                  Enable Tech Tips or ask LokCO for help anytime. We're here to make 
                  technical workflows accessible to everyone.
                </p>
              </div>
            </div>
          )}
        </div>

        <footer className="flex items-center justify-between">
          <div className="flex gap-2">
            {[1, 2, 3].map((step) => (
              <div
                key={step}
                className={`w-2 h-2 rounded-full ${
                  currentGuideStep === step ? 'bg-purple-600' : 'bg-gray-300'
                }`}
              />
            ))}
          </div>
          
          <div className="flex gap-3">
            <Button 
              variant="outline"
              onClick={() => setShowGuidedTour(false)}
            >
              Skip Tour
            </Button>
            
            {currentGuideStep < 3 ? (
              <Button 
                variant="primary"
                onClick={() => setCurrentGuideStep(prev => prev + 1)}
              >
                Next Step
              </Button>
            ) : (
              <Button 
                variant="primary"
                onClick={() => setShowGuidedTour(false)}
              >
                Get Started
              </Button>
            )}
          </div>
        </footer>
      </div>
    </div>
  );

  // Render tech tips for integration nodes
  const renderTechTips = () => showTechTips && (
    <div className="grid grid-cols-3 gap-4 absolute top-24 right-4 z-50">
      <TechTip title="SAP Integration">
        Connect to SAP systems for data exchange. Don't worry about the technical details - 
        we'll guide you through the connection setup!
      </TechTip>
      
      <TechTip title="Database Connection">
        Easily connect to databases without writing complex queries. 
        Our visual tools help you map and transform data.
      </TechTip>
      
      <TechTip title="API Integration">
        Connect to external systems via APIs. No coding required - 
        just configure the connection using our simple interface.
      </TechTip>
    </div>
  );

  return (
    <div className="flex h-screen bg-gray-100">
      <Sidebar onDragStart={onDragStart} />
      <div className="flex-grow flex flex-col">
        {renderTopBar()}
        <IntegrationToolbar onAddIntegration={handleAddIntegration} />
        {renderTechTips()}
        
        <div className="flex-grow relative" ref={reactFlowWrapper}>
          <ReactFlow
            nodes={nodes}
            edges={edges}
            onNodesChange={onNodesChange}
            onEdgesChange={onEdgesChange}
            onConnect={onConnect}
            onInit={onInit}
            onDrop={onDrop}
            onDragOver={onDragOver}
            onNodeDoubleClick={onNodeDoubleClick}
            onNodeContextMenu={onNodeContextMenu}
            onPaneClick={onPaneClick}
            nodeTypes={nodeTypes}
            fitView
          >
            <Background />
            <Controls />
            <MiniMap />
          </ReactFlow>
          {selectedNode && contextMenuPosition && (
            <NodeContextMenu
              nodeId={selectedNode.id}
              position={contextMenuPosition}
              onEdit={() => setShowNodeEditor(true)}
              onDelete={handleNodeDelete}
            />
          )}
          {showLokCO && (
            <LokCO
              message={`How can I assist you with your ${currentWorkflow || 'workflow'} design?`}
              onClose={() => setShowLokCO(false)}
              onGenerateWorkflow={handleGenerateWorkflow}
              navigateToWorkflowBuilder={() => {}}
              currentWorkflow={currentWorkflow}
            />
          )}
        </div>
      </div>
      {showNodeEditor && selectedNode && (
        <NodeEditor
          node={selectedNode}
          onUpdate={handleNodeUpdate}
          onClose={() => setShowNodeEditor(false)}
        />
      )}

      {/* Add integration configuration dialog */}
      {showIntegrationConfig && selectedIntegration && (
          <IntegrationConfigDialog
            integration={selectedIntegration}
            onClose={() => setShowIntegrationConfig(false)}
            onUpdate={(config) => {
              handleConnectionStatusUpdate(selectedIntegration.id, 'Connected');
              setIntegrationConnections((prev) => ({
                ...prev,
                [selectedIntegration.id]: config,
              }));
              setShowIntegrationConfig(false);
            }}
          />
        )}
        
      {showFormBuilder && selectedNode && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
          <div className="bg-white p-6 rounded-lg w-5/6 h-5/6 overflow-auto">
            <h2 className="text-2xl font-bold mb-4">Edit Form: {selectedNode.data.label}</h2>
            <FormBuilder
              fields={selectedNode.data.formFields || []}
              onUpdate={handleFormUpdate}
            />
            <div className="mt-4 flex justify-end space-x-2">
              <Button onClick={() => setShowFormBuilder(false)} variant="outline">Cancel</Button>
              <Button onClick={handleFormSave} variant="primary">Save Changes</Button>
            </div>
          </div>
        </div>
      )}
      
      <AlertDialog open={showAlertDialog} onOpenChange={setShowAlertDialog}>
        <AlertDialogContent>
          <AlertDialogHeader>
            <AlertDialogTitle>{alertDialogContent.title}</AlertDialogTitle>
            <AlertDialogDescription>{alertDialogContent.description}</AlertDialogDescription>
          </AlertDialogHeader>
          <AlertDialogFooter>
            <AlertDialogCancel>Cancel</AlertDialogCancel>
            {alertDialogContent.action && (
              <AlertDialogAction onClick={alertDialogContent.action}>Confirm</AlertDialogAction>
            )}
            {!alertDialogContent.action && <AlertDialogAction>Okay</AlertDialogAction>}
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialog>
      
      {showGuidedTour && renderGuidedTour()}
    </div>
  );
};

export default function EnhancedWorkflowBuilder(props) {
  return (
    <ReactFlowProvider>
      <WorkflowBuilder {...props} />
    </ReactFlowProvider>
  );
}

