import React, { useState, useCallback, useEffect, useRef } from 'react';
import { Brain, Send, Loader, X, Paperclip, Lightbulb, AlertTriangle } from 'lucide-react';
import { Button } from './ui/Button';
import { 
  getRelevantRisks, 
  generateRiskReport, 
  getRiskAnalysisPrompts 
} from './riskAnalysisSuggestions';
import {
  getWorkflowSuggestion,
  analyzeUserInput,
} from './workflowSuggestions';

const TASK_SUGGESTIONS = [
  { 
    task: "Risk Assessment",
    description: "Analyze potential compliance risks", 
    category: 'risk'
  },
  { 
    task: "Generate GDPR Workflow", 
    description: "Create GDPR compliance process",
    category: 'workflow'
  },
  { 
    task: "Generate TPRM Workflow",
    description: "Create vendor risk management process",
    category: 'workflow'
  },
  { 
    task: "Review EU AI Act Requirements",
    description: "Check AI compliance status",
    category: 'risk'
  }
];

const WORKFLOW_OPTIONS = {
  tprm: {
    title: 'Third-Party Risk Management',
    variants: [
      {
        id: 'standard',
        name: 'Standard TPRM',
        description: 'Uniform assessment process for all vendors'
      },
      {
        id: 'riskBased',
        name: 'Risk-Based TPRM',
        description: 'Differentiated assessment based on vendor risk level'
      }
    ]
  },
  gdpr: {
    title: 'GDPR Compliance',
    variants: [
      {
        id: 'standard',
        name: 'Standard GDPR',
        description: 'Complete data protection workflow'
      }
    ]
  }
};

const LokCO = ({ 
  onClose, 
  initiateRiskAnalysis,
  onGenerateWorkflow,
  organization = {},
  currentWorkflow
}) => {
  const [state, setState] = useState({
    input: '',
    messages: [{
      text: "Hello! I'm LokCO, your Compliance Co-pilot. How can I assist you today?",
      sender: 'LokCO'
    }],
    isAnalyzing: false,
    isGeneratingWorkflow: false,
    riskAnalysis: {
      inProgress: false,
      currentRiskIndex: -1,
      relevantRisks: [],
      processedRisks: []
    },
    proactiveRecommendation: null,
    alternativeWorkflow: null,
    pendingWorkflow: null
  });
  const chatContainerRef = useRef(null);
  const fileInputRef = useRef(null);

  const addMessage = useCallback((text, sender) => {
    setState(prev => ({
      ...prev,
      messages: [...prev.messages, { text, sender }]
    }));
  }, []);

  const beginRiskAnalysis = useCallback(() => {
    const relevantRisks = getRelevantRisks(
      organization.industry,
      organization.region,
      organization.features
    );

    setState(prev => ({
      ...prev,
      riskAnalysis: {
        ...prev.riskAnalysis,
        inProgress: true,
        relevantRisks,
        currentRiskIndex: 0
      }
    }));

    addMessage(getRiskAnalysisPrompts(0), 'LokCO');
    presentRiskFactor(relevantRisks[0]);
  }, [organization, addMessage]);

  useEffect(() => {
    if (initiateRiskAnalysis) {
      beginRiskAnalysis();
    }
  }, [initiateRiskAnalysis, beginRiskAnalysis]);

  useEffect(() => {
    if (chatContainerRef.current) {
      chatContainerRef.current.scrollTop = chatContainerRef.current.scrollHeight;
    }
  }, [state.messages]);

  const presentRiskFactor = (risk) => {
    if (!risk) return;
    
    addMessage(`
Risk Factor: ${risk.title}
Severity: ${risk.severity.toUpperCase()}
Description: ${risk.description}

Is this relevant to your organization?`, 'LokCO');
  };

  const processRiskResponse = (response) => {
    const { currentRiskIndex, relevantRisks, processedRisks } = state.riskAnalysis;
    const currentRisk = relevantRisks[currentRiskIndex];

    if (response.toLowerCase().includes('yes')) {
      const updatedProcessedRisks = [...processedRisks, currentRisk];
      setState(prev => ({
        ...prev,
        riskAnalysis: {
          ...prev.riskAnalysis,
          processedRisks: updatedProcessedRisks
        }
      }));

      addMessage(`Adding ${currentRisk.title} to analysis. Here are recommended actions:
${currentRisk.recommendation}`, 'LokCO');
    }

    const nextIndex = currentRiskIndex + 1;
    if (nextIndex < relevantRisks.length) {
      setState(prev => ({
        ...prev,
        riskAnalysis: {
          ...prev.riskAnalysis,
          currentRiskIndex: nextIndex
        }
      }));
      presentRiskFactor(relevantRisks[nextIndex]);
    } else {
      completeRiskAnalysis();
    }
  };

  const completeRiskAnalysis = () => {
    const report = generateRiskReport(
      state.riskAnalysis.processedRisks,
      organization.currentControls || []
    );

    addMessage(`Risk Analysis Complete

Key Findings:
${report.map(risk => `• ${risk.title}
  - Priority: ${risk.timeline.priority}
  - Next Steps: ${risk.requiredActions[0]}`).join('\n')}

Would you like a detailed action plan for any specific risk?`, 'LokCO');

    setState(prev => ({
      ...prev,
      riskAnalysis: {
        ...prev.riskAnalysis,
        inProgress: false
      }
    }));
  };

  const handleWorkflowGeneration = async (workflowType, variant = 'standard') => {
    setState(prev => ({ ...prev, isGeneratingWorkflow: true }));
    
    const workflow = getWorkflowSuggestion(workflowType, variant);
    await onGenerateWorkflow(workflow);
    
  // For TPRM workflows, suggest the alternative variant
  if (workflowType === 'tprm') {
    const variantName = variant === 'standard' ? 'Risk-Based' : 'Standard';
    const suggestion = variant === 'standard' 
      ? `I've generated a Standard TPRM workflow. Given your organization's profile, you might also consider a Risk-Based TPRM workflow that:

      1. Tailors assessment depth based on vendor risk levels
      2. Optimizes resource allocation
      3. Focuses enhanced due diligence on high-risk vendors

      Would you like me to generate a Risk-Based TPRM workflow instead?`
      : `I've generated a Risk-Based TPRM workflow. I can also show you a Standard TPRM workflow for comparison if you'd like to see both approaches.`;

    addMessage(suggestion, 'LokCO');

    setState(prev => ({
      ...prev,
      isGeneratingWorkflow: false,
      alternativeWorkflow: {
        type: 'tprm',
        currentVariant: variant,
        available: true
      }
    }));
  } else {
    addMessage(`I've generated a ${workflowType.toUpperCase()} workflow. Would you like me to explain any specific part?`, 'LokCO');
    setState(prev => ({ ...prev, isGeneratingWorkflow: false }));
  }
};

const processUserInput = async (input) => {
  const lowerInput = input.toLowerCase();
  setState(prev => ({ ...prev, isAnalyzing: true }));

  if (state.alternativeWorkflow?.available) {
    if (lowerInput.includes('yes') || lowerInput.includes('show') || lowerInput.includes('generate')) {
      const newVariant = state.alternativeWorkflow.currentVariant === 'standard' ? 'riskBased' : 'standard';
      await handleWorkflowGeneration(state.alternativeWorkflow.type, newVariant);
    }
    setState(prev => ({ ...prev, alternativeWorkflow: null }));
  } else if (state.pendingWorkflow?.awaitingSelection) {
    const selection = parseInt(input);
    const options = WORKFLOW_OPTIONS[state.pendingWorkflow.type];
    if (selection > 0 && selection <= options.variants.length) {
      const variant = options.variants[selection - 1].id;
      await handleWorkflowGeneration(state.pendingWorkflow.type, variant);
    }
    setState(prev => ({ ...prev, pendingWorkflow: null }));
  } else if (state.riskAnalysis.inProgress) {
    processRiskResponse(input);
  } else if (lowerInput.includes('generate') && lowerInput.includes('workflow')) {
    const analysis = analyzeUserInput(input);
    if (analysis) {
      presentWorkflowOptions(analysis.type);
    }
  } else if (lowerInput.includes('risk') || lowerInput.includes('analysis')) {
    beginRiskAnalysis();
  } else {
    addMessage("I understand you're interested in " + input + ". Would you like to start with a risk analysis or generate a specific workflow?", 'LokCO');
  }

  setState(prev => ({ ...prev, isAnalyzing: false }));
};

  const handleSend = () => {
    if (state.input.trim()) {
      addMessage(state.input, 'User');
      processUserInput(state.input);
      setState(prev => ({ ...prev, input: '' }));
    }
  };

  const handleKeyPress = (e) => {
    if (e.key === 'Enter' && !e.shiftKey) {
      e.preventDefault();
      handleSend();
    }
  };

  const handleSuggestionClick = (suggestion) => {
    addMessage(suggestion.task, 'User');
    if (suggestion.category === 'risk') {
      beginRiskAnalysis();
    } else {
      handleWorkflowGeneration(suggestion.task.toLowerCase().includes('gdpr') ? 'gdpr' : 'tprm');
    }
  };


  const presentWorkflowOptions = (workflowType) => {
    const options = WORKFLOW_OPTIONS[workflowType];
    if (!options) return;
  
    const message = `For ${options.title}, I can offer these workflow variants:
  ${options.variants.map((v, i) => `
  ${i + 1}. ${v.name}
     ${v.description}`).join('\n')}
  
  Which variant would you prefer? (Enter the number)`;
  
    addMessage(message, 'LokCO');
    setState(prev => ({
      ...prev,
      pendingWorkflow: {
        type: workflowType,
        awaitingSelection: true
      }
    }));
  };
  

  return (
    <div className="fixed bottom-4 right-4 w-96 bg-white rounded-lg shadow-xl border border-gray-200 z-50">
      <div className="flex justify-between items-center p-4 bg-blue-600 text-white rounded-t-lg">
        <div className="flex items-center">
          <Brain className="w-6 h-6 mr-2" />
          <h4 className="text-lg font-semibold">LokCO</h4>
        </div>
        <button onClick={onClose} className="text-white hover:text-gray-200">
          <X className="w-5 h-5" />
        </button>
      </div>

      {state.riskAnalysis.inProgress && (
        <div className="bg-yellow-50 p-2 text-sm flex items-center">
          <AlertTriangle className="w-4 h-4 text-yellow-500 mr-2" />
          Risk Analysis in Progress
        </div>
      )}

      <div ref={chatContainerRef} className="h-96 overflow-auto p-4 space-y-4">
        {state.messages.map((msg, index) => (
          <div key={index} className={`flex ${msg.sender === 'User' ? 'justify-end' : 'justify-start'}`}>
            <div className={`max-w-[75%] p-3 rounded-lg ${
              msg.sender === 'User' ? 'bg-blue-500 text-white' : 'bg-gray-100 text-gray-500'
            }`}>
              <p className="text-sm whitespace-pre-wrap">{msg.text}</p>
            </div>
          </div>
        ))}
        {(state.isAnalyzing || state.isGeneratingWorkflow) && (
          <div className="flex items-center justify-center">
            <Loader className="w-5 h-5 text-blue-500 animate-spin" />
            <span className="ml-2 text-sm text-gray-600">
              {state.isGeneratingWorkflow ? "Generating workflow..." : "Analyzing..."}
            </span>
          </div>
        )}
      </div>

      <div className="p-4 border-t">
        <div className="mb-2">
          <h5 className="text-sm font-semibold mb-1 text-gray-400">Suggestions:</h5>
          <div className="flex flex-wrap gap-2">
            {TASK_SUGGESTIONS.map((suggestion, index) => (
              <button
                key={index}
                onClick={() => handleSuggestionClick(suggestion)}
                className="text-xs bg-blue-100 text-blue-700 px-2 py-1 rounded hover:bg-blue-200"
                title={suggestion.description}
              >
                {suggestion.task}
              </button>
            ))}
          </div>
        </div>

        <div className="flex items-center">
          <textarea
            value={state.input}
            onChange={(e) => setState(prev => ({ ...prev, input: e.target.value }))}
            onKeyDown={handleKeyPress}
            placeholder="Ask LokCO..."
            className="flex-1 border rounded-l-md p-2 text-gray-500 focus:outline-none focus:ring-2 focus:ring-blue-500 resize-none"
            rows="2"
          />
          <Button 
            onClick={() => fileInputRef.current.click()} 
            className="px-3 py-2 bg-gray-200 hover:bg-gray-300"
          >
            <Paperclip className="w-5 h-5" />
          </Button>
          <input type="file" ref={fileInputRef} className="hidden" />
          <Button 
            onClick={handleSend} 
            className="px-4 py-2 bg-blue-600 hover:bg-blue-700 text-white rounded-r-md"
          >
            <Send className="w-5 h-5" />
          </Button>
        </div>
      </div>
      </div>
    );
  };

  export default LokCO;