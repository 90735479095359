import React, { useState, forwardRef } from 'react';
import { Input } from './ui/Input';
import { Select } from './ui/Select';
import { Button } from './ui/Button';
import { ArrowRight } from 'lucide-react';

const CompanyProfileSetup = forwardRef(({ onComplete }, ref) => {
  const [profile, setProfile] = useState({
    companyName: '',
    industry: '',
    size: '',
    country: '',
    region: '',
  });

  const handleInputChange = (e) => {
    setProfile({ ...profile, [e.target.name]: e.target.value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    onComplete(profile);
  };

  return (
    <div ref={ref} className="max-w-3xl mx-auto">
      <h2 className="text-2xl font-bold text-center text-purple-800 mb-4">
        Company Profile Setup
      </h2>
     
      <div className="bg-white p-8 rounded-lg shadow-md">
        <form onSubmit={handleSubmit} className="space-y-6">
          <Input
            name="companyName"
            value={profile.companyName}
            onChange={handleInputChange}
            placeholder="Company Name"
            required
            className="text-gray-800"
          />
          <Select
            name="industry"
            value={profile.industry}
            onChange={handleInputChange}
            required
            className="text-gray-800"
          >
            <option value="">Select Industry</option>
            <option value="healthcare">Healthcare</option>
            <option value="pharmaceutical">Pharmaceutical</option>
            <option value="biotech">Biotech</option>
            <option value="medtech">MedTech</option>
          </Select>
          <Select
            name="size"
            value={profile.size}
            onChange={handleInputChange}
            required
            className="text-gray-800"
          >
            <option value="">Company Size</option>
            <option value="small">Small (1-50 employees)</option>
            <option value="medium">Medium (51-500 employees)</option>
            <option value="large">Large (500+ employees)</option>
          </Select>
          <Select
            name="country"
            value={profile.country}
            onChange={handleInputChange}
            required
            className="text-gray-800"
          >
            <option value="">Select Country</option>
            <option value="US">United States</option>
            <option value="UK">United Kingdom</option>
            <option value="Germany">Germany</option>
            <option value="France">France</option>
            <option value="Other">Other</option>
          </Select>
          <Select
            name="region"
            value={profile.region}
            onChange={handleInputChange}
            required
            className="text-gray-800"
          >
            <option value="">Select Region</option>
            <option value="North America">North America</option>
            <option value="Europe">Europe</option>
            <option value="Asia">Asia</option>
            <option value="Other">Other</option>
          </Select>
          <div className="flex justify-end">
            <Button
              type="submit"
              variant="primary"
              className="flex items-center"
            >
              Next <ArrowRight className="ml-2" />
            </Button>
          </div>
        </form>
      </div>
    </div>
  );
});

CompanyProfileSetup.displayName = 'CompanyProfileSetup';

export default CompanyProfileSetup;
