// components/Notification.js
import React from 'react';

const Notification = ({ title, message, type, onClose }) => {
  const bgColor = type === 'error' ? 'bg-red-100' : 'bg-green-100';
  const textColor = type === 'error' ? 'text-red-800' : 'text-green-800';

  return (
    <div className={`fixed top-4 right-4 p-4 rounded-md shadow-md ${bgColor} ${textColor}`}>
      <h3 className="font-bold">{title}</h3>
      <p>{message}</p>
      <button onClick={onClose} className="mt-2 text-sm underline">Close</button>
    </div>
  );
};

export default Notification;