import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { 
  ShieldCheck,
  Brain,
  Download,
  Info,
  FileText,
  MessageSquare,
  ChevronUp,
  ChevronDown,
  Activity,
  History,
  Workflow, X
} from 'lucide-react';
import { Card, CardContent } from './ui/Card';
import { Collapsible, CollapsibleContent, CollapsibleTrigger } from './ui/Collapsible';
import { Button } from './ui/Button';
import { Textarea } from './ui/Textarea';
import LokCOIntegration from './LokCOIntegration';
import MockAuditWizard from './MockAuditWizard';


const QuickNote = ({ sectionId }) => {
  const [note, setNote] = useState('');
  const [isEditing, setIsEditing] = useState(false);

  useEffect(() => {
    const savedNote = localStorage.getItem(`euaia-note-${sectionId}`);
    if (savedNote) setNote(savedNote);
  }, [sectionId]);

  const saveNote = () => {
    localStorage.setItem(`euaia-note-${sectionId}`, note);
    setIsEditing(false);
  };

  return (
    <div className="mt-2 bg-gray-50 rounded-lg p-3">
      {isEditing ? (
        <div className="space-y-2">
          <Textarea
            value={note}
            onChange={(e) => setNote(e.target.value)}
            placeholder="Add your notes here..."
            className="min-h-[100px]"
          />
          <div className="flex justify-end space-x-2">
            <Button variant="outline" size="sm" onClick={() => setIsEditing(false)}>
              Cancel
            </Button>
            <Button size="sm" onClick={saveNote}>
              Save Note
            </Button>
          </div>
        </div>
      ) : (
        <div 
          className="min-h-[40px] cursor-pointer hover:bg-gray-100 p-2 rounded text-gray-500"
          onClick={() => setIsEditing(true)}
        >
          {note || "Click to add notes..."}
        </div>
      )}
    </div>
  );
};


const SectionCard = ({ title, children, variant = "default" }) => {
  const [isOpen, setIsOpen] = useState(true);
  
  const variantStyles = {
    default: "bg-white",
    impact: "bg-blue-50 border-blue-200",
    compliance: "bg-green-50 border-green-200"
  };

  return (
    <Collapsible open={isOpen} onOpenChange={setIsOpen}>
      <Card className={`${variantStyles[variant]} transition-all duration-200`}>
        <CollapsibleTrigger className="w-full">
          <div className="flex justify-between items-center p-4 hover:bg-opacity-50">
            <h4 className="text-lg font-semibold text-gray-600">{title}</h4>
            {isOpen ? <ChevronUp className="h-4 w-4" /> : <ChevronDown className="h-4 w-4" />}
          </div>
        </CollapsibleTrigger>
        <CollapsibleContent>
          <CardContent>
            {children}
          </CardContent>
        </CollapsibleContent>
      </Card>
    </Collapsible>
  );
};

const PracticalTip = ({ children }) => (
  <div className="flex items-start gap-2 p-4 bg-blue-50 rounded-lg mt-2">
    <div className="text-sm text-blue-700">
      💡 {children}
    </div>
  </div>
);

const ContextualHelp = ({ onHelp, className = "" }) => (
  <Button
    variant="ghost"
    size="sm"
    onClick={onHelp}
    className={`opacity-0 group-hover:opacity-100 transition-opacity p-1 hover:bg-gray-100 ${className}`}
  >
    <MessageSquare className="h-4 w-4 text-blue-600" />
  </Button>
);

const EUAIActOverview = () => {
  const navigate = useNavigate();
  const [showLokCO, setShowLokCO] = useState(false);
  const [showAuditWizard, setShowAuditWizard] = useState(false);
  const [actionItems, setActionItems] = useState([
    { id: 1, text: "Conduct an organization-wide AI inventory and risk assessment", completed: false },
    { id: 2, text: "Establish a cross-functional AI governance committee", completed: false },
    { id: 3, text: "Develop a compliance roadmap with clear milestones and responsibilities", completed: false },
    { id: 4, text: "Invest in AI literacy training for staff at all levels", completed: false },
    { id: 5, text: "Engage with regulatory bodies and industry peers for best practice sharing", completed: false },
  ]);


  useEffect(() => {
    const savedItems = localStorage.getItem('euAiActActionItems');
    if (savedItems) {
      setActionItems(JSON.parse(savedItems));
    }
  }, []);

  useEffect(() => {
    localStorage.setItem('euAiActActionItems', JSON.stringify(actionItems));
  }, [actionItems]);

  const handleWorkflowTemplate = (templateId) => {
    navigate('/workflow-builder', { 
      state: { templateId, context: 'eu-ai-act' } 
    });
  };

  const impactAreas = [
    { 
      icon: ShieldCheck, 
      title: "Clinical Decision Support",
      description: "AI systems for diagnosis and treatment planning will require rigorous validation and continuous monitoring.",
      tip: "Start by creating an inventory of all AI-powered clinical decision support tools and map them to EU AI Act risk categories.",
    },
    { 
      icon: ShieldCheck,
      title: "Drug Discovery",
      description: "AI models used in drug discovery must adhere to strict data governance and transparency standards.",
      tip: "Document your data governance practices and ensure transparency in model development.",
    },
    { 
      icon: ShieldCheck,
      title: "Patient Data Management",
      description: "Enhanced data protection measures and explicit consent mechanisms for AI-driven patient data analysis.",
      tip: "Review and update your data protection policies to include AI-specific considerations.",
    },
    { 
      icon: ShieldCheck,
      title: "Human Oversight",
      description: "Mandatory human supervision for AI systems used in critical healthcare decisions.",
      tip: "Develop clear protocols for human oversight in AI-driven decision making.",
    },
  ];


  const handleShowAuditWizard = () => {
    setShowAuditWizard(true);
    // Wait for the component to render before scrolling
    setTimeout(() => {
      const auditSection = document.getElementById('audit-wizard-section');
      if (auditSection) {
        auditSection.scrollIntoView({ 
          behavior: 'smooth',
          block: 'start'
        });
      }
    }, 100);
  };

  const complianceSteps = [
    {
      icon: Info, 
      title: "Risk Assessment",
      description: "Conduct thorough risk assessments for all AI systems used in healthcare settings.",
      tip: "Use our pre-built assessment templates for healthcare AI systems.",
    },
    {
      icon: FileText,
      title: "Documentation",
      description: "Maintain comprehensive technical documentation for all AI systems.",
      tip: "Follow our specifically designed checklist for your industry.",
    },
    {
      icon: Activity,
      title: "Continuous Monitoring",
      description: "Implement ongoing monitoring of AI system performance and compliance.",
      tip: "Set up automated monitoring using our compliance tracking tools.",
      actions: [
        {
          label: "Configure Monitoring",
          onClick: handleShowAuditWizard, 
          icon: Activity
        },
        {
          label: "View Reports",
          onClick: () => console.log("View reports"),
          icon: History
        }
      ]
    },
    {
      icon: History,
      title: "Regular Audits",
      description: "Conduct periodic audits of AI systems and processes. Stay ever ready with our Mock Audit Wizard.",
      tip: "Schedule quarterly internal audits using our audit framework.",
      actions: [
        {
          label: "Start Audit",
          onClick: handleShowAuditWizard, 
          icon: Activity
        },
        {
          label: "View Audit History",
          onClick: () => console.log("View audit history"),
          icon: History
        }
      ]
    },
  ];

  return (
    <div className="space-y-6">
      {/* Header Actions */}
      <div className="flex justify-between items-center mb-6">
        <div>
          <h2 className="text-3xl font-bold text-blue-800">EU AI Act: Healthcare & Pharma Impact</h2>
          <p className="text-gray-600 mt-2">Last updated: October 31, 2024</p>
        </div>
        <div className="flex gap-4">
          <Button variant="default" className="bg-purple-600 hover:bg-purple-700 text-white" onClick={() => setShowLokCO(true)}>
            <Brain className="mr-2 h-4 w-4" />
            Ask LokCO
          </Button>
          <Button variant="outline">
            <Download className="mr-2 h-4 w-4" />
            Export Summary
          </Button>
        </div>
      </div>

      {/* Overview Section */}
      <Card>
        <CardContent className="pt-6">
          <p className="text-lg text-gray-700 mb-4">
            The EU AI Act introduces comprehensive regulations for AI systems, with significant implications for healthcare and pharmaceutical industries. This framework aims to ensure patient safety, data privacy, and ethical use of AI in medical applications.
          </p>
          <PracticalTip>
            Start with a gap analysis of your current AI systems against EU AI Act requirements. Use our interactive assessment tool to identify priority areas.
          </PracticalTip>
          <QuickNote sectionId="overview" />
        </CardContent>
      </Card>

      {/* Impact Areas */}
      <section className="space-y-4">
        <h3 className="text-2xl font-semibold text-blue-800">Key Impact Areas</h3>
        <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
          {impactAreas.map((area, index) => (
            <SectionCard 
              key={index}
              title={area.title}
              variant="impact"
            >
              <div className="flex items-start gap-4 group">
                <area.icon className="w-8 h-8 text-blue-600 flex-shrink-0" />
                <div className="flex-1">
                  <p className="text-gray-700 mb-4">{area.description}</p>
                  <PracticalTip>{area.tip}</PracticalTip>
                  <div className="mt-4 flex items-center gap-2">
                    <Button
                      variant="outline"
                      size="sm"
                      onClick={() => handleWorkflowTemplate(area.templateId)}
                    >
                      <Workflow className="mr-2 h-4 w-4" />
                      Use Workflow Template
                    </Button>
                    <ContextualHelp 
                      onHelp={() => setShowLokCO(true)}
                    />
                  </div>
                </div>
              </div>
            </SectionCard>
          ))}
        </div>
      </section>

      {/* Compliance Steps */}
      <section className="space-y-4">
        <h3 className="text-2xl font-semibold text-green-800">Compliance Steps</h3>
        <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
          {complianceSteps.map((step, index) => (
            <SectionCard
              key={index}
              title={step.title}
              variant="compliance"
            >
              <div className="flex items-start gap-4 group">
                <step.icon className="w-8 h-8 text-green-600 flex-shrink-0" />
                <div className="flex-1">
                  <p className="text-gray-700 mb-4">{step.description}</p>
                  <PracticalTip>{step.tip}</PracticalTip>
                  <div className="mt-4 flex flex-wrap gap-2">
                    {step.actions?.map((action, actionIndex) => (
                      <Button
                        key={actionIndex}
                        variant="outline"
                        size="sm"
                        onClick={action.onClick}
                      >
                        <action.icon className="mr-2 h-4 w-4" />
                        {action.label}
                      </Button>
                    ))}
                    <ContextualHelp 
                      onHelp={() => setShowLokCO(true)}
                    />
                  </div>
                </div>
              </div>
            </SectionCard>
          ))}
        </div>
      </section>

      {/* Audit Wizard - Now Inline */}
      {showAuditWizard && (
      <section id="audit-wizard-section" className="mt-6 scroll-mt-6 animate-fade-in">
        <Card>
          <CardContent className="p-6">
              <div className="flex justify-between items-center mb-4">
                <h2 className="text-xl font-semibold text-gray-700">Compliance Audit Wizard</h2>
                <Button
                  variant="ghost"
                  size="sm"
                  onClick={() => setShowAuditWizard(false)}
                  className="hover:bg-gray-100"
                >
                  <X className="w-4 h-4" />
                </Button>
              </div>
              <MockAuditWizard onClose={() => setShowAuditWizard(false)} />
            </CardContent>
          </Card>
        </section>
      )}

      {/* LokCO Integration remains as a floating component */}
      {showLokCO && (
        <LokCOIntegration
          onClose={() => setShowLokCO(false)}
          context="eu-ai-act"
        />
      )}
    </div>
  );
};

export default EUAIActOverview;