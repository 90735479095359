import React, { useEffect, useState } from 'react';
import { useForm, useFieldArray, Controller } from 'react-hook-form';
import { Input } from '../components/ui/Input';
import { Textarea } from '../components/ui/Textarea';
import { Button } from '../components/ui/Button';
import { Card, CardHeader, CardContent } from '../components/ui/Card';
import { Tooltip, TooltipContent, TooltipProvider, TooltipTrigger } from '../components/ui/Tooltip';
import useAISuggestions from '../components/useAISuggestions';
import { Sparkles, Bot, AlertTriangle } from 'lucide-react';
import DatePicker from '../components/ui/DatePicker';

const AuditPlanning = ({ data, updateData, setStepValidity }) => {
  const {
    control,
    handleSubmit,
    formState: { errors, isValid },
    watch,
  } = useForm({
    defaultValues: data,
    mode: 'onChange',
  });

  const { fields, append, remove } = useFieldArray({
    control,
    name: 'auditSteps',
  });

  const { aiSuggestions, updateAISuggestions, isLoading, error } = useAISuggestions();
  const [aiGeneratedSteps, setAiGeneratedSteps] = useState([]);

  const watchAuditSteps = watch('auditSteps');

  useEffect(() => {
    if (data) {
      updateAISuggestions(data);
    }
  }, [data, updateAISuggestions]);

  useEffect(() => {
    if (aiSuggestions && aiSuggestions.auditSteps && fields.length === 0) {
      const newSteps = aiSuggestions.auditSteps.map((step) => ({
        name: step.name || '',
        description: step.description || '',
        dueDate: step.dueDate ? new Date(step.dueDate) : null,
        isAiGenerated: true,
      }));
      setAiGeneratedSteps(newSteps.map((step) => step.name));
      newSteps.forEach((step) => append(step));
    }
  }, [aiSuggestions, append, fields.length]);

  useEffect(() => {
    const isStepValid =
      isValid &&
      watchAuditSteps.length > 0 &&
      watchAuditSteps.every(
        (step) => step.name && step.description && step.dueDate
      );
    setStepValidity(isStepValid);
  }, [isValid, watchAuditSteps, setStepValidity]);

  const onSubmit = (formData) => {
    updateData(formData);
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)} className="space-y-6">
      <h3 className="text-2xl font-semibold mb-4 flex items-center">
        Audit Planning
        <Sparkles className="ml-2 text-yellow-500" />
      </h3>

      {isLoading && (
        <div className="flex items-center justify-center space-x-2 text-blue-500">
          <Bot className="animate-pulse" />
          <p>AI is generating audit step suggestions...</p>
        </div>
      )}
      {error && (
        <div className="flex items-center space-x-2 text-red-500">
          <AlertTriangle />
          <p>Error: {error}</p>
        </div>
      )}

      {fields.map((field, index) => (
        <Card
          key={field.id}
          className={`${
            aiGeneratedSteps.includes(field.name)
              ? 'border-blue-300 bg-blue-50'
              : ''
          }`}
        >
          <CardHeader className="flex flex-row items-center justify-between space-y-0 pb-2">
            <h4 className="text-sm font-medium">Audit Step {index + 1}</h4>
            {aiGeneratedSteps.includes(field.name) && (
              <TooltipProvider>
                <Tooltip>
                  <TooltipTrigger>
                    <Bot className="text-blue-500" />
                  </TooltipTrigger>
                  <TooltipContent>
                    <p>AI-generated audit step based on identified risks</p>
                  </TooltipContent>
                </Tooltip>
              </TooltipProvider>
            )}
          </CardHeader>
          <CardContent>
            <Controller
              name={`auditSteps.${index}.name`}
              control={control}
              rules={{ required: 'Step name is required' }}
              render={({ field }) => (
                <Input {...field} placeholder="Audit Step Name" className="mb-2" />
              )}
            />
            {errors.auditSteps?.[index]?.name && (
              <p className="text-red-500 text-sm mt-1">
                {errors.auditSteps[index].name.message}
              </p>
            )}

            <Controller
              name={`auditSteps.${index}.description`}
              control={control}
              rules={{ required: 'Step description is required' }}
              render={({ field }) => (
                <Textarea
                  {...field}
                  placeholder="Step Description"
                  className="mb-2"
                />
              )}
            />
            {errors.auditSteps?.[index]?.description && (
              <p className="text-red-500 text-sm mt-1">
                {errors.auditSteps[index].description.message}
              </p>
            )}

            <Controller
              name={`auditSteps.${index}.dueDate`}
              control={control}
              rules={{ required: 'Due date is required' }}
              render={({ field }) => (
                <DatePicker
                  selected={field.value}
                  onChange={(date) => field.onChange(date)}
                  placeholderText="Select due date"
                  className="mb-2 w-full"
                />
              )}
            />
            {errors.auditSteps?.[index]?.dueDate && (
              <p className="text-red-500 text-sm mt-1">
                {errors.auditSteps[index].dueDate.message}
              </p>
            )}

            <Button
              type="button"
              onClick={() => remove(index)}
              className="mt-2"
              variant="destructive"
            >
              Remove Step
            </Button>
          </CardContent>
        </Card>
      ))}

      <Button
        type="button"
        onClick={() =>
          append({ name: '', description: '', dueDate: null, isAiGenerated: false })
        }
        className="mt-4"
      >
        Add Audit Step
      </Button>

      <Button type="submit" className="mt-6">
        Save and Continue
      </Button>
    </form>
  );
};

export default AuditPlanning;