import React, { useEffect } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import * as z from 'zod';
import { motion } from 'framer-motion';
import { Button } from '../components/ui/Button';
import Select from 'react-select';
import { Input } from '../components/ui/Input';
import { Textarea } from '../components/ui/Textarea';
import {
  FormControl,
  FormItem,
  FormLabel,
  FormMessage,
  FormDescription,
} from '../components/ui/Form';
import DatePicker from '../components/ui/DatePicker';
import {
  TooltipProvider,
  Tooltip,
  TooltipTrigger,
  TooltipContent,
} from '../components/ui/Tooltip';
import { Card, CardHeader, CardContent } from '../components/ui/Card';
import { Progress } from '../components/ui/Progress';
import { Tabs, TabsList, TabsTrigger, TabsContent } from '../components/ui/Tabs';
import {
  CalendarIcon,
  Users,
  Building,
  Globe,
  Cog,
  FileText,
  AlertTriangle,
  Lock,
  ClipboardList,
} from 'lucide-react';

const formSchema = z.object({
  auditObjective: z.string().min(1, 'Audit objective is required'),
  auditType: z
    .object({ value: z.string(), label: z.string() })
    .nullable()
    .refine((val) => val !== null, 'Audit type is required'),
  businessUnits: z
    .array(z.object({ value: z.string(), label: z.string() }))
    .min(1, 'Please select at least one business unit'),
  subsidiaries: z
    .array(z.object({ value: z.string(), label: z.string() }))
    .min(1, 'Please select at least one subsidiary'),
  geographicLocations: z
    .array(z.object({ value: z.string(), label: z.string() }))
    .min(1, 'Please select at least one location'),
  processes: z
    .array(z.object({ value: z.string(), label: z.string() }))
    .min(1, 'Please select at least one process'),
  regulatoryFrameworks: z
    .array(z.object({ value: z.string(), label: z.string() }))
    .min(1, 'Please select at least one regulatory framework'),
  riskAreas: z
    .array(z.object({ value: z.string(), label: z.string() }))
    .min(1, 'Please select at least one risk area'),
  auditScope: z.string().min(1, 'Audit scope is required'),
  auditStartDate: z.date().refine((val) => val !== null, 'Start date is required'),
  auditEndDate: z.date().refine((val) => val !== null, 'End date is required'),
  auditTeam: z
    .array(z.object({ value: z.string(), label: z.string() }))
    .min(1, 'Please select at least one team member'),
  stakeholders: z
    .array(z.object({ value: z.string(), label: z.string() }))
    .min(1, 'Please select at least one stakeholder'),
  confidentiality: z
    .object({ value: z.string(), label: z.string() })
    .nullable()
    .refine((val) => val !== null, 'Please select confidentiality level'),
  expectedDeliverables: z.string().min(1, 'Please describe expected deliverables'),
});

const AuditScopeDefinition = ({
  data,
  updateData,
  currentStep,
  totalSteps,
  setStepValidity,
}) => {
  const form = useForm({
    resolver: zodResolver(formSchema),
    defaultValues: data,
    mode: 'onChange', // This will make the form validate on change
  });

  // Use useEffect to update the parent component about form validity
  useEffect(() => {
    const subscription = form.watch((value) => {
      const isValid = form.formState.isValid;
      const formErrors = form.formState.errors;
      console.log('Form validity:', isValid);
      console.log('Form errors:', formErrors);
      console.log('Form values:', value);
      setStepValidity(isValid);
    });
    return () => subscription.unsubscribe();
  }, [form, setStepValidity]);

  function onSubmit(values) {
    updateData(values);
  }

  const fadeInUp = {
    initial: { opacity: 0, y: 20 },
    animate: { opacity: 1, y: 0 },
    transition: { duration: 0.5 },
  };

  const auditTypes = [
    { value: 'internal', label: 'Internal Audit' },
    { value: 'external', label: 'External Audit' },
    { value: 'regulatory', label: 'Regulatory Audit' },
    { value: 'supplier', label: 'Supplier Audit' },
    { value: 'quality', label: 'Quality Audit' },
    { value: 'financial', label: 'Financial Audit' },
    { value: 'it', label: 'IT Audit' },
    { value: 'operational', label: 'Operational Audit' },
  ];

  const businessUnits = [
    { value: 'manufacturing', label: 'Manufacturing' },
    { value: 'rnd', label: 'R&D' },
    { value: 'qa', label: 'Quality Assurance' },
    { value: 'regulatory', label: 'Regulatory Affairs' },
    { value: 'sales', label: 'Sales & Marketing' },
    { value: 'supply_chain', label: 'Supply Chain' },
    { value: 'finance', label: 'Finance' },
    { value: 'it', label: 'IT' },
    { value: 'hr', label: 'HR' },
  ];

  const subsidiaries = [
    { value: 'subsidiary1', label: 'Subsidiary 1' },
    { value: 'subsidiary2', label: 'Subsidiary 2' },
    { value: 'subsidiary3', label: 'Subsidiary 3' },
  ];

  const geographicLocations = [
    { value: 'north_america', label: 'North America' },
    { value: 'europe', label: 'Europe' },
    { value: 'asia_pacific', label: 'Asia Pacific' },
    { value: 'latin_america', label: 'Latin America' },
    { value: 'middle_east', label: 'Middle East' },
    { value: 'africa', label: 'Africa' },
  ];

  const processes = [
    { value: 'clinical_trials', label: 'Clinical Trials' },
    { value: 'drug_safety', label: 'Drug Safety' },
    { value: 'pharmacovigilance', label: 'Pharmacovigilance' },
    { value: 'production', label: 'Production Processes' },
    { value: 'quality_control', label: 'Quality Control' },
    { value: 'data_management', label: 'Data Management' },
  ];

  const regulatoryFrameworks = [
    { value: 'fda', label: 'FDA' },
    { value: 'ema', label: 'EMA' },
    { value: 'iso13485', label: 'ISO 13485' },
    { value: 'iso9001', label: 'ISO 9001' },
    { value: 'gxp', label: 'GxP' },
    { value: 'hipaa', label: 'HIPAA' },
  ];

  const riskAreas = [
    { value: 'compliance', label: 'Compliance' },
    { value: 'financial', label: 'Financial' },
    { value: 'operational', label: 'Operational' },
    { value: 'strategic', label: 'Strategic' },
    { value: 'reputational', label: 'Reputational' },
    { value: 'it_security', label: 'IT Security' },
  ];

  const confidentialityOptions = [
    { value: 'standard', label: 'Standard Confidentiality' },
    { value: 'high', label: 'High Confidentiality' },
    { value: 'restricted', label: 'Restricted Access' },
  ];

  const teamMembers = [
    { value: 'steven_mueller', label: 'Steven Müller' },
    { value: 'jane_smith', label: 'Jane Smith' },
    { value: 'michael_brown', label: 'Michael Brown' },
    { value: 'emily_johnson', label: 'Emily Johnson' },
    { value: 'madhura_sawant', label: 'Madhura Sawant' },
  ];

  const stakeholders = [
    { value: 'alice_johnson', label: 'Alice Johnson' },
    { value: 'david_wilson', label: 'David Wilson' },
    { value: 'emily_davis', label: 'Emily Davis' },
    { value: 'james_thompson', label: 'James Thompson' },
    { value: 'sarah_miller', label: 'Sarah Miller' },
    { value: 'michael_clark', label: 'Michael Clark' },
  ];

  return (
    <motion.div
      className="audit-scope-definition max-w-4xl mx-auto"
      initial="initial"
      animate="animate"
      variants={fadeInUp}
    >
      <Progress value={(currentStep / totalSteps) * 100} className="mb-6" />
      <h2 className="text-3xl font-bold mb-8 text-center text-gray-800">
        Audit Scope Definition
      </h2>

      <form onSubmit={form.handleSubmit(onSubmit)} className="space-y-8">
        <Tabs defaultValue="basic" className="w-full">
          <TabsList className="grid w-full grid-cols-3 mb-8">
            <TabsTrigger value="basic">Basic Information</TabsTrigger>
            <TabsTrigger value="scope">Audit Scope</TabsTrigger>
            <TabsTrigger value="details">Additional Details</TabsTrigger>
          </TabsList>

          {/* Basic Information Tab */}
          <TabsContent value="basic">
            <Card>
              <CardHeader className="flex items-center space-x-2">
                <FileText className="w-6 h-6 text-blue-500" />
                <h3 className="text-xl font-semibold text-gray-600">Basic Audit Information</h3>
              </CardHeader>
              <CardContent className="space-y-6">
                {/* Audit Objective */}
                <FormItem>
                  <FormLabel>Audit Objective</FormLabel>
                  <FormControl>
                    <Controller
                      control={form.control}
                      name="auditObjective"
                      render={({ field }) => (
                        <Input
                          placeholder="e.g., Evaluate Compliance Risk Assessment adequacy"
                          {...field}
                          className="w-full p-2 border rounded"
                        />
                      )}
                    />
                  </FormControl>
                  <FormDescription>
                    Clearly state the primary goal of this audit.
                  </FormDescription>
                  <FormMessage>
                    {form.formState.errors.auditObjective?.message}
                  </FormMessage>
                </FormItem>

                {/* Audit Type */}
                <FormItem>
                  <FormLabel>Audit Type</FormLabel>
                  <FormControl>
                    <Controller
                      control={form.control}
                      name="auditType"
                      render={({ field }) => (
                        <Select
                          {...field}
                          options={auditTypes}
                          placeholder="Select audit type"
                          className="w-full"
                          value={field.value}
                          onChange={field.onChange}
                        />
                      )}
                    />
                  </FormControl>
                  <FormDescription>
                    Choose the category that best describes this audit.
                  </FormDescription>
                  <FormMessage>
                    {form.formState.errors.auditType?.message}
                  </FormMessage>
                </FormItem>

                <div className="grid grid-cols-2 gap-4">
                  {/* Start Date */}
                  <FormItem>
                    <FormLabel>Start Date</FormLabel>
                    <FormControl>
                      <Controller
                        control={form.control}
                        name="auditStartDate"
                        render={({ field }) => (
                          <DatePicker
                            selected={field.value}
                            onChange={(date) => field.onChange(date)}
                            placeholderText="Select start date"
                            minDate={new Date()}
                            isClearable={true}
                            showYearDropdown
                            showMonthDropdown
                          />
                        )}
                      />
                    </FormControl>
                    <FormMessage>
                      {form.formState.errors.auditStartDate?.message}
                    </FormMessage>
                  </FormItem>

                  {/* End Date */}
                  <FormItem>
                    <FormLabel>End Date</FormLabel>
                    <FormControl>
                      <Controller
                        control={form.control}
                        name="auditEndDate"
                        render={({ field }) => (
                          <DatePicker
                            selected={field.value}
                            onChange={(date) => field.onChange(date)}
                            placeholderText="Select end date"
                            minDate={form.watch('auditStartDate') || new Date()}
                            isClearable={true}
                            showYearDropdown
                            showMonthDropdown
                          />
                        )}
                      />
                    </FormControl>
                    <FormMessage>
                      {form.formState.errors.auditEndDate?.message}
                    </FormMessage>
                  </FormItem>
                </div>
              </CardContent>
            </Card>
          </TabsContent>

          {/* Audit Scope Tab */}
          <TabsContent value="scope">
            <Card>
              <CardHeader className="flex items-center space-x-2">
                <Globe className="w-6 h-6 text-green-500" />
                <h3 className="text-xl font-semibold">Audit Scope</h3>
              </CardHeader>
              <CardContent className="space-y-6">

                {/* Audit Scope */}
                <FormItem>
                  <FormLabel>Audit Scope</FormLabel>
                  <FormControl>
                    <Controller
                      control={form.control}
                      name="auditScope"
                      render={({ field }) => (
                        <Textarea
                          {...field}
                          placeholder="Describe the audit scope"
                          className="w-full p-2 border rounded resize-none"
                          rows={4}
                        />
                      )}
                    />
                  </FormControl>
                  <FormDescription>
                    Provide a detailed description of the audit scope.
                  </FormDescription>
                  <FormMessage>
                    {form.formState.errors.auditScope?.message}
                  </FormMessage>
                </FormItem>
                
                {/* Business Units */}
                <FormItem>
                  <FormLabel>Business Units/Departments</FormLabel>
                  <FormControl>
                    <Controller
                      control={form.control}
                      name="businessUnits"
                      render={({ field }) => (
                        <Select
                          isMulti
                          {...field}
                          options={businessUnits}
                          placeholder="Select business units"
                          className="w-full"
                          value={field.value}
                          onChange={field.onChange}
                        />
                      )}
                    />
                  </FormControl>
                  <FormDescription>
                    Select all departments included in the audit scope.
                  </FormDescription>
                  <FormMessage>
                    {form.formState.errors.businessUnits?.message}
                  </FormMessage>
                </FormItem>

                {/* Subsidiaries */}
                <FormItem>
                  <FormLabel>Subsidiaries/Entities</FormLabel>
                  <FormControl>
                    <Controller
                      control={form.control}
                      name="subsidiaries"
                      render={({ field }) => (
                        <Select
                          isMulti
                          {...field}
                          options={subsidiaries}
                          placeholder="Select subsidiaries"
                          className="w-full"
                          value={field.value}
                          onChange={field.onChange}
                        />
                      )}
                    />
                  </FormControl>
                  <FormDescription>
                    Choose the specific entities to be audited.
                  </FormDescription>
                  <FormMessage>
                    {form.formState.errors.subsidiaries?.message}
                  </FormMessage>
                </FormItem>

                {/* Geographic Locations */}
                <FormItem>
                  <FormLabel>Geographic Locations</FormLabel>
                  <FormControl>
                    <Controller
                      control={form.control}
                      name="geographicLocations"
                      render={({ field }) => (
                        <Select
                          isMulti
                          {...field}
                          options={geographicLocations}
                          placeholder="Select locations"
                          className="w-full"
                          value={field.value}
                          onChange={field.onChange}
                        />
                      )}
                    />
                  </FormControl>
                  <FormDescription>
                    Specify the regions where the audit will take place.
                  </FormDescription>
                  <FormMessage>
                    {form.formState.errors.geographicLocations?.message}
                  </FormMessage>
                </FormItem>
              </CardContent>
            </Card>
          </TabsContent>

          {/* Additional Details Tab */}
          <TabsContent value="details">
            <Card>
              <CardHeader className="flex items-center space-x-2">
                <ClipboardList className="w-6 h-6 text-purple-500" />
                <h3 className="text-xl font-semibold">Additional Details</h3>
              </CardHeader>
              <CardContent className="space-y-6">
                {/* Processes */}
                <FormItem>
                  <FormLabel>Processes/Functions</FormLabel>
                  <FormControl>
                    <Controller
                      control={form.control}
                      name="processes"
                      render={({ field }) => (
                        <Select
                          isMulti
                          {...field}
                          options={processes}
                          placeholder="Select processes"
                          className="w-full"
                          value={field.value}
                          onChange={field.onChange}
                        />
                      )}
                    />
                  </FormControl>
                  <FormDescription>
                    Select the specific processes to be examined.
                  </FormDescription>
                  <FormMessage>
                    {form.formState.errors.processes?.message}
                  </FormMessage>
                </FormItem>

                {/* Regulatory Frameworks */}
                <FormItem>
                  <FormLabel>Regulatory Frameworks/Standards</FormLabel>
                  <FormControl>
                    <Controller
                      control={form.control}
                      name="regulatoryFrameworks"
                      render={({ field }) => (
                        <Select
                          isMulti
                          {...field}
                          options={regulatoryFrameworks}
                          placeholder="Select frameworks"
                          className="w-full"
                          value={field.value}
                          onChange={field.onChange}
                        />
                      )}
                    />
                  </FormControl>
                  <FormDescription>
                    Choose the relevant regulatory standards for this audit.
                  </FormDescription>
                  <FormMessage>
                    {form.formState.errors.regulatoryFrameworks?.message}
                  </FormMessage>
                </FormItem>

                {/* Risk Areas */}
                <FormItem>
                  <FormLabel>Risk Areas/Focus Areas</FormLabel>
                  <FormControl>
                    <Controller
                      control={form.control}
                      name="riskAreas"
                      render={({ field }) => (
                        <Select
                          isMulti
                          {...field}
                          options={riskAreas}
                          placeholder="Select risk areas"
                          className="w-full"
                          value={field.value}
                          onChange={field.onChange}
                        />
                      )}
                    />
                  </FormControl>
                  <FormDescription>
                    Identify the key risk areas to prioritize.
                  </FormDescription>
                  <FormMessage>
                    {form.formState.errors.riskAreas?.message}
                  </FormMessage>
                </FormItem>

                {/* Audit Team */}
                <FormItem>
                  <FormLabel>Audit Team Members</FormLabel>
                  <FormControl>
                    <Controller
                      control={form.control}
                      name="auditTeam"
                      render={({ field }) => (
                        <Select
                          isMulti
                          {...field}
                          options={teamMembers}
                          placeholder="Select team members"
                          className="w-full"
                          value={field.value}
                          onChange={field.onChange}
                        />
                      )}
                    />
                  </FormControl>
                  <FormDescription>
                    Select the team members who will conduct the audit.
                  </FormDescription>
                  <FormMessage>
                    {form.formState.errors.auditTeam?.message}
                  </FormMessage>
                </FormItem>

                {/* Stakeholders */}
                <FormItem>
                  <FormLabel>Stakeholders</FormLabel>
                  <FormControl>
                    <Controller
                      control={form.control}
                      name="stakeholders"
                      render={({ field }) => (
                        <Select
                          isMulti
                          {...field}
                          options={stakeholders}
                          placeholder="Select stakeholders"
                          className="w-full"
                          value={field.value}
                          onChange={field.onChange}
                        />
                      )}
                    />
                  </FormControl>
                  <FormDescription>
                    Select stakeholders involved in the audit.
                  </FormDescription>
                  <FormMessage>
                    {form.formState.errors.stakeholders?.message}
                  </FormMessage>
                </FormItem>

                {/* Confidentiality */}
                <FormItem>
                  <FormLabel>Confidentiality Requirements</FormLabel>
                  <FormControl>
                    <Controller
                      control={form.control}
                      name="confidentiality"
                      render={({ field }) => (
                        <Select
                          {...field}
                          options={confidentialityOptions}
                          placeholder="Select confidentiality level"
                          className="w-full"
                          value={field.value}
                          onChange={field.onChange}
                        />
                      )}
                    />
                  </FormControl>
                  <FormDescription>
                    Specify the level of confidentiality for this audit.
                  </FormDescription>
                  <FormMessage>
                    {form.formState.errors.confidentiality?.message}
                  </FormMessage>
                </FormItem>

                {/* Expected Deliverables */}
                <FormItem>
                  <FormLabel>Expected Deliverables</FormLabel>
                  <FormControl>
                    <Controller
                      control={form.control}
                      name="expectedDeliverables"
                      render={({ field }) => (
                        <Textarea
                          {...field}
                          placeholder="List expected audit outputs"
                          className="w-full p-2 border rounded resize-none"
                          rows={4}
                        />
                      )}
                    />
                  </FormControl>
                  <FormDescription>
                    List the specific outputs expected from this audit.
                  </FormDescription>
                  <FormMessage>
                    {form.formState.errors.expectedDeliverables?.message}
                  </FormMessage>
                </FormItem>
              </CardContent>
            </Card>
          </TabsContent>
        </Tabs>

        <TooltipProvider>
          <Tooltip>
            <TooltipTrigger asChild>
              <div>
                {/* Wrap in a div instead of directly using Button as trigger */}
                <Button
                  type="submit"
                  className="w-full mt-8 py-3 bg-blue-600 hover:bg-blue-700 text-white rounded-lg shadow-lg transition duration-300"
                >
                  Save and Continue
                </Button>
              </div>
            </TooltipTrigger>
            <TooltipContent>
              <p>Save your audit scope definition and proceed to the next step</p>
            </TooltipContent>
          </Tooltip>
        </TooltipProvider>
      </form>
    </motion.div>
  );
};

export default AuditScopeDefinition;
