import React, { forwardRef } from 'react';
import { 
  Brain, 
  Shield, 
  Target, 
  Clock,
  ArrowRight, 
  CheckCircle,
  AlertTriangle
} from 'lucide-react';
import { motion } from 'framer-motion';

const ProcessAndGovernance = forwardRef((props, ref) => {
  const outcomes = [
    {
      title: "Accelerate Time-to-Compliance",
      icon: Clock,
      color: "purple",
      description: "Reduce compliance implementation time from months to weeks",
      benefits: [
        "AI-powered process design wizard",
        "Industry-specific templates & frameworks",
        "Rapid deployment workflows",
        "Built-in best practices"
      ]
    },
    {
      title: "Navigate AI Regulations",
      icon: Shield,
      color: "blue",
      description: "Future-proof your AI systems with automated governance",
      benefits: [
        "EU AI Act readiness assessment",
        "Risk-based AI system classification",
        "Automated documentation generation",
        "Continuous monitoring setup"
      ]
    },
    {
      title: "Ensure Sustainable Compliance",
      icon: Target,
      color: "green",
      description: "Build scalable processes that adapt to regulatory changes",
      benefits: [
        "Process effectiveness tracking",
        "Automated risk assessments",
        "Change impact analysis",
        "Regulatory update monitoring"
      ]
    }
  ];

  return (
    <section ref={ref} className="py-24 bg-gradient-to-b from-white to-gray-50">
      <div className="max-w-7xl mx-auto px-6 lg:px-8">
        {/* Main Section */}
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          whileInView={{ opacity: 1, y: 0 }}
          viewport={{ once: true }}
          className="text-center mb-16"
        >
          <h2 className="text-4xl font-bold text-gray-900 mb-6">
            Design & Govern Complex Processes{" "}
            <span className="text-transparent bg-clip-text bg-gradient-to-r from-purple-600 to-blue-600">
              with Confidence
            </span>
          </h2>
          <p className="text-xl text-gray-600 max-w-3xl mx-auto">
            As AI regulations evolve, combine the speed of automation with the precision of 
            governance to build sustainable compliance processes
          </p>
        </motion.div>

        {/* Outcomes Grid */}
        <div className="grid grid-cols-1 md:grid-cols-3 gap-8 mb-16">
          {outcomes.map((outcome, index) => (
            <motion.div
              key={index}
              initial={{ opacity: 0, y: 20 }}
              whileInView={{ opacity: 1, y: 0 }}
              viewport={{ once: true }}
              transition={{ delay: index * 0.2 }}
              className="bg-white rounded-xl p-8 shadow-sm hover:shadow-lg transition-all duration-300"
            >
              <div className={`inline-flex items-center justify-center p-3 rounded-xl bg-${outcome.color}-50 mb-6`}>
                <outcome.icon className={`w-6 h-6 text-${outcome.color}-600`} />
              </div>
              
              <h3 className="text-xl font-bold text-gray-900 mb-4">
                {outcome.title}
              </h3>
              
              <p className="text-gray-600 mb-6">
                {outcome.description}
              </p>

              <ul className="space-y-3">
                {outcome.benefits.map((benefit, i) => (
                  <li key={i} className="flex items-start">
                    <CheckCircle className={`w-5 h-5 text-${outcome.color}-500 mr-2 flex-shrink-0 mt-0.5`} />
                    <span className="text-gray-600">{benefit}</span>
                  </li>
                ))}
              </ul>
            </motion.div>
          ))}
        </div>

        {/* AI Governance Alert */}
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          whileInView={{ opacity: 1, y: 0 }}
          viewport={{ once: true }}
          className="bg-gradient-to-r from-purple-50 to-blue-50 rounded-xl p-8 relative overflow-hidden"
        >
          <div className="max-w-3xl mx-auto text-center">
            <div className="flex items-center justify-center mb-6">
              <AlertTriangle className="w-6 h-6 text-purple-600 mr-2" />
              <span className="text-sm font-medium text-purple-800">
                New AI Regulations Alert
              </span>
            </div>
            <h3 className="text-2xl font-bold text-gray-900 mb-4">
              Getting Ready for the EU AI Act?
            </h3>
            <p className="text-gray-600 mb-8">
              Start preparing now with our AI governance toolkit:
            </p>
            <ul className="text-gray-600 mb-8 space-y-2">
              <li>• Get a head start on compliance requirements.
              Build trust with your stakeholders</li>
              <li>• No long PDFs, no endless emails—just a dynamic, one-stop hub for AI regulations in healthcare, pharma, biotech, and medtech.</li>
            </ul>
            <motion.a 
              href="https://adi18k4liww.typeform.com/to/JuMco5Ug"
              target="_blank"
              rel="noopener noreferrer" 
              className="inline-flex items-center px-6 py-3 rounded-lg bg-gradient-to-r from-purple-600 to-blue-600 text-white font-medium hover:from-purple-700 hover:to-blue-700 transition-all duration-200 hover:shadow-lg"
              whileHover={{ scale: 1.02 }}
              whileTap={{ scale: 0.98 }}
            >
              Get the AI Governance Guide
            </motion.a>
          </div>        </motion.div>
      </div>
    </section>
  );
});

ProcessAndGovernance.displayName = 'ProcessAndGovernance';

export default ProcessAndGovernance;