import React, { useState, useEffect, useCallback } from 'react';
import { Input } from '../ui/Input';
import { Button } from '../ui/Button';
import { PlusCircle, Trash2, Bot } from 'lucide-react';
import AIThinkingAnimation from '../AIThinkingAnimation';

const StakeholdersStep = ({ data, onChange, aiSuggestions, isLoading }) => {
  const [newStakeholder, setNewStakeholder] = useState('');
  const [userStakeholders, setUserStakeholders] = useState(data || []);
  const [suggestedStakeholders, setSuggestedStakeholders] = useState([]);

  useEffect(() => {
    if (Array.isArray(aiSuggestions)) {
      setSuggestedStakeholders(aiSuggestions.filter(suggestion => 
        !userStakeholders.includes(suggestion)
      ));
    }
  }, [aiSuggestions, userStakeholders]);

  const handleAddStakeholder = useCallback(() => {
    if (newStakeholder.trim()) {
      const updatedStakeholders = [...userStakeholders, newStakeholder.trim()];
      setUserStakeholders(updatedStakeholders);
      onChange(updatedStakeholders);
      setNewStakeholder('');
    }
  }, [newStakeholder, userStakeholders, onChange]);

  const handleRemoveStakeholder = useCallback((index) => {
    const updatedStakeholders = userStakeholders.filter((_, i) => i !== index);
    setUserStakeholders(updatedStakeholders);
    onChange(updatedStakeholders);
  }, [userStakeholders, onChange]);

  const handleAcceptSuggestion = useCallback((acceptedStakeholder) => {
    const updatedUserStakeholders = [...userStakeholders, acceptedStakeholder];
    setUserStakeholders(updatedUserStakeholders);
    onChange(updatedUserStakeholders);
    setSuggestedStakeholders(prevSuggestions => 
      prevSuggestions.filter(suggestion => suggestion !== acceptedStakeholder)
    );
  }, [userStakeholders, onChange]);

  return (
    <div className="space-y-4">
      {/* AI-Suggested Stakeholders */}
      <div>
        <h3 className="text-lg font-medium flex items-center text-purple-500">
          <Bot className="w-5 h-5 mr-2 text-purple-500" />
          AI-Suggested Stakeholders
        </h3>
        <div className="space-y-2 mt-2">
          {isLoading ? (
            <div className="my-8 p-4 bg-purple-50 rounded-lg shadow-inner">
              <AIThinkingAnimation />
              <p className="text-center text-purple-700 mt-4 font-medium">
                AI is generating stakeholder suggestions...
              </p>
            </div>
          ) : suggestedStakeholders.length > 0 ? (
            suggestedStakeholders.map((stakeholder, index) => (
              <div key={index} className="flex items-center bg-purple-50 p-2 rounded">
                <span className="flex-grow text-purple-700">{stakeholder}</span>
                <Button
                  variant="outline"
                  size="sm"
                  className="ml-2"
                  onClick={() => handleAcceptSuggestion(stakeholder)}
                >
                  Accept
                </Button>
              </div>
            ))
          ) : (
            <p className="text-gray-500 italic">No AI suggestions available.</p>
          )}
        </div>
      </div>

      {/* User Stakeholders */}
      <div>
        <h3 className="text-lg font-medium text-gray-800">Added Stakeholders</h3>
        <div className="space-y-2 mt-2">
          {userStakeholders.map((stakeholder, index) => (
            <div key={index} className="flex items-center bg-gray-100 p-2 rounded">
              <span className="flex-grow text-gray-700">{stakeholder}</span>
              <Button
                variant="ghost"
                size="icon"
                className="text-red-500 hover:text-red-700 hover:bg-red-100"
                onClick={() => handleRemoveStakeholder(index)}
              >
                <Trash2 className="w-4 h-4" />
              </Button>
            </div>
          ))}
        </div>
      </div>

      {/* Add New Stakeholder */}
      <div className="flex items-center mt-4">
        <Input
          placeholder="Add a new stakeholder"
          value={newStakeholder}
          onChange={(e) => setNewStakeholder(e.target.value)}
        />
        <Button
          variant="outline"
          onClick={handleAddStakeholder}
          className="ml-2 bg-purple-100 text-purple-700 hover:bg-purple-200"
        >
          <PlusCircle className="w-4 h-4 mr-1" />
          Add
        </Button>
      </div>
    </div>
  );
};

export default StakeholdersStep;