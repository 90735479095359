import React from 'react';
import { useForm } from 'react-hook-form';
import { Input } from '../components/ui/Input';
import { Textarea } from '../components/ui/Textarea';
import { Button } from '../components/ui/Button';

const Reporting = ({ data, updateData }) => {
  const { register, handleSubmit, formState: { errors } } = useForm({
    defaultValues: data
  });

  const onSubmit = (formData) => {
    updateData(formData);
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)} className="space-y-4">
      <h3 className="text-xl font-semibold mb-4">Audit Reporting</h3>
      
      <div>
        <label className="block mb-2">Executive Summary</label>
        <Textarea
          {...register('executiveSummary', { required: 'Executive summary is required' })}
          placeholder="Provide an executive summary of the audit"
          rows={4}
        />
        {errors.executiveSummary && <p className="text-red-500 text-sm mt-1">{errors.executiveSummary.message}</p>}
      </div>

      <div>
        <label className="block mb-2">Key Findings</label>
        <Textarea
          {...register('keyFindings', { required: 'Key findings are required' })}
          placeholder="List the key findings of the audit"
          rows={4}
        />
        {errors.keyFindings && <p className="text-red-500 text-sm mt-1">{errors.keyFindings.message}</p>}
      </div>

      <div>
        <label className="block mb-2">Recommendations</label>
        <Textarea
          {...register('recommendations', { required: 'Recommendations are required' })}
          placeholder="Provide recommendations based on the audit findings"
          rows={4}
        />
        {errors.recommendations && <p className="text-red-500 text-sm mt-1">{errors.recommendations.message}</p>}
      </div>

      <Button type="submit">Generate Report</Button>
    </form>
  );
};

export default Reporting;