import React, { useState, useEffect } from 'react';
import { Input } from '../ui/Input';
import { Textarea } from '../ui/Textarea';
import { Button } from '../ui/Button';
import { Select } from '../ui/Select';
import { PlusCircle, Trash2, CheckCircle, AlertTriangle, InfoIcon } from 'lucide-react';
import { Tooltip } from '../ui/Tooltip';
import { predefinedComplianceActivities, activityCategories, activityFrequencies } from './predefinedComplianceActivities';

const ComplianceActivitiesSection = ({ activities: initialActivities, onUpdate, aiSuggestions, riskProfile, maturityLevel }) => {
  const [activities, setActivities] = useState(initialActivities || []);
  const [newActivity, setNewActivity] = useState({ name: '', description: '', frequency: '', responsible: '', category: '' });
  const [suggestedActivities, setSuggestedActivities] = useState([]);
  const [error, setError] = useState(null);

  useEffect(() => {
    let filteredSuggestions = [];
    if (aiSuggestions && aiSuggestions.complianceActivities && aiSuggestions.complianceActivities.length > 0) {
      filteredSuggestions = aiSuggestions.complianceActivities.filter(activity => {
        if (riskProfile === 'Low Risk' && maturityLevel === 'Initial') {
          return activity.priority === 'Low';
        }
        if (riskProfile === 'Medium Risk' || maturityLevel === 'Managed') {
          return ['Low', 'Medium'].includes(activity.priority);
        }
        return true;
      });
    }
    
    if (filteredSuggestions.length === 0) {
      filteredSuggestions = predefinedComplianceActivities;
    }
    
    const uniqueSuggestions = filteredSuggestions.filter(suggestion => 
      !activities.some(activity => activity.name === suggestion.name)
    );
    
    setSuggestedActivities(uniqueSuggestions);
  }, [aiSuggestions, riskProfile, maturityLevel, activities]);

  useEffect(() => {
    if (typeof onUpdate === 'function') {
      try {
        onUpdate(activities);
      } catch (err) {
        console.error('Error in onUpdate:', err);
        setError('An error occurred while updating activities. Please try again.');
      }
    } else {
      console.warn('onUpdate is not a function. Activities will not be synced with parent component.');
    }
  }, [activities, onUpdate]);

  const handleAddActivity = () => {
    if (newActivity.name && newActivity.description && newActivity.frequency && newActivity.responsible && newActivity.category) {
      setActivities(prevActivities => [...prevActivities, { ...newActivity, id: Date.now() }]);
      setNewActivity({ name: '', description: '', frequency: '', responsible: '', category: '' });
    }
  };

  const handleRemoveActivity = (id) => {
    setActivities(prevActivities => prevActivities.filter(activity => activity.id !== id));
  };

  const handleUpdateActivity = (id, field, value) => {
    setActivities(prevActivities => prevActivities.map(activity => 
      activity.id === id ? { ...activity, [field]: value } : activity
    ));
  };

  const handleUseSuggestedActivity = (suggestedActivity) => {
    setNewActivity({
      name: suggestedActivity.name,
      description: suggestedActivity.description,
      category: suggestedActivity.category,
      frequency: suggestedActivity.frequency || '',
      responsible: '',
    });
  };

  return (
    <div className="space-y-6">
      <div className="flex items-center justify-between">
        <h3 className="text-xl font-semibold text-green-700 flex items-center">
          <CheckCircle className="mr-2" /> Compliance Activities
        </h3>
        <Tooltip content="Compliance activities are specific tasks or processes that an organization implements to ensure adherence to regulatory requirements and internal policies in the healthcare, pharmaceutical, and biotech industries.">
          <InfoIcon className="text-gray-400 hover:text-gray-600 cursor-pointer" />
        </Tooltip>
      </div>

      {error && (
        <div className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative" role="alert">
          <strong className="font-bold">Error!</strong>
          <span className="block sm:inline"> {error}</span>
        </div>
      )}
      
      {activities.length > 0 ? (
        <div className="space-y-4">
          <h4 className="text-lg font-medium text-gray-700">Planned Activities</h4>
          {activities.map((activity) => (
            <div key={activity.id} className="bg-green-50 p-4 rounded-lg space-y-2">
              <div className="flex items-center justify-between">
                <Input 
                  value={activity.name} 
                  onChange={(e) => handleUpdateActivity(activity.id, 'name', e.target.value)}
                  className="font-medium text-green-700"
                />
                <Button variant="ghost" onClick={() => handleRemoveActivity(activity.id)}>
                  <Trash2 className="h-4 w-4 text-red-500" />
                </Button>
              </div>
              <Textarea 
                value={activity.description}
                onChange={(e) => handleUpdateActivity(activity.id, 'description', e.target.value)}
                rows={2}
              />
              <div className="flex space-x-2">
                <Select 
                  value={activity.frequency}
                  onChange={(e) => handleUpdateActivity(activity.id, 'frequency', e.target.value)}
                >
                  <option value="">Select Frequency</option>
                  {activityFrequencies.map((freq) => (
                    <option key={freq} value={freq}>{freq}</option>
                  ))}
                </Select>
                <Input 
                  value={activity.responsible}
                  onChange={(e) => handleUpdateActivity(activity.id, 'responsible', e.target.value)}
                  placeholder="Responsible party"
                />
                <Select 
                  value={activity.category}
                  onChange={(e) => handleUpdateActivity(activity.id, 'category', e.target.value)}
                >
                  <option value="">Select Category</option>
                  {activityCategories.map((category) => (
                    <option key={category} value={category}>{category}</option>
                  ))}
                </Select>
              </div>
            </div>
          ))}
        </div>
      ) : (
        <div className="bg-yellow-50 border border-yellow-100 p-4 rounded-lg flex items-center">
          <AlertTriangle className="text-yellow-500 mr-2" />
          <p className="text-sm text-yellow-700">No activities added yet. Use the form below or select from suggested activities to get started.</p>
        </div>
      )}
      
      {/* New Activity Form */}
      <div className="bg-white p-4 rounded-lg shadow space-y-4">
        <h4 className="text-lg font-medium text-gray-700">Add New Activity</h4>
        <Input 
          value={newActivity.name}
          onChange={(e) => setNewActivity({...newActivity, name: e.target.value})}
          placeholder="Activity name"
        />
        <Textarea 
          value={newActivity.description}
          onChange={(e) => setNewActivity({...newActivity, description: e.target.value})}
          placeholder="Describe the activity"
          rows={3}
        />
        <div className="flex space-x-2">
          <Select 
            value={newActivity.frequency}
            onChange={(e) => setNewActivity({...newActivity, frequency: e.target.value})}
          >
            <option value="">Select Frequency</option>
            {activityFrequencies.map((freq) => (
              <option key={freq} value={freq}>{freq}</option>
            ))}
          </Select>
          <Input 
            value={newActivity.responsible}
            onChange={(e) => setNewActivity({...newActivity, responsible: e.target.value})}
            placeholder="Responsible party"
          />
          <Select 
            value={newActivity.category}
            onChange={(e) => setNewActivity({...newActivity, category: e.target.value})}
          >
            <option value="">Select Category</option>
            {activityCategories.map((category) => (
              <option key={category} value={category}>{category}</option>
            ))}
          </Select>
        </div>
        <Button onClick={handleAddActivity} variant="outline" className="w-full">
          <PlusCircle className="h-4 w-4 mr-2" /> Add Activity
        </Button>
      </div>

      {/* Suggested Activities */}
      {suggestedActivities.length > 0 && (
        <div className="bg-blue-50 p-4 rounded-lg space-y-4">
          <h4 className="text-lg font-medium text-blue-700">Suggested Activities</h4>
          {suggestedActivities.map((suggestedActivity) => (
            <div key={suggestedActivity.id} className="bg-white p-3 rounded-md shadow-sm">
              <p className="font-medium text-gray-700">{suggestedActivity.name}</p>
              <p className="text-sm text-gray-600 mt-1">{suggestedActivity.description}</p>
              <div className="flex justify-between items-center mt-2">
                <span className="text-xs text-blue-600 font-medium">{suggestedActivity.category}</span>
                <Button 
                  onClick={() => handleUseSuggestedActivity(suggestedActivity)} 
                  variant="outline" 
                  size="sm"
                >
                  Use This Activity
                </Button>
              </div>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default ComplianceActivitiesSection;