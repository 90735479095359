import React, { useState, useRef, useEffect } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { Button } from '../components/ui/Button';
import { Input } from '../components/ui/Input';
import { Textarea } from '../components/ui/Textarea';
import { Card, CardHeader, CardContent } from '../components/ui/Card';
import { Tabs, TabsList, TabsTrigger, TabsContent } from '../components/ui/Tabs';
import { Upload, Camera, MessageSquare } from 'lucide-react';
import LokCO from '../components/LokCO';

const FieldworkVerification = ({ data, updateData, setStepValidity }) => {
  const { control, handleSubmit, watch } = useForm({ defaultValues: data });
  const [activeTab, setActiveTab] = useState('documents');
  const [showLokCO, setShowLokCO] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null);
  const fileInputRef = useRef(null);
  const cameraInputRef = useRef(null);

  const interviewNotes = watch('interviewNotes');

  useEffect(() => {
    const hasNotes = interviewNotes && interviewNotes.trim().length > 0;
    const hasFile = selectedFile !== null;
    const newHasContent = hasNotes || hasFile;
    setStepValidity(newHasContent);
  }, [interviewNotes, selectedFile, setStepValidity]);

  const onSubmit = (formData) => {
    updateData(formData);
  };

  const handleFileUpload = (event) => {
    const file = event.target.files[0];
    setSelectedFile(file);
    // TODO: Implement file upload logic
  };

  const handlePhotoCapture = (event) => {
    const file = event.target.files[0];
    setSelectedFile(file);
    // TODO: Implement photo capture logic
  };

  const handleAISummary = () => {
    if (selectedFile) {
      // TODO: Implement AI summary logic
      console.log('Generating AI summary for', selectedFile.name);
    }
  };

  const toggleLokCO = () => {
    setShowLokCO(!showLokCO);
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)} className="space-y-6">
      <h2 className="text-2xl font-bold mb-4">Fieldwork and Verification</h2>

      <Tabs value={activeTab} onValueChange={setActiveTab}>
        <TabsList>
          <TabsTrigger value="documents">Documents</TabsTrigger>
          <TabsTrigger value="notes">Notes</TabsTrigger>
          <TabsTrigger value="photos">Photos</TabsTrigger>
        </TabsList>

        <TabsContent value="documents">
          <Card>
            <CardHeader>
              <h3 className="text-lg font-semibold">Document Upload</h3>
            </CardHeader>
            <CardContent>
              <Input
                type="file"
                onChange={handleFileUpload}
                ref={fileInputRef}
                className="mb-4"
              />
              {selectedFile && (
                <div className="mb-4">
                  <p>Selected file: {selectedFile.name}</p>
                  <Button onClick={handleAISummary} className="mt-2">
                    Generate AI Summary
                  </Button>
                </div>
              )}
              <Button onClick={() => fileInputRef.current.click()}>
                <Upload className="mr-2" />
                Upload Document
              </Button>
            </CardContent>
          </Card>
        </TabsContent>

        <TabsContent value="notes">
          <Card>
            <CardHeader>
              <h3 className="text-lg font-semibold">Interview Notes</h3>
            </CardHeader>
            <CardContent>
              <Controller
                name="interviewNotes"
                control={control}
                render={({ field }) => (
                  <Textarea
                    {...field}
                    placeholder="Enter interview notes here..."
                    rows={10}
                    className="mb-4"
                  />
                )}
              />
              <Button onClick={toggleLokCO}>
                <MessageSquare className="mr-2" />
                Ask LokCO to Summarize
              </Button>
            </CardContent>
          </Card>
        </TabsContent>

        <TabsContent value="photos">
          <Card>
            <CardHeader>
              <h3 className="text-lg font-semibold">Photo Capture</h3>
            </CardHeader>
            <CardContent>
              <Input
                type="file"
                accept="image/*"
                capture="environment"
                onChange={handlePhotoCapture}
                ref={cameraInputRef}
                className="mb-4"
              />
              <Button onClick={() => cameraInputRef.current.click()}>
                <Camera className="mr-2" />
                Capture Photo
              </Button>
            </CardContent>
          </Card>
        </TabsContent>
      </Tabs>

      <Button type="submit" className="mt-6">
        Save and Continue
      </Button>

      {showLokCO && (
        <LokCO
          message="How can I assist with summarizing your fieldwork?"
          onClose={() => setShowLokCO(false)}
        />
      )}
    </form>
  );
};

export default FieldworkVerification;