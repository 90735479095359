import React from 'react';

const AIThinkingAnimation = () => {
  return (
    <div className="flex justify-center items-center space-x-2 h-20">
      <div className="w-4 h-4 bg-purple-500 rounded-full animate-pulse"></div>
      <div
        className="w-4 h-4 bg-purple-500 rounded-full animate-pulse"
        style={{ animationDelay: '0.2s' }}
      ></div>
      <div
        className="w-4 h-4 bg-purple-500 rounded-full animate-pulse"
        style={{ animationDelay: '0.4s' }}
      ></div>
    </div>
  );
};

export default AIThinkingAnimation;
