import { useState, useCallback } from 'react';
import axios from 'axios';

const useAISuggestions = () => {
  const [aiSuggestions, setAiSuggestions] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);

  const fetchAISuggestions = useCallback(
    async (params) => {
      setIsLoading(true);
      setError(null);
  
      try {
        let response;
  
        // Check if params contain audit objective (new wizard)
        if (params.auditObjective) {
          console.log('Fetching AI suggestions with audit scope data:', params);
  
          // Send the params directly as the request body
          response = await axios.post('/api/ai/generate-suggestions', params);
  
          // Validate the response structure for the new wizard
          const requiredFields = [
            'risks', 
            'controls', 
            'recommendations', 
            'auditSteps',
            'evidenceRequests',
            'documentAnalysis'
          ];
  
          const missingFields = requiredFields.filter(
            (field) => !response.data.hasOwnProperty(field)
          );
  
          if (missingFields.length > 0) {
            throw new Error(
              `AI suggestions are missing required fields: ${missingFields.join(', ')}`
            );
          }
        } else {
          // Existing wizard parameters
          const { processType, riskProfile, maturityLevel, additionalData } = params;
          console.log('Fetching AI suggestions with process parameters:', params);

          // API call for the existing wizard
          response = await axios.post('/api/ai/generate-suggestions', {
            processType,
            riskProfile,
            maturityLevel,
            additionalData,
          });

          // Validate the response structure for the existing wizard
          const requiredFields = [
            'stepSpecific',
            'riskAreas',
            'kpis',
            'complianceActivities',
            'riskDescriptions',
            'controlSuggestions',
            'treatmentStrategies',
            'relevantStandards',
            'evidenceRequests',
            'documentAnalysis'
          ];

          const missingFields = requiredFields.filter(
            (field) => !response.data.hasOwnProperty(field)
          );

          if (missingFields.length > 0) {
            throw new Error(
              `AI suggestions are missing required fields: ${missingFields.join(', ')}`
            );
          }

          // Validate step-specific suggestions
          const requiredSteps = [
            'ObjectivesStep',
            'RegulatoryRequirementsStep',
            'ProcessScopeStep',
            'ProcessExclusionsStep',
            'StakeholdersStep',
            'DepartmentsStep',
          ];

          const missingSteps = requiredSteps.filter(
            (step) => !response.data.stepSpecific.hasOwnProperty(step)
          );

          if (missingSteps.length > 0) {
            throw new Error(
              `Step-specific suggestions are missing for: ${missingSteps.join(', ')}`
            );
          }

          // Validate that StakeholdersStep and DepartmentsStep are arrays
          if (!Array.isArray(response.data.stepSpecific.StakeholdersStep)) {
            throw new Error('StakeholdersStep suggestions must be an array');
          }
          if (!Array.isArray(response.data.stepSpecific.DepartmentsStep)) {
            throw new Error('DepartmentsStep suggestions must be an array');
          }
        }

        console.log('API Response:', response.data);
        setAiSuggestions(response.data);
      } catch (err) {
        console.error('Error fetching AI suggestions:', err);
        setError(err.message || 'An error occurred while fetching suggestions');
      } finally {
        setIsLoading(false);
      }
    },
    []
  );

  const updateAISuggestions = useCallback(
    async (params) => {
      await fetchAISuggestions(params);
    },
    [fetchAISuggestions]
  );

  const analyzeDocument = useCallback(async (document) => {
    setIsLoading(true);
    setError(null);

    try {
      const response = await axios.post('/api/ai/analyze-document', { document });
      
      setAiSuggestions(prevSuggestions => ({
        ...prevSuggestions,
        documentAnalysis: [
          ...(prevSuggestions?.documentAnalysis || []),
          response.data
        ]
      }));

      return response.data;
    } catch (err) {
      console.error('Error analyzing document:', err);
      setError(err.message || 'An error occurred while analyzing the document');
    } finally {
      setIsLoading(false);
    }
  }, []);

  return { aiSuggestions, updateAISuggestions, analyzeDocument, isLoading, error };
};

export default useAISuggestions;